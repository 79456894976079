//@ts-nocheck
import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font, Svg, G, Path, Defs, ClipPath, Rect } from '@react-pdf/renderer';
import BackgroundOndas from './BackgroundOndas';
import BackgroundColono from './BackgroundColono';
import BackgroundEDA from './BackgroundEDA';

const font = require('../../assets/fonts/Roboto-Bold.ttf');

Font.register({ family: 'Roboto-Bold', src: font });

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    paddingTop: 30,
    justifyContent: 'space-between'
  },
  // section: {
  //   margin: 10,
  //   padding: 10,
  //   flexGrow: 1
  // },
  container: {
    marginHorizontal: 35,
  },
  header: {
    flexDirection: 'row',
    paddingBottom: 20,
    alignItems: 'center',
  },
  clinica: {
    marginRight: 50
  },
  textClinica: {
    fontSize: 6,
    textAlign: 'right',
    marginBottom: 2
  },
  titulo: {
    fontSize: 12,
    fontFamily: 'Roboto-Bold',
    textAlign: 'center',
  },
  paciente: {
    border: '1pt solid #c1c2c2',
    margin: '5px 0',
    flexDirection: 'row',
    padding: 2
  },
  label: {
    fontSize: 8,
    fontFamily: 'Roboto-Bold',
  },
  text: {
    fontSize: 8,
  },
  flex: {
    flexDirection: 'row',
  },
  subtitulo: {
    fontFamily: 'Roboto-Bold',
    fontSize: 10,
    // fontWeight: 'bold',
    margin: '0 1px'
  },
  section: {
    marginBottom: 60
  },
  headerSection: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  linha: {
    height: 1,
    backgroundColor: '#4CDCDB'
  },
  assinatura: {
    marginLeft: '62%',
    width: 150,
    marginBottom: 30
  },
  textFooter: {
    fontSize: 6,
    textAlign: 'center'
  },
  icon: { 
    height: 52, 
    width: 52
  },
  conteudoSection: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  imgSection: {
    height: 112, 
    width: 112
  }
});

interface PdfLaudoProps {
  conteudo: any;
}

function background(index: number, conteudo: any) {
  switch (index) {
    case 1:
      return <BackgroundOndas color={conteudo.template.color} />
    case 2:
      return <BackgroundEDA color={conteudo.template.color} />
    case 3:
      return <BackgroundColono color={conteudo.template.color} />
    default:
      break;
  }
}

const PdfLaudo = ({ conteudo }: PdfLaudoProps) => (
  <Document>
    <Page style={styles.page}>
      {
        background(Number(conteudo.template.backgroundTemplate), conteudo)
      }
      <View>
        <View style={styles.container}>
          <View style={styles.header}>
            { conteudo.template.icon !== null ? <Image src={conteudo.template.icon} style={styles.icon} /> : <></>}
            <View style={styles.clinica}>
              <Text style={[styles.textClinica, { fontSize: 8 }]}>{conteudo.template.cabecalho.clinica}</Text>
              <Text style={styles.textClinica}>{conteudo.template.cabecalho.endereco}</Text>
              <Text style={styles.textClinica}>{conteudo.template.cabecalho.telefone}</Text>
            </View>
          </View>
          <Text style={styles.titulo}>LAUDO DE {conteudo.exame_realizado === "EDA" ? "ENDOSCOPIA DIGESTIVA ALTA" : "COLONOSCOPIA"}</Text>
          <View style={styles.paciente}>
            <View style={{ marginRight: 150 }}>
              <View style={styles.flex}><Text style={styles.label}>Paciente: </Text><Text style={styles.text}>{conteudo.nome}</Text></View>
              <View style={styles.flex}><Text style={styles.label}>Sexo: </Text><Text style={styles.text}>{conteudo.sexo}</Text></View>
              <View style={styles.flex}><Text style={styles.label}>Idade: </Text><Text style={styles.text}>{conteudo.idade}</Text></View>
              <View style={styles.flex}><Text style={styles.label}>Data do exame: </Text><Text style={styles.text}>{conteudo.dt_exame}</Text></View>
              <View style={styles.flex}><Text style={styles.label}>Indicação: </Text><Text style={styles.text}>{conteudo.indicacoes}</Text></View>
            </View>
            <View>
              <View style={styles.flex}><Text style={styles.label}>Cadastro: </Text><Text style={styles.text}>{conteudo.id_paciente}</Text></View>
              <View style={styles.flex}><Text style={styles.label}>Convênio: </Text><Text style={styles.text}>{conteudo.convenio ? conteudo.convenio : "Não"}</Text></View>
              <View style={styles.flex}><Text style={styles.label}>Médico Solicitante: </Text><Text style={styles.text}>{conteudo.medico_solicitante}</Text></View>
              <View style={styles.flex}><Text style={styles.label}>Aparelho/Modelo: </Text><Text style={styles.text}>{conteudo.aparelho}</Text></View>
            </View>
          </View>
          <View style={{ padding: 2 }}>
            <View style={styles.flex}><Text style={styles.label}>Sedoanalgesia: </Text><Text style={styles.text}>{conteudo.sedoanalgesia}</Text></View>
            <View style={[styles.flex, { marginBottom: 15 }]}><Text style={styles.label}>Passagem no cricofaríngeo: </Text><Text style={styles.text}>{conteudo.cricofaringeo}</Text></View>

            <View style={styles.section}>
              <View style={styles.headerSection}>
                <View style={[styles.linha, { width: 15, backgroundColor: conteudo.template.color }]}></View>
                <Text style={styles.subtitulo}>ESÔFAGO</Text>
                <View style={[styles.linha, { width: '87%', backgroundColor: conteudo.template.color }]}></View>
              </View>
              <View style={styles.conteudoSection}>
                <Text style={styles.text}>{conteudo.esofago}</Text>
                {
                  conteudo.template.comImg === "com-img" && conteudo.template.esofago 
                  ? <Image src={conteudo.template.esofago} style={styles.imgSection} />: <></>
                }
              </View>
            </View>

            <View style={styles.section}>
              <View style={styles.headerSection}>
                <View style={[styles.linha, { width: 15, backgroundColor: conteudo.template.color }]}></View>
                <Text style={styles.subtitulo}>ESTÔMAGO</Text>
                <View style={[styles.linha, { width: '85%', backgroundColor: conteudo.template.color }]}></View>
              </View>
              <View style={styles.conteudoSection}>
                <Text style={styles.text}>{conteudo.estomago}</Text>
                {
                  conteudo.template.comImg === "com-img" && conteudo.template.estomago 
                  ? <Image src={conteudo.template.estomago} style={styles.imgSection} />: <></>
                }
              </View>
            </View>

            <View style={styles.section}>
              <View style={styles.headerSection}>
                <View style={[styles.linha, { width: 15, backgroundColor: conteudo.template.color }]}></View>
                <Text style={styles.subtitulo}>DUODENO</Text>
                <View style={[styles.linha, { width: '86.7%', backgroundColor: conteudo.template.color }]}></View>
              </View>
              <View style={styles.conteudoSection}>
                <Text style={styles.text}>{conteudo.duodeno}</Text>
                {
                  conteudo.template.comImg === "com-img" && conteudo.template.duodeno 
                  ? <Image src={conteudo.template.duodeno} style={styles.imgSection} />: <></>
                }
              </View>
            </View>

            <View style={styles.section}>
              <View style={styles.headerSection}>
                <View style={[styles.linha, { width: 15, backgroundColor: conteudo.template.color }]}></View>
                <Text style={styles.subtitulo}>CONCLUSÃO</Text>
                <View style={[styles.linha, { width: '84.1%', backgroundColor: conteudo.template.color }]}></View>
              </View>
              <Text style={styles.text}>{conteudo.conclusao}</Text>
            </View>

            <View>
              <View style={styles.flex}><Text style={styles.label}>Notas: </Text><Text style={styles.text}>{conteudo.notas}</Text></View>
            </View>
          </View>
        </View>
      </View>
      <View>
        <View style={styles.assinatura}>
          <View style={{ height: 1, width: 150, backgroundColor: '#74799C', marginBottom: 5 }}></View>
          <Text style={[styles.text, { textAlign: 'center' }]}>{conteudo.nome_medico} - CRM {conteudo.crm}</Text>
        </View>
        <View style={{ marginBottom: 8, marginHorizontal: 20 }}>
          <View style={[styles.linha, { width: '100%', marginBottom: 5, backgroundColor: conteudo.template.color }]}></View>
          <Text style={styles.textFooter}>Laudo produzido por EndoCloud - laudos inteligentes e automáticos.</Text>
          <Text style={styles.textFooter}>www.endocloud.com.br</Text>
        </View>
      </View>
    </Page>
  </Document>
)

export default PdfLaudo;