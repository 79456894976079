//@ts-nocheck
import React, { FormEvent, useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { LogoutUser, SubmitPasswordRecovery } from "../../utils/auth"

import { CheckCircle, Facebook, Instagram, Mail, Phone, X } from 'react-feather'

import LogoBranca from '../../assets/logo/LogoBranca'
import Background from '../../assets/Background'

import Input from '../../components/Input'
import Button from '../../components/Button'

import './styles.css'

function EsqueciMinhaSenha() {

    useEffect(() => {
        LogoutUser()
    })
    
    const [email, setEmail] = useState('');
    const [alert, setAlert] = useState('');
    
    const history = useHistory();

    async function submitRecovery(e: FormEvent) {
        e.preventDefault();
    
        if (
          email
          && email.toString().trim()
        ) {
          const {success, message} = await SubmitPasswordRecovery({ email })
  
          if (success) {
            setAlert('Email enviado com sucesso!');
            history.push('/login');
          } else {
            setAlert(message);
          }
    
        }
    }

    return (
        <div className="page-esqueci-minha-senha">
          <Background />
          <div className="navbar-login">
            <nav className="container">
              <Link to="/home"><LogoBranca /></Link>
              <ul>
                <li><Link to="/login">Laudo online</Link></li>
                <li><a target='_blacnk' href="https://endocloud.com.br/cadastro-medico">Planos</a></li>
                <li><Link to="/login">Blog</Link></li>
                <li><Link to="/login">Entrar</Link></li>
              </ul>
            </nav>
          </div>
    
          <main>
            <form onSubmit={submitRecovery}>
              <p>Esqueci minha senha</p>
              <div>
                <Input
                  name="email"
                  type="email"
                  placeholder="Email da sua conta Endo Cloud"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoFocus
                  data-cy="login-email"
                />
    
              </div>
    
              {
                alert &&
                <div
                  className="alert-login"
                  data-cy="login-alert"
                >
                  <p>{alert}</p>
                  <span onClick={() => setAlert('')}>
                    <X />
                  </span>
                </div>
              }
    
              <Button
                width="large"
                type="submit"
                data-cy="login-btn"
              >
                <CheckCircle />
                Enviar email
              </Button>
    
            </form>
          </main>
    
          <div className="footer-login">
            <footer className="container">
              <Link to="/home"><LogoBranca /></Link>
              <ul>
                <li>
                  <Mail color="#FFFFFF" />
                  <a href="#">
                    endocloud@email.com
                  </a>
                </li>
                <li>
                  <Phone color="#FFFFFF" />
                  <a href="#">(85)99999-9999</a>
                </li>
                <li>
                  <Instagram color="#FFFFFF" />
                  <a href="#">@endocloud</a>
                </li>
                <li>
                  <Facebook color="#FFFFFF" />
                  <a href="#">endocloud/facebook.com</a>
                </li>
              </ul>
            </footer>
          </div>
        </div>
      )
}
export default EsqueciMinhaSenha;