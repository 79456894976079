//@ts-nocheck
import React, {useContext, useEffect} from 'react'
import { Route, Switch, useParams, useHistory, Redirect, RouteProps } from 'react-router-dom'

export interface ProtectRouterProps extends RouteProps {
  isAuthenticated: boolean,
  typeUser: string,
  redirectRouter: string
}

// develop = true => DESATIVA TODAS AS VALIDAÇÕES DE ROTA
// develop = false => ATIVA TODAS AS VALIDAÇÕES DE ROTA
const develop = false

export class ProtectedRouteAdmin extends Route<ProtectRouterProps> {
  public render() {

    console.log(this.props)

    let redirectPath: string = '';

    if (!this.props.isAuthenticated || this.props.typeUser !== 'admin'  ) {
        redirectPath = this.props.redirectRouter;
    }

    if (redirectPath && !develop) {
        const renderComponent = () => (<Redirect to={{pathname: redirectPath}}/>);
        return <Route {...this.props} component={renderComponent} render={undefined}/>;
    } else {
        return <Route {...this.props}/>;
    }
  }
}

export class ProtectedRouteMedico extends Route<ProtectRouterProps> {
  public render() {
    let redirectPath: string = '';

    console.log(this.props)

    if (!this.props.isAuthenticated) {
        redirectPath = this.props.redirectRouter;
    }

    if (this.props.typeUser === 'admin'){
    redirectPath = '/admin/blog'
    }

    if (redirectPath && !develop) {
        const renderComponent = () => (<Redirect to={{pathname: redirectPath}}/>);
        return <Route {...this.props} component={renderComponent} render={undefined}/>;
    } else {
        return <Route {...this.props}/>;
    }
  }
}

export class ProtectedRouteResidente extends Route<ProtectRouterProps> {
  public render() {
    let redirectPath: string = '';

    console.log(this.props)

    if (!this.props.isAuthenticated || this.props.typeUser != 'residente'  ) {
        redirectPath = this.props.redirectRouter;
    }

    if (redirectPath && !develop) {
        const renderComponent = () => (<Redirect to={{pathname: redirectPath}}/>);
        return <Route {...this.props} component={renderComponent} render={undefined}/>;
    } else {
        return <Route {...this.props}/>;
    }
  }
}