import React, { useState, useEffect } from 'react'
import { ArrowLeft, Check, Edit, ExternalLink, Search, Trash2, User, UserCheck, UserX, X } from 'react-feather';
import Input from '../../components/Input';
import Pagination from '../../components/Pagination';
import SelectPadrao from '../../components/SelectPadrao';

import './styles.css'

import { dataPageAssinantes, dataPageResidentes } from './dados'
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import ModalAlerta from '../../components/ModalAlerta';
import { GetToken, GetMedico, GetResidente, MedicoUpdateAdmin } from '../../utils/auth'

interface UserInfoPropsMedico {
  nome: string;
  id: number;
  telefone: string;
  email: string;
  senha: string;
  medico: {
    crm: string;
    uf: string;
    especialidade: string;
  }
}

interface UserInfoPropsResidente {
  nome: string;
  telefone: string;
  email: string;
  residente: {
    curso: string;
    instituicao: string;
    data_conclusao: string;
    status_conclusao: boolean;
  }
}

// informações dos modais de alerta
const infosAlertaVoltar = {
  titulo: "Tem certeza que deseja voltar?",
  info: "Há alterações que foram feitas nesse usuário que não serão salvas caso você continue.",
  msgRejeicao: "Não, continuar editando",
  msgConfirmacao: "Sim, perder alterações"
}
const infosAlertaExcluir = {
  titulo: "Tem certeza que deseja excluir o usuário?",
  info: "Todos os dados desse médico serão perdidos e caso ele seja assinante está sujeito a ter problemas jurídicos",
  msgRejeicao: "Não, manter no sistema",
  msgConfirmacao: "Sim, excluir usuário"
}
const infosAlertaDesaprovar = {
  titulo: "Tem certeza que deseja desaprovar a solicitação?",
  info: "Todos os dados que foram preenchidos por esse médico serão perdidos e uma nova solicitação vai precisar ser enviada para que ele seja aceito pelo sistema",
  msgRejeicao: "Não, continuar checando",
  msgConfirmacao: "Sim, excluir solicitação"
}

function Admin() {
  const [pageSelected, setPageSelected] = useState('admin-assinantes');
  const [dataAssinantes, setDataAssinantes] = useState<Array<{
    nome: string;
    crm: string;
    uf: string;
    email: string;
    especialidade: string;
    assinatura: string;
  }>>(dataPageAssinantes);

  const [dataResidentes, setDataResidentes] = useState<Array<{
    nome: string;
    email: string;
    curso: string;
    instituicao: string;
    status: boolean;
    conclusao: string;
    declaracao: string;
  }>>(dataPageResidentes);

  // modal editar/aprovar
  const [modal, setModal] = useState(false);
  const [flagModal, setFlagModal] = useState("");

  const [dataModalAssinantes, setDataModalAssinantes] = useState({
    nome: "",
    telefone: "",
    email: "",
    assinatura: "",
    senha: "",
    medico: {
      crm: "",
      uf: "",
      especialidade: "",
    }
  });
  const [dataModalAssinantesEditado, setDataModalAssinantesEditado] = useState({
    nome: "",
    id: -1,
    telefone: "",
    email: "",
    assinatura: "",
    senha: "",
    medico: {
      crm: "",
      uf: "",
      especialidade: "",
    }
  });

  const [dataModalResidentes, setDataModalResidentes] = useState({
    nome: "",
    telefone: "",
    email: "",
    residente: {
      curso: "",
      instituicao: "",
      data_conclusao: "",
      status_conclusao: false,
    },
    declaracao: "",
  });
  const [dataModalResidentesEditado, setDataModalResidentesEditado] = useState({
    nome: "",
    telefone: "",
    email: "",
    residente: {
      curso: "",
      instituicao: "",
      data_conclusao: "",
      status_conclusao: false,
    },
    declaracao: "",
  });

  // modal alerta
  const [modalAlerta, setModalAlerta] = useState(false);
  const [infosModalAlerta, setInfosModalAlerta] = useState({
    titulo: "",
    info: "",
    msgRejeicao: "",
    msgConfirmacao: ""
  });
  
  const labelsAssinantes = [
    "NOME",
    "CRM",
    "UF",
    "EMAIL",
    "ESPECIALIDADE",
    "ASSINATURA",
    " "
  ];

  const labelsResidentes = [
    "NOME",
    "EMAIL",
    "CURSO",
    "INSTITUIÇÃO",
    "RESIDÊNCIA",
    " "
  ];

  const widthsLabelsAssinantes = [
    245, 105, 55, 240, 160, 180
  ]
  const widthsLabelsResidentes = [
    250, 250, 130, 210
  ]

  function VerificarAlteracao(tipoUsuario: string) {
    if (tipoUsuario === "residente") {
      return dataModalResidentes.nome === dataModalResidentesEditado.nome && dataModalResidentes.email === dataModalResidentesEditado.email && dataModalResidentes.residente.curso === dataModalResidentesEditado.residente.curso && dataModalResidentes.residente.instituicao === dataModalResidentesEditado.residente.instituicao && dataModalResidentes.residente.data_conclusao === dataModalResidentesEditado.residente.data_conclusao
    }
    else {
      return dataModalAssinantes.nome === dataModalAssinantesEditado.nome && dataModalAssinantes.medico.crm === dataModalAssinantesEditado.medico.crm && dataModalAssinantes.email === dataModalAssinantesEditado.email && dataModalAssinantes.medico.especialidade === dataModalAssinantesEditado.medico.especialidade && dataModalAssinantes.medico.uf === dataModalAssinantesEditado.medico.uf && dataModalAssinantes.assinatura === dataModalAssinantesEditado.assinatura
    }
  }

  const [infoMedico, setInfoMedico] = useState<Array<UserInfoPropsMedico>>()
  const [infoResidente, setInfoResidente] = useState<Array<UserInfoPropsResidente>>()

  // medico
  useEffect(() => {
    const getInfoMedico = async () => {
      const {token} = await GetToken()
      const medico = await GetMedico(token)
      setInfoMedico(medico)
    }
    getInfoMedico()
  }, [])

  // residente
  useEffect(() => {
    const getInfoResidente = async () => {
      const {token} = await GetToken()
      const residente = await GetResidente(token)
      setInfoResidente(residente)
    }
    getInfoResidente()
  }, [])

  return (
    <div className="page-admin">
      <div className="navbar-sec">
        <nav className="container">
          <ul>
            <li
              className={pageSelected === "admin-assinantes" ? "selected" : ''}
              onClick={() => {
                setPageSelected("admin-assinantes")
              }}
            >Assinantes</li>
            <li
              className={pageSelected === "admin-residentes" ? "selected" : ''}
              onClick={() => {
                setPageSelected("admin-residentes");
              }}
            >Residentes</li>
          </ul>

          {pageSelected === "admin-residentes" ?
            <div className="mostrar-pendentes">
              Ver pendentes
              <span>100+</span>
            </div>
            :
            <span>Olá Anderson!</span>
          }
        </nav>

      </div>

      <main>
        <header>
          <p>Médicos {pageSelected === "admin-assinantes" ? "assinantes" : "residentes"}</p>
          <div>
            <ExternalLink />
            Exportar .xsl
          </div>
        </header>
        <section>
          <Input 
            name="search-doctor" 
            type="search" 
            placeholder="Procure pelo nome do médico ou CRM"
          />
          <div className="flex align-center">
            <span>Status de {pageSelected === "admin-assinantes" ? "assinatura" : "residência"}:</span>
            <SelectPadrao  
              onChange={() => {}}
              options={[
                { value: "0", label: "Todas" }
              ]}
            />
          </div>
          <span>500 médicos</span>
        </section>

        <div className="table-padrao">
          {
            pageSelected === "admin-assinantes" ?
              <>
                <div className="labels" >
                  {labelsAssinantes.map((label, i) => <span key={label} style={{ width: widthsLabelsAssinantes[i] }}>{label}</span>)}
                </div>
                <div className="dados">
                  {infoMedico?.map((item, i) => {
                    const strStatus = i % 2 === 0 ? "Expirou dia: 02/08/20" : "Conclusão: 24/08/20"
                    const status = strStatus.split(": ")[0];
                    const date = strStatus.split(": ")[1];
                    const dateArray = date.split("/");
                    const dateFormated = `20${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;

                    return (
                      <div key={i} className="linha">
                        <span style={{ width: 230, marginRight: 20 }}>{item.nome}</span>
                        <span style={{ width: 90, marginRight: 20 }}>{item.medico.crm}</span>
                        <span style={{ width: 35, marginRight: 20 }}>{item.medico.uf}</span>
                        <span style={{ width: 230, marginRight: 20 }}>{item.email}</span>
                        <span style={{ width: 140, marginRight: 20 }}>{item.medico.especialidade}</span>
                        <span style={{ width: 160, marginRight: 20, color: status === "Expirou dia" ? "#BC2A2A" : '#2FBD81' }}>{strStatus}</span>
                        <span
                          className="button-action"
                          onClick={() => {
                            setDataModalAssinantes({ ...item, assinatura: dateFormated });
                            setDataModalAssinantesEditado({ ...item, assinatura: dateFormated });
                            setModal(true);
                            setFlagModal("assinantes");
                          }}
                        >
                          <Edit />
                          Editar
                        </span>
                      </div>
                    )
                  })}
                </div>
              </>
              : pageSelected === "admin-residentes" ?
                <>
                  <div className="labels" >
                    {labelsResidentes.map((label, i) => <span key={label} style={{ width: widthsLabelsResidentes[i] }}>{label}</span>)}
                  </div>
                  <div className="dados">
                    {infoResidente?.map((item, i) => {
                      const justDate = item.residente.data_conclusao.split('T')
                      const dateLocale = new Date(justDate[0]).toLocaleDateString();

                      const dateArray = dateLocale.split("/");
                      const dateFormated = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
                      return (
                        <div key={i} className="linha">
                          <span style={{ width: 230, marginRight: 20 }}>{item.nome}</span>
                          <span style={{ width: 230, marginRight: 20 }}>{item.email}</span>
                          <span style={{ width: 110, marginRight: 20 }}>{item.residente.curso}</span>
                          <span style={{ width: 190, marginRight: 20 }}>{item.residente.instituicao}</span>
                          <span style={{ width: 100, marginRight: 20 }}>
                            {!item.residente.status_conclusao ? <span style={{ color: "#DAA213" }}>Aprovação pendente</span>
                              : new Date(item.residente.data_conclusao) <= new Date() ?
                                <span style={{ color: "#2FBD81" }}>Residência concluída</span>
                                : `Conclusão: ${dateLocale}`}
                          </span>
                          {
                            !item.residente.status_conclusao ?
                              <span
                                className="button-action"
                                onClick={() => {
                                  setDataModalResidentes({ ...item, declaracao: "residência_hospital_universitário.pdf", residente: {...item.residente, data_conclusao: dateFormated} });
                                  setDataModalResidentesEditado({ ...item, declaracao: "residência_hospital_universitário.pdf", residente: {...item.residente, data_conclusao: dateFormated} });
                                  setModal(true);
                                  setFlagModal("residentes");
                                }}
                              >
                                <Search />
                                Verificar
                              </span> :
                              <span
                                className="button-action"
                                onClick={() => {
                                  setDataModalResidentes({ ...item, declaracao: "residência_hospital_universitário.pdf", residente: {...item.residente, data_conclusao: dateFormated} });
                                  setDataModalResidentesEditado({ ...item, declaracao: "residência_hospital_universitário.pdf", residente: {...item.residente, data_conclusao: dateFormated} });
                                  setModal(true);
                                  setFlagModal("residentes");
                                }}
                              >
                                <Edit />
                                Editar
                              </span>
                          }
                        </div>
                      )
                    })}
                  </div>
                </> : ''
          }

          {modal &&
            <Modal setModal={setModal} position="right">
              {
                flagModal === "assinantes" ?
                  <div className="modal-page-admin">
                    <header>
                      <h3>
                        <User />
                        Editar dados
                      </h3>
                      <div onClick={() => {
                        // caso tenha modificações
                        if (!VerificarAlteracao("assinante")) {
                          setInfosModalAlerta(infosAlertaVoltar)
                          setModalAlerta(true);
                        }
                        else {
                          setModal(false)
                        }
                      }}>
                        <X />
                      </div>
                    </header>

                    <form>
                      <div className="field">
                        <label>Nome:</label>

                        <Input
                          name="nome"
                          type="text"
                          placeholder="Joselino Costa da Silva"
                          onChange={(e) => setDataModalAssinantesEditado({ ...dataModalAssinantesEditado, nome: e.target.value })}
                          value={dataModalAssinantesEditado.nome}
                        />
                      </div>

                      <div className="field-group">
                        <label>CRM:</label>

                        <Input
                          name="crm"
                          type="text"
                          placeholder="891830"
                          onChange={(e) => setDataModalAssinantesEditado({ ...dataModalAssinantesEditado, medico: {...dataModalAssinantesEditado.medico, crm: e.target.value} })}
                          value={dataModalAssinantesEditado.medico.crm}
                        />

                        <label>UF:</label>

                        <Input
                          name="uf"
                          type="text"
                          placeholder="CE"
                          onChange={(e) => setDataModalAssinantesEditado({ ...dataModalAssinantesEditado, medico: {...dataModalAssinantesEditado.medico, uf: e.target.value} })}
                          value={dataModalAssinantesEditado.medico.uf}
                          style={{ width: 111 }}
                        />
                      </div>

                      <div className="field">
                        <label style={{ width: 70 }}>E-mail:</label>

                        <Input
                          name="email"
                          type="text"
                          placeholder="joselinocosta@endocloud.com"
                          onChange={(e) => setDataModalAssinantesEditado({ ...dataModalAssinantesEditado, email: e.target.value })}
                          value={dataModalAssinantesEditado.email}
                        />
                      </div>

                      <div className="field">
                        <label>Especialialidade:</label>
                        <Input
                          name="especialidade"
                          type="text"
                          placeholder="Endoscopia"
                          onChange={(e) => setDataModalAssinantesEditado({ ...dataModalAssinantesEditado, medico: {...dataModalAssinantesEditado.medico, especialidade: e.target.value} })}
                          value={dataModalAssinantesEditado.medico.especialidade}
                        />
                      </div>

                      <div className="field">
                        <label style={{ width: 350 }}>Vencimento da assinatura:</label>
                        <Input
                          name="vencimento-assinatura"
                          type="date"
                          onChange={(e) => setDataModalAssinantesEditado({ ...dataModalAssinantesEditado, assinatura: e.target.value })}
                          value={dataModalAssinantesEditado.assinatura}
                        />
                      </div>

                      <span onClick={() => {
                        setInfosModalAlerta(infosAlertaExcluir)
                        setModalAlerta(true);
                      }}>
                        <Trash2 />
                    Excluir usuário
                  </span>

                      <footer>
                        <main className="container">
                          <span onClick={() => {
                            // caso tenha modificações
                            if (!VerificarAlteracao("assinante")) {
                              setInfosModalAlerta(infosAlertaVoltar)
                              setModalAlerta(true);
                            }
                            else {
                              setModal(false)
                            }
                          }}>
                            <ArrowLeft />
                        Voltar
                      </span>

                          {(dataModalAssinantes.nome === dataModalAssinantesEditado.nome && dataModalAssinantes.medico.crm === dataModalAssinantesEditado.medico.crm && dataModalAssinantes.email === dataModalAssinantesEditado.email && dataModalAssinantes.medico.especialidade === dataModalAssinantesEditado.medico.especialidade && dataModalAssinantes.medico.uf === dataModalAssinantesEditado.medico.uf && dataModalAssinantes.assinatura === dataModalAssinantesEditado.assinatura) ?
                            <Button type="submit" disabled>
                              <Check />
                              Salvar alterações
                            </Button> :
                            <Button type="submit" onClick={(e) => {
                              e.preventDefault()
                              MedicoUpdateAdmin({
                                nome: dataModalAssinantesEditado.nome,
                                id: dataModalAssinantesEditado.id,
                                email: dataModalAssinantesEditado.email,
                                medico: {
                                  especialidade: dataModalAssinantesEditado.medico.especialidade
                                }
                              })
                            }} > 
                              <Check />
                              Salvar alterações
                            </Button>
                          }

                        </main>
                      </footer>

                    </form>
                  </div>
                  :
                  <div className="modal-page-admin modal-page-admin-residente">
                    <header>
                      <h3>
                        {
                          dataModalResidentes.residente.status_conclusao ?
                            <>
                              <User />
                          Editar dados
                        </>
                            :
                            <>
                              <UserCheck />
                          Aprovar residente
                        </>
                        }
                      </h3>
                      <div onClick={() => {
                        // caso tenha modificações
                        if (!VerificarAlteracao("residente")) {
                          setInfosModalAlerta(infosAlertaVoltar)
                          setModalAlerta(true);
                        }
                        else {
                          setModal(false)
                        }
                      }}>
                        <X />
                      </div>
                    </header>

                    <form>
                      <div className="field">
                        <label>Nome:</label>

                        {dataModalResidentes.residente.status_conclusao ?
                          <Input
                            name="nome"
                            type="text"
                            placeholder="Joselino Costa da Silva"
                            onChange={(e) => setDataModalResidentesEditado({ ...dataModalResidentesEditado, nome: e.target.value })}
                            value={dataModalResidentesEditado.nome}
                          /> :
                          <span>{dataModalResidentes.nome}</span>
                        }

                      </div>

                      <div className="field">
                        <label style={{ width: 70 }}>E-mail:</label>

                        {dataModalResidentes.residente.status_conclusao ?
                          <Input
                            name="email"
                            type="text"
                            placeholder="joselinocosta@endocloud.com"
                            onChange={(e) => setDataModalResidentesEditado({ ...dataModalResidentesEditado, email: e.target.value })}
                            value={dataModalResidentesEditado.email}
                          /> :
                          <span>{dataModalResidentes.email}</span>
                        }
                      </div>

                      <div className="field">
                        <label>Curso:</label>

                        {dataModalResidentes.residente.status_conclusao ?
                          <Input
                            name="curso"
                            type="text"
                            placeholder="Medicina"
                            onChange={(e) => setDataModalResidentesEditado({ ...dataModalResidentesEditado, residente: {...dataModalResidentesEditado.residente, curso: e.target.value} })}
                            value={dataModalResidentesEditado.residente.curso}
                          /> :
                          <span>{dataModalResidentes.residente.curso}</span>
                        }

                      </div>
                      <div className="field">
                        <label>Instituição:</label>

                        {dataModalResidentes.residente.status_conclusao ?
                          <Input
                            name="instituicao"
                            type="text"
                            placeholder="Universidade Federal do Ceará"
                            onChange={(e) => setDataModalResidentesEditado({ ...dataModalResidentesEditado, residente: {...dataModalResidentesEditado.residente, instituicao: e.target.value} })}
                            value={dataModalResidentesEditado.residente.instituicao}
                          /> :
                          <span>{dataModalResidentes.residente.instituicao}</span>
                        }
                      </div>

                      <div className="field">
                        <label style={{ width: 350 }}>Conclusão da residência:</label>

                        <Input
                          name="vencimento-assinatura"
                          type="date"
                          onChange={(e) => setDataModalResidentesEditado({ ...dataModalResidentesEditado, residente: {...dataModalResidentesEditado.residente, data_conclusao: e.target.value} })}
                          value={dataModalResidentesEditado.residente.data_conclusao}
                          disabled={dataModalResidentes.residente.status_conclusao ? false : true}
                        />
                      </div>

                      <div className="field">
                        <label>Declaração: </label>
                        <span>{dataModalResidentes.declaracao}</span>
                      </div>

                      {
                        dataModalResidentes.residente.status_conclusao ?
                          <span onClick={() => {
                            setInfosModalAlerta(infosAlertaExcluir)
                            setModalAlerta(true);
                          }}>
                            <Trash2 />
                        Excluir usuário
                      </span> :
                          <span onClick={() => {
                            setInfosModalAlerta(infosAlertaDesaprovar)
                            setModalAlerta(true);
                          }}>
                            <UserX />
                        Desaprovar solicitação
                      </span>
                      }

                      <footer>
                        <main className="container">
                          <span onClick={() => {
                            // caso tenha modificações
                            if (!VerificarAlteracao("residente")) {
                              setInfosModalAlerta(infosAlertaVoltar)
                              setModalAlerta(true);
                            }
                            else {
                              setModal(false)
                            }
                          }}>
                            <ArrowLeft />
                        Voltar
                      </span>

                          {
                            dataModalResidentes.residente.status_conclusao ?
                              (dataModalResidentes.nome === dataModalResidentesEditado.nome && dataModalResidentes.email === dataModalResidentesEditado.email && dataModalResidentes.residente.curso === dataModalResidentesEditado.residente.curso && dataModalResidentes.residente.instituicao === dataModalResidentesEditado.residente.instituicao && dataModalResidentes.residente.data_conclusao === dataModalResidentesEditado.residente.data_conclusao) ?
                                <Button type="submit" disabled>
                                  <Check />
                              Salvar alterações
                            </Button> :
                                <Button type="submit">
                                  <Check />
                              Salvar alterações
                            </Button>
                              :
                              <Button type="submit" >
                                <Check />
                            Aprovar solicitação
                          </Button>
                          }

                        </main>
                      </footer>

                    </form>
                  </div>
              }
            </Modal>
          }

          {modalAlerta &&
            <ModalAlerta
              setModal={setModalAlerta}
              titulo={infosModalAlerta.titulo}
              info={infosModalAlerta.info}
              msgRejeicao={infosModalAlerta.msgRejeicao}
              msgConfirmacao={infosModalAlerta.msgConfirmacao}
              acaoBtnConfirmacao={() => setModal(false)}
            />
          }

        </div>

        <Pagination />
      </main>
    </div>
  )
}

export default Admin
