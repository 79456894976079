//@ts-nocheck
import React, { ButtonHTMLAttributes } from 'react'
import './styles.css'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /** indica o tamanho do botão */
  width?: string;
  color?: string;
  backgroundColor?: string;
}

/* 
* Botão padrão
*/
function Button({ width, color, backgroundColor, children, ...rest }: ButtonProps) {
  return (
    <button
      className="btn"
      style={{ 
        width: width === "large" ? 320 : width,
        backgroundColor: backgroundColor,
        color: color
     }}
      {...rest}
    >
      {children}
    </button>
  )
}

export default Button
