//@ts-nocheck
import React from 'react'

import './styles.css'

interface CheckboxProps {
  id: string;
  state: boolean;
  setState: Function;
}

function Checkbox({id, state, setState} : CheckboxProps) {
  return (
    <>
      <input className={state ? "checkbox-padrao checked" : "checkbox-padrao" } type="checkbox" id={id} />
      <label className="checkbox-padrao-label" htmlFor={id} onClick={() => setState()}>Toggle</label>
    </>
  )
}

export default Checkbox
