//@ts-nocheck
import React, { useState, useEffect, useContext } from "react"
import { Link } from 'react-router-dom'
import { ArrowLeft, Camera, Check } from 'react-feather'
import SelectPadrao from '../SelectPadrao'
import Input from '../Input'
import Button from '../Button'
import Background from '../../assets/Background'
import './styles.css'
import Header from '../Header'
import { GetToken, GetUser, MedicoUpdate } from '../../utils/auth'
import { NavbarContext } from "../../providers/NavbarProvider"

interface UserInfoPropsMedico {
    nome?: string;
    cpf?: string;
    sexo?: string;
    telefone?: string;
    email?: string;
    img_perfil?: string;
    medico?: {
      crm?: string;
      especialidade?: string;
    }
}

function EditarDadosMedico() {
    const [navbarInfo, setNavbarInfo] = useContext(NavbarContext);

    function maskPhone(value: any) {
        let teste = String(value)
        return teste
          .replace(/\D/g, '')
          .replace(/(\d{0})(\d)/, '$1($2')
          .replace(/(\d{2})(\d)/, '$1) $2')
          .replace(/(\d{5})(\d)/, '$1-$2')
    }

    function numberOnly(value: any) {
        let teste = String(value)
        return teste.replace(/\D/g, '')
    }

    function letterOnly(value: any) {
        let teste = String(value)
        return teste.replace(/[\"\'~`!@#$%^&()_={}[\]:;,.<>+\/?-]+|\d+|^\s+$/g, '').replace(/\s+/ig, ' ')
    }

    const uploadImage = async (e: any) => {
    const file = e.target.files[0]
    const base64 = await convertBase64(file)
    let strBase64 = String(base64)
    setInfoUser({ ...infoUser, img_perfil: strBase64 })
    }

    const convertBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)

        fileReader.onload = () => {
            resolve(fileReader.result)
        }

        fileReader.onerror = (error) => {
            reject(error)
        }
        })
    }
  
    const [infoUser, setInfoUser] = useState<UserInfoPropsMedico>();
    
    useEffect(() => {
      const getUserInfo = async () => {
      const { token } = await GetToken()
      const user = await GetUser(token)
      setInfoUser(user)
    }
    getUserInfo()
    }, [])
  
    return (
      <div className="page-editar-dados">
        <Background />
        <Header name="Meus dados" />
  
        <form>
          <div style={{ width: 470 }}>
            <fieldset className="field-sobre-voce">
              <legend>Sobre você e contato</legend>
  
              <div className="field">
                <label>Nome:</label>
                <div style={{
                  width: 180,
                  marginRight: 20
                }}>
                  <SelectPadrao
                    options={[
                      { value: "0", label: "Dr." },
                      { value: "1", label: "Dra" }
                    ]}
                    onChange={() => { }}
                  />
                </div>
  
                <Input
                  name="nome"
                  type="text"
                  placeholder="Nome e sobrenome"
                  onChange={(e) => {
                    setInfoUser({...infoUser, nome: letterOnly(e.target.value)})
                    }}
                  value={infoUser?.nome}
                />
              </div>
  
              <div className="field">
                <label>CPF:</label>
                <Input
                  name="cpf"
                  type="text"
                  placeholder={infoUser?.cpf}
                  disabled
                  /*
                  onChange={(e) => SetCpf(e.target.value)}
                  value={cpf}
                  */
                />
              </div>
  
              <div className="field">
                <label>Telefone:</label>
                <Input
                  maxLength={15}
                  name="telefone"
                  type="tel"
                  placeholder="(DDD) 9999-9999"
                  onChange={(e) => {
                    setInfoUser({...infoUser, telefone: numberOnly(e.target.value)})
                  }}
                  value={maskPhone(infoUser?.telefone)}
                />
              </div>

              <div className="field">
                <label>Email:</label> 
                <Input
                  name="email"
                  type="email"
                  placeholder="medico@gmail.com"
                  onChange={(e) => setInfoUser({...infoUser, email: e.target.value})}
                  value={infoUser?.email}
                />
              </div>
              <div className="field-group">
                <div>
                  <label>Senha:</label>
                  <Input
                    name="password"
                    type="password"
                    placeholder="*********"
                    //onChange={(e) => setInfoUser({...infoUser, senha: e.target.value})}
                    //value={infoUser?.senha}
                  />
                </div>
                <div>
                  <label>Confirmar senha:</label>
                  <Input
                    name="confirm-password"
                    type="password"
                    placeholder="*********"
                    /*
                    onChange={(e) => SetConfirmSenha(e.target.value)}
                    value={confirmSenha}
                    */
                  />
                </div>
              </div>
            </fieldset>
  
            <fieldset className="field-sobre-trabalho">
              <legend>Sobre o trabalho</legend>
  
              <div className="field">
                <label>Especialidade:</label>
                <Input
                name="especialidade"
                type="text"
                placeholder="especialidade"
                onChange={(e) => setInfoUser({...infoUser, medico: {especialidade: e.target.value}})}
                value={infoUser?.medico?.especialidade}
                />
              </div>
  
              <div className="field">
                <label>CRM:*</label>
                <Input
                    name="crm"
                    type="number"
                    placeholder={infoUser?.medico?.crm}
                    disabled
                    style={{
                        width: 250,
                        marginRight: 20
                    }}
                />
  
                <Input
                    name="uf"
                    type="text"
                    placeholder="CE"
                    disabled
                />
              </div>
            </fieldset>
          </div>
  
          <section className="section-img-perfil">
            <div className="img-perfil">
                {infoUser?.img_perfil === null ?
                <Camera />
                :
                <img src={infoUser?.img_perfil} style={{ width: "100%", height: "100%", borderRadius: "160px" }} alt="icon" />
                }
            </div>
            <input type="file" id="input-file" name="image-upload" accept="image/*" onChange={uploadImage} style={{ display: "none" }} />
            <label htmlFor="input-file"
                style={{ display: "flex", alignItems: "center", cursor: "pointer", float: "right", fontSize: "22px", marginRight: "18px" }}
            >
                <Camera style={{ marginRight: "8px" }} />
                <span>Alterar imagem de Perfil</span>
            </label>
          </section>
        </form>
  
        <footer>
          <main className="container">
            <Link to="/meus-dados">
              <ArrowLeft />
              Voltar
            </Link>
            <a href="/meus-dados" style={{textDecoration: "none"}}>
              <Button onClick={(e) => {
                //e.preventDefault()
                MedicoUpdate({
                    nome: infoUser?.nome,
                    telefone: infoUser?.telefone,
                    email: infoUser?.email,
                    img_perfil: infoUser?.img_perfil,
                    medico: {
                      especialidade: infoUser?.medico?.especialidade
                    }
                })
                setNavbarInfo({
                  ...navbarInfo,
                  nome: infoUser?.nome,
                  img_perfil: infoUser?.img_perfil
                });
              }}>
                <Check />
                Salvar alterações
              </Button>
            </a>
          </main>
        </footer>
      </div>
    )
}

export default EditarDadosMedico