//@ts-nocheck
import React from 'react'

import './styles.css'

interface RadioProps {
  id: string;
  label: string;
  state?: boolean;
  onChangeRadio: Function;
  style?: React.CSSProperties;
}

function Radio({ id, label, state, onChangeRadio, style }: RadioProps) {
  return (
    <div className="radiobutton" onClick={() => onChangeRadio()} style={style}>
      <input id={id} type="radio" name="radios" className={state ? "checked" : ''}/>
      <label htmlFor={id}>{label}</label>
    </div>
  )
}

export default Radio
