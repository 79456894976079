//@ts-nocheck
import React from "react"
import "./styles.css"
import styled, { css } from "styled-components"

interface DivProps {
    colorSelect: string;
    active: boolean;
}

function ColorRadio ( { colorSelect, active }: DivProps ) {

    return (
        <Qualquer colorSelect={colorSelect} active={active} />
    )
}

const Qualquer = styled.div<DivProps>`
    ${props => props.active ?
        css`
            position: relative;
            width: 52px;
            height: 52px;
            border-radius: 50%;
            background-color: ${props.colorSelect};
            position: relative;
            z-index: 1;
            display: grid;
            place-items: center;
            margin-right: 10px;
            cursor: pointer;

            &:before {
                content: "";
                position: absolute;
                width: 48px;
                height: 48px;
                border-radius: 50%;
                background-color: #FFFFFF;
                z-index: 2;
            }

            &:after {
                content: "";
                position: absolute;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: ${props.colorSelect};
                z-index: 3;
            }
        `
        :
        css`
            position: relative;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: ${props.colorSelect};
            z-index: 3;
            margin-right: 10px;
            cursor: pointer;
        `
        }
`

export default ColorRadio