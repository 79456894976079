//@ts-nocheck
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components';

// Componentes externos
import { Repeat, X, XCircle, PlusCircle } from 'react-feather';
import Cards from 'react-credit-cards';

// Componentes internos
import Button from '../../components/Button';
import Header from '../../components/Header'
import Input from '../../components/Input';
import Modal from '../../components/Modal';
import Radio from '../../components/Radio';

// API
import api from '../../servidor/api';

// CSS
import './styles.css'
import 'react-credit-cards/es/styles-compiled.css';
import Footer from '../../components/Footer';

// utils
import { makeHashCreditCard, maskCreditCard, numberOnly, maskValidateCreditCard } from '../../utils';

// Interface Card
interface FocusCard {
  focusName: "name" | "number" | "expiry" | "cvc"
}

function MeuPlano() {
  // Modal
  const [modal, setModal] = useState(false); // Formas de pagamento

  // Novo cartao
  const [newCard, setNewCard] = useState({
    cardNumber: '',
    holderName: '',
    securityCode: '',
    expiration: '',
  })
  const [focusCard, setFocusCard] = useState<FocusCard>({
    focusName: 'name'
  })
  const [openNewCard, setOpenNewCard] = useState(false)

  // hase
  const [hash, setHash] = useState({
    status: true,
    content: ''
  })

  const [tipoMedico, setTipoMedico] = useState('medico');

  const [statusPlano, setStatusPlano] = useState(false);

  const [plano, setPlano] = useState('Mensal');


  useEffect(() => {
    api.get('/juno/plano')
      .then(res => {  
      })
  }, [])

  function handleInputFocus(e: any){
    setFocusCard({ focusName: e.target.name });
  }

  // Inicializar script do Hash card
  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://sandbox.boletobancario.com/boletofacil/wro/direct-checkout.min.js";

    document.head.appendChild(script);
  }, [])

  // Gerar hash
  function newHash(e: any){
    setHash(e)
  }

  // Adicionar Cartão
  async function AddNewCreditCard(){
    const teste = await makeHashCreditCard(newCard, newHash)
    if(teste){
    }
  }

  const [efetuarPagamento, setEfetuarPagamento] = useState(false);
  const [cardNumber, setCardNumber] = useState('');
  const [holderName, setHolderName] = useState('');
  const [securityCode, setSecurityCode] = useState('');
  const [expirationMonth, setExpirationMonth] = useState('');
  const [expirationYear, setExpirationYear] = useState('');

  return (
    <div className="page-meu-plano">
      <Header name="Meu plano" />

      <main>
        <section className="status">
          <p>Status do seu plano</p>

          <div className="info-status">
            <div>
              <span>Plano atual</span>
              <div className="chip">Semestral</div>
            </div>
            <div>
              <span>Vigência do plano</span>
              <div className={statusPlano ? "chip" : "chip expirado"}>até 19/09/21</div>
            </div>
          </div>
        </section>

        {tipoMedico === "residente" ?
          <a href="https://juno.com.br">Efetivar cadastro pós-residencia</a> :

          <div className="opcoes-plano">
            <div style={{ color: '#6A79D7' }} onClick={() => setModal(true)}>
              <Repeat color="#6A79D7" />
              Mudança de Plano
            </div>
            <div style={{ color: '#BC2A2A' }}>
              <XCircle color="#BC2A2A" />
              Cancelar plano vigente
            </div>
          </div>
        }

      </main>

      {
        modal &&
        <Modal setModal={(state: boolean) => {
          setModal(state)
          setEfetuarPagamento(state)
        }} position="center">
          <div className="modal-page-meu-plano">
            <div className="close-modal" onClick={() => {
              setModal(false)
              setEfetuarPagamento(false)
            }}>
              <X />
            </div>

            <header>Escolha um plano</header>

            <section className="proximo-plano">
              <div>
                <div className="flex">
                  <Radio id="radio_1" label="Mensal" state={plano === "Mensal"} onChangeRadio={() => setPlano("Mensal")}/>
                </div>
                <span>R$129,90</span>
              </div>
              <div>
                <div className="flex">
                  <Radio id="radio_2" label="Semestral" state={plano === "Semestral"} onChangeRadio={() => setPlano('Semestral')}/>
                </div>
                <span>R$119,00</span>
              </div>
              <div>
                <div className="flex">
                  <Radio id="radio_3" label="Anual" state={plano === "Anual"} onChangeRadio={() => setPlano('Anual')}/>
                </div>
                <span>R$98,00</span>
              </div>
            </section>

            <header>Selecione um cartão de crédito</header>

            <CreditCardContainer>

              <Cards 
                cvc={1234}
                expiry={'12/18'}
                name={'Nome do Cartão'}
                number={4532612311234}
              />
              
              <AddCreditCardContainer style={{display: !openNewCard ? 'flex' : 'none'}} onClick={() => setOpenNewCard(true) } >
                <PlusCircle size='40' color="#6A79D7"/>
                <p> Adicionar Cartão </p>
              </AddCreditCardContainer>

            </CreditCardContainer>

            <AddCardConateiner style={{height: openNewCard ? '300px' : '0px', display: openNewCard ? 'flex' : 'none'}} >
              <span className="close-modal" onClick={() => setOpenNewCard(false)}>
                <X />
              </span>
              <Cards 
                cvc={newCard.securityCode}
                expiry={newCard.expiration}
                name={newCard.holderName}
                number={newCard.cardNumber}
                focused={focusCard?.focusName}
                placeholders={{name: 'Seu nome aqui'}}
              />
              <AddCardInputsContainer>
                <Input name='number' maxLength={19} onFocus={(e) => handleInputFocus(e)} placeholder='Número do Cartão' value={newCard.cardNumber} onChange={(e) => { setNewCard({...newCard, cardNumber: maskCreditCard(e.target.value)})}} />
                <Input name='name' maxLength={70} onFocus={(e) => handleInputFocus(e)} placeholder='Nome do cartão' value={newCard.holderName} onChange={(e) => { setNewCard({...newCard, holderName: e.target.value})}} />
                <div>
                  <Input name='cvc' maxLength={4} onFocus={(e) => handleInputFocus(e)} placeholder='CVC' value={newCard.securityCode} onChange={(e) => { setNewCard({...newCard, securityCode: numberOnly(e.target.value)})}} />
                  <Input name='expiry' maxLength={7} onFocus={(e) => handleInputFocus(e)} placeholder='Validade' value={newCard.expiration} onChange={(e) => { setNewCard({...newCard, expiration: maskValidateCreditCard(e.target.value)})}} />
                </div>
                
                {
                  !hash.status && <p style={{textAlign: 'center', color: 'red', marginTop: '10px'}} > {hash.content} </p> 
                }

                <div style={{height: '50px', margin: 'auto', marginTop: '10px'}} >
                  <Button onClick={() => AddNewCreditCard()} >
                    <p style={{margin: '0 15px'}} >
                      Adicionar Cartão
                    </p>
                  </Button>
                </div>

              </AddCardInputsContainer>
            </AddCardConateiner>
          
            <div className="opcoes">
              <span onClick={() => setModal(false)}>Cancelar</span>
              <Button>
                <p style={{margin: '0 15px'}} >
                  Efetuar alteração
                </p>
              </Button>
            </div>
          </div>
        </Modal>
      }
    </div>
  )
}

export default MeuPlano


const CreditCardContainer = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  > div {
    margin: 15px 0;
  }
`

const AddCreditCardContainer = styled.div`
  width: 250px;
  height: 160px;
  background-color: #FFFFFF;
  box-shadow: 0px 5px 14px rgba(52, 60, 88, 0.4);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 1s ease all;

  > p {
    margin-top: 20px;
    color: #6A79D7;
    size: 20px;
    font-family: 'Roboto';
    font-weight: 700;
  }

  &:hover{
    box-shadow: 0px 7px 14px rgba(52, 60, 88, 0.4);
  }
`

const AddCardConateiner = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  position: relative;

  > span {
    position: absolute;
    top: 0px;
    right: 0px;
  }
`

const AddCardInputsContainer = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    flex-direction: row;
  }
`