//@ts-nocheck
import React, { useEffect, useState } from 'react'
import { Bar, Pie, defaults } from 'react-chartjs-2'

import Background from '../../assets/Background'
import Input from '../../components/Input'
import NavbarSec from '../../components/NavbarSec'
import SelectPadrao from '../../components/SelectPadrao'
import { GetStats, GetToken } from '../../utils/auth'

import './styles.css'

function Estatisticas() {
  const [tipoEstatistica, setTipoEstatistica] = useState({
    label: 'Exames Realizados',
    value: 'exames-realizados'
  })

  defaults.global.defaultFontFamily = 'Roboto';
  defaults.global.defaultFontSize = 15;
  defaults.global.defaultFontColor = '#323654';

  const dataTipo1 = {
    labels: ['EDA', 'Colonoscopia'],
    datasets: [
      {
        data: [100, 75],
        backgroundColor: '#6A79D7',
      },
    ],
  }

  const dataTipo2 = {
    labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho'],
    datasets: [
      {
        data: [100, 75, 50, 75, 100, 125],
        backgroundColor: '#6A79D7',
      },
    ],
  }
  const dataTipo3 = {
    labels: ['Ligadura Elástica', 'Polipectomia', 'Retirada de corpo estranho'],
    datasets: [
      {
        data: [65, 25, 10],
        backgroundColor: [
          '#2FBDBC',
          '#323654',
          '#6A79D7',
        ],
        borderWidth: 0
      },
    ],
  }
  const dataTipo4 = {
    labels: ['Dor abdominal', 'Vômitos', 'HDA', 'Constipação', 'Anemia', 'Epigastralgia'],
    datasets: [
      {
        data: [100, 75, 50, 75, 100, 125],
        backgroundColor: '#6A79D7',
      },
    ],
  }
  const dataTipo5 = {
    labels: ['Duodenites', 'Esofagites', 'Gastrite de corpo gástrico'],
    datasets: [
      {
        data: [25, 65, 10],
        backgroundColor: [
          '#2FBDBC',
          '#323654',
          '#6A79D7',
        ],
        borderWidth: 0
      },
    ],
  }

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          }
        },
      ],
    },
    legend: {
      display: false
    },
  }

  const [stats, setStats] = useState<any>([]);

  useEffect(() => {
    const getStatsInfo = async () => {
      const stats = await GetStats("qtdexames", { datainicio: '2000-01-01', datafim: '2300-01-01' });
      setStats(stats);
    }
    getStatsInfo()

  }, [])

  return (
    <div className="page-estatisticas">
      <Background />
      <NavbarSec page="estatisticas" />

      <h1>Bem-vinda, Dra. Sandra</h1>
      <section>
        <SelectPadrao
          options={[
            { value: 'exames-realizados', label: 'Exames Realizados' },
            { value: 'numero-de-atendimentos-por-mes', label: 'Número de atendimentos por mês ' },
            { value: 'procedimentos-terapeuticos', label: 'Procedimentos terapêuticos' },
            { value: 'indicacoes', label: 'Indicações' },
            { value: 'diagnosticos', label: 'Diagnósticos' }
          ]}
          onChange={setTipoEstatistica}
        />
        <span>
          <p>Filtre por período:</p>
          <Input name="filtro-periodo" type="date" />
        </span>
      </section>
      <div style={{ maxWidth: 693 }}>
        {
          tipoEstatistica.value === "exames-realizados" ? 
          <Bar data={dataTipo1} options={options} /> :
            tipoEstatistica.value === "numero-de-atendimentos-por-mes" ? 
              <Bar data={dataTipo2} options={options} /> : 
              tipoEstatistica.value === "procedimentos-terapeuticos" ? 
                <Pie data={dataTipo3} /> : 
                tipoEstatistica.value === "indicacoes" ? 
                  <Bar data={dataTipo4} options={options} /> :
                    <Pie data={dataTipo5} /> 

        }
      </div>
    </div>
  )
}

export default Estatisticas
