//@ts-nocheck
import React, { FormEvent, useState, useEffect, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { CheckCircle, Facebook, Instagram, Mail, Phone, UserPlus, X } from 'react-feather'

import LogoBranca from '../../assets/logo/LogoBranca'
import Background from '../../assets/Background'
import ImgLogin from '../../assets/imgs/login.png'

import Input from '../../components/Input'
import Button from '../../components/Button'

import { UserLogin, GetToken, GetUser, LogoutUser } from "../../utils/auth"
import { NavbarContext } from '../../providers/NavbarProvider'

import './styles.css'

function Login() {

  const [navbarInfo, setNavbarInfo] = useContext(NavbarContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [alert, setAlert] = useState(''); 

  const history = useHistory();

  useEffect(() => {
    LogoutUser()
    setNavbarInfo({
      ...navbarInfo,
      nome: '',
      img_perfil: '',
      logado: false,
      roleUser: ''
    })
  }, [])

  async function submitLogin(e: FormEvent) {
    e.preventDefault();

    if (
      email
      && password
      && email.toString().trim()
      && password.toString().trim()
    ) {
      const {sucess, messenger, type} = await UserLogin({
        email: email,
        password: password
      })
      // sucesso no login
      if (sucess && (type == "medico" || type == "residente")) {
        setAlert('');

        // obtendo dados do medico logado para usar na navbar
        await setNavbarContext();

        history.push('/home');
      } else if (sucess && type == "admin") {
        setAlert('');
        history.push('/admin');
      } else {
        setAlert(messenger);
      }

    }
  }

  async function setNavbarContext() {
    const { token, typeUser } = await GetToken();
    let { nome, img_perfil } = await GetUser(token);
    
    setNavbarInfo({
      ...navbarInfo,
      nome,
      img_perfil,
      logado: true,
      roleUser: typeUser
    });
  }

  return (
    <div className="page-login">
      <Background />
      <div className="navbar-login">
        <nav className="container">
          <Link to="/home"><LogoBranca /></Link>
          <ul>
            <li><Link to="/login">Laudo online</Link></li>
            <li><a href="https://endocloud.com.br/#planos" target='_blank'>Planos</a></li>
            <li><Link to="/login">Blog</Link></li>
            <li><Link to="/login">Entrar</Link></li>
          </ul>
        </nav>
      </div>

      <main>
        <form onSubmit={submitLogin}>
          <p>Login</p>
          <div>
            <Input
              name="email"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoFocus
              data-cy="login-email"
            />

            <Input
              name="senha"
              type="password"
              placeholder="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              data-cy="login-password"
            />
          </div>

          {
            alert &&
            <div
              className="alert-login"
              data-cy="login-alert"
            >
              <p>{alert}</p>
              <span onClick={() => setAlert('')}>
                <X />
              </span>
            </div>
          }

          <Button
            width="large"
            type="submit"
            data-cy="login-btn"
          >
            <CheckCircle />
            Acessar
          </Button>
          <Link to='/esqueci-minha-senha' >
            <a href="#">Esqueci minha senha</a>
          </Link>
        </form>

        <aside>
          <img src={ImgLogin} alt="imagem login" />
          <p>Ainda não tem uma conta? cadastre-se</p>
          <Button
            width="large"
            type="submit"
            onClick={() => window.open('https://endocloud.com.br/cadastro-medico', '_blank')}
          >
            <UserPlus />
            Cadastro de médicos
          </Button>
          <a href="https://endocloud.com.br/cadastro-medico" target='_blank'>É residente? Cadastre-se aqui.</a>
        </aside>
      </main>

      <div className="footer-login">
        <footer className="container">
          <Link to="/home"><LogoBranca /></Link>
          <ul>
            <li>
              <Mail color="#FFFFFF" />
              <a href="#">
                endocloud@email.com
              </a>
            </li>
            <li>
              <Phone color="#FFFFFF" />
              <a href="#">(85)99999-9999</a>
            </li>
            <li>
              <Instagram color="#FFFFFF" />
              <a href="#">@endocloud</a>
            </li>
            <li>
              <Facebook color="#FFFFFF" />
              <a href="#">endocloud/facebook.com</a>
            </li>
          </ul>
        </footer>
      </div>
    </div>
  )
}

export default Login
