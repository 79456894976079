export const dataPageAssinantes = [
  {
    nome: "Joselino Costa da Silva",
    crm: "891830",
    uf: "CE",
    email: "joselino@endocloud.com",
    especialidade: "Endocrinologista",
    assinatura: "Conclusão: 24/08/20"
  },
  {
    nome: "Joselino Costa da Silva",
    crm: "891830",
    uf: "CE",
    email: "joselino@endocloud.com",
    especialidade: "Endocrinologista",
    assinatura: "Conclusão: 24/08/20"
  },
  {
    nome: "Joselino Costa da Silva",
    crm: "891830",
    uf: "CE",
    email: "joselino@endocloud.com",
    especialidade: "Endocrinologista",
    assinatura: "Expirou dia: 02/08/20"
  },
  {
    nome: "Joselino Costa da Silva",
    crm: "891830",
    uf: "CE",
    email: "joselino@endocloud.com",
    especialidade: "Endocrinologista",
    assinatura: "Expirou dia: 04/08/20"
  },
  {
    nome: "Joselino Costa da Silva",
    crm: "891830",
    uf: "CE",
    email: "joselino@endocloud.com",
    especialidade: "Endocrinologista",
    assinatura: "Expirou dia: 04/08/20"
  },
  {
    nome: "Joselino Costa da Silva",
    crm: "891830",
    uf: "CE",
    email: "joselino@endocloud.com",
    especialidade: "Endocrinologista",
    assinatura: "Expirou dia: 04/08/20"
  },
  {
    nome: "Joselino Costa da Silva",
    crm: "891830",
    uf: "CE",
    email: "joselino@endocloud.com",
    especialidade: "Endocrinologista",
    assinatura: "Expirou dia: 04/08/20"
  },
  {
    nome: "Joselino Costa da Silva",
    crm: "891830",
    uf: "CE",
    email: "joselino@endocloud.com",
    especialidade: "Endocrinologista",
    assinatura: "Expirou dia: 04/08/20"
  },
]

export const dataPageResidentes = [
  {
    nome: "Joselino Costa da Silva",
    email: "joselino@endocloud.com",
    curso: "Medicina",
    instituicao: "Universidade Federal do Ceará",
    status: true,
    conclusao: "2020-11-18",
    declaracao: "residência_hospital_universitário.pdf"
  },
  {
    nome: "Joselino Costa da Silva",
    email: "joselino@endocloud.com",
    curso: "Medicina",
    instituicao: "Universidade Federal do Ceará",
    status: true,
    conclusao: "2020-11-19",
    declaracao: "residência_hospital_universitário.pdf"
  },
  {
    nome: "Joselino Costa da Silva",
    email: "joselino@endocloud.com",
    curso: "Medicina",
    instituicao: "Universidade Federal do Ceará",
    status: true,
    conclusao: "2020-12-20",
    declaracao: "residência_hospital_universitário.pdf"
  },
  {
    nome: "Joselino Costa da Silva",
    email: "joselino@endocloud.com",
    curso: "Medicina",
    instituicao: "Universidade Federal do Ceará",
    status: false,
    conclusao: "2020-11-21",
    declaracao: "residência_hospital_universitário.pdf"
  },

]