//@ts-nocheck
import React, { SVGAttributes, HTMLAttributes } from "react"

interface DivProps extends HTMLAttributes<SVGPathElement> {
    color?: string;
}

function BackgroundTemplate1 ({ color, ...rest }: DivProps) {

    return (

        <svg viewBox="0 0 495 572" fill="none" xmlns="http://www.w3.org/2000/svg"
            style={{width: "100%", height: "100%", position: "absolute", top: "0", left: "0", pointerEvents: 'none'}}
        >
            <path opacity="0.2" fill={color} d="M83.9562 110.632L69.0856 114.852C-19.4774 139.985 -82.6871 218.068 -88.8277 309.923C-97.3262 437.048 4.59413 544.31 131.987 542.31L433.91 537.57C598.667 534.984 730.132 399.326 727.546 234.569L726.061 139.96C725.258 88.8393 696.534 42.2603 651.215 18.5924C596.664 -9.89696 529.886 0.759351 486.911 44.8115L468.569 63.6129C432.14 100.956 380.928 120.064 328.941 115.712L169.435 102.358C140.676 99.9506 111.719 102.754 83.9562 110.632Z" fill-opacity="0.5"/>
            <path opacity="0.2" fill={color} d="M113.179 138.192L97.7266 138.612C5.70054 141.113 -74.8227 201.183 -103.44 288.682C-143.046 409.779 -70.7462 538.874 53.2008 568.372L346.956 638.284C507.256 676.434 668.132 577.412 706.282 417.112L728.189 325.062C740.026 275.325 723.684 223.098 685.607 188.979C639.774 147.909 572.431 141.757 519.914 173.842L497.501 187.536C452.983 214.734 398.639 220.615 349.334 203.568L198.056 151.267C170.781 141.837 142.027 137.408 113.179 138.192Z" fill-opacity="0.6"/>
            <defs>
                <linearGradient id="paint0_linear" x1="49.2895" y1="-6.41006" x2="51.436" y2="130.331" gradientUnits="userSpaceOnUse">
                    <stop stopColor={color}/>
                    <stop offset="1" stopColor={color} stopOpacity="0"/>
                </linearGradient>
                <linearGradient id="paint0_linear" x1="304.942" y1="-25.4114" x2="313.809" y2="539.456" gradientUnits="userSpaceOnUse">
                    <stop stop-color={color}/>
                    <stop offset="1" stop-color={color} stop-opacity="0"/>
                </linearGradient>
                <linearGradient id="paint1_linear" x1="360.901" y1="60.887" x2="230.104" y2="610.474" gradientUnits="userSpaceOnUse">
                    <stop stop-color={color}/>
                    <stop offset="1" stop-color={color} stop-opacity="0"/>
                </linearGradient>
            </defs>
        </svg>

        // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130 132" fill="none"
        //     style={{width: "100%", height: "100%", position: "absolute", top: "0", left: "0"}}
        // >
        //     <path {...rest} opacity="0.3" d="M-4.2058 26.5229L-7.80562 27.5444C-29.2446 33.6284 -44.5462 52.5305 -46.0327 74.7664C-48.0899 105.54 -23.4174 131.506 7.42139 131.022L80.5097 129.874C120.393 129.248 152.218 96.4087 151.592 56.525L151.232 33.6224C151.038 21.2473 144.085 9.97165 133.114 4.24221C119.909 -2.65438 103.743 -0.0747454 93.3399 10.5892L88.8999 15.1406C80.0812 24.1804 67.6841 28.8061 55.0993 27.7525L16.4866 24.5199C9.52483 23.9371 2.51499 24.6156 -4.2058 26.5229Z" fill={color} fillOpacity="0.4"/>
        //     <defs>
        //         <linearGradient id="paint0_linear" x1="49.2895" y1="-6.41006" x2="51.436" y2="130.331" gradientUnits="userSpaceOnUse">
        //         <stop stopColor={color}/>
        //         <stop offset="1" stopColor={color} stopOpacity="0"/>
        //         </linearGradient>
        //     </defs>
        // </svg> // #005596
    )
}

export default BackgroundTemplate1