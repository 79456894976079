//@ts-nocheck
import { Defs, LinearGradient, Path, Stop, Svg, StyleSheet } from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
  background: {
    marginTop: 100,
    position: "absolute"
  }
});

interface BackgroundOndasProps {
  color: string;
}

function BackgroundOndas({ color }: BackgroundOndasProps) {
  return (
    <>
      <Svg width="595" height="546" viewBox="0 0 595 546" style={styles.background}>
        <Path opacity={0.07} d="M83.9562 110.632L69.0856 114.852C-19.4774 139.985 -82.6871 218.068 -88.8277 309.923C-97.3262 437.048 4.59413 544.31 131.987 542.31L433.91 537.57C598.667 534.984 730.132 399.326 727.546 234.569L726.061 139.96C725.258 88.8393 696.534 42.2603 651.215 18.5924C596.664 -9.89696 529.886 0.759351 486.911 44.8115L468.569 63.6129C432.14 100.956 380.928 120.064 328.941 115.712L169.435 102.358C140.676 99.9506 111.719 102.754 83.9562 110.632Z" fill={"url(#paint0_linear)"} fillOpacity={0.1} />
        <Defs>
          <LinearGradient id="paint0_linear" x1="304.942" y1="-25.4114" x2="313.809" y2="539.456" >
            <Stop offset="0" stopColor={color} />
            <Stop offset="1" stopColor={color} stopOpacity="0" />
          </LinearGradient>
        </Defs>

      </Svg>

      <Svg width="595" height="672" viewBox="0 0 595 672" style={styles.background}>
        <Path opacity={0.05} d="M113.176 138.192L97.724 138.612C5.69804 141.113 -74.8252 201.183 -103.443 288.682C-143.049 409.779 -70.7487 538.874 53.1983 568.372L346.954 638.284C507.254 676.434 668.129 577.412 706.28 417.112L728.187 325.062C740.024 275.325 723.682 223.098 685.605 188.979C639.771 147.909 572.428 141.757 519.912 173.842L497.498 187.536C452.98 214.734 398.637 220.615 349.332 203.568L198.054 151.267C170.778 141.837 142.025 137.408 113.176 138.192Z" fill="url(#paint1_linear)" fill-opacity={0.2} />
        <Defs>
          <LinearGradient id="paint1_linear" x1="360.899" y1="60.887" x2="230.101" y2="610.474" >
            <Stop offset="0" stopColor={color} />
            <Stop offset="1" stopColor={color} stop-opacity="0" />
          </LinearGradient>
        </Defs>
      </Svg>
    </>

  )
}

export default BackgroundOndas;