import React, { FormEvent, useState } from 'react'
import { ArrowLeft, FileText, MessageSquare, Send, X } from 'react-feather'
import Header from '../../components/Header'
import Modal from '../../components/Modal';

import './styles.css'



function Ajuda() {
  const [modal, setModal] = useState(false);
  const [titulo, setTitulo] = useState('');
  // const [conteudo, setConteudo] = useState('');

  return (
    <div className="page-ajuda">
      <Header name="Ajuda e Suporte" />

      <main>
        <section>
          <h3>FAQ</h3>
          <div className="botao-circular" onClick={() => {
            setTitulo('FAQ');
            setModal(true);
          }}>
            <MessageSquare />
          </div>
          <p>Lista de perguntas frequentes que podem te ajudar </p>
        </section>

        <section>
          <h3>Laudos</h3>
          <div className="botao-circular" onClick={() => {
            setTitulo('Laudos');
            setModal(true);
          }}>
            <FileText />
          </div>
          <p>Informações sobre geração de laudos</p>
        </section>

        <section>
          <h3>E-mail</h3>
          <div className="botao-circular" onClick={() => {
            setTitulo('E-mail');
            setModal(true);
          }}>
            <Send />
          </div>
          <p>Envie um e-mail para endocloud@gmail.com</p>
        </section>
      </main>

      {modal &&
        <Modal setModal={setModal} position="right">
          <div className="modal-page-ajuda">
            <header>
              <h3>{titulo}</h3>
              <div onClick={() => setModal(false)}>
                <X />
              </div>
            </header>
            <div>
              <div>
                <h4>1. O que é a Endo Cloud e como ela pode melhorar minha produtividade?</h4>
                <p>A Endo Cloud é uma plataforma online de produção de laudos de Endoscopia Digestiva de forma automática e rápida, garantindo agilidade no seu trabalho. O sistema oferece 2 tipos de laudos (Endoscopia Digestiva Alta e Colonoscopia). Os usuários da plataforma serão médicos que estão habilitados para realizar exames de Endoscopia Digestiva no seu trabalho e que necessitam de uma plataforma que atenda suas necessidades para produção de laudos. A Endo Cloud possibilita a produção de laudo de forma automática, ou seja, sem a necessidade de digitar a descrição do achado endoscópico, que acelera a produtividade do médico, proporcionando tempo extra para o profissional. </p>
              </div>
              <div>
                <h4>2. Como funciona o período de teste da Endo Cloud?</h4>
                <p>Após o cadastro no site e a confirmação do cartão de crédito no check-out da Juno, você pode utilizar todos os serviços da Endo Cloud gratuitamente durante 14 dias, com início da data de cadastro. Somente após esse período, caso o usuário não faça o cancelamento do plano, será cobrado o valor referente ao plano contratado no momento do cadastro inicial. </p>
              </div>
              <div>
                <h4>3. Como cadastrar um paciente para consulta?</h4>
                <p>Após fazer login no sistema Endo Cloud, na página inicial vá em “Paciente para consulta”. Selecione a opção caso seja um novo cadastro ou se o paciente já foi cadastrado pelo(a) médico(a) usuário(a) em outro momento. Caso o paciente já tenha cadastro, você poderá procurar o paciente pelo nome e selecionar o paciente desejado. Caso seja um novo cadastro, você deverá preencher os campos com os dados dos pacientes e assim o salvará na sua Nuvem. </p>
              </div>
              <div>
                <h4>4. Se cadastrei um paciente para consulta e quero gerar um laudo desse mesmo paciente, preciso cadastrá-lo novamente?</h4>
                <p>Não. Quando você salva um paciente para consulta, os dados cadastrados do paciente já podem ser utilizados para gerar um laudo sem novo cadastro (basta fazer uma busca pelo nome do paciente). </p>
              </div>
              <div>
                <h4>5. Como gerar um laudo de Endoscopia Alta?</h4>
                <p style={{marginBottom: "5px"}}>Na página inicial do sistema Endo Cloud, selecione um template de laudo, em seguida vá em “Gerar laudo” {'->'} Inserir os dados do paciente {'->'} Escolher a opção de laudo com ou sem imagem {'->'} Escolher opção EDA {'->'} Nesse ponto você poderá escolher duas opções:</p>
                <p style={{marginBottom: "5px"}}>- Laudo Express: laudos pré-estruturados de acordo com a patologia escolhida ou laudo normal. Ao escolher e confirmar essa opção de Laudo Express você passa diretamente para a página de visualização/edição do laudo.</p>
                <p>- Gerar laudo personalizado: Nesta opção você pode montar o seu laudo definindo as patologias para cada órgão examinado. No campo “diagnóstico” você seleciona a patologia e confirma para validar a escolha. Ao lado aparecerá a opção confirmada com dois ícones: um deles é a “lixeira” para excluir o diagnóstico confirmado e o outro representado por “Bx” que adicionará a palavra “biopsiamos” ao final da descrição do diagnóstico. Após selecionar todos os campos, você confirma no botão “avançar”. Após esse ponto, você terá a visualização do laudo com todas as descrições automáticas dos diagnósticos previamente escolhidos, com a possibilidade de edição.</p>
              </div>
              <div>
                <h4>6. Como gerar um laudo de Colonoscopia?</h4>
                <p>Na página inicial do sistema Endo Cloud, selecione um template de laudo, em seguida vá em “Gerar laudo” {'->'} Inserir os dados do paciente {'->'} Escolher a opção de laudo com ou sem imagem {'->'} Escolher opção Colonoscopia {'->'} Nesse ponto você irá selecionar os laudos pré-estruturados de acordo com a patologia escolhida ou laudo normal (Laudo Express). Ao escolher e confirmar essa opção de Laudo Express você passa diretamente para a página de visualização/edição do laudo. </p>
              </div>
              <div>
                <h4>7. Posso adicionar imagens nos meus laudos?</h4>
                <p>Sim. Você precisa primeiramente de um sistema de captura que conecte sua processadora endoscópica ao computador e que possa salvar as imagens no arquivo desktop. Na produção do laudo, na seção “Visualizar e editar” você pode adicionar essas imagens previamente salvas através do “upload” delas nos ícones das imagens no próprio laudo. </p>
              </div>
              <div>
                <h4>8. Como consultar os diagnósticos existentes na plataforma?</h4>
                <p>Vá em “suporte e ajuda” {'->'} “Laudos”. Nesta seção você encontra todas as orientações sobre laudos do sistema Endo Cloud. </p>
              </div>
              <div>
                <h4>9. Posso sugerir um diagnóstico e/ou descrição de diagnóstico para a Endo Cloud?</h4>
                <p>Sim. Sua sugestão é sempre bem-vinda para nós. Envie um e-mail para endocloudsuporte@gmail.com nos contando sobre sua sugestão. </p>
              </div>
              <div>
                <h4>10. Como editar os meus dados pessoais?</h4>
                <p>Após o login, na página inicial do sistema Endo Cloud, vá na aba do canto superior esquerdo e selecione “Meus Dados” {'->'} Editar dados {'->'} Confirmar. Seu sistema Endo Cloud é único e intransferível, já que somente você poderá elaborar seus laudos, pois cada login só pertence a um único médico (CRM). </p>
              </div>
              <div>
                <h4>11. Como obter suporte Endo Cloud?</h4>
                <p>Enviando um e-mail para endocloudsuporte@gmail.com . Nossa equipe está a postos para te atender. </p>
              </div>
              <div>
                <h4>11. Como obter suporte Endo Cloud?</h4>
                <p>Enviando um e-mail para endocloudsuporte@gmail.com . Nossa equipe está a postos para te atender. </p>
              </div>
              <div>
                <h4>12. Quais formas de compartilhar o laudo com o meu paciente?</h4>
                <p style={{marginBottom: "5px"}}>Ao concluir o laudo, três opções serão sugeridas ao médico(a): </p>
                <p style={{marginBottom: "5px"}}>i. impressão do laudo – seu computador deve estar conectado a uma impressora para efetuar essa ação.</p>
                <p style={{marginBottom: "5px"}}>ii. Enviar laudo para o e-mail do paciente</p>
                <p>iii. Fornecer ao paciente o seu ID e senha (que aparecem na tela de conclusão do laudo) – o médico pode fornecer por escrito ou enviar ID e senha por SMS para o celular do paciente. Com o ID e senha, o paciente poderá acessar o laudo online, podendo visualizar remotamente e/ou imprimir. Cada ID e senha são únicos e intransferíveis para cada paciente.</p>
              </div>
              <div>
                <h4>13. Como acessar os meus laudos salvos na Nuvem?</h4>
                <p>Na página inicial do sistema Endo Cloud, na barra superior, selecione a opção “Minha Nuvem”. Nesta seção você terá duas opções de escolha: "Laudos" e “Pacientes”. Em “Laudos” você terá acesso a todos os laudos produzidos com a identificação por paciente. Nesta opção você poderá visualizar e /ou editar o Laudo salvo na Nuvem. </p>
              </div>
              <div>
                <h4>14. Como acessar os dados dos meus pacientes salvos na Nuvem?</h4>
                <p>Na página inicial do sistema Endo Cloud, na barra superior, selecione a opção “Minha Nuvem”. Nesta seção você terá duas opções de escolha: "Laudos" e “Pacientes”. Em “Pacientes” você terá acesso a todos os pacientes cadastrados no sistema Endo Cloud, bem como a rápida visualização do seu ID e senha (para acesso no “laudo online”). Nesta seção você pode visualizar e/ou editar os dados dos pacientes salvos na Nuvem. </p>
              </div>
              <div>
                <h4>15. Como criar um template de laudo?</h4>
                <p>Na página inicial do sistema Endo Cloud, na barra inferior, selecione a opção “+” na seção templates de laudo. Após isso você será redirecionado para a seção de customização do template. Nele você poderá adicionar um nome, logotipo, cor e estilo do seu laudo. </p>
              </div>
              <div>
                <h4>16. O que é ID e senha do paciente?</h4>
                <p>O ID é o número de identificação de cada paciente, sendo, portanto, esse número único e intransferível. O ID também é utilizado como login na seção “laudo online” onde o paciente pode acessar os laudos remotamente para visualização ou impressão. Quando o médico finaliza a produção do laudo de um paciente, é mostrado o ID e senha para o acesso do paciente ao “laudo online”. </p>
              </div>
              <div>
                <h4>17. O banco de dados da Minha Nuvem é seguro?</h4>
                <p>Sim! A Endo Cloud tem certificado de segurança SSL e nosso servidor garante a segurança dos laudos de cada médico na Nuvem de forma sigilosa e compartimentalizada. </p>
              </div>
              <div>
                <h4>18. Outros médicos podem ver os meus laudos?</h4>
                <p>Não. Cada médico só pode visualizar os laudos produzidos por ele próprio. Nenhum médico tem acesso aos laudos de outro médico. </p>
              </div>
              <div>
                <h4>19. Os pacientes podem acessar e ver os laudos de outros pacientes?</h4>
                <p>Não. Cada paciente possui um ID e senha únicos e intransferíveis o que impede de um paciente acessar laudos de terceiros. </p>
              </div>
              <div>
                <h4>20. Sou residente e meu período gratuito está próximo ao fim. Como efetivo o meu plano?</h4>
                <p>Antes da data limite para do encerramento do programa, o residente deverá cadastrar-se na plataforma Juno e escolher o plano para seguir como especialista na seção “Meu Plano” {"->"} “Efetivar cadastro pós-residência”. Em caso de não adesão antes da data máxima de término do período gratuito, os serviços Endo Cloud serão interrompidos. </p>
              </div>
              <div>
                <h4>21. Como posso mudar de plano?</h4>
                <p>O usuário especialista que deseja mudar de plano, deve ir à seção “Meu plano” {"->"} “mudança de plano” e fazer a alteração que deseja. </p>
              </div>
              <div>
                <h4>22. A plataforma de pagamento é segura?</h4>
                <p>Sim. A plataforma Juno é uma empresa que trabalha há mais de 15 anos no mercado e cada transação conta com sistema anti-fraude e check-out seguro. </p>
              </div>
            </div>
            <footer>
              <div onClick={() => setModal(false)}>
                <ArrowLeft />
              Voltar
              </div>
            </footer>

          </div>
        </Modal>
      }

    </div>
  )
}

export default Ajuda