//@ts-nocheck
import React, { ReactElement, HTMLAttributes } from "react"
import { ArrowLeft } from 'react-feather'
import { Link } from 'react-router-dom'
import Button from '../../components/Button'
import "./styles.css"

interface FooterProps extends HTMLAttributes<HTMLElement> {
    name: string;
    link_back: string;
    link_btn?: string;
    name_btn: string;
    icon_btn?: ReactElement<any, any>;
    state_btn?: boolean;

    // como receber as props para mexer no botão?
}

function Footer({ name, link_back, link_btn, name_btn, icon_btn, state_btn, ...rest }: FooterProps) {
    return (

        <footer className="footer-padrao">
            <main className="container">
                {
                    state_btn ?
                        <>
                            <Link to={link_back}>
                                <ArrowLeft />
                                {name}
                            </Link>
                            {
                                link_btn ?
                                    <Link to={link_btn}>
                                        <Button {...rest} >
                                            {icon_btn}
                                            {name_btn}
                                        </Button>
                                    </Link> :
                                    <Button {...rest} >
                                        {icon_btn}
                                        {name_btn}
                                    </Button>
                            }
                        </> :
                        <>
                            <Link to={link_back}>
                                <ArrowLeft />
                                {name}
                            </Link>
                            <Button disabled {...rest}>
                                {icon_btn}
                                {name_btn}
                            </Button>
                        </>
                }
            </main>
        </footer>

    )
}

export default Footer;