//@ts-nocheck
import React, { useState, useEffect } from 'react'
import { GetToken } from '../../../utils/auth'
import EditarDadosResidente from "../../../components/EditarDadosResidente"
import EditarDadosMedico from "../../../components/EditarDadosMedico"
import './styles.css'

function EditarDados() {

  const [typeUser, setTypeUser] = useState('');

  useEffect(() => {
    const getUserInfo = async () => {
    const { typeUser } = await GetToken()
    setTypeUser(typeUser)
  }
  getUserInfo()
  }, [])

  return (
    <div>
      {typeUser === "residente" ? 
      <EditarDadosResidente />
      :
      <EditarDadosMedico />
      }
    </div>
  )
}

export default EditarDados
