//@ts-nocheck
import React, { useState } from "react"
import { Link } from "react-router-dom"
import "./styles.css"
import { UserPlus, X } from "react-feather"
import Input from "../../../components/Input"
import Radio from "../../../components/Radio"
import Header from "../../../components/Header"
import Background from "../../../assets/Background"
import Footer from "../../../components/Footer"
import Dropdown from "../../../components/Dropdown"
import Button from "../../../components/Button"
import { SetPaciente } from "../../../utils/auth"
import Modal from "../../../components/Modal"
import { convenios } from "../../../utils/dados/convenios"
import { calculaIdade } from '../../../utils/metodos/dataIdade'

interface DataDropdown {
    value: string;
    label: string;
}

interface PacienteProps {
    nome?: string;
    sexo?: string;
    dt_nascimento?: string;
    convenio?: string;
    senha?: string;
}

function NovoPaciente() {

    const [idade, setIdade] = useState("")

    const [response, setResponse] = useState(null)

    const setPaciente = async () => {
        const resp = await SetPaciente(infoPaciente)
        setResponse(resp)
    }

    const stateInfoPacienteInitial = {
        nome: "",
        sexo: "Masculino",
        dt_nascimento: "",
        convenio: "",
    }

    const [infoPaciente, setInfoPaciente] = useState<PacienteProps>({...stateInfoPacienteInitial});

    const stateConvenioInitial = {
        label: 'Selecione o convênio',
        value: 'Selecione o convênio',
    }

    const [convenio, setConvenio] = useState<DataDropdown>({...stateConvenioInitial});

    function handleSetConvenio({ value }: DataDropdown) {
        setConvenio({
            label: value,
            value: value
        });
        setInfoPaciente({...infoPaciente, convenio: value});
    }

    function closeModalAndClearFields(stateModal: boolean = false) {
        setModalOn(stateModal);
        setResponse(null);
        setInfoPaciente({...stateInfoPacienteInitial});
        setConvenio({...stateConvenioInitial})
    }

    const [sexo, setSexo] = useState("Masculino");

    const [modalOn, setModalOn] = useState(false)

    return (
        <div className="container-paciente-consulta">
            <Background />
            <Header name="Novo Paciente" />
            <div className="form-paciente-consulta">
                <div className="form-elements">
                    
                    <div className="paciente-nome">
                        <span>Nome*</span>
                            <Input 
                                name="name-patient"
                                type="text"
                                placeholder="Nome Sobrenome"
                                value={infoPaciente.nome}
                                onChange={(e) => {
                                    setInfoPaciente({...infoPaciente, nome: e.target.value})
                                }}
                             />
                        <span>Sexo*</span>

                        <Radio id="masc" label="M" state={sexo === "Masculino"} onChangeRadio={() => {
                            setSexo("Masculino")
                            setInfoPaciente({...infoPaciente, sexo: "Masculino"})
                            }}/>
                        <Radio id="fem" label="F" state={sexo === "Feminino"} onChangeRadio={() => {
                            setSexo("Feminino")
                            setInfoPaciente({...infoPaciente, sexo: "Feminino"})
                            }}/>
                    </div>
                    <div className="paciente-data-idade-id">
                        <span>Data de Nascimento*</span>
                        <Input 
                            name="data-nascimento"
                            type="date"
                            placeholder="08/11/2001"
                            onChange={(e) => {
                                let date = String(e.target.value)
                                let dateArray = date.split("-")
                                let dateStr = `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`
                                setIdade(String(calculaIdade(dateStr)))
                                setInfoPaciente({...infoPaciente, dt_nascimento: String(e.target.value)})
                            }}
                            value={infoPaciente.dt_nascimento}
                        />
                        <span>Idade*</span>
                        <Input
                            disabled 
                            name="idade-paciente"
                            type="text"
                            style={{width: "70px"}}
                            value={idade}
                        />
                    </div>
                    <div className="paciente-hospital">
                        <div>
                            <span>Convênio*</span>
                            <Dropdown
                                placeholder="Selecione seu convênio"
                                noOptionsMsg="Nenhum convênio encontrado"
                                options={
                                    convenios.map(item => {
                                    return {
                                        label: item,
                                        value: item,
                                    }
                                    })
                                }
                                value={convenio}
                                onChange={handleSetConvenio}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {modalOn && <Modal setModal={closeModalAndClearFields} children={
                <div className="modal-novo-paciente">
                    <X
                        size={30} 
                        color={"#74799C"} 
                        style={{marginTop: "30px", marginBottom: "22px", marginLeft: "700px", cursor: "pointer"}}
                        onClick={() =>  closeModalAndClearFields()}
                    />
                    {response === null ? 
                    <h4 style={{marginBottom: "56px"}}>Carregando...</h4>
                    :
                    response ?
                    <>
                        <h4 style={{color: "#2FBDBC", marginBottom: "56px"}}>Paciente cadastrado com sucesso</h4>
                        <Link to="/home" style={{textDecoration: "none"}}>
                            <Button color="#FFFFFF" backgroundColor="#6A79D7" width="120px" style={{padding: "12px", fontSize: "18px", marginBottom: "30px"}} >
                                Ir para página inicial
                            </Button>
                        </Link>
                    </>    
                    : 
                    <h4 style={{color: "#BC2A2A", marginBottom: "56px"}}>Erro inesperado, tente novamente mais tarde!</h4>
                    }
                </div>
            } />}
            <Footer 
                name="Voltar" 
                link_back="/" 
                link_btn="#" 
                name_btn="Adicionar Paciente" 
                icon_btn={<UserPlus />} 
                state_btn={
                    infoPaciente.nome !== "" && 
                    infoPaciente.dt_nascimento !== "" && 
                    infoPaciente.convenio !== ""
                }
                onClick={() => {
                    setModalOn(true)
                    setPaciente()
                }}
                style={{width: "230px"}}
            />
        </div>
    )
}

export default NovoPaciente