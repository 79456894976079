import React, { useState } from 'react'
import { useHistory } from "react-router-dom";
import Thumbnail from '../../components/ThumbnailPost';
import './styles.css'

export default function BlogListPostAdmin() {

  const [pageSelected, setPageSelected] = useState('admin-assinantes');

  let history = useHistory();

  function ChangePage(router: string){
    history.push(router)
  }


  return (
    <div  className="page-blog-list">
      <div className="navbar-sec">
        <nav className="container">
          <ul>
            <li
              className={pageSelected === "admin-assinantes" ? "selected" : ''}
              onClick={() => {
                ChangePage("/admin/blog")
              }}
            >Minhas Postagens</li>
            <li
              className={pageSelected === "admin-residentes" ? "selected" : ''}
              onClick={() => {
                ChangePage("/admin/blog/nova-postagem");
              }}
            >+ Nova Postagem</li>
          </ul>

          <span>Olá Anderson!</span>

        </nav>
      </div> 
      
      <main>
        <header>
          <p> Minhas Postagens </p>
        </header>
        <section>
          <div style={{paddingBottom: '90px'}} >

            {dataPosts.map(cur => {
              return (
                <Thumbnail _id={cur._id} createdAt={cur.createdAt} image_url={cur.image_url} title={cur.title} resume={cur.resume} />
                )
            })}

          </div>
        </section>
      </main>

    </div>
  )
}


const dataPosts = [
  {
    _id: '0986421',
    createdAt: '10 de Janeiro de 2021 ',
    image_url: 'https://www.fundacred.org.br/site/wp-content/uploads/2019/06/curso-de-medicina-640x340.jpg',
    title: 'Laudo de endoscopia em segundos! Muitos laudos de endoscopia em poucos segundos!',
    resume: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.'
  },
  {
    _id: '09234421',
    createdAt: '10 de Janeiro de 2021 ',
    image_url: 'https://www.fundacred.org.br/site/wp-content/uploads/2019/06/curso-de-medicina-640x340.jpg',
    title: 'Laudo de endoscopia em segundos!',
    resume: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.'
  },
  {
    _id: '098fa21',
    createdAt: '10 de Janeiro de 2021 ',
    image_url: 'https://www.fundacred.org.br/site/wp-content/uploads/2019/06/curso-de-medicina-640x340.jpg',
    title: 'Laudo de endoscopia em segundos!',
    resume: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.'
  },
  {
    _id: '0006421',
    createdAt: '10 de Janeiro de 2021 ',
    image_url: 'https://www.fundacred.org.br/site/wp-content/uploads/2019/06/curso-de-medicina-640x340.jpg',
    title: 'Laudo de endoscopia em segundos!',
    resume: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.'
  }
]