//@ts-nocheck
import React, { InputHTMLAttributes, ReactElement } from 'react'

import './styles.css'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  icon?: ReactElement<any, any> | null;
  style?: React.CSSProperties;
}

/**
 * Input padrão
 */
function Input({ name, icon, style, ...rest }: InputProps) {
  return (
    <div
      className="input-padrao"
      style={{
        display: icon ? 'block' : ''
      }}
    >
      <input
        id={name}
        name={name}
        {...rest}
        style={{
          paddingLeft: icon ? 49 : '',
          ...style
        }}
      />
      {icon}
    </div>
  )
}

export default Input
