//@ts-nocheck
import React from 'react';
import { G, Path, Svg, Defs, ClipPath, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  background: {
    marginTop: 65,
    position: "absolute"
  }
});
interface BackgroundColonoProps {
  color: string;
}

function BackgroundColono({ color }: BackgroundColonoProps) {
  return (
    <Svg width="487" height="641" viewBox="0 0 487 641" style={styles.background}>
      <G opacity={0.3} clip-path="url(#clip0)">
        <Path opacity={0.05} d="M-34.8273 376.606C-55.7153 333.138 -68.8169 286.42 -60.5597 237.4C-55.4396 207.01 -41.4256 177.786 -20.0301 155.561C2.64037 132.012 34.3986 118.254 63.3233 104.159C96.4082 88.0316 131.293 75.5922 167.136 67.1884C186.552 62.6349 206.523 59.2454 226.401 61.0235C268.857 64.8172 306.857 101.907 324.822 139.169C334.941 160.16 342.843 182.216 348.453 204.829C362.027 259.515 360.982 316.691 350.044 371.642C342.971 407.176 331.125 441.732 315.055 474.171C286.76 531.277 246.944 593.431 173.832 578.426C147.609 573.044 123.719 559.507 101.889 543.966C41.8933 501.24 -3.09012 442.651 -34.8273 376.606Z" fill={color} />
        <Path opacity={0.07} d="M149.965 544.069C170.378 546.912 191.33 541.966 209.966 527.657C240.606 504.135 262.322 465.996 278.461 431.507C302.453 380.23 305.218 323.383 283.183 270.756C268.422 235.508 239.73 192.674 202.367 178.995C174.74 168.88 143.312 173.249 115.874 181.594C93.5144 188.394 72.2846 198.394 51.1448 208.357C35.6096 215.681 19.8944 223.117 6.65812 234.068C-34.4953 268.112 -24.7571 330.712 -6.28866 374.235C15.9299 426.596 59.3362 486.976 102.942 522.64C117.248 534.346 133.431 541.763 149.965 544.069Z" fill={color} />
        <Path opacity={0.12} d="M48.1052 366.763C47.5858 387.334 50.2714 408.518 54.7555 427.969C59.0797 446.735 67.6181 465.509 82.1513 478.562C95.1175 490.21 114.067 491.072 130.543 490.945C150.073 490.793 169.56 486.922 187.638 479.552C193.021 477.357 198.445 474.737 202.243 470.332C216.883 453.366 196.393 406.345 191.063 388.36C178.905 347.323 163.739 290.837 134.975 257.949C130.286 252.588 124.171 248.192 117.183 246.875C93.046 242.326 77.8743 271.676 68.6907 288.986C60.3898 304.628 54.3846 321.502 51.013 338.903C49.2652 347.907 48.3455 357.271 48.1052 366.763Z" fill={color} />
      </G>
      <Defs>
        <ClipPath id="clip0">
          <rect width="430.483" height="494.258" fill="white" transform="translate(-128.537 207.413) rotate(-28.7563)" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export default BackgroundColono;