//@ts-nocheck
import React from 'react'
import Select from 'react-select'

import './styles.css'

const customStyles = {
  option: (provided: any, state: any) => ({
    padding: 15,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: "rgb(238, 238, 238)"
    },
    backgroundColor: state.isSelected ? "rgb(238, 238, 238)" : "",
    fontSize: 16,
  }),
  control: (provided: any, state: any) => ({
    height: '48px',
    display: 'flex',
    background: '#FFFFFF',
    boxShadow: '0px 1px 2px rgba(52, 60, 88, 0.4)',
    borderRadius: '6px',
    padding: '0 5px',
    cursor: "pointer",
    fontSize: 16,
    border: state.isFocused ? '2px solid var(--color-button)' : '2px solid transparent'
  })
}

const DropdownIndicator = () => {
  return (
    <div className="arrow-select"></div>
  );
};

const IndicatorSeparator = () => {
  return <span style={{
    display: 'none'
  }} />;
};

interface SelectProps {
  options: {
    label: string;
    value: string;
  }[];
  value?: {
    label: string;
    value: string;
  };
  onChange: Function;
  disabled?: boolean;
}

function SelectPadrao({ options, value, onChange, disabled }: SelectProps) {
  

  return (
    <Select
      styles={customStyles}
      components={{ DropdownIndicator, IndicatorSeparator }}
      options={options}
      className='react-select-container'
      value={value}
      onChange={(e) => onChange({
        label: e?.label,
        value: e?.value
      })}
      isSearchable={false}
      defaultValue={options[0]}
      isDisabled={disabled}
    />
  )
}

export default SelectPadrao
