import React from 'react'
import { Link } from 'react-router-dom'
import './styles.css'

interface ThumbnailProps {
  _id: string,
  createdAt: string,
  image_url: string,
  title: string,
  resume: string
}

export default function Thumbnail({ _id, createdAt, image_url, title, resume }: ThumbnailProps) {


  const myRouter = '/admin/blog/post/' + _id

  return (
    <Link to={myRouter} style={{ textDecoration: 'none' }}>
      <section className={'thumbnail-container'}>
        <div className={'thumbnail-imgPreview'}>
          <img src={image_url} alt={_id} />
        </div>
        <div className={'thumbnail-infoPost'}>
          <h3 className={'thumbnail-title'}>
            {title}
          </h3>
          <div>
            <p className={'thumbnail-resume'}>
            {resume}
            </p>
          </div>
          <div className={'thumbnail-infoAuthor'}>
              <img src={'https://http2.mlstatic.com/D_NQ_NP_614041-MLB27185740295_042018-O.jpg'} alt="Autor"/>
              <p> Anderson Brasil <span className={'thumbnail-point'}>---</span> <span>{createdAt} </span></p>
            </div>
        </div>
      </section>
    </Link>
  )
}
