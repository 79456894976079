//@ts-nocheck
import React, {ReactElement} from "react"
import "./styles.css"
import {CheckCircle} from "react-feather"
import BackgroundTemplate1 from '../../assets/BackgroundTemplate1'
import BackgroundTemplate2 from '../../assets/BackgroundTemplate2';
import BackgroundTemplate3 from "../../assets/BackgroundTemplate3";

interface DivProps {
    active: boolean;
    background: string;
    icon: ReactElement<any, any>;
    color?: string
}

function MiniLaudo( { icon, background, active, color }: DivProps ) {
    return (
        <div className={ active ?  "target-template" : "template"}>
            {background === "1"? <BackgroundTemplate1 color={color} /> : background === "2"? <BackgroundTemplate2 color={color} /> : background === "3"? <BackgroundTemplate3 color={color} /> : <span style={{position: "absolute", top: "85px", left: "18px"}}>Fundo branco</span> }
            <div>
                {icon}
                {active ? <CheckCircle size={20} color={"#2FBDBC"} /> : <div></div>}
            </div>
        </div>
    )
}

export default MiniLaudo