//@ts-nocheck
import React from 'react'

import './styles.css'

interface ModalInfoProps {
  setModal: Function; // usada para fechar ou abrir o modal
  titulo: string;
  info: string;
  children: React.ReactNode;
}

function ModalInfo({ setModal, titulo, info, children }: ModalInfoProps) {

  window.addEventListener('click', (e) => {
    if ((e.target as HTMLElement).id === "modal-alerta-info-container") {
      setModal(false);
    }
  })

  window.addEventListener('keyup', (e) => {
    if (e.key === "Escape") {
      setModal(false);
    }
  })

  return (
    <div className="modal-alerta-info-container" id="modal-alerta-info-container">
      <div className="modal-alerta-info">
        <h1>{titulo}</h1>
        <p>{info}</p>
        {children}
      </div>
    </div>
  )
}

export default ModalInfo
