import React, { useState, useEffect, useContext } from 'react';
import { Check, Camera } from 'react-feather';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import './styles.css';
import Background from '../../../assets/Background';
import BackgroundTemplate1 from "../../../assets/BackgroundTemplate1"
import BackgroundTemplate2 from "../../../assets/BackgroundTemplate2"
import { GetToken, GetUser, GetTemplateLaudo, SetLaudo, GetOneTemplateLaudo } from '../../../utils/auth'
import { LaudoContext } from "../../../utils/LaudoProvider"
import { cricofaringeoDefault, duodenoDefault, esofagoDefault, estomagoDefault } from '../LaudoPersonalizado/FluxoLaudos';

import './styles.css';
import { useHistory } from 'react-router-dom';
import Laudo, { TitleContent, TextContent, Notes, TitleAndText} from '../../../components/Laudo';
import { NavbarContext } from '../../../providers/NavbarProvider';

interface PacienteProps {
  nome: string;
  sexo: string;
  idade: string;
  cadastro: string;
  convenio: string;
  indicacao: string;
}

interface LaudoProps {
  sedoanalgesia: string;
  passagem: string;
  esofago: string[],
  estomago: string[],
  duodeno: string[],
  conclusao: string;
  imgEsofago: string;
  imgEstomago: string;
  imgDuodeno: string;
  nota: string;
}

interface TemplateProps {
  aparelho: string;
  backgroundTemplate: string;
  color: string;
  comImg: string;
  icon: string;
  nome_laudo: string;
  cabecalho: {
    clinica: string;
    endereco: string;
    telefone: string;
  }
}

function VisualizacaoLaudo() {

  function autoResize(id: string) {
    let element = document.getElementById(id);
    if (element) {
      element.style.height = "auto";
      element.style.height = (element.scrollHeight) + "px";
    }
  }

  const [state, setState] = useContext(LaudoContext)


  const [diagnosticosReq, setDiagnosticosReq] = useState<string[]>([]);
  const [terapeuticasReq, setTerapeuticasReq] = useState<string[]>([]);

  const [conteudo, setConteudo] = useState({
    sedoanalgesia: "",
    fentanil: '',
    midazolan: '',
    propofol: '',
    cricofaringeo: '',
    esofago: '',
    estomago: '',
    duodeno: '',
    conclusao: '',
    notas: '',
    id_paciente: 0,
    senha: "",
    nome: "",
    data_nasc: "",
    idade: 0,
    sexo: "",
    convenio: "",
    medico_solicitante: "",
    dt_exame: "",
    indicacoes: "",
    aparelho: "", // vem do template
    exame_realizado: ""
  })

  function stringBuilderSedoanalgesia() {
    let newString = state.laudo.sedoanalgesia === "sedoanalgesia1" ?
      "Aos cuidados do(a) anestesia" : state.laudo.sedoanalgesia === "sedoanalgesia2" ?
        "Aos cuidados do(a) médico(a) assistente" : "Lidocaína Spray";

    let fentanil = `${state.laudo.fentanil ? `${state.laudo.fentanil}mcg` : ""}`
    let midazolan = `${state.laudo.midazolan ? `${state.laudo.midazolan}mg` : ""}`
    let propofol = `${state.laudo.propofol ? `${state.laudo.propofol}mg` : ""}`

    if (fentanil || midazolan || propofol) {
      newString += " | sedoanalgesia com ";

      newString += fentanil && midazolan && propofol
        ? `midazolan ${midazolan} + fentanil ${fentanil} + propofol ${propofol}.`
        : fentanil && midazolan
          ? `midazolan ${midazolan} + fentanil ${fentanil}.`
          : fentanil && propofol
            ? `fentanil ${fentanil} + propofol ${propofol}.`
            : midazolan && propofol
              ? `midazolan ${midazolan} + propofol ${propofol}.`
              : `${fentanil
                ? `fentanil ${fentanil}`
                : midazolan
                  ? `midazolan ${midazolan}`
                  : `propofol ${propofol}`}.`;
    }

    return newString;
  }

  function stringBuilder(array: string[]) {
    let newString = array.reduce((accumulator, item) => `${accumulator}${item}\n`, '');

    return newString;
  }

  function stringBuilderDescricao(array: any[]) {
    let newString = array.reduce((accumulator, item) =>
      `${accumulator}${item.descricao}${item.bx ? " (Biopsiamos)" : ''}\n`, ''
    );

    return newString;
  }

  function stringBuilderConclusao() {
    let newString = '';

    newString += state.laudo.cricofaringeo === "passagem-no-cricofaríngeo-normal" ?
      "Passagem no cricofaríngeo normal\n" : stringBuilderDiagnostico(state.laudo.cricofaringeo, cricofaringeoDefault);

    newString += state.laudo.esofago === "esofago-normal" ?
      "Esôfago normal\n" : state.laudo.esofago === "esofago-nao-examinado" ? "" : stringBuilderDiagnostico([...state.laudo.esofago.diagnostico, ...state.laudo.esofago.terapeutica], esofagoDefault);

    newString += state.laudo.estomago === "estomago-normal" ?
      "Estômago normal\n" : state.laudo.estomago === "estomago-nao-examinado" ? "" : stringBuilderDiagnostico([...state.laudo.estomago.diagnostico, ...state.laudo.estomago.terapeutica], estomagoDefault);

    newString += state.laudo.duodeno === "duodeno-normal" ?
      "Duodeno normal\n" : state.laudo.duodeno === "duodeno-nao-examinado" ? "" :
        stringBuilderDiagnostico([...state.laudo.duodeno.diagnostico, ...state.laudo.duodeno.terapeutica], duodenoDefault);

    return newString;
  }

  function stringBuilderDiagnostico(array: any[], defaultArray: string[]) {
    let newString = array.reduce((accumulator, item) => {
      if (!defaultArray.includes(item.diagnostico)) {
        
        //dados para requisição
        if (item.descricao.includes("TERAPÊUTICA / PROCEDIMENTO")) {
          setTerapeuticasReq([...terapeuticasReq, item.diagnostico]);
        }
        else {
          setDiagnosticosReq([...diagnosticosReq, item.diagnostico]);
        }

        return `${accumulator}${item.diagnostico}\n`;
      }
      else {
        return `${accumulator}`;
      }
    }, '');

    // let newString = array.reduce((accumulator, item) =>
    //   `${accumulator}${defaultArray.includes(item.diagnostico) ? "" : `${item.diagnostico}\n`}`, ''
    // );

    return newString;
  }

  function stringBuilderNotas(array: any[]) {
    let newString = array.reduce((accumulator, item) => `${accumulator}${item ? `${item}\n` : ""}`, '');

    return newString;
  }

  function mask(value: string) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{0})(\d)/, '$1($2')
      .replace(/(\d{2})(\d)/, '$1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
  }

  const [aparelho, setAparelho] = useState("OLYMPUS C-180");

  const [infoUser, setInfoUser] = useState<any>();
  const [type, setType] = useState("")

  const [idTemplateFavorito, setIdTemplateFavorito] = useState<number>();

  useEffect(() => {
    const getUserInfo = async () => {
      const { token, typeUser } = await GetToken()
      const user = await GetUser(token)
      setInfoUser(user)
      setType(typeUser)
    }
    getUserInfo()

  }, [])

  const [navbarInfo] = useContext(NavbarContext);
  const [infoTemplate, setInfoTemplate] = useState<any>();

  useEffect(() => {
    const getTemplateLaudo = async () => {
      const response = await GetOneTemplateLaudo(navbarInfo.laudoId === null ? 1 : navbarInfo.laudoId);
      setIdTemplateFavorito(navbarInfo.laudoId === null ? 1 : navbarInfo.laudoId);
      setInfoTemplate(response.conteudo);
    }
    getTemplateLaudo()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      let elements = document.querySelectorAll('textarea');
      elements.forEach(element => {
        autoResize(element.id);
      });
    }, 500);
  }, [])

  const [imgsTemplate, setImgsTemplate] = useState({
    esofago: '',
    estomago: '',
    duodeno: ''
  });

  const uploadImage = async (e: any, value: string) => {
    const file = e.target.files[0]
    const base64 = await convertBase64(file)
    let strBase64 = String(base64)


    /*const obj = {
      ...infoLaudo
    }*/

    if (value === "esofago") {
      setState({ ...state, template: { ...state.template, imgEsofago: strBase64 } })
      setImgsTemplate({ ...imgsTemplate, esofago: strBase64 })
    } else if (value === "estomago") {
      setState({ ...state, template: { ...state.template, imgEstomago: strBase64 } })
      setImgsTemplate({ ...imgsTemplate, estomago: strBase64 })
    } else if (value === "duodeno") {
      setState({ ...state, template: { ...state.template, imgDuodeno: strBase64 } })
      setImgsTemplate({ ...imgsTemplate, duodeno: strBase64 })
    }

  }

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  async function registerNewLaudo() {
    let dataReq = {
      conteudo: {
        ...conteudo,
        template: {
          ...infoTemplate,
          ...imgsTemplate
        }
      },
      id_paciente: conteudo.id_paciente,
      id_template: idTemplateFavorito,
      // dados para estatítica
      /*
      exame_realizado: conteudo.exame_realizado,
      diagnostico: diagnosticosReq,
      procedimento_terapeutico: terapeuticasReq,
      indicacoes: conteudo.indicacoes
      */
    }
    await SetLaudo(dataReq);

    // att ctx
    setState({
      ...conteudo,
      nome_medico: infoUser?.nome,
      crm: type === "medico" ? infoUser?.medico?.crm : infoUser?.residente?.crm,
      email: infoUser.email,
      template: {
        ...infoTemplate,
        ...imgsTemplate
      }
    })
  }

  const history = useHistory();

  useEffect(() => {
    if (state.finalizado) {
      history.push('/novo-laudo');
    }
    else {
      const newConteudo = {
        sedoanalgesia: state.isLaudoExpress ? state.laudo.sedoanalgesia : stringBuilderSedoanalgesia(),
        fentanil: state.isLaudoExpress ? state.laudo.fentanil : `${state.laudo.fentanil ? `${state.laudo.fentanil}mcg` : ""}`,
        midazolan: state.isLaudoExpress ? state.laudo.midazolan : `${state.laudo.midazolan ? `${state.laudo.midazolan}mg` : ""}`,
        propofol: state.isLaudoExpress ? state.laudo.propofol : `${state.laudo.propofol ? `${state.laudo.propofol}mg` : ""}`,
        cricofaringeo: state.isLaudoExpress ? state.laudo.cricofaringeo : state.laudo.cricofaringeo === "passagem-no-cricofaríngeo-normal"
          ? stringBuilder(cricofaringeoDefault)
          : stringBuilderDescricao(state.laudo.cricofaringeo),
        esofago: state.isLaudoExpress ? state.laudo.esofago : state.laudo.esofago === "esofago-normal" ? stringBuilder(esofagoDefault)
          : state.laudo.esofago === "esofago-nao-examinado" ? "Não examinado" : stringBuilderDescricao([...state.laudo.esofago.diagnostico, ...state.laudo.esofago.terapeutica]),
        estomago: state.isLaudoExpress ? state.laudo.estomago : state.laudo.estomago === "estomago-normal" ? stringBuilder(estomagoDefault)
          : state.laudo.estomago === "estomago-nao-examinado" ? "Não examinado" : stringBuilderDescricao([...state.laudo.estomago.diagnostico, ...state.laudo.estomago.terapeutica]),
        duodeno: state.isLaudoExpress ? state.laudo.duodeno : state.laudo.duodeno === "duodeno-normal" ? stringBuilder(duodenoDefault)
          : state.laudo.duodeno === "duodeno-nao-examinado" ? "Não examinado" : stringBuilderDescricao([...state.laudo.duodeno.diagnostico, ...state.laudo.duodeno.terapeutica]),
        conclusao: state.isLaudoExpress ? state.laudo.conclusao : stringBuilderConclusao(),
        notas: state.isLaudoExpress ? state.laudo.notas : stringBuilderNotas(state.laudo.notas),
        id_paciente: state.paciente.id,
        senha: state.paciente.senha,
        nome: state.paciente.nome,
        data_nasc: state.paciente.data_nasc,
        idade: state.paciente.idade,
        sexo: state.paciente.sexo,
        convenio: state.paciente.convenio,
        medico_solicitante: state.paciente.medico_solicitante,
        dt_exame: state.paciente.dt_exame,
        indicacoes: state.paciente.indicacoes,
        aparelho: "", // vem do template
        exame_realizado: state.paciente.exame
      }
      setConteudo({...newConteudo})
    }
  }, [state]);
  return (
    <div className="page-visualizacao-laudo">
      <Background />
      <Header name="Visualização / Edição de laudo" />
      <div className="pre-laudo">
        <Laudo 
          withImage={false}
          colorTemplate={infoTemplate ? infoTemplate.color : 'black'} 
          indexBackGround={Number(infoTemplate ? infoTemplate.backgroundTemplate : 0)} 
          headerData={{
              hospitalName: infoTemplate ? infoTemplate.cabecalho?.clinica : 'Einstein Hospital Israelita',
              hospitalRua: infoTemplate ? infoTemplate.cabecalho?.endereco : 'Rua Riachuelo 900 Papicu - Fortaleza/CE',
              hospitalTelefone: infoTemplate ? infoTemplate.cabecalho?.telefone : '(99) 99999-9999'
              }
          }
          modelo={infoTemplate ? infoTemplate.aparelho : 'OLYMPUS C-180'}
          content={
              <div>
              {TitleContent({title: 'Esôfago', color: infoTemplate ? infoTemplate.color : 'black'})}
              {TextContent({
                text: conteudo.esofago,
                setText: (e: any) => setConteudo({ ...conteudo, esofago: e.target.textContent })
              })}
              {TitleContent({title: 'Estômago', color: infoTemplate ? infoTemplate.color : 'black'})}
              {TextContent({
                text: conteudo.estomago,
                setText: (e: any) => setConteudo({ ...conteudo, estomago: e.target.textContent })
              })} 
              {TitleContent({title: 'Duodeno', color: infoTemplate ? infoTemplate.color : 'black'})}
              {TextContent({
                text: conteudo.duodeno,
                setText: (e: any) => setConteudo({ ...conteudo, duodeno: e.target.textContent })
              })}
              {TitleContent({title: 'Conclusão', color: infoTemplate ? infoTemplate.color : 'black'})}
              {TextContent({
                text: conteudo.conclusao,
                setText: (e: any) => { setConteudo({ ...conteudo, conclusao: e.target.textContent })}
              })}
              </div>
          }
          medicName={infoUser !== undefined ? infoUser.nome : 'Seu nome aqui!'}
          notes={
              <Notes> 
                  <span>Notas:</span>
                  <input value={conteudo.notas} onChange={(e: any) => setConteudo({ ...conteudo, notas: e.target.value })} />
              </Notes>
          }
          logo={infoTemplate ? infoTemplate.icon : null}
          headerContent={
            <div>
                <div>
                    {TitleAndText({
                      title: 'Paciente',
                      text: state.paciente.nome, 
                      setText: (e: any) => setState({...state, paciente: {...state.paciente, nome: e.target.textContent}})
                    })}
                    {TitleAndText({
                      title: 'Sexo', 
                      text: state.paciente.sexo,
                      setText: (e: any) => setState({...state, paciente: {...state.paciente, sexo: e.target.textContent}})
                    })}
                    {TitleAndText({ 
                      title:'Idade', 
                      text: state.paciente.idade,
                      setText: (e: any) => setState({...state, paciente: {...state.paciente, idade: e.target.textContent}})
                    })}
                    {TitleAndText({ 
                      title:'Data do exame', 
                      text: state.paciente.dt_exame,
                      setText: (e: any) => setState({...state, paciente: {...state.paciente, dt_exame: e.target.textContent}})
                    })}
                    {TitleAndText({ 
                      title:'indicação',
                      text: state.paciente.indicacoes,
                      setText: (e: any) => setState({...state, paciente: {...state.paciente, indicacoes: e.target.textContent}})
                    })}
                </div>
                <div>
                    {TitleAndText({ 
                      title:'Cadastro', 
                      text: state.paciente.id
                    })}
                    {TitleAndText({ 
                      title:'Convênio', 
                      text: state.paciente.convenio,
                      setText: (e: any) => setState({...state, paciente: {...state.paciente, convenio: e.target.textContent}})
                    })}
                    {TitleAndText({ 
                      title: 'Médico solicitante', 
                      text: state.paciente.medico_solicitante,
                      setText: (e: any) => setState({...state, paciente: {...state.paciente, medico_solicitante: e.target.textContent}})
                    })}
                    {TitleAndText({ 
                      title:'Aparelho/modelo', 
                      text: infoTemplate?.aparelho,
                      setText: (e: any) => setInfoTemplate({ ...infoTemplate, aparelho: e.target.textContent })
                    })}
                </div>
            </div>
        }
        subHeaderContent={
            <div>
                {TitleAndText({title: 'Sedoanalgesia', text: 'Anestesia orofaríngea com lidocaína spray | endovenosa com Midazolam 3mg + Fentanil 50mcg + Propofol 40mg'})}
                {TitleAndText({title: 'Passagem no Cricofaríngeo', text: 'Passagem do aparelho sob visão direta pelo crocofaringeo sem obstáculos ou alterações'})}
            </div>
        }
        /> 
      </div>

      <Footer name="Voltar" link_back="/" link_btn="/novo-laudo/visualizacao-de-laudo/conclusao-laudo" name_btn="Finalizar" icon_btn={<Check />} state_btn={true} onClick={() => {
        // e.preventDefault()
        // setState({
        //   ...state, template: {
        //     ...state.template, aparelho: infoTemplate?.aparelho,
        //     backgroundTemplate: infoTemplate?.backgroundTemplate, color: infoTemplate?.color,
        //     comImg: infoTemplate?.comImg, icon: infoTemplate?.icon, nome_laudo: infoTemplate?.nome_laudo,
        //     cabecalho: {
        //       ...state.template.cabecalho, clinica: infoTemplate?.cabecalho.clinica,
        //       endereco: infoTemplate?.cabecalho.endereco, telefone: infoTemplate?.cabecalho.telefone
        //     }
        //   }
        // });
        
        registerNewLaudo()
      }} />
    </div>
  );
}

export default VisualizacaoLaudo;