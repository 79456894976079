//@ts-nocheck
import React, { useState, useEffect } from 'react'
// import { Link } from 'react-router-dom'

import MeusDadosResidente from "../../components/MeusDadosResidente"
import MeusDadosMedico from "../../components/MeusDadosMedico"

import { GetToken } from '../../utils/auth'
//import TesteStyleguide from '../../components/TesteStyleguide'
//import { dataLaudosFake } from '../MinhaNuvem/dados'
//import { userInfo } from 'os'
import './styles.css'

function MeusDados() {

  const [typeUser, setTypeUser] = useState('');

  useEffect(() => {
    const getUserInfo = async () => {
    const { typeUser } = await GetToken()
    setTypeUser(typeUser)
  }
  getUserInfo()
  }, [])

  return (
    <div >
      {typeUser === "residente" ? 
      <MeusDadosResidente />
      :
      <MeusDadosMedico />
      }
    </div>
  )
}

export default MeusDados
