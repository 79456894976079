// LAUDO CRICOFARINGEO
export const cricofaringeoDefault = [
  "Passagem do aparelho sob visão direta pelo cricofaríngeo sem obstáculos ou alterações."
]

export const fluxoCricofaringeo = [
  {
    descricao: 'Passagem do aparelho com acentuada dificuldade ao nível do cricofaríngeo sem fator obstrutivo presente.',
    del: ["0"],
    diagnostico: "PASSAGEM COM DIFICULDADE PELO CRICOFARÍNGEO",
  },
  {
    descricao: 'Passagem do aparelho com leve resistência ao nível do cricofaríngeo sem fator obstrutivo presente.',
    del: ["0"],
    diagnostico: "PASSAGEM COM LEVE RESISTÊNCIA PELO CRICOFARÍNGEO",
  },
  {
    descricao: 'Nota-se estenose completa ao nível do cricofaríngeo que impede a passagem do aparelho.',
    del: ["0"],
    diagnostico: "ESTENOSE COMPLETA AO NÍVEL DO CRICOFARÍNGEO",
  },
  {
    descricao: 'Nota-se, ao nível do cricofaríngeo, lesão neoplásica avançada, vegetante, friável e estenosante que impede a passagem do aparelho.',
    del: ["0"],
    diagnostico: "NEOPLASIA AVANÇADA COM ESTENOSE COMPLETA AO NÍVEL DO CRICOFARÍNGEO",
  },
  {
    descricao: 'Nota-se, ao nível do cricofaríngeo, lesão neoplásica vegetante, friável , que estenosa parcialmente a luz, porém permite a passagem do aparelho com resistência.',
    del: ["0"],
    diagnostico: "NEOPLASIA AVANÇADA COM ESTENOSE PARCIAL AO NÍVEL DO CRICOFARÍNGEO QUE PERMITE A PASSAGEM DO APARELHO"
  },
  {
    descricao: 'Nota-se, ao nível do cricofaríngeo, lesão neoplásica vegetante, friável , que estenosa parcialmente a luz, não permitindo a passagem do aparelho.',
    del: ["0"],
    diagnostico: "NEOPLASIA AVANÇADA COM ESTENOSE PARCIAL AO NÍVEL DO CRICOFARÍNGEO QUE NÃO PERMITE A PASSAGEM DO APARELHO"
  },
  {
    descricao: 'Nota-se, em hipofaringe, lesão neoplásica vegetante, friável , que estenosa parcialmente a luz, porém permite a passagem do aparelho com leve resistência.',
    del: ["0"],
    diagnostico: "LESÃO VEGETANTE EM HIPOFARINGE DE ASPECTO NEOPLÁSICO"
  },
  {
    descricao: 'Nota-se, em hipofaringe, lesão neoplásica vegetante, friável , que estenosa parcialmente a luz, porém permite a passagem do aparelho com leve resistência.',
    del: ["0"],
    diagnostico: "LESÃO NEOPLÁSICA EM HIPOFARINGE"
  },
  {
    descricao: 'Nota-se, em hipofaringe, formação cística não estenosante.',
    del: ["0"],
    diagnostico: "CISTO EM HIPOFARINGE"
  },
  {
    descricao: 'Nota-se, em pregas aritenoideas, formação cística não estenosante.',
    del: ["0"],
    diagnostico: "CISTO EM PREGAS ARITENOIDEAS"
  },
  {
    descricao: 'Nota-se, em pregas aritenoideas, lesão vegetante. (sugerimos avaliação com Otorrinolaringolosita).',
    del: ["0"],
    diagnostico: "LESÃO VEGETANTE EM PREGAS ARITENOIDEAS"
  },
  {
    descricao: 'Nota-se, em hiporafinge, presença de corpo estranho. Retirada com pinça de apreensão.',
    del: ["0"],
    diagnostico: "CORPO ESTRANHO EM HIPOFARINGE"
  },
  {
    descricao: 'Nota-se, ao nível do cricofaríngeo, presença de corpo estranho. Retirada com pinça de apreensão.',
    del: ["0"],
    diagnostico: "CORPO ESTRANHO AO NÍVEL DO CRICOFARINGEO"
  },
]

// LAUDO ESOFAGO
export const esofagoDefault = [
  "Calibre e distensibilidade preservados",
  "Ausência de varizes",
  "Relevo mucoso íntegro em todo trajeto",
  "A transição esofagogástrica (TEG) situa-se ao nível do pinçamento diafragmático",
  "Passagem fácil pelo cárdia"
]
export const fluxoEsofago = [
  { diagnostico: "ACALÁSIA DO CÁRDIA", descricao: "Passagem do aparelho pelo cárdia com moderada resistência.", del: ["3", "4"] },
  { diagnostico: "ACALÁSIA DO CÁRDIA INTRANSPONÍVEL AO ENDOSCÓPIO", descricao: "Grande resistência ao nível do cárdia, impossibilitando a passagem do aparelho.", del: ["3", "4"] },
  { diagnostico: "ACHADOS SUGESTIVOS DE ESOFAGITE EOSINOFÍLICA", descricao: "Nota-se mucosa esofágica esbranquiçada, com presença de exsudato e discretos anéis concêntricos sugestivo de esofagite eosinofílica.", del: ["2"] },
  { diagnostico: "ESOFAGITE EOSINOFÍLICA", descricao: "Nota-se mucosa esofágica edemaciada, com presença de placas brancas exsudativas, estrias longitudinais e anéis concêntricos característico de esofagite eosinofílica.", del: ["2"] },
  { diagnostico: "ESOFAGITE EOSINOFÍLICA COM CALIBRE ESOFÁGICO DIMINUÍDO", descricao: "Calibre levemente diminuído. Nota-se mucosa esofágica edemaciada, com presença de placas brancas exsudativas, estrias longitudinais e anéis concêntricos característico de esofagite eosinofílica.", del: ["0", "2"] },
  { diagnostico: "ESOFAGITE EOSINOFÍLICA EM ESÔFAGO PROXIMAL", descricao: "Nota-se mucosa esofágica esbranquiçada, com presença placas de exsudato e discretos anéis concêntricos sugestivo de esofagite eosinofílica em esôfago proximal.", del: ["2"] },
  { diagnostico: "ESOFAGITE EOSINOFÍLICA EM ESÔFAGO PROXIMAL E MÉDIO", descricao: "Nota-se mucosa esofágica esbranquiçada, com presença placas de exsudato e discretos anéis concêntricos sugestivo de esofagite eosinofílica em esôfago médio e proximal.", del: ["2"] },
  { diagnostico: "ESOFAGITE EOSINOFÍLICA EM TODA EXTENSÃO ESOFÁGICA", descricao: "Nota-se mucosa esofágica esbranquiçada, com presença placas de exsudato e discretos anéis concêntricos sugestivo de esofagite eosinofílica em toda extensão esofágica.", del: [""] },
  { diagnostico: "ALTERAÇÕES ESOFÁGICAS FIBROCICATRICIAIS EM ESÔFAGO PROXIMAL", descricao: "Notam-se áreas de retração fibrocicatricial em esôfago proximal.", del: ["2"] },
  { diagnostico: "ALTERAÇÕES ESOFÁGICAS FIBROCICATRICIAIS EM ESÔFAGO DISTAL", descricao: "Notam-se áreas de retração fibrocicatricial em esôfago distal.", del: ["2"] },
  { diagnostico: "ALTERAÇÕES ESOFÁGICAS FIBROCICATRICIAIS EM ESÔFAGO MÉDIO", descricao: "Notam-se áreas de retração fibrocicatricial em esôfago médio.", del: ["2"] },
  { diagnostico: "ALTERAÇÕES ESOFÁGICAS FIBROCICATRICIAIS EM ESÔFAGO MÉDIO E DISTAL", descricao: "Notam-se áreas de retração fibrocicatricial em esôfago médio e distal.", del: ["2"] },
  { diagnostico: "RETRAÇÕES FIBROCICATRICIAIS EM ESÔFAGO DISTAL PÓS TRATAMENTO DE VARIZES", descricao: "Notam-se áreas de retração fibrocicatricial em esôfago distal decorrentes de terapia endoscópica prévia.", del: ["2"] },
  { diagnostico: "RETRAÇÕES FIBROCICATRICIAIS EM ESÔFAGO MÉDIO PÓS TRATAMENTO DE VARIZES", descricao: "Notam-se áreas de retração fibrocicatricial em esôfago médio decorrentes de terapia endoscópica prévia.", del: ["2"] },
  { diagnostico: "RETRAÇÕES FIBROCICATRICIAIS EM ESÔFAGO MÉDIO E DISTAL PÓS TRATAMENTO DE VARIZES", descricao: "Notam-se áreas de retração fibrocicatricial em esôfago médio e distal decorrentes de terapia endoscópica prévia.", del: ["2"] },
  { diagnostico: "ANEL DE SCHATZKI", descricao: "Nota-se a __ cm da arcada dentária superior (ADS), presença de membrana esofágica fina, anelar e concêntrica que permite a passagem do aparelho sem resistência(anel de Schatzki).", del: ["2"] },
  { diagnostico: "CANDIDÍASE ESOFÁGICA (KODSI I)", descricao: "Notam-se em mucosa esofágica, placas brancas elevadas, com leve enantema, medindo até 2mm.", del: ["2"] },
  { diagnostico: "CANDIDÍASE ESOFÁGICA (KODSI II)", descricao: "Notam-se em mucosa esofágica, placas brancas elevadas, com leve enantema e edema, medindo até 2mm.", del: ["2"] },
  { diagnostico: "CANDIDÍASE ESOFÁGICA (KODSI III)", descricao: "Notam-se em mucosa esofágica, placas brancas elevadas, confluentes, com enantema e ulceração.", del: ["2"] },
  { diagnostico: "CANDIDÍASE ESOFÁGICA (KODSI IV)", descricao: "Calibre diminuído. Notam-se em mucosa esofágica, membranas esbranquiçadas espessas, confluentes, friáveis, que ocupam toda a luz esofágica, causando redução concêntrica do calibre do órgão.", del: ["0", "2"] },
  { diagnostico: "COMPRESSÃO EXTRÍNSECA DO ESÔFAGO", descricao: "Nota-se a __ cm da arcada dentária superior (ADS), abaulamento na luz esofágica por compressão extrínseca que permite a passagem do aparelho sem resistência.", del: ["2"] },
  { diagnostico: "COMPRESSÃO EXTRÍNSECA DO ESÔFAGO PROXIMAL", descricao: "Nota-se em esôfago proximal, abaulamento na luz esofágica por compressão extrínseca que permite a passagem do aparelho sem resistência.", del: ["2"] },
  { diagnostico: "COMPRESSÃO EXTRÍNSECA DO ESÔFAGO MÉDIO", descricao: "Nota-se em esôfago médio, abaulamento na luz esofágica por compressão extrínseca que permite a passagem do aparelho sem resistência.", del: ["2"] },
  { diagnostico: "COMPRESSÃO EXTRÍNSECA DO ESÔFAGO DISTAL", descricao: "Nota-se em esôfago médio, abaulamento na luz esofágica por compressão extrínseca que permite a passagem do aparelho sem resistência.", del: ["2"] },
  { diagnostico: "CORPO ESTRANHO ESOFÁGICO", descricao: "Nota-se a __ cm da arcada dentária superior (ADS), presença de corpo estranho.", del: ["2"] },
  { diagnostico: "CORPO ESTRANHO ESOFÁGICO – REALIZADO REMOÇÃO COM PINÇA ENDOSCÓPICA", descricao: "Nota-se a __ cm da arcada dentária superior (ADS), presença de corpo estranho. Realizamos retirada com pinça endoscópica sem intercorrências.", del: ["2"] },
  { diagnostico: "DIEULAFOY ESOFÁGICO", descricao: "Nota-se a __ cm da arcada dentária superior (ADS), presença de coto vascular visível com sangramento em babação.", del: ["2"] },
  { diagnostico: "DIVERTÍCULO DE ZENKER", descricao: "Nota-se abaixo do cricofaríngeo, duplicidade da luz esofágica com presença de septo e recesso em fundo cego, compatível com divertículo de Zenker.", del: ["0"] },
  { diagnostico: "DIVERTÍCULO ESOFÁGICO DE TRAÇÃO", descricao: "Nota-se em esôfago médio, presença de divertículo, de base larga, compatível com tração esofágica extramural.", del: ["2"] },
  { diagnostico: "PSEUDODIVERTICULOSE ESOFÁGICA", descricao: "Notam-se em esôfago, predominantemente no terço médio e proximal, diminutos receptáculos, intramurais, alguns com secreção esbranquiçada em seu interior, medindo de 1 a 4mm, compatíveis com pseudodivertículos.", del: ["2"] },
  { diagnostico: "MUCOSA GÁSTRICA ECTÓPICA EM ESÔFAGO DISTAL", descricao: "Nota-se em esôfago distal, ilhota de mucosa gástrica ectópica.", del: ["2"] },
  { diagnostico: "MUCOSA GÁSTRICA ECTÓPICA EM ESÔFAGO MÉDIO", descricao: "Nota-se em esôfago médio, ilhota de mucosa gástrica ectópica.", del: ["2"] },
  { diagnostico: "MUCOSA GÁSTRICA ECTÓPICA EM ESÔFAGO PROXIMAL", descricao: "Nota-se em esôfago proximal, ilhota de mucosa gástrica ectópica.", del: ["2"] },
  { diagnostico: "ESÔFAGO DE BARRETT (PRAGA Cz My )", descricao: "Nota-se em esôfago distal, a __ cm da ADS, presença de mucosa vermelho salmão, circunferencial e com presença de digitações, medindo cerca de “y” cm de comprimento máximo e extensão circunferencial de “z” cm.", del: ["2"] },
  { diagnostico: "ESÔFAGO DE BARRETT CURTO", descricao: "Nota-se em esôfago distal, presença de pequena faixa de mucosa vermelho salmão.", del: ["2"] },
  { diagnostico: "ESÔFAGO DE BARRETT LONGO", descricao: "Nota-se em esôfago distal e médio, presença de extensa área de mucosa vermelho salmão.", del: ["2"] },
  { diagnostico: "ESOFAGITE CÁUSTICA ZARGAR 1", descricao: "Nota-se mucosa esofágica com presença de leve edema e enantema.", del: ["2"] },
  { diagnostico: "ESOFAGITE CÁUSTICA ZARGAR 2A", descricao: "Nota-se mucosa esofágica friável com presença de moderado edema, exsudato, erosões e úlceras superficiais.", del: ["2"] },
  { diagnostico: "ESOFAGITE CÁUSTICA ZARGAR 2B", descricao: "Nota-se mucosa esofágica friável com presença de intenso edema, exsudato, ulcerações profundas e hemorragia autolimitada.", del: ["2"] },
  { diagnostico: "ESOFAGITE CÁUSTICA ZARGAR 3A", descricao: "Nota-se mucosa esofágica friável e sangrante com presença de intenso edema, ulcerações profundas e pontos de necrose.", del: ["2"] },
  { diagnostico: "ESOFAGITE CÁUSTICA ZARGAR 3B", descricao: "Nota-se mucosa esofágica friável e sangrante com presença de intenso edema, ulcerações profundas e necrose extensa.", del: ["2"] },
  { diagnostico: "ESOFAGITE NECROTIZANTE – “BLACK” ESÔFAGO", descricao: "Nota-se em todo esôfago, principalmente dos 2/3 distais, mucosa esofágica escurecida, com comprometimento circunferencial difuso, exibindo áreas de necrose, interrompendo-se abruptamente na junção gastroesofágica.", del: ["2"] },
  { diagnostico: "ESOFAGITE DISSECANTE SUPERFICIAL", descricao: "Notam-se em toda extensão esofágica, presença de lâminas de tecido escamoso descamado com mucosa subjacente normal.", del: ["2"] },
  { diagnostico: "ESOFAGITE DE REFLUXO NÃO EROSIVA", descricao: "Presença de mucosa esofágica esbranquiçada sem visualização da trama vascular em terço distal sugestivo de alterações por refluxo gastroesofágico.", del: ["2"] },
  { diagnostico: "ESOFAGITE EROSIVA GRAU A DE LOS ANGELES", descricao: "Notam-se em terço distal, erosões lineares, longitudinais, fibrinosas, menores que 5mm.", del: ["2"] },
  { diagnostico: "ESOFAGITE EROSIVA LEVE (GRAU A DE LOS ANGELES)", descricao: "Notam-se em terço distal, erosões lineares, longitudinais, fibrinosas, menores que 5mm.", del: ["2"] },
  { diagnostico: "ESOFAGITE EROSIVA GRAU B DE LOS ANGELES", descricao: "Notam-se em terço distal, erosões lineares, longitudinais, fibrinosas, maiores que 5mm.", del: ["2"] },
  { diagnostico: "ESOFAGITE EROSIVA LEVE (GRAU B DE LOS ANGELES)", descricao: "Notam-se em terço distal, erosões lineares, longitudinais, fibrinosas, maiores que 5mm.", del: ["2"] },
  { diagnostico: "ESOFAGITE EROSIVA GRAU C DE LOS ANGELES", descricao: "Notam-se em terço distal, erosões lineares, longitudinais, fibrinosas, maiores que 5mm, confluentes e que ocupam até 75% da luz esofágica.", del: ["2"] },
  { diagnostico: "ESOFAGITE EROSIVA MODERADA (GRAU C DE LOS ANGELES)", descricao: "Notam-se em terço distal, erosões lineares, longitudinais, fibrinosas, maiores que 5mm, confluentes e que ocupam até 75% da circunferência esofágica.", del: ["2"] },
  { diagnostico: "ESOFAGITE EROSIVA GRAU D DE LOS ANGELES", descricao: "Notam-se em terço distal, erosões lineares, longitudinais, fibrinosas, maiores que 5mm, confluentes e que ocupam mais de 75% da circunferência esofágica.", del: ["2"] },
  { diagnostico: "ESOFAGITE EROSIVA INTENSA (GRAU D DE LOS ANGELES)", descricao: "Notam-se em terço distal, erosões lineares, longitudinais, fibrinosas, maiores que 5mm, confluentes e que ocupam mais de 75% da circunferência esofágica.", del: ["2"] },
  { diagnostico: "ESOFAGITE POR PÍLULA", descricao: "Notam-se em mucosa de terço distal, duas erosões fibrinosas em espelho sugestivo de agressão mecânica por pílula.", del: ["2"] },
  { diagnostico: "ESOFAGITE EM ESPELHO POR PÍLULA", descricao: "Notam-se em mucosa de terço distal, duas erosões fibrinosas em espelho sugestivo de agressão mecânica por pílula.", del: ["2"] },
  { diagnostico: "STATUS PÓS-ESOFAGECTOMIA COM ESÔFAGOGASTROANASTOMOSE", descricao: "Nota-se a __ cm da arcada dentária superior (ADS), anastomose esofagogástrica ampla, pérvia, sem alterações de mucosa e com a presença de fios de sutura.", del: ["0", "1", "2", "3", "4"] },
  { diagnostico: "STATUS PÓS-ESOFAGECTOMIA COM ESÔFAGOJEJUNOANASTOMOSE", descricao: "Nota-se a __ cm da arcada dentária superior (ADS), anastomose esofagojejunal ampla, pérvia, sem alterações de mucosa e com a presença de fios de sutura.", del: ["0", "1", "2", "3", "4"] },
  { diagnostico: "ESTENOSE TOTAL DE ANASTOMOSE ESÔFAGOJEJUNAL", descricao: "Nota-se a __ cm da arcada dentária superior (ADS), anastomose esofagojejunal com acentuada estenose concêntrica que não permite a passagem do aparelho. Observamos mucosa de anastomose sem alterações e com a presença de fios de sutura.", del: ["0", "1", "2", "3", "4"] },
  { diagnostico: "ESTENOSE PARCIAL DE ANASTOMOSE ESÔFAGOJEJUNAL", descricao: "Nota-se a __ cm da arcada dentária superior (ADS), anastomose esofagojejunal com leve estenose concêntrica que permite a passagem do aparelho. Observamos mucosa de anastomose sem alterações e com a presença de fios de sutura.", del: ["0", "1", "2", "3", "4"] },
  { diagnostico: "ESTENOSE ESOFÁGICA ACTÍNICA", descricao: "Observa-se estenose complexa de esôfago médio de aspecto actínico que permite a passagem do aparelho com leve resistência.", del: ["0", "1"] },
  { diagnostico: "ESTENOSE ESOFÁGICA ACTÍNICA INTRANSPONÍVEL AO ENDOSCÓPIO", descricao: "Observa-se estenose complexa de esôfago médio de aspecto actínico que não permite a passagem do aparelho", del: ["0", "2", "3", "4"] },
  { diagnostico: "ESTENOSE ESOFÁGICA CÁUSTICA", descricao: "Observa-se estenose cáustica de esôfago médio que permite a passagem do aparelho com leve resistência.", del: ["0", "2"] },
  { diagnostico: "ESTENOSE ESOFÁGICA PÉPTICA", descricao: "Observa-se concêntrica redução da luz esofágica distal de aspecto péptico que permite a passagem do aparelho sem resistência.", del: ["0", "2"] },
  { diagnostico: "ESTENOSE ESOFÁGICA POR ESOFAGITE EOSINOFÍLICA", descricao: "Calibre diminuído. Nota-se mucosa esofágica edemaciada, com presença de placas brancas exsudativas, estrias longitudinais e anéis concêntricos característico de esofagite eosinofílica, conferindo redução do calibre esofágico, que impede a passagem do aparelho.", del: ["0", "2"] },
  { diagnostico: "SUBESTENOSE POR NEOPLASIA EM ESÔFAGO", descricao: "Nota-se a __ cm da ADS (arcada dentária superior), lesão vegetante, friável, de aspecto neoplásico, que reduz a luz do órgão e que permite a passagem do aparelho com leve resistência.", del: ["0", "1", "2"] },
  { diagnostico: "SUBESTENOSE POR NEOPLASIA EM ESÔFAGO PROXIMAL", descricao: "Nota-se em esôfago proximal, lesão vegetante, friável, de aspecto neoplásico, que reduz a luz do órgão e que permite a passagem do aparelho com leve resistência.", del: ["0", "1", "2"] },
  { diagnostico: "SUBESTENOSE POR NEOPLASIA EM ESÔFAGO MÉDIO", descricao: "Nota-se em esôfago médio, lesão vegetante, friável, de aspecto neoplásico, que reduz a luz do órgão e que permite a passagem do aparelho com leve resistência.", del: ["0", "1", "2"] },
  { diagnostico: "SUBESTENOSE POR NEOPLASIA EM ESÔFAGO DISTAL", descricao: "Nota-se em esôfago distal, lesão vegetante, friável, de aspecto neoplásico, que reduz a luz do órgão e que permite a passagem do aparelho com leve resistência.", del: ["0", "1", "2"] },
  { diagnostico: "ESTENOSE COMPLETA EM ESÔFAGO POR NEOPLASIA AVANÇADA", descricao: "Nota-se a __ cm da ADS, lesão vegetante, friável, concêntrica, de aspecto neoplásico, com luz esofágica puntiforme que não permite a passagem do aparelho.", del: ["0", "1", "2"] },
  { diagnostico: "ESTENOSE COMPLETA EM ESÔFAGO PROXIMAL POR NEOPLASIA AVANÇADA", descricao: "Nota-se em esôfago proximal, lesão vegetante, friável, concêntrica, de aspecto neoplásico, com luz esofágica puntiforme que não permite a passagem do aparelho.", del: ["0", "1", "2"] },
  { diagnostico: "ESTENOSE COMPLETA EM ESÔFAGO MÉDIO POR NEOPLASIA AVANÇADA", descricao: "Nota-se em esôfago médio, lesão vegetante, friável, concêntrica, de aspecto neoplásico, com luz esofágica puntiforme que não permite a passagem do aparelho.", del: ["0", "1", "2"] },
  { diagnostico: "ESTENOSE COMPLETA EM ESÔFAGO DISTAL POR NEOPLASIA AVANÇADA", descricao: "Nota-se em esôfago distal, lesão vegetante, friável, concêntrica, de aspecto neoplásico, com luz esofágica puntiforme que não permite a passagem do aparelho.", del: ["0", "1", "2"] },
  { diagnostico: "FÍSTULA ESÔFAGOTRAQUEAL", descricao: "Nota-se a __ cm da ADS (arcada dentária superior), orifício fistuloso para via aérea principal, medindo cerca de __ mm no seu maior diâmetro.", del: ["2"] },
  { diagnostico: "HÉRNIA HIATAL POR DESLIZAMENTO DE PEQUENO VOLUME", descricao: "A transição esofagogástrica (TEG) situa-se a 2cm nível do pinçamento diafragmático", del: ["3"] },
  { diagnostico: "HÉRNIA HIATAL POR DESLIZAMENTO DE MÉDIO VOLUME", descricao: "A transição esofagogástrica (TEG) situa-se a 4cm nível do pinçamento diafragmático", del: ["3"] },
  { diagnostico: "HÉRNIA HIATAL POR DESLIZAMENTO DE GRANDE VOLUME", descricao: "A transição esofagogástrica (TEG) situa-se a 6cm nível do pinçamento diafragmático", del: ["3"] },
  { diagnostico: "LESÃO ELEVADA SUBEPITELIAL DO ESÔFAGO", descricao: "Nota-se a __ cm da ADS (arcada dentária superior), lesão elevada, móvel ao toque da pinça, de aspecto subepitelial em esôfago", del: ["2"] },
  { diagnostico: "LESÃO ELEVADA SUBEPITELIAL EM ESÔFAGO PROXIMAL", descricao: "Nota-se lesão elevada, móvel ao toque da pinça, de aspecto subepitelial em esôfago proximal", del: ["2"] },
  { diagnostico: "LESÃO ELEVADA SUBEPITELIAL EM ESÔFAGO MÉDIO", descricao: "Nota-se lesão elevada, móvel ao toque da pinça, de aspecto subepitelial em esôfago médio", del: ["2"] },
  { diagnostico: "LESÃO ELEVADA SUBEPITELIAL EM ESÔFAGO DISTAL", descricao: "Nota-se lesão elevada, móvel ao toque da pinça, de aspecto subepitelial em esôfago distal", del: ["2"] },
  { diagnostico: "LESÃO INFILTRATIVA DO ESÔFAGO", descricao: "Nota-se a __ cm da ADS (arcada dentária superior), lesão plana, infiltrativa, friável, de bordos imprecisos em esôfago", del: ["2"] },
  { diagnostico: "LESÃO INFILTRATIVA EM ESÔFAGO PROXIMAL", descricao: "Nota-se lesão plana, infiltrativa, friável, de bordos imprecisos em esôfago proximal", del: ["2"] },
  { diagnostico: "LESÃO INFILTRATIVA EM ESÔFAGO MÉDIO", descricao: "Nota-se lesão plana, infiltrativa, friável, de bordos imprecisos em esôfago médio", del: ["2"] },
  { diagnostico: "LESÃO INFILTRATIVA EM ESÔFAGO DISTAL", descricao: "Nota-se lesão plana, infiltrativa, friável, de bordos imprecisos em esôfago médio", del: [""] },
  { diagnostico: "LESÃO DE MALLORY WEISS", descricao: "Nota-se em esôfago distal, lesão linear, longitudinal, recoberta por  e hematina compatível com Mallory-Weiss.", del: ["2"] },
  { diagnostico: "LESÕES DE MALLORY WEISS", descricao: "Nota-se em esôfago distal, lesões lineares, longitudinais, recobertas por fibrina e hematina compatíveis com lesões de Mallory-Weiss", del: ["2"] },
  { diagnostico: "LESÃO DE MALLORY WEISS COM SANGRAMENTO ATIVO", descricao: "Nota-se em esôfago distal, lesão linear, longitudinal, com presença de sangramento ativo, compatível com lesão de Mallory-Weiss", del: ["2"] },
  { diagnostico: "LIPOMA ESOFÁGICO", descricao: "Nota-se em esôfago, lesão de aspecto subepitelial, amolecida, depressível, móvel ao toque da pinça, sugestivo de lipoma.", del: ["2"] },
  { diagnostico: "MEGAESÔFAGO", descricao: "Nota-se calibre aumentado, desviado de eixo, tortuoso, com presença de resíduos alimentares.", del: ["0"] },
  { diagnostico: "PRESENÇA DE RESÍDUOS ALIMENTARES ESOFÁGICOS ASPIRÁVEIS", descricao: "Nota-se presença de pequena quantidade de resíduos alimentares semissólidos aspiráveis", del: ["0"] },
  { diagnostico: "PRESENÇA DE RESÍDUOS ALIMENTARES ESOFÁGICOS NÃO ASPIRÁVEIS", descricao: "Nota-se presença de moderada quantidade de resíduos alimentares sólidos e semissólidos não aspiráveis", del: ["0"] },
  { diagnostico: "PRESENÇA DE GRANDE QUANTIDADE DE RESÍDUOS ALIMENTARES ESOFÁGICOS NÃO ASPIRÁVEIS", descricao: "Nota-se presença de grande quantidade de resíduos alimentares sólidos e semissólidos não aspiráveis.", del: ["0"] },
  { diagnostico: "MEMBRANA ESOFÁGICA", descricao: "Nota-se a __ cm da ADS (arcada dentária superior), presença de membrana axial, anelar, de fina espessura.", del: ["2"] },
  { diagnostico: "MEMBRANA ESOFÁGICA EM TERÇO PROXIMAL", descricao: "Nota-se em esôfago, a presença de uma membrana axial, anelar, de fina espessura.", del: ["2"] },
  { diagnostico: "MEMBRANA ESOFÁGICA EM TERÇO MÉDIO", descricao: "Nota-se em esôfago, a presença de uma membrana axial, anelar, de fina espessura", del: ["2"] },
  { diagnostico: "MEMBRANA ESOFÁGICA EM TERÇO DISTAL", descricao: "Nota-se em esôfago, a presença de uma membrana axial, anelar, de fina espessura.", del: ["2"] },
  { diagnostico: "MUCOSA GÁSTRICA ECTÓPICA EM ESÔFAGO PROXIMAL", descricao: "Nota-se em esôfago proximal, pequena área avermelhada característica de ilha de mucosa gástrica ectópica", del: ["2"] },
  { diagnostico: "MUCOSA GÁSTRICA ECTÓPICA EM ESÔFAGO MÉDIO", descricao: "Nota-se em esôfago médio, pequena área avermelhada característica de ilha de mucosa gástrica ectópica", del: ["2"] },
  { diagnostico: "MUCOSA GÁSTRICA ECTÓPICA EM ESÔFAGO DISTAL", descricao: "Nota-se em esôfago distal, pequena área avermelhada característica de ilha de mucosa gástrica ectópica", del: ["2"] },
  { diagnostico: "NEOPLASIA AVANÇADA DE ESÔFAGO", descricao: "Nota-se a __ cm da ADS (arcada dentária superior), lesão vegetante, de bordos imprecisos e infiltrativos, friável, de aspecto neoplásico.", del: ["2"] },
  { diagnostico: "NEOPLASIA AVANÇADA DE ESÔFAGO PROXIMAL", descricao: "Nota-se em esôfago proximal, lesão vegetante, de bordos imprecisos e infiltrativos, friável, de aspecto neoplásico.", del: ["2"] },
  { diagnostico: "NEOPLASIA AVANÇADA DE ESÔFAGO MÉDIO", descricao: "Nota-se em esôfago médio, lesão vegetante, de bordos imprecisos e infiltrativos, friável, de aspecto neoplásico.", del: ["2"] },
  { diagnostico: "NEOPLASIA AVANÇADA DE ESÔFAGO DISTAL", descricao: "Nota-se em esôfago distal, lesão vegetante, de bordos imprecisos e infiltrativos, friável, de aspecto neoplásico.", del: ["2"] },
  { diagnostico: "NEOPLASIA PRECOCE DE ESÔFAGO", descricao: "Nota-se a __ cm da ADS (arcada dentária superior), lesão esofágica circunscrita, levemente elevada, pálida, de bordos bem delimitados por mucosa normal.", del: ["2"] },
  { diagnostico: "LESÃO SUGESTIVA DE NEOPLASIA PRECOCE DE ESÔFAGO", descricao: "Nota-se lesão esofágica circunscrita, levemente elevada, pálida, de bordos bem delimitados por mucosa normal.", del: ["2"] },
  { diagnostico: "NEOPLASIA ESOFÁGICA PRECOCE (PARIS I)", descricao: "Nota-se a __ cm da ADS (arcada dentária superior), pequena lesão esofágica elevada (&gt; 2,5mm), pálida, de bordos bem delimitados por mucosa normal.", del: ["2"] },
  { diagnostico: "NEOPLASIA ESOFÁGICA PRECOCE (PARIS I-p)", descricao: "Nota-se a __ cm da ADS (arcada dentária superior), pequena lesão esofágica polipóide, pálida, de bordos bem delimitados por mucosa normal.", del: ["2"] },
  { diagnostico: "NEOPLASIA ESOFÁGICA PRECOCE (PARIS I-s)", descricao: "Nota-se a __ cm da ADS (arcada dentária superior), pequena lesão esofágica elevada séssil, pálida, de bordos bem delimitados por mucosa normal.", del: ["2"] },
  { diagnostico: "NEOPLASIA ESOFÁGICA PRECOCE (PARIS IIA)", descricao: "Nota-se a __cm da ADS (arcada dentária superior), pequena lesão esofágica, levemente elevada, pálida, de bordos bem delimitados por mucosa normal.", del: ["2"] },
  { diagnostico: "NEOPLASIA ESOFÁGICA PRECOCE (PARIS IIA + IIC)", descricao: "Nota-se a __cm da ADS (arcada dentária superior), lesão esofágica, predominantemente elevada com área central de leve depressão, pálida, de bordos bem delimitados por mucosa normal.", del: ["2"] },
  { diagnostico: "NEOPLASIA ESOFÁGICA PRECOCE (PARIS IIA + IIB)", descricao: "Nota-se a __cm da ADS (arcada dentária superior), lesão esofágica, predominantemente elevada com área central plana, pálida, de bordos bem delimitados por mucosa normal.", del: ["2"] },
  { diagnostico: "NEOPLASIA ESOFÁGICA PRECOCE (PARIS IIA + III)", descricao: "Nota-se a __cm da ADS (arcada dentária superior), lesão esofágica, predominantemente elevada com área central ulcerada, pálida, de bordos bem delimitados por mucosa normal.", del: ["2"] },
  { diagnostico: "NEOPLASIA ESOFÁGICA PRECOCE (PARIS IIB)", descricao: "Nota-se a __cm da ADS (arcada dentária superior), lesão esofágica, plana, pálida, de bordos bem delimitados por mucosa normal.", del: ["2"] },
  { diagnostico: "NEOPLASIA ESOFÁGICA PRECOCE (PARIS IIB + IIA)", descricao: "Nota-se a __cm da ADS (arcada dentária superior), lesão esofágica, predominantemente plana com área elevada periférica, pálida, de bordos bem delimitados por mucosa normal.", del: ["2"] },
  { diagnostico: "NEOPLASIA ESOFÁGICA PRECOCE (PARIS IIB + IIC)", descricao: "Nota-se a __cm da ADS (arcada dentária superior), lesão esofágica, predominantemente plana com área central de leve depressão, pálida, de bordos bem delimitados por mucosa normal.", del: ["2"] },
  { diagnostico: "NEOPLASIA ESOFÁGICA PRECOCE (PARIS IIB + III)", descricao: "Nota-se a __cm da ADS (arcada dentária superior), lesão esofágica, predominantemente plana com área central ulcerada fibrinosa , pálida, de bordos bem delimitados por mucosa normal.", del: ["2"] },
  { diagnostico: "NEOPLASIA ESOFÁGICA PRECOCE (PARIS IIC)", descricao: "Nota-se a __cm da ADS (arcada dentária superior), lesão esofágica, levemente deprimida, pálida, de bordos bem delimitados por mucosa normal.", del: ["2"] },
  { diagnostico: "NEOPLASIA ESOFÁGICA PRECOCE (PARIS IIC + IIA)", descricao: "Nota-se a __cm da ADS (arcada dentária superior), lesão esofágica, predominantemente deprimida e com área elevada periférica, pálida, de bordos bem delimitados por mucosa normal.", del: ["2"] },
  { diagnostico: "NEOPLASIA ESOFÁGICA PRECOCE (PARIS IIC + IIB)", descricao: "Nota-se a __cm da ADS (arcada dentária superior), lesão esofágica, predominantemente deprimida com uma pequena área plana, pálida, de bordos bem delimitados por mucosa normal.", del: ["2"] },
  { diagnostico: "NEOPLASIA ESOFÁGICA PRECOCE (PARIS IIC + III)", descricao: "Nota-se a __cm da ADS (arcada dentária superior), lesão esofágica, predominantemente deprimida com área central ulcerada fibrinosa , pálida, de bordos bem delimitados por mucosa normal.", del: ["2"] },
  { diagnostico: "NEOPLASIA ESOFÁGICA PRECOCE (PARIS III)", descricao: "Nota-se a __cm da ADS (arcada dentária superior), lesão esofágica com ulceração fibrinosa de bordos bem delimitados por mucosa normal.", del: ["2"] },
  { diagnostico: "NEOPLASIA ESOFÁGICA PRECOCE (PARIS III + IIA)", descricao: "Nota-se a __cm da ADS (arcada dentária superior), lesão esofágica predominantemente ulcerada e com pequena área elevada periférica, pálida, de bordos bem delimitados por mucosa normal.", del: ["2"] },
  { diagnostico: "NEOPLASIA ESOFÁGICA PRECOCE (PARIS III + IIB)", descricao: "Nota-se a __cm da ADS (arcada dentária superior), lesão esofágica predominantemente ulcerada e com pequena área plana periférica, pálida, de bordos bem delimitados por mucosa normal.", del: ["2"] },
  { diagnostico: "NEOPLASIA ESOFÁGICA PRECOCE (PARIS III + IIC)", descricao: "Nota-se a __cm da ADS (arcada dentária superior), lesão esofágica predominantemente ulcerada e com pequena área deprimida periférica, pálida, de bordos bem delimitados por mucosa normal.", del: ["2"] },
  { diagnostico: "PAPILOMA ESOFÁGICO", descricao: "Nota-se a __cm da ADS (arcada dentária superior), lesão de crescimento exofítico, verrucosa, esbranquiçada, com vasos cruzando sua superfície, sugestivo de papiloma.", del: ["2"] },
  { diagnostico: "PAPILOMA EM ESÔFAGO PROXIMAL", descricao: "Nota-se em esôfago proximal, lesão de crescimento exofítico, verrucosa, esbranquiçada, com vasos cruzando sua superfície, sugestivo de papiloma.", del: ["2"] },
  { diagnostico: "PAPILOMA EM ESÔFAGO MÉDIO", descricao: "Nota-se em esôfago médio, lesão de crescimento exofítico, verrucosa, esbranquiçada, com vasos cruzando sua superfície, sugestivo de papiloma.", del: ["2"] },
  { diagnostico: "PAPILOMA EM ESÔFAGO DISTAL", descricao: "Nota-se em esôfago distal, lesão de crescimento exofítico, verrucosa, esbranquiçada, com vasos cruzando sua superfície, sugestivo de papiloma.", del: ["2"] },
  { diagnostico: "PÓLIPO ESOFÁGICO", descricao: "Nota-se em esôfago, a __cm da ADS (arcada dentária superior), pequeno pólipo séssil, liso, menor que 5mm.", del: ["2"] },
  { diagnostico: "PÓLIPO SÉSSIL EM ESÔFAGO PROXIMAL", descricao: "Nota-se em esôfago proximal, pequeno pólipo séssil, liso, menor que 5mm.", del: ["2"] },
  { diagnostico: "PÓLIPO SÉSSIL EM ESÔFAGO MÉDIO", descricao: "Nota-se em esôfago médio, pequeno pólipo séssil, liso, menor que 5mm.", del: ["2"] },
  { diagnostico: "PÓLIPO SÉSSIL EM ESÔFAGO DISTAL", descricao: "Nota-se em esôfago distal, pequeno pólipo séssil, liso, menor que 5mm.", del: ["2"] },
  { diagnostico: "PÓLIPO SEMIPEDICULADO EM ESÔFAGO PROXIMAL", descricao: "Nota-se em esôfago proximal, pólipo semipediculado, liso, medindo cerca de 5mm.", del: ["2"] },
  { diagnostico: "PÓLIPO SEMIPEDICULADO EM ESÔFAGO MÉDIO", descricao: "Nota-se em esôfago médio, pólipo semipediculado, liso, medindo cerca de 5mm.", del: ["2"] },
  { diagnostico: "PÓLIPO SEMIPEDICULADO EM ESÔFAGO DISTAL", descricao: "Nota-se em esôfago distal, pólipo semipediculado, liso, medindo cerca de 5mm.", del: ["2"] },
  { diagnostico: "PÓLIPO PEDICULADO EM ESÔFAGO PROXIMAL", descricao: "Nota-se em esôfago proximal, pólipo pediculado, liso, medindo cerca de 5mm.", del: ["2"] },
  { diagnostico: "PÓLIPO PEDICULADO EM ESÔFAGO MÉDIO", descricao: "Nota-se em esôfago médio, pólipo pediculado, liso, medindo cerca de 5mm.", del: ["2"] },
  { diagnostico: "PÓLIPO PEDICULADO EM ESÔFAGO DISTAL", descricao: "Nota-se em esôfago distal, pólipo pediculado, liso, medindo cerca de 5mm.", del: ["2"] },
  { diagnostico: "PÓLIPO SENTINELA EM ESÔFAGO DISTAL", descricao: "Observa-se em esôfago distal próximo à TEG (transição esofagogástrica), diminuto pólipo de etiologia inflamatória (pólipo sentinela).", del: ["2"] },
  { diagnostico: "PÓLIPO INFLAMATÓRIO EM ESÔFAGO DISTAL (PÓLIPO SENTINELA)", descricao: "Observa-se em esôfago distal próximo à TEG (transição esofagogástrica), diminuto pólipo de etiologia inflamatória (pólipo sentinela).", del: ["2"] },
  { diagnostico: "SARCOMA DE KAPOSI EM ESÔFAGO", descricao: "Nota-se a __cm da ADS (arcada dentária superior), lesão maculopapular violácea, arredondada, levemente elevada, sugestiva de sarcoma de Kaposi.", del: ["2"] },
  { diagnostico: "SARCOMA DE KAPOSI EM ESÔFAGO PROXIMAL", descricao: "Nota-se em esôfago proximal, lesão maculopapular violácea, arredondada, levemente elevada, sugestiva de sarcoma de Kaposi.", del: ["2"] },
  { diagnostico: "SARCOMA DE KAPOSI EM ESÔFAGO MÉDIO", descricao: "Nota-se em esôfago médio, lesão maculopapular violácea, arredondada, levemente elevada, sugestiva de sarcoma de Kaposi.", del: ["2"] },
  { diagnostico: "SARCOMA DE KAPOSI EM ESÔFAGO DISTAL", descricao: "Nota-se em esôfago distal, lesão maculopapular violácea, arredondada, levemente elevada, sugestiva de sarcoma de Kaposi.", del: ["2"] },
  { diagnostico: "ÚLCERA EM ESÔFAGO PROXIMAL", descricao: "Nota-se em esôfago proximal, uma lesão ulcerada, de base limpa, arredondada, medindo 5mm.", del: ["2"] },
  { diagnostico: "ÚLCERA EM ESÔFAGO MÉDIO", descricao: "Nota-se em esôfago médio, uma lesão ulcerada, de base limpa, arredondada, medindo 5mm.", del: ["2"] },
  { diagnostico: "ÚLCERA EM ESÔFAGO DISTAL", descricao: "Nota-se em esôfago distal, uma lesão ulcerada, de base limpa, arredondada, medindo 5mm.", del: ["2"] },
  { diagnostico: "ÚLCERA EM ESÔFAGO DISTAL DECORRENTE DE TERAPIA ENDOSCÓPICA PRÉVIA", descricao: "Nota-se em esôfago distal, uma lesão ulcerada, de base limpa, arredondada, medindo 5mm, decorrente de terapia endoscópica prévia.", del: ["2"] },
  { diagnostico: "ÚLCERAS ESOFÁGICAS", descricao: "Notam-se em toda extensão esofágica, pequenas lesões ulceradas, de base limpa, arredondadas, medindo cerca de 5mm.", del: ["2"] },
  { diagnostico: "ÚLCERAS ESOFÁGICAS DE PROVÁVEL ETIOLOGIA INFECCIOSA (CMV? HSV?)", descricao: "Notam-se em esôfago médio, úlceras esofágicas múltiplas, rasas, de fundo nacarado, com bordos elevados e hiperemiados. (CMV?HSV?)", del: ["2"] },
  { diagnostico: "ÚLCERAS ESOFÁGICAS (CMV? HSV?)", descricao: "Notam-se em esôfago médio, úlceras esofágicas múltiplas, rasas, de fundo nacarado, com bordos elevados e hiperemiados. (CMV?HSV?)", del: ["2"] },
  { diagnostico: "VARIZES ESOFÁGICAS ERRADICADAS", descricao: "Varizes esofágicas erradicadas.", del: ["1"] },
  { diagnostico: "VARIZES ESOFÁGICAS RESIDUAIS", descricao: "Notam-se pequenas varizes residuais de fino calibre.", del: ["2"] },
  { diagnostico: "VARIZES ESOFÁGICAS INCIPIENTES", descricao: "Notam-se filetes varicosos esofágicos incipientes.", del: ["2"] },
  { diagnostico: "VARIZES ESOFÁGICAS SANGRANTES", descricao: "Notam-se varizes esofágicas com sangramento ativo.", del: ["2"] },
]

export const fluxoTerapeuticaEsofago = [
  {
    diagnostico: "APOSIÇÃO DE PRÓTESE ESOFÁGICA METÁLICA AUTOEXPANSÍVEL TOTALMENTE RECOBERTA",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizada aposição de prótese esofágica metálica autoexpansível totalmente recoberta com extremidade distal a __ cm do cárdia e extremidade proximal a __ cm do cricofaríngeo. Procedimento sem intercorrências e com bom resultado imediato."
  },
  {
    diagnostico: "APOSIÇÃO DE PRÓTESE ESOFÁGICA METÁLICA AUTOEXPANSÍVEL PARCIALMENTE RECOBERTA",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizada aposição de prótese esofágica metálica autoexpansível parcialmente recoberta com extremidade distal a __ cm do cárdia e extremidade proximal a __ cm do cricofaríngeo. Procedimento sem intercorrências e com bom resultado imediato."
  },
  {
    diagnostico: "APOSIÇÃO DE PRÓTESE ESOFÁGICA METÁLICA AUTOEXPANSÍVEL DESCOBERTA",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizada aposição de prótese esofágica metálica autoexpansível descoberta com extremidade distal a __ cm do cárdia e extremidade proximal a __ cm do cricofaríngeo. Procedimento sem intercorrências e com bom resultado imediato."
  },
  {
    diagnostico: "DILATAÇÃO DE ANASTOMOSE COM BALÃO TTS",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Passagem de balão TTS (through-the-scope) e, sob visão direta, realizada dilatação de anastomose até __ mm. Após o procedimento, observam-se lacerações superficiais com sangramento autolimitado. Procedimento sem intercorrências e com bom resultado imediato."
  },
  {
    diagnostico: "DILATAÇÃO DE ESTENOSE ESOFÁGICA COM BALÃO TTS",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Passagem de balão TTS (through-the-scope) e, sob visão direta, realizada dilatação de anastomose até __ mm. Após o procedimento, observam-se lacerações superficiais com sangramento autolimitado. Procedimento sem intercorrências e com bom resultado imediato."
  },
  {
    diagnostico: "DILATAÇÃO DE ANASTOMOSE COM BALÃO TTS (CONTROLE RADIOSCÓPICO)",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Passagem de balão TTS (through-the-scope) e, sob controle radioscópico, realizada dilatação de anastomose até __ mm. Após o procedimento, observam-se lacerações superficiais com sangramento autolimitado. Procedimento sem intercorrências e com bom resultado imediato."
  },
  {
    diagnostico: "DILATAÇÃO DE ESTENOSE ESOFÁGICA COM BALÃO TTS (CONTROLE RADIOSCÓPICO)",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Passagem de balão TTS (through-the-scope) e, sob controle radioscópico, realizada dilatação de anastomose até __ mm. Após o procedimento, observam-se lacerações superficiais com sangramento autolimitado. Procedimento sem intercorrências e com bom resultado imediato."
  },
  {
    diagnostico: "DILATAÇÃO DE ESTENOSE ESOFÁGICA COM SONDAS DE SAVARY-GILLIARD",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado passagem de fio-guia de Savary seguido de dilatação de estenose esofágica com sondas de Savary-Gilliard nºs ___mm. Após o procedimento, na revisão do trajeto dilatado, observam-se poucas lacerações lineares superficiais com sangramento autolimitado. Procedimento sem intercorrências e com bom resultado imediato."
  },
  {
    diagnostico: "DILATAÇÃO DE CÁRDIA COM BALÃO",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Passagem de balão com posicionamento no cárdia e, sob controle radioscópico, realizada dilatação até diâmetro máximo de __ mm. Na revisão, observam-se poucas lacerações superficiais com sangramento autolimitado. Procedimento sem intercorrências e com bom resultado imediato."
  },
  {
    diagnostico: "ESCLEROTERAPIA ENDOSCÓPICA DE VARIZES ESOFÁGICAS",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizada escleroterapia das varizes esofágicas supracitadas com solução de Ethamolin 2% com infusão em __ pontos de punção e total de __ ml de solução. Procedimento sem intercorrências."
  },
  {
    diagnostico: "HEMOSTASIA COM INJEÇÃO DE ADRENALINA EM MALLORY-WEISS",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizada hemostasia endoscópica em laceração de Mallory-Weiss: Injetado solução de adrenalina (1:10000) em __ pontos de punção com total infundido de __ ml. Procedimento sem intercorrências e com bom resultado imediato."
  },
  {
    diagnostico: "HEMOSTASIA ENDOSCÓPICA COM HEMOCLIP EM MALLORY-WEISS",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizada hemostasia endoscópica em laceração de Mallory-Weiss com aposição de hemoclip. Procedimento sem intercorrências e com bom resultado imediato."
  },
  {
    diagnostico: "HEMOSTASIA ENDOSCÓPICA COM HEMOCLIP EM ÚLCERA",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizada hemostasia endoscópica em úlcera esofágica com aposição de hemoclip. Procedimento sem intercorrências e com bom resultado imediato."
  },
  {
    diagnostico: "INJEÇÃO DE TRIANCINOLONA EM ESTENOSE ESOFÁGICA",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado injeção de Triancinolona (20mg/ml) na área estenosada, com infusão total de __ ml de solução divididos em 4 quadrantes. Procedimento sem intercorrências."
  },
  {
    diagnostico: "INJEÇÃO DE TOXINA BOTULÍNICA NO EEI (ESFÍNCTER ESOFÁGICO INFERIOR)",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado injeção de Toxina Botulínica ao nível do cárdia, com infusão total de __ ml de solução divididos em 4 quadrantes (infundido total de __ UI). Procedimento sem intercorrências."
  },
  {
    diagnostico: "HEMOSTASIA COM ADRENALINA EM ÚLCERA SANGRANTE",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizada hemostasia endoscópica em úlcera esofágica sangrante com injeção de solução de adrenalina (1:10000) em __ pontos de punção com total infundido de __ ml. Procedimento sem intercorrências e com bom resultado imediato."
  },
  {
    diagnostico: "HEMOSTASIA COM ADRENALINA EM ÚLCERA",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizada hemostasia endoscópica em úlcera esofágica com injeção de solução de adrenalina (1:10000) em __ pontos de punção com total infundido de __ ml. Procedimento sem intercorrências e com bom resultado imediato."
  },
  {
    diagnostico: "DUPLA HEMOSTASIA EM ÚLCERA ESOFÁGICA (INJEÇÃO DE ADRENALINA + HEMOCLIP)",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizada dupla hemostasia endoscópica em úlcera esofágica: Injetado solução de adrenalina (1:10000) em __ pontos de punção com total infundido de __ ml. Após, realizamos aposição de hemoclip no centro da úlcera. Procedimento sem intercorrência e com bom resultado imediato."
  },
  {
    diagnostico: "DUPLA HEMOSTASIA EM MALLORY-WEISS (INJEÇÃO DE ADRENALINA + HEMOCLIP)",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizada dupla hemostasia endoscópica em laceração de Mallory-Weiss: Injetado solução de adrenalina (1:10000) em __ pontos de punção com total infundido de __ ml. Após, realizamos aposição de hemoclip, aproximando as bordas da laceração. Procedimento sem intercorrências e com bom resultado imediato."
  },
  {
    diagnostico: "LIGADURA ELÁSTICA DE VARIZES ESOFÁGICAS",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizada ligadura elástica das varizes esofágicas supracitadas em espiral e ascendente com aposição de _ bandas elásticas. Procedimento sem intercorrências."
  },
  {
    diagnostico: "PASSAGEM DE BALÃO DE SENGSTAKEN BLAKEMORE",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizamos passagem de balão de Sengstaken- Blakemore via nasal seguido de preenchimento de ambos os balões do sistema (gástrico e esofágico) e adequado posicionamento com contrapeso em extremidade distal."
  },
  {
    diagnostico: "POLIPECTOMIA (ALÇA A FRIO)",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado polipectomia com alça a frio. Procedimento sem intercorrências."
  },
  {
    diagnostico: "POLIPECTOMIA (ALÇA DIATÉRMICA)",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado polipectomia com alça diatérmica (Corte Puro / Potência 25W). Procedimento sem intercorrências."
  },
  {
    diagnostico: "RETIRADA DE PRÓTESE ESOFÁGICA",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado retirada de prótese metálica esofágica com pinça de corpo estranho. Procedimento sem intercorrências."
  },
  {
    diagnostico: "RETIRADA DE CORPO ESTRANHO DO ESÔFAGO COM PINÇA",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado retirada de corpo estranho do esôfago com pinça de apreensão. Procedimento sem intercorrências."
  },
  {
    diagnostico: "RETIRADA DE CORPO ESTRANHO DO ESÔFAGO COM BASKET",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado retirada de corpo estranho do esôfago com basket. Procedimento sem intercorrências."
  },
  {
    diagnostico: "RETIRADA DE CORPO ESTRANHO DO ESÔFAGO COM ROTHNET",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado retirada de corpo estranho do esôfago com Rothnet. Procedimento sem intercorrências."
  },
  {
    diagnostico: "RETIRADA DE CORPO ESTRANHO COM AUXÍLIO DE OVERTUBE",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado retirada de corpo estranho do esôfago com pinça de apreensão e auxílio protetor de overtube. Procedimento sem intercorrências."
  },
]

// LAUDO ESTOMAGO
export const estomagoDefault = [
  "Anatomia preservada, boa distensibilidade",
  "Lago mucoso claro em volume habitual",
  "À retrovisão, hiato justo ao aparelho e cárdia de aspecto normal",
  "Fundo gástrico sem alterações",
  "Região do corpo com pregueado mucoso normotrófico, sem erosões, úlceras ou pólipos",
  "Incisura angularis sem lesões ",
  "Antro com mucosa íntegra.",
  "Piloro pérvio sem deformidades",
]

export const fluxoEstomago = [
  {

    descricao: 'Nota-se em corpo gástrico, área avermelhada, de aspecto arboriforme, compatível com ectasia vascular, medindo cerca de 3mm.',
    del: ["4"],
    diagnostico: "ANGIECTASIA EM CORPO GÁSTRICO",
  },
  {
    descricao: 'Nota-se em antro, área avermelhada, de aspecto arboriforme, compatível com ectasia vascular, medindo cerca de 3mm.',
    del: ["6"],
    diagnostico: "ANGIECTASIA EM ANTRO GÁSTRICO",
  },
  {
    descricao: 'Observa-se em grande curvatura de corpo distal, uma lesão elevada, enantemática, com leve depressão central, medindo cerca de 5mm, sugestiva de lesão carcinóide.',
    del: ["4"],
    diagnostico: "LESÃO SUGESTIVA DE CARCINÓIDE EM CORPO GÁSTRICO",
  },
  {
    descricao: 'Observa-se em grande curvatura de antro, uma lesão elevada, enantemática, com leve depressão central, medindo cerca de 5mm, sugestiva de lesão carcinóide.',
    del: ["6"],
    diagnostico: "LESÃO SUGESTIVA DE CARCINÓIDE EM ANTRO GÁSTRICO",
  },
  {
    descricao: 'Nota-se em antro gástrico, uma cicatriz de úlcera, linear, avermelhada, de bordos enantemáticos, em área de convergência de pregas.',
    del: ["6"],
    diagnostico: "CICATRIZ DE ÚLCERA EM ANTRO GÁSTRICO (SAKITA S1)",
  },
  {
    descricao: 'Nota-se em antro gástrico, uma cicatriz branca de úlcera prévia, linear, em área de convergência de pregas.',
    del: ["6"],
    diagnostico: "CICATRIZ DE ÚLCERA EM ANTRO GÁSTRICO (SAKITA S2)",
  },
  {
    descricao: 'Nota-se em corpo gástrico, uma cicatriz de úlcera, linear, avermelhada, de bordos enantemáticos, em área de convergência de pregas.',
    del: ["4"],
    diagnostico: "CICATRIZ DE ÚLCERA EM CORPO GÁSTRICO (SAKITA S1)",
  },
  {
    descricao: 'Nota-se em corpo gástrico, uma cicatriz branca de úlcera prévia, linear, em área de convergência de pregas.',
    del: ["4"],
    diagnostico: "CICATRIZ DE ÚLCERA EM CORPO GÁSTRICO (SAKITA S2)",
  },
  {
    descricao: 'Nota-se em incisura angularis, uma cicatriz de úlcera, linear, avermelhada, de bordos enantemáticos, em área de convergência de pregas.',
    del: ["5"],
    diagnostico: "CICATRIZ DE ÚLCERA EM INCISURA GÁSTRICA (SAKITA S1)",
  },
  {
    descricao: 'Nota-se em incisura angularis, uma cicatriz branca de úlcera prévia, linear, em área de convergência de pregas.',
    del: ["5"],
    diagnostico: "CICATRIZ DE ÚLCERA EM INCISURA GÁSTRICA (SAKITA S2)",
  },
  {
    descricao: 'Nota-se em grande curvatura e parede anterior de corpo distal, área abaulada, endurecida, compatível com compressão extrínseca.',
    del: ["4"],
    diagnostico: "COMPRESSÃO EXTRÍNSECA EM CORPO GÁSTRICO",
  },
  {
    descricao: 'Nota-se em antro, área abaulada, endurecida, fixa, compatível com compressão extrínseca. ',
    del: ["6"],
    diagnostico: "COMPRESSÃO EXTRÍNSECA EM ANTRO GÁSTRICO",
  },
  {
    descricao: 'Lago mucoso com sangue. \nNota-se em grande curvatura de corpo, coto vascular visível com sangramento ativo em babação, compatível com Dieulafoy gástrico.',
    del: ["1", "4"],
    diagnostico: "DIEULAFOY GÁSTRICO EM CORPO COM SANGRAMENTO ATIVO",
  },
  {
    descricao: 'Lago mucoso com sangue. \nNota-se em grande curvatura de corpo, coto vascular visível com coágulo aderido, compatível com Dieulafoy gástrico.',
    del: ["1", "4"],
    diagnostico: "DIEULAFOY GÁSTRICO EM CORPO COM COÁGULO ADERIDO",
  },
  {
    descricao: 'Lago mucoso com sangue. \nNota-se em antro, coto vascular visível com sangramento ativo em babação, compatível com Dieulafoy gástrico.',
    del: ["1", "6"],
    diagnostico: "DIEULAFOY GÁSTRICO EM ANTRO COM SANGRAMENTO ATIVO",
  },
  {
    descricao: 'Lago mucoso com sangue. \nNota-se em antro, coto vascular visível com coágulo aderido, compatível com Dieulafoy gástrico.',
    del: ["1", "6"],
    diagnostico: "DIEULAFOY GÁSTRICO EM ANTRO COM COÁGULO ADERIDO",
  },
  {
    descricao: 'Nota-se em antro, mucosa enantemática e edemaciada, com áreas de aspecto nodular em “pedra de calçamento”e lesões aftóides fibrinosas de permeio.',
    del: ["6"],
    diagnostico: "DOENÇA DE CROHN (ANTRO)",
  },
  {
    descricao: 'Nota-se em corpo, mucosa enantemática e edemaciada, com áreas de aspecto nodular em “pedra de calçamento”e lesões aftóides fibrinosas de permeio.',
    del: ["4"],
    diagnostico: "DOENÇA DE CROHN (CORPO)",
  },
  {
    descricao: 'Nota-se em antro, mucosa enantemática e edemaciada, com áreas de aspecto nodular em “pedra de calçamento”e lesões aftóides fibrinosas de permeio. (Crohn?)',
    del: ["6"],
    diagnostico: "MUCOSA DE ASPECTO NODULAR EM ANTRO (CROHN?)",
  },
  {
    descricao: 'Nota-se em corpo distal, mucosa enantemática e edemaciada, com áreas de aspecto nodular em “pedra de calçamento”e lesões aftóides fibrinosas de permeio. (Crohn?)',
    del: ["4"],
    diagnostico: "MUCOSA DE ASPECTO NODULAR EM CORPO (CROHN?)",
  },
  {
    descricao: 'Notam-se em antro, áreas puntiformes, avermelhadas, compatíveis com ectasias vasculares.',
    del: ["6"],
    diagnostico: "ECTASIAS VASCULARES ANTRAIS (GAVE)",
  },
  {
    descricao: 'Notam-se em antro, áreas puntiformes, avermelhadas, compatíveis com ectasias vasculares, com sangramento em porejamento.',
    del: ["6"],
    diagnostico: "ECTASIAS VASCULARES ANTRAIS (GAVE) COM SANGRAMENTO ATIVO",
  },
  {
    descricao: 'Notam-se em antro, extensas estrias avermelhadas, com enantema acentuado, de aspecto vascular, compatíveis com ectasias vasculares (estômago em melancia).',
    del: ["6"],
    diagnostico: "ECTASIAS VASCULARES ANTRAIS ACENTUADAS (GAVE) – “WATERMELON STOMACH”",
  },
  {
    descricao: 'Boa distensibilidade. À retrovisão, notamos presença de válvula antirrefluxo com cárdia competente.',
    del: ["0", "2", "3"],
    diagnostico: "STATUS PÓS-FUNDOPLICATURA",
  },
  {
    descricao: 'Boa distensibilidade. À retrovisão, notamos presença de válvula antirrefluxo parcialmente desgarrada.',
    del: ["0", "2", "3"],
    diagnostico: "VÁLVULA ANTIRREFLUXO PARCIALMENTE DESGARRADA",
  },
  {
    descricao: 'Boa distensibilidade. À retrovisão, notamos presença de válvula antirrefluxo totalmente desgarrada.',
    del: ["0", "2", "3"],
    diagnostico: "VÁLVULA ANTIRREFLUXO TOTALMENTE DESGARRADA",
  },
  {
    descricao: 'Boa distensibilidade. À retrovisão, notamos presença de válvula antirrefluxo com cárdia competente.',
    del: ["0", "2", "3"],
    diagnostico: "FUNDOPLICATURA ÍNTEGRA",
  },
  {
    descricao: 'Boa distensibilidade. À retrovisão, notamos presença de válvula antirrefluxo parcialmente desgarrada.',
    del: ["0", "2", "3"],
    diagnostico: "FUNDOPLICATURA PARCIALMENTE DESGARRADA",
  },
  {
    descricao: 'Boa distensibilidade. À retrovisão, notamos presença de válvula antirrefluxo totalmente desgarrada.',
    del: ["0", "2", "3"],
    diagnostico: "FUNDOPLICATURA TOTALMENTE DESGARRADA",
  },
  {
    descricao: 'À retrovisão, hiato alargado e cárdia incontinente.',
    del: ["2"],
    diagnostico: "INCONTINÊNCIA CARDIOHIATAL",
  },
  {
    descricao: 'Ausência de estômago (status pós gastrectomia total). Presença de anastomose esôfagojejunal término-lateral ampla e pérvia, com visualização de duas bocas: uma em fundo cego (cajado) e a outra correspondendo a alça alimentar percorrida até o alcance do aparelho. Não observamos alterações de mucosa dos segmentos examinados.',
    del: ["0", "1", "2", "3", "4", "5", "6", "7",],
    diagnostico: "STATUS PÓS GASTRECTOMIA TOTAL COM RECONSTRUÇÃO EM Y DE ROUX",
  },
  {
    descricao: 'Ausência de estômago (status pós gastrectomia total). Presença de anastomose esôfago-jejunal término-lateral reduzida de diâmetro, com redução circunferencial da luz em 50%, que permite a passagem do aparelho com leve resistência e visualização de duas bocas: uma em fundo cego (cajado) e a outra correspondendo a alça alimentar percorrida até o alcance do aparelho. Não observamos alterações de mucosa dos segmentos examinados.',
    del: ["0", "1", "2", "3", "4", "5", "6", "7",],
    diagnostico: "STATUS PÓS GASTRECTOMIA TOTAL (RECONSTRUÇÃO EM Y DE ROUX) COM ESTENOSE PARCIAL EM ANASTOMOSE ESÔFAGO-JEJUNAL",
  },
  {
    descricao: 'Ausência de estômago (status pós gastrectomia total). Presença de anastomose esôfago-jejunal término-lateral apresentando estenose completa, com luz puntiforme, que não permite a passagem do aparelho e impossibilita realizar avaliação dos segmentos pós-estenose. Não observamos alterações de mucosa dos segmentos examinados.',
    del: ["0", "1", "2", "3", "4", "5", "6", "7",],
    diagnostico: "STATUS PÓS GASTRECTOMIA TOTAL (RECONSTRUÇÃO EM Y DE ROUX) COM ESTENOSE TOTAL EM ANASTOMOSE ESÔFAGO-JEJUNAL",
  },
  {
    descricao: 'Estômago operado. Presença de coto gástrico medindo 10cm pela pequena curvatura com mucosa gástrica remanescente normal. Visualiza-se anastomose gastroduodenal término-terminal única, ampla, pérvia, com presença de fios de sutura e sem alterações de mucosa (status pós gastrectomia parcial com reconstrução à Billroth I)',
    del: ["0", "1", "3", "4", "5", "6", "7",],
    diagnostico: "STATUS PÓS GASTRECTOMIA PARCIAL COM RECONSTRUÇÃO À BILLROTH I",
  },
  {
    descricao: 'Estômago operado. Lago mucoso bilioso. Presença de coto gástrico medindo 10cm pela pequena curvatura com mucosa gástrica remanescente normal. Visualiza-se anastomose gastrojejunal término-lateral, ampla, pérvia, com fios de sutura e presença de duas bocas anastomóticas (status pós gastrectomia parcial com reconstrução à Billroth II). Alça aferente e alimentar examinadas até o alcance do aparelho, sem alterações de mucosa ou anatômicas.',
    del: ["0", "1", "3", "4", "5", "6", "7",],
    diagnostico: "STATUS PÓS GASTRECTOMIA PARCIAL COM RECONSTRUÇÃO À BILLROTH II",
  },
  {
    descricao: 'Estômago operado. Lago mucoso bilioso. Presença de coto gástrico medindo 10cm pela pequena curvatura com mucosa gástrica remanescente normal. Visualiza-se anastomose gastrojejunal término-lateral, ampla, pérvia, com fios de sutura e presença de duas bocas anastomóticas (status pós gastrectomia parcial com reconstrução à Billroth II). Alça aferente e alimentar examinadas até o alcance do aparelho, sem alterações de mucosa. A cerca de 30 cm do início da alça alimentar, observamos anastomose jejunojejunal latero-lateral com alça aferente, ampla e pérvia (ômega de Braun anastomose).',
    del: ["0", "1", "3", "4", "5", "6", "7",],
    diagnostico: "STATUS PÓS GASTRECTOMIA PARCIAL COM RECONSTRUÇÃO À BILLROTH II + ÔMEGA DE BRAUN",
  },
  {
    descricao: 'Estômago operado. Presença de pequena bolsa gástrica remanescente com anastomose gastrojejunal término-lateral única, ampla, pérvia, com fios de sutura. Examinado alça alimentar até o alcance do aparelho sem alterações. Não observamos alterações de mucosa dos segmentos examinados.',
    del: ["0", "1", "2", "3", "4", "5", "6", "7",],
    diagnostico: "STATUS PÓS BYPASS GÁSTRICO EM Y DE ROUX",
  },
  {
    descricao: 'Estômago operado e diminuído de volume. Observamos tubo gástrico remanescente com presença de fios de sutura em grande curvatura.',
    del: ["0", "1", "3", "4", "5"],
    diagnostico: "STATUS PÓS GASTRECTOMIA VERTICAL (SLEEVE)",
  },
  {
    descricao: 'Estômago operado e diminuído de volume. Observamos tubo gástrico remanescente com presença de fios de sutura em grande curvatura.',
    del: ["0", "1", "3", "4", "5"],
    diagnostico: "STATUS PÓS SLEEVE GÁSTRICO",
  },
  {
    descricao: 'Estômago diminuído de volume, com remanescente gástrico por exclusão e presença de botões de sutura em grande curvatura. Mucosa gástrica sem alterações.',
    del: ["0", "1", "3", "4", "5"],
    diagnostico: "STATUS PÓS SLEEVE GÁSTRICO ENDOSCÓPICO",
  },
  {
    descricao: 'Estase gástrica acentuada. \nObservamos deformidade pilórica acentuada que impede a passagem do endoscópio.',
    del: ["1", "7"],
    diagnostico: "ESTENOSE PILÓRICA TOTAL",
  },
  {
    descricao: 'Observamos deformidade pilórica leve que permite a passagem do endoscópio com leve resistência.',
    del: ["7"],
    diagnostico: "ESTENOSE PILÓRICA PARCIAL",
  },
  {
    descricao: 'Nota-se deformidade pilórica acentuada por cicatriz de úlcera prévia que permite a passagem do aparelho sem resistência.',
    del: ["7"],
    diagnostico: "DEFORMIDADE PILÓRICA POR CICATRIZ DE ÚLCERA PRÉVIA",
  },
  {
    descricao: 'Nota-se desvio de eixo e deformidade pilórica acentuada devido à úlcera em atividade. Passagem do aparelho pelo piloro sem resistência.',
    del: ["7"],
    diagnostico: "DEFORMIDADE PILÓRICA POR ÚLCERA EM ATIVIDADE",
  },
  {
    descricao: 'Nota-se desvio de eixo e deformidade pilórica acentuada devido à úlcera em cicatrização. Passagem do aparelho pelo piloro sem resistência.',
    del: ["7"],
    diagnostico: "DEFORMIDADE PILÓRICA POR ÚLCERA EM CICATRIZAÇÃO",
  },
  {
    descricao: 'Nota-se piloro permanentemente relaxado e amplo, sem dinâmica peristáltica.',
    del: ["7"],
    diagnostico: "PILORO CALÁSICO",
  },
  {
    descricao: 'Nota-se em corpo gástrico, atrofia das pregas gástricas e mucosa adelgaçada com visualização dos vasos da submucosa.',
    del: ["4"],
    diagnostico: "GASTRITE ATRÓFICA DE CORPO",
  },
  {
    descricao: 'Nota-se em antro gástrico, mucosa adelgaçada com visualização dos vasos da submucosa, característico de mucosa atrófica.',
    del: ["6"],
    diagnostico: "GASTRITE ATRÓFICA DE ANTRO",
  },
  {
    descricao: 'Nota-se em corpo e antro, atrofia das pregas gástricas e mucosa adelgaçada com visualização dos vasos da submucosa.',
    del: ["4", "6"],
    diagnostico: "PANGASTRITE ATRÓFICA",
  },
  {
    descricao: 'Nota-se em mucosa de corpo, presença de enantema difuso de leve intensidade.',
    del: ["4"],
    diagnostico: "GASTRITE ENANTEMÁTICA LEVE DE CORPO",
  },
  {
    descricao: 'Nota-se em mucosa de antro, presença de enantema difuso de leve intensidade.',
    del: ["6"],
    diagnostico: "GASTRITE ENANTEMÁTICA LEVE DE ANTRO",
  },
  {
    descricao: 'Nota-se em mucosa de corpo e antro, presença de enantema difuso de leve intensidade',
    del: ["4", "6"],
    diagnostico: "PANGASTRITE ENANTEMÁTICA LEVE",
  },
  {
    descricao: 'Nota-se em mucosa de corpo, presença de enantema difuso de moderada intensidade.',
    del: ["4"],
    diagnostico: "GASTRITE ENANTEMÁTICA MODERADA DE CORPO",
  },
  {
    descricao: 'Nota-se em mucosa de antro, presença de enantema difuso de moderada intensidade.',
    del: ["6"],
    diagnostico: "GASTRITE ENANTEMÁTICA MODERADA DE ANTRO",
  },
  {
    descricao: 'Nota-se em mucosa de corpo e antro, presença de enantema difuso de moderada intensidade',
    del: ["4", "6"],
    diagnostico: "PANGASTRITE ENANTEMÁTICA MODERADA",
  },
  {
    descricao: 'Nota-se em mucosa de corpo, presença de enantema difuso intenso.',
    del: ["4"],
    diagnostico: "GASTRITE ENANTEMÁTICA INTENSA DE CORPO",
  },
  {
    descricao: 'Nota-se em mucosa de antro, presença de enantema difuso intenso.',
    del: ["6"],
    diagnostico: "GASTRITE ENANTEMÁTICA INTENSA DE ANTRO",
  },
  {
    descricao: 'Nota-se em mucosa de corpo e antro, presença de enantema difuso intenso.',
    del: ["4", "6"],
    diagnostico: "PANGASTRITE ENANTEMÁTICA INTENSA",
  },
  {
    descricao: 'Notam-se em mucosa de corpo, erosões planas, recobertas por fibrina, em pouca quantidade.',
    del: ["4"],
    diagnostico: "GASTRITE EROSIVA LEVE DE CORPO",
  },
  {
    descricao: 'Notam-se em mucosa de corpo, erosões planas, recobertas por fibrina, em pouca quantidade.',
    del: ["4"],
    diagnostico: "GASTRITE EROSIVA PLANA LEVE DE CORPO",
  },
  {
    descricao: 'Notam-se em mucosa de corpo, erosões elevadas, recobertas por fibrina, em pouca quantidade.',
    del: ["4"],
    diagnostico: "GASTRITE EROSIVA ELEVADA LEVE DE CORPO",
  },
  {
    descricao: 'Nota-se em mucosa de antro, presença de erosões planas, recobertas por fibrina, em pouca quantidade.',
    del: ["6"],
    diagnostico: "GASTRITE EROSIVA LEVE DE ANTRO",
  },
  {
    descricao: 'Nota-se em mucosa de antro, presença de erosões planas, recobertas por fibrina, em pouca quantidade.',
    del: ["6"],
    diagnostico: "GASTRITE EROSIVA PLANA LEVE DE ANTRO",
  },
  {
    descricao: 'Nota-se em mucosa de antro, presença de erosões elevadas, recobertas por fibrina, em pouca quantidade.',
    del: ["6"],
    diagnostico: "GASTRITE EROSIVA ELEVADA LEVE DE ANTRO",
  },
  {
    descricao: 'Nota-se em mucosa de corpo e antro, presença de erosões planas, recobertas por fibrina, em pouca quantidade.',
    del: ["4", "6"],
    diagnostico: "PANGASTRITE EROSIVA LEVE",
  },
  {
    descricao: 'Nota-se em mucosa de corpo e antro, presença de erosões planas, recobertas por fibrina, em pouca quantidade.',
    del: ["4", "6"],
    diagnostico: "PANGASTRITE EROSIVA PLANA LEVE",
  },
  {
    descricao: 'Nota-se em mucosa de corpo e antro, presença de erosões elevadas, recobertas por fibrina, em pouca quantidade.',
    del: ["4", "6"],
    diagnostico: "PANGASTRITE EROSIVA ELEVADA LEVE",
  },
  {
    descricao: 'Notam-se em mucosa de corpo, erosões planas, recobertas por fibrina, em moderada quantidade.',
    del: ["4"],
    diagnostico: "GASTRITE EROSIVA MODERADA DE CORPO",
  },
  {
    descricao: 'Notam-se em mucosa de corpo, erosões planas, recobertas por fibrina, em moderada quantidade.',
    del: ["4"],
    diagnostico: "GASTRITE EROSIVA PLANA MODERADA DE CORPO",
  },
  {
    descricao: 'Notam-se em mucosa de corpo, erosões elevadas, recobertas por fibrina, em moderada quantidade.',
    del: ["4"],
    diagnostico: "GASTRITE EROSIVA ELEVADA MODERADA DE CORPO",
  },
  {
    descricao: 'Nota-se em mucosa de antro, presença de erosões planas, recobertas por fibrina, em moderada quantidade.',
    del: ["6"],
    diagnostico: "GASTRITE EROSIVA MODERADA DE ANTRO",
  },
  {
    descricao: 'Notam-se em mucosa de antro, presença de erosões planas, recobertas por fibrina, em moderada quantidade.',
    del: ["6"],
    diagnostico: "GASTRITE EROSIVA PLANA MODERADA DE ANTRO",
  },
  {
    descricao: 'Notam-se em mucosa de antro, presença de erosões elevadas, recobertas por fibrina, em moderada quantidade.',
    del: ["6"],
    diagnostico: "GASTRITE EROSIVA ELEVADA MODERADA DE ANTRO",
  },
  {
    descricao: 'Nota-se em mucosa de corpo e antro, presença de erosões planas, recobertas por fibrina, em moderada quantidade.',
    del: ["4", "6"],
    diagnostico: "PANGASTRITE EROSIVA MODERADA",
  },
  {
    descricao: 'Nota-se em mucosa de corpo e antro, presença de erosões planas, recobertas por fibrina, em moderada quantidade.',
    del: ["4", "6"],
    diagnostico: "PANGASTRITE EROSIVA PLANA MODERADA",
  },
  {
    descricao: 'Nota-se em mucosa de corpo e antro, presença de erosões elevadas, recobertas por fibrina, em moderada quantidade.',
    del: ["4", "6"],
    diagnostico: "PANGASTRITE EROSIVA ELEVADA MODERADA",
  },
  {
    descricao: 'Notam-se em mucosa de corpo, erosões planas, recobertas por fibrina, em grande quantidade.',
    del: ["4"],
    diagnostico: "GASTRITE EROSIVA INTENSA DE CORPO",
  },
  {
    descricao: 'Notam-se em mucosa de corpo, erosões planas, recobertas por fibrina, em grande quantidade.',
    del: ["4"],
    diagnostico: "GASTRITE EROSIVA PLANA INTENSA DE CORPO",
  },
  {
    descricao: 'Notam-se em mucosa de corpo, erosões elevadas, recobertas por fibrina, em grande quantidade.',
    del: ["4"],
    diagnostico: "GASTRITE EROSIVA ELEVADA INTENSA DE CORPO",
  },
  {
    descricao: 'Nota-se em mucosa de antro, presença de erosões planas, recobertas por fibrina, em grande quantidade.',
    del: ["6"],
    diagnostico: "GASTRITE EROSIVA INTENSA DE ANTRO",
  },
  {
    descricao: 'Nota-se em mucosa de antro, presença de erosões planas, recobertas por fibrina, em grande quantidade.',
    del: ["6"],
    diagnostico: "GASTRITE EROSIVA PLANA INTENSA DE ANTRO",
  },
  {
    descricao: 'Nota-se em mucosa de antro, presença de erosões elevadas, recobertas por fibrina, em grande quantidade.',
    del: ["6"],
    diagnostico: "GASTRITE EROSIVA ELEVADA INTENSA DE ANTRO",
  },
  {
    descricao: 'Nota-se em mucosa de corpo e antro, presença de erosões planas, recobertas por fibrina, em grande quantidade.',
    del: ["4", "6"],
    diagnostico: "PANGASTRITE EROSIVA INTENSA",
  },
  {
    descricao: 'Nota-se em mucosa de corpo e antro, presença de erosões planas, recobertas por fibrina, em grande quantidade.',
    del: ["4", "6"],
    diagnostico: "PANGASTRITE EROSIVA PLANA INTENSA",
  },
  {
    descricao: 'Nota-se em mucosa de corpo e antro, presença de erosões elevadas, recobertas por fibrina, em grande quantidade.',
    del: ["4", "6"],
    diagnostico: "PANGASTRITE EROSIVA ELEVADA INTENSA",
  },
  {
    descricao: 'Notam-se em mucosa de corpo, pontos de hemorragia transmural em pouca quantidade.',
    del: ["5"],
    diagnostico: "GASTRITE HEMORRÁGICA LEVE DE CORPO",
  },
  {
    descricao: 'Notam-se em mucosa de antro, pontos de hemorragia transmural em pouca quantidade.',
    del: ["6"],
    diagnostico: "GASTRITE HEMORRÁGICA LEVE DE ANTRO",
  },
  {
    descricao: 'Notam-se em mucosa de corpo e antro, pontos de hemorragia transmural em pouca quantidade.',
    del: ["4", "6"],
    diagnostico: "PANGASTRITE HEMORRÁGICA LEVE",
  },
  {
    descricao: 'Notam-se em mucosa de corpo, pontos de hemorragia transmural em moderada quantidade.',
    del: ["4"],
    diagnostico: "GASTRITE HEMORRÁGICA MODERADA DE CORPO",
  },
  {
    descricao: 'Notam-se em mucosa de antro, pontos de hemorragia transmural em moderada quantidade.',
    del: ["6"],
    diagnostico: "GASTRITE HEMORRÁGICA MODERADA DE ANTRO",
  },
  {
    descricao: 'Notam-se em mucosa de corpo e antro, pontos de hemorragia transmural em moderada quantidade.',
    del: ["4", "6"],
    diagnostico: "PANGASTRITE HEMORRÁGICA MODERADA",
  },
  {
    descricao: 'Lago mucoso com sangue. \nNotam-se em mucosa de corpo, pontos de hemorragia transmural em grande quantidade.',
    del: ["1", "4"],
    diagnostico: "GASTRITE HEMORRÁGICA INTENSA DE CORPO",
  },
  {
    descricao: 'Lago mucoso com sangue. \nNotam-se em mucosa de antro, pontos de hemorragia transmural em grande quantidade.',
    del: ["1", "6"],
    diagnostico: "GASTRITE HEMORRÁGICA INTENSA DE ANTRO",
  },
  {
    descricao: 'Lago mucoso com sangue. \nNotam-se em mucosa de corpo e antro, pontos de hemorragia transmural em grande quantidade.',
    del: ["1", "4", "6"],
    diagnostico: "PANGASTRITE HEMORRÁGICA INTENSA",
  },
  {
    descricao: "Nota-se em antro, mucosa de aspecto nodular difuso.",
    del: ["6"],
    diagnostico: "GASTROPATIA NODOSA DE ANTRO",
  },
  {
    descricao: "Nota-se em antro, mucosa de aspecto nodular difuso.",
    del: ["6"],
    diagnostico: "GASTRITE NOSODA DE ANTRO",
  },
  {
    descricao: "Nota-se em antro, mucosa de aspecto nodular difuso.",
    del: ["4"],
    diagnostico: "GASTROPATIA NODOSA DE CORPO",
  },
  {
    descricao: "Nota-se em antro, mucosa de aspecto nodular difuso.",
    del: ["4"],
    diagnostico: "GASTRITE NOSODA DE CORPO",
  },
  {
    descricao: "Nota-se em antro, mucosa de aspecto nodular difuso.",
    del: ["4", "6"],
    diagnostico: "GASTROPATIA NODOSA DE CORPO E ANTRO",
  },
  {
    descricao: "Nota-se em antro, mucosa de aspecto nodular difuso.",
    del: ["4", "6"],
    diagnostico: "PANGASTRITE NOSODA",
  },
  {
    descricao: "Mucosa de corpo de aspecto sistematizado, exibindo rendilhado padrão mosaico, característico de gastropatia hipertensiva de leve intensidade.",
    del: ["4"],
    diagnostico: "GASTROPATIA HIPERTENSIVA PORTAL LEVE",
  },
  {
    descricao: "Mucosa de corpo de aspecto sistematizado, exibindo rendilhado padrão mosaico e esparsos “cherry red spots”, característico de gastropatia hipertensiva de moderada intensidade.",
    del: ["4"],
    diagnostico: "GASTROPATIA HIPERTENSIVA PORTAL MODERADA",
  },
  {
    descricao: "Mucosa de corpo de aspecto sistematizado, exibindo rendilhado padrão mosaico e inúmeros “cherry red spots”, além de pontos de hemorragia intramural, característico de gastropatia hipertensiva severa.",
    del: ["4"],
    diagnostico: "GASTROPATIA HIPERTENSIVA PORTAL SEVERA",
  },
  {
    descricao: "Nota-se em mucosa de corpo e antro, presença de enantema difuso de leve intensidade com poucas erosões planas em antro.",
    del: ["4", "6"],
    diagnostico: "PANGASTRITE ENANTEMÁTICA LEVE COM COMPONENTE EROSIVO ANTRAL",
  },
  {
    descricao: "Nota-se em mucosa de corpo e antro, presença de enantema difuso de leve intensidade com poucas erosões planas em corpo.",
    del: ["4", "6"],
    diagnostico: "PANGASTRITE ENANTEMÁTICA LEVE COM COMPONENTE EROSIVO EM CORPO",
  },
  {
    descricao: "Nota-se em mucosa de corpo e antro, presença de erosões planas em pouca quantidade e enantema difuso de leve intensidade em antro.",
    del: ["4", "6"],
    diagnostico: "PANGASTRITE EROSIVA LEVE COM COMPONENTE ENANTEMÁTICO ANTRAL",
  },
  {
    descricao: "Nota-se em mucosa de corpo e antro, presença de erosões planas em pouca quantidade e enantema difuso de leve intensidade em corpo.",
    del: ["4", "6"],
    diagnostico: "PANGASTRITE EROSIVA LEVE COM COMPONENTE ENANTEMÁTICO EM CORPO",
  },
  {
    descricao: "Observam-se pregas gástricas espessadas e aumentadas de tamanho com leve edema de mucosa.",
    del: ["4"],
    diagnostico: "HIPERTROFIA DE PREGAS GÁSTRICAS",
  },
  {
    descricao: "Notam-se em mucosa de corpo e antro, pontos de hemorragia transmural em moderada quantidade (lesão aguda de mucosa?).",
    del: ["4", "6"],
    diagnostico: "LESÃO AGUDA DE MUCOSA GÁSTRICA (LAMG)",
  },
  {
    descricao: "Notam-se em antro, áreas enantemáticas, levemente deprimidas, sugestivas de metaplasia intestinal.",
    del: ["6"],
    diagnostico: "ÁREAS GÁSTRICAS DEPRIMIDAS SUGESTIVAS DE METAPLASIA INTESTINAL",
  },
  {
    descricao: "Notam-se em antro, placas esbranquiçadas, levemente elevadas, sugestivas de metaplasia intestinal.",
    del: ["6"],
    diagnostico: "ÁREAS SUGESTIVAS DE METAPLASIA INTESTINAL EM ANTRO",
  },
  {
    descricao: "Notam-se em corpo, placas esbranquiçadas, levemente elevadas, sugestivas de metaplasia intestinal.",
    del: ["4"],
    diagnostico: "ÁREAS SUGESTIVAS DE METAPLASIA INTESTINAL EM CORPO",
  },
  {
    descricao: "Notam-se em corpo e antro, placas esbranquiçadas, levemente elevadas, sugestivas de metaplasia intestinal.",
    del: ["4", "6"],
    diagnostico: "ÁREAS SUGESTIVAS DE METAPLASIA INTESTINAL EM CORPO E ANTRO",
  },
  {
    diagnostico: "PÂNCREAS ECTÓPICO GÁSTRICO",
    del: ["6"],
    descricao: "Nota-se em grande curvatura de antro, uma área arredondada, elevada, envolta por mucosa normal, com umbilicação central, sugestiva de pâncreas gástrico ecotópico."
  },
  {
    diagnostico: "POLIPOSE GÁSTRICA",
    del: ["4", "6"],
    descricao: "Notam-se em corpo e antro, inúmeros pólipos sésseis, lisos, alguns com mucosa normal e outros enantemáticos em sua superfície, medindo entre 10 e 20mm."
  },
  {
    diagnostico: "PSEUDOMELANOSE GÁSTRICA",
    del: ["6"],
    descricao: "Nota-se em antro, mucosa exibindo pontilhado difuso, de coloração acastanhada e levemente enegrecida, com aspecto “tigróide”, sem soluções de continuidade, sugestivo de pseudomelanose.  "
  },
  {
    diagnostico: "PREGAS GÁSTRICAS HIPERPLÁSICAS",
    del: ["4"],
    descricao: "Notam-se em corpo, pregas gástricas espessadas, aumentadas de tamanho, com mucosa levemente edemaciada. "
  },
  {
    diagnostico: "SINAIS DE INCONTINÊNCIA CARDIO-HIATAL",
    del: ["2"],
    descricao: "À retrovisão, nota-se hiato alargado com cárdia relaxado. "
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE FINO CALIBRE SEM “RED SPOTS” (GOV-1)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se em pequena curvatura prolongamentos varicosos de fino calibre, azulados, retilíneos, sem “red spots”."
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE FINO CALIBRE SEM “RED SPOTS” (GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se em fórnix de grande curvatura, cordões varicosos de fino calibre, azulados, sem “red spots”."
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE FINO CALIBRE SEM “RED SPOTS” (GOV-1 e GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se cordões varicosos de fino calibre, azulados, sem “red spots”, prolongando-se tanto para pequena curvatura quanto para fórnix de grande curvatura. "
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE FINO CALIBRE COM “RED SPOTS” (GOV-1)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se em pequena curvatura prolongamentos varicosos de fino calibre, azulados, retilíneos, com “red spots”."
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE FINO CALIBRE COM “RED SPOTS” (GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se em fórnix de grande curvatura, cordões varicosos de fino calibre, azulados, com “red spots”."
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE FINO CALIBRE COM “RED SPOTS” (GOV-1 e GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se cordões varicosos de fino calibre, azulados, com “red spots”, prolongando-se tanto para pequena curvatura quanto para fórnix de grande curvatura. "
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE MÉDIO CALIBRE SEM “RED SPOTS” (GOV-1)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se em pequena curvatura prolongamentos varicosos de médio calibre, azulados, retilíneos, sem “red spots”."
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE MÉDIO CALIBRE SEM “RED SPOTS” (GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se em fórnix de grande curvatura, cordões varicosos de médio calibre, azulados, sem “red spots”."
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE MÉDIO CALIBRE SEM “RED SPOTS” (GOV-1 e GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se cordões varicosos de médio calibre, azulados, sem “red spots”, prolongando-se tanto para pequena curvatura quanto para fórnix de grande curvatura. "
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE MÉDIO CALIBRE COM “RED SPOTS” (GOV-1)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se em pequena curvatura prolongamentos varicosos de médio calibre, azulados, retilíneos, com “red spots”."
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE MÉDIO CALIBRE COM “RED SPOTS” (GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se em fórnix de grande curvatura, cordões varicosos de médio calibre, azulados, com “red spots”."
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE MÉDIO CALIBRE COM “RED SPOTS” (GOV-1 e GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se cordões varicosos de médio calibre, azulados, com “red spots”, prolongando-se tanto para pequena curvatura quanto para fórnix de grande curvatura. "
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE FINO E MÉDIO CALIBRE SEM “RED SPOTS” (GOV-1)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se em pequena curvatura prolongamentos varicosos de fino e médio calibre, azulados, retilíneos, sem “red spots”."
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE FINO E MÉDIO CALIBRE SEM “RED SPOTS” (GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se em fórnix de grande curvatura, cordões varicosos de fino e médio calibre, azulados, sem “red spots”."
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE FINO E MÉDIO CALIBRE SEM “RED SPOTS” (GOV-1 e GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se cordões varicosos de fino e médio calibre, azulados, sem “red spots”, prolongando-se tanto para pequena curvatura quanto para fórnix de grande curvatura. "
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE FINO E MÉDIO CALIBRE COM “RED SPOTS” (GOV-1)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se em pequena curvatura prolongamentos varicosos de fino e médio calibre, azulados, retilíneos, com “red spots”."
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE FINO E MÉDIO CALIBRE COM “RED SPOTS” (GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se em fórnix de grande curvatura, cordões varicosos de fino e médio calibre, azulados, com “red spots”."
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE FINO E MÉDIO CALIBRE COM “RED SPOTS” (GOV-1 e GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se cordões varicosos de fino e médio calibre, azulados, com “red spots”, prolongando-se tanto para pequena curvatura quanto para fórnix de grande curvatura. "
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE GROSSO CALIBRE SEM “RED SPOTS” (GOV-1)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se em pequena curvatura prolongamentos varicosos de grosso calibre, azulados, retilíneos, sem “red spots”."
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE GROSSO CALIBRE SEM “RED SPOTS” (GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se em fórnix de grande curvatura, cordões varicosos de grosso calibre, azulados, sem “red spots”."
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE GROSSO CALIBRE SEM “RED SPOTS” (GOV-1 e GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se cordões varicosos de grosso calibre, azulados, sem “red spots”, prolongando-se tanto para pequena curvatura quanto para fórnix de grande curvatura. "
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE GROSSO CALIBRE COM “RED SPOTS” (GOV-1)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se em pequena curvatura prolongamentos varicosos de grosso calibre, azulados, retilíneos, com “red spots”."
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE GROSSO CALIBRE COM “RED SPOTS” (GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se em fórnix de grande curvatura, cordões varicosos de grosso calibre, azulados, com “red spots”."
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE GROSSO CALIBRE COM “RED SPOTS” (GOV-1 e GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se cordões varicosos de grosso calibre, azulados, com “red spots”, prolongando-se tanto para pequena curvatura quanto para fórnix de grande curvatura. "
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE FINO E GROSSO CALIBRE SEM “RED SPOTS” (GOV-1)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se em pequena curvatura prolongamentos varicosos de fino e grosso calibre, azulados, retilíneos, sem “red spots”."
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE FINO E GROSSO CALIBRE SEM “RED SPOTS” (GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se em fórnix de grande curvatura, cordões varicosos de fino e grosso calibre, azulados, sem “red spots”."
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE FINO E GROSSO CALIBRE SEM “RED SPOTS” (GOV-1 e GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se cordões varicosos de fino e grosso calibre, azulados, sem “red spots”, prolongando-se tanto para pequena curvatura quanto para fórnix de grande curvatura. "
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE FINO E GROSSO CALIBRE COM “RED SPOTS” (GOV-1)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se em pequena curvatura prolongamentos varicosos de fino e grosso calibre, azulados, retilíneos, com “red spots”."
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE FINO E GROSSO CALIBRE COM “RED SPOTS” (GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se em fórnix de grande curvatura, cordões varicosos de fino e grosso calibre, azulados, com “red spots”."
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE FINO E GROSSO CALIBRE COM “RED SPOTS” (GOV-1 e GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se cordões varicosos de fino e grosso calibre, azulados, com “red spots”, prolongando-se tanto para pequena curvatura quanto para fórnix de grande curvatura. "
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE MÉDIO E GROSSO CALIBRE SEM “RED SPOTS” (GOV-1)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se em pequena curvatura prolongamentos varicosos de médio e grosso calibre, azulados, retilíneos, sem “red spots”."
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE MÉDIO E GROSSO CALIBRE SEM “RED SPOTS” (GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se em fórnix de grande curvatura, cordões varicosos de médio e grosso calibre, azulados, sem “red spots”."
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE MÉDIO E GROSSO CALIBRE SEM “RED SPOTS” (GOV-1 e GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se cordões varicosos de médio e grosso calibre, azulados, sem “red spots”, prolongando-se tanto para pequena curvatura quanto para fórnix de grande curvatura."
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE MÉDIO E GROSSO CALIBRE COM “RED SPOTS” (GOV-1)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se em pequena curvatura prolongamentos varicosos de médio e grosso calibre, azulados, retilíneos, com “red spots”."
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE MÉDIO E GROSSO CALIBRE COM “RED SPOTS” (GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se em fórnix de grande curvatura, cordões varicosos de médio e grosso calibre, azulados, com “red spots”."
  },
  {
    diagnostico: "VARIZES DE FUNDO GÁSTRICO DE MÉDIO E GROSSO CALIBRE COM “RED SPOTS” (GOV-1 e GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se cordões varicosos de médio e grosso calibre, azulados, com “red spots”, prolongando-se tanto para pequena curvatura quanto para fórnix de grande curvatura."
  },
  {
    diagnostico: "VARIZ DE FUNDO GÁSTRICO DE FINO CALIBRE COM “RED SPOTS” (GOV-1)",
    del: ["2", "3"],
    descricao: "À retrovisão, nota-se em pequena curvatura, prolongamento varicoso de fino calibre, azulado, retilíneo, com “red spots”."
  },
  {
    diagnostico: "VARIZ DE FUNDO GÁSTRICO DE FINO CALIBRE SEM “RED SPOTS” (GOV-1)",
    del: ["2", "3"],
    descricao: "À retrovisão, nota-se em pequena curvatura, prolongamento varicoso de fino calibre, azulado, retilíneo, sem “red spots”."
  },
  {
    diagnostico: "VARIZ DE FUNDO GÁSTRICO DE FINO CALIBRE COM “RED SPOTS” (GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, nota-se em fórnix de grande curvatura, cordão varicoso de fino calibre, azulado, com “red spots”."
  },
  {
    diagnostico: "VARIZ DE FUNDO GÁSTRICO DE FINO CALIBRE SEM “RED SPOTS” (GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, nota-se em fórnix de grande curvatura, cordão varicoso de fino calibre, azulado, sem “red spots”."
  },
  {
    diagnostico: "VARIZ DE FUNDO GÁSTRICO DE MÉDIO CALIBRE COM “RED SPOTS” (GOV-1)",
    del: ["2", "3"],
    descricao: "À retrovisão, nota-se em pequena curvatura, prolongamento varicoso de médio calibre, azulado, retilíneo, com “red spots”."
  },
  {
    diagnostico: "VARIZ DE FUNDO GÁSTRICO DE MÉDIO CALIBRE SEM “RED SPOTS” (GOV-1)",
    del: ["2", "3"],
    descricao: "À retrovisão, nota-se em pequena curvatura, prolongamento varicoso de médio calibre, azulado, retilíneo, sem “red spots”."
  },
  {
    diagnostico: "VARIZ DE FUNDO GÁSTRICO DE MÉDIO CALIBRE COM “RED SPOTS” (GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, nota-se em fórnix de grande curvatura, cordão varicoso de médio calibre, azulado, com “red spots”."
  },
  {
    diagnostico: "VARIZ DE FUNDO GÁSTRICO DE MÉDIO CALIBRE SEM “RED SPOTS” (GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, nota-se em fórnix de grande curvatura, cordão varicoso de médio calibre, azulado, sem “red spots”."
  },
  {
    diagnostico: "VARIZ DE FUNDO GÁSTRICO DE GROSSO CALIBRE COM “RED SPOTS” (GOV-1)",
    del: ["2", "3"],
    descricao: "À retrovisão, nota-se em pequena curvatura, prolongamento varicoso de grosso calibre, azulado, retilíneo, com “red spots”."
  },
  {
    diagnostico: "VARIZ DE FUNDO GÁSTRICO DE GROSSO CALIBRE SEM “RED SPOTS” (GOV-1)",
    del: ["2", "3"],
    descricao: "À retrovisão, nota-se em pequena curvatura, prolongamento varicoso de grosso calibre, azulado, retilíneo, sem “red spots”."
  },
  {
    diagnostico: "VARIZ DE FUNDO GÁSTRICO DE GROSSO CALIBRE COM “RED SPOTS” (GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, nota-se em fórnix de grande curvatura, cordão varicoso de grosso calibre, azulado, com “red spots”."
  },
  {
    diagnostico: "VARIZ DE FUNDO GÁSTRICO DE GROSSO CALIBRE SEM “RED SPOTS” (GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, nota-se em fórnix de grande curvatura, cordão varicoso de grosso calibre, azulado, sem “red spots”."
  },
  {
    diagnostico: "PROLONGAMENTO VARICOSO DE FINO CALIBRE PARA PEQUENA CURVATURA SEM “RED SPOTS” (GOV-1)",
    del: ["2", "3"],
    descricao: "À retrovisão, nota-se em pequena curvatura, prolongamento varicoso de fino calibre, azulado, retilíneo, sem “red spots”."
  },
  {
    diagnostico: "PROLONGAMENTO VARICOSO DE FINO CALIBRE PARA PEQUENA CURVATURA COM “RED SPOTS” (GOV-1)",
    del: ["2", "3"],
    descricao: "À retrovisão, nota-se em pequena curvatura, prolongamento varicoso de fino calibre, azulado, retilíneo, com “red spots”."
  },
  {
    diagnostico: "PROLONGAMENTO VARICOSO DE FINO CALIBRE PARA PEQUENA CURVATURA COM “RED SPOTS” E PONTO DE RUPTURA VISÍVEL(GOV - 1)",
    del: ["2", "3"],
    descricao: "À retrovisão, nota-se em pequena curvatura, prolongamento varicoso de fino calibre, azulado, retilíneo, com “red spots” e ponto de ruptura recente visível,  recoberto por tampão fibrinoso sem sangramento ativo."
  },
  {
    diagnostico: "PROLONGAMENTO VARICOSO DE MÉDIO CALIBRE PARA PEQUENA CURVATURA COM “RED SPOTS” E PONTO DE RUPTURA VISÍVEL(GOV - 1)",
    del: ["2", "3"],
    descricao: "À retrovisão, nota-se em pequena curvatura, prolongamento varicoso de médio calibre, azulado, retilíneo, com “red spots” e ponto de ruptura recente visível, recoberto por tampão fibrinoso sem sangramento ativo. "
  },
  {
    diagnostico: "PROLONGAMENTO VARICOSO DE GROSSO CALIBRE PARA PEQUENA CURVATURA COM “RED SPOTS” E PONTO DE RUPTURA VISÍVEL(GOV - 1)",
    del: ["2", "3"],
    descricao: "À retrovisão, nota-se em pequena curvatura, prolongamento varicoso de grosso calibre, azulado, retilíneo, com “red spots” e ponto de ruptura recente visível, recoberto por tampão fibrinoso sem sangramento ativo. "
  },
  {
    diagnostico: "NOVELO VARICOSO EM FUNDO GÁSTRICO DE MÉDIO CALIBRE SEM “RED SPOTS” (GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, nota-se em fórnix de grande curvatura, novelo varicoso de médio calibre, azulado, sem “red spots”."
  },
  {
    diagnostico: "NOVELO VARICOSO EM FUNDO GÁSTRICO DE MÉDIO CALIBRE COM “RED SPOTS” (GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, nota-se em fórnix de grande curvatura, novelo varicoso de médio calibre, azulado, com “red spots”."
  },
  {
    diagnostico: "NOVELO VARICOSO EM FUNDO GÁSTRICO DE MÉDIO CALIBRE COM “RED SPOTS” E PONTO DE RUPTURA VISÍVEL (GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, nota-se em fórnix de grande curvatura, novelo varicoso de médio calibre, azulado, com “red spots” e ponto de ruptura recente visível, recoberto por tampão fibrinoso sem sangramento ativo."
  },
  {
    diagnostico: "NOVELO VARICOSO EM FUNDO GÁSTRICO DE GROSSO CALIBRE SEM “RED SPOTS” (GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, nota-se em fórnix de grande curvatura, novelo varicoso de grosso calibre, azulado, sem “red spots”."
  },
  {
    diagnostico: "NOVELO VARICOSO EM FUNDO GÁSTRICO DE GROSSO CALIBRE COM “RED SPOTS” (GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, nota-se em fórnix de grande curvatura, novelo varicoso de grosso calibre, azulado, com “red spots”."
  },
  {
    diagnostico: "NOVELO VARICOSO EM FUNDO GÁSTRICO DE GROSSO CALIBRE COM “RED SPOTS” E PONTO DE RUPTURA VISÍVEL (GOV-2)",
    del: ["2", "3"],
    descricao: "À retrovisão, nota-se em fórnix de grande curvatura, novelo varicoso de grosso calibre, azulado, com “red spots” e ponto de ruptura recente visível, recoberto por tampão fibrinoso sem sangramento ativo."
  },
  {
    diagnostico: "NOVELO VARICOSO EM FUNDO GÁSTRICO COM SANGRAMENTO ATIVO (GOV-2)",
    del: ["0", "1", "2", "3", "4", "5", "6", "7"],
    descricao: "Lago mucoso com grande quantidade de sangue vivo e coágulos. \nÀ retrovisão, nota-se em fórnix de grande curvatura, novelo varicoso de grosso calibre, azulado, com “red spots” e ponto de ruptura com sangramento ativo em jato. \nVisualização da mucosa gástrica prejudicada pela grande quantidade de resíduos hematínicos."
  },
  {
    diagnostico: "VARIZ ISOLADA DE FUNDO GÁSTRICO DE FINO CALIBRE (IGV-1)",
    del: ["2", "3"],
    descricao: "À retrovisão, nota-se em fórnix de grande curvatura, cordão varicoso isolado, de fino calibre, azulado, retilíneo, sem “red spots”."
  },
  {
    diagnostico: "  VARIZ ISOLADA DE FUNDO GÁSTRICO DE MÉDIO CALIBRE (IGV-1)",
    del: ["2", "3"],
    descricao: "À retrovisão, nota-se em fórnix de grande curvatura, cordão varicoso isolado, de médio calibre, azulado, sem “red spots”."
  },
  {
    diagnostico: "  VARIZ ISOLADA DE FUNDO GÁSTRICO DE GROSSO CALIBRE (IGV-1)",
    del: ["2", "3"],
    descricao: "À retrovisão, nota-se em fórnix de grande curvatura, cordão varicoso isolado, de grosso calibre, azulado, sem “red spots”."
  },
  {
    diagnostico: "  VARIZES ISOLADAS DE FUNDO GÁSTRICO DE FINO CALIBRE (IGV-1)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se em fórnix de grande curvatura, cordões varicosos isolados, de fino calibre, azulados, sem “red spots”."
  },
  {
    diagnostico: "VARIZES ISOLADAS DE FUNDO GÁSTRICO DE MÉDIO CALIBRE (IGV-1)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se em fórnix de grande curvatura, cordões varicosos isolados,  de médio calibre, azulados, sem “red spots”."
  },
  {
    diagnostico: "VARIZES ISOLADAS DE FUNDO GÁSTRICO DE GROSSO CALIBRE (IGV-1)",
    del: ["2", "3"],
    descricao: "À retrovisão, notam-se em fórnix de grande curvatura, cordões varicosos isolados,  de grosso calibre, azulados, sem “red spots”."
  },
  {
    diagnostico: "VARIZ DE FUNDO GÁSTRICO COM EXTRUSÃO DE CIANOACRILATO",
    del: ["2", "3"],
    descricao: "À retrovisão, nota-se em fórnix de grande curvatura, variz de fundo gástrico com ponto de extrusão de cianoacrilato decorrente de terapia prévia. "
  },
  {
    diagnostico: "VARIZ DE FUNDO GÁSTRICO OBLITERADO (EXTRUSÃO DE CIANOACRILATO)",
    del: ["2", "3"],
    descricao: "À retrovisão, nota-se em fórnix de grande curvatura, variz de fundo gástrico com ponto de extrusão de cianoacrilato decorrente de terapia prévia. "
  },
  {
    diagnostico: "NOVELO VARICOSO OBLITERADO POR CIANOACRILATO COM PONTO DE EXTRUSÃO DE COLA",
    del: ["2", "3"],
    descricao: "À retrovisão, nota-se em fórnix de grande curvatura, novelo varicoso com ponto de extrusão de cianoacrilato decorrente de terapia prévia."
  },
  {
    diagnostico: "VARIZES ISOLADAS EM ANTRO",
    del: ["6"],
    descricao: "Notam-se em antro, cordões varicosos isolados, de fino calibre, sem “red spots” ou sinais de sangramento."
  }
]

export const fluxoEstomagoDiagnosticosX = [
  {
    diagnostico: "NEOPLASIA GÁSTRICA AVANÇADA BORRMANN I",
    descricao: "Nota-se em __ , lesão vegetante, polipóide, enantemática, de bordos bem delimitados, de aspecto neoplásico, friável ao toque da pinça de biópsia."
  },
  {
    diagnostico: "NEOPLASIA GÁSTRICA AVANÇADA BORRMANN II",
    descricao: "Nota-se em __ , lesão ulcerada, com fundo fibrinoso, bordos bem delimitados e elevados, de aspecto neoplásico, friável ao toque da pinça de biópsia."
  },
  {
    diagnostico: "NEOPLASIA GÁSTRICA AVANÇADA BORRMANN III",
    descricao: "Nota-se em __ , lesão ulceroinfiltrativa, de fundo fibrinoso, bordos mal delimitados, de aspecto neoplásico, friável ao toque da pinça de biópsia."
  },
  {
    diagnostico: "NEOPLASIA GÁSTRICA AVANÇADA BORRMANN IV",
    descricao: "Nota-se em __ , lesão difusamente infiltrativa, extensa, de bordos imprecisos, de aspecto neoplásico, friável ao toque da pinça de biópsia."
  },
  {
    diagnostico: "NEOPLASIA GÁSTRICA PRECOCE (PARIS I)",
    descricao: "Nota-se em __ , pequena lesão gástrica elevada (> 2,5mm), pálida, de bordos bem delimitados por mucosa normal."
  },
  {
    diagnostico: "NEOPLASIA GÁSTRICA PRECOCE (PARIS I-p)",
    descricao: "Nota-se em __, pequena lesão gástrica polipóide, pálida, de bordos bem delimitados por mucosa normal."
  },
  {
    diagnostico: "NEOPLASIA GÁSTRICA PRECOCE (PARIS I-s)",
    descricao: "Nota-se em __, pequena lesão gástrica elevada séssil, pálida, de bordos bem delimitados por mucosa normal."
  },
  {
    diagnostico: "NEOPLASIA GÁSTRICA PRECOCE (PARIS IIA)",
    descricao: "Nota-se em __, pequena lesão gástrica, levemente elevada, pálida, de bordos bem delimitados por mucosa normal."
  },
  {
    diagnostico: "NEOPLASIA GÁSTRICA PRECOCE (PARIS IIA + IIC)",
    descricao: "Nota-se em __, ),  lesão gástrica, predominantemente elevada com área central de leve depressão, pálida, de bordos bem delimitados por mucosa normal."
  },
  {
    diagnostico: "NEOPLASIA GÁSTRICA PRECOCE (PARIS IIA + IIB)",
    descricao: "Nota-se em __, lesão gástrica, predominantemente elevada com área central plana, pálida, de bordos bem delimitados por mucosa normal."
  },
  {
    diagnostico: "NEOPLASIA GÁSTRICA PRECOCE (PARIS IIA + III)",
    descricao: "Nota-se em __, lesão gástrica, predominantemente elevada com área central ulcerada, pálida, de bordos bem delimitados por mucosa normal."
  },
  {
    diagnostico: "NEOPLASIA GÁSTRICA PRECOCE (PARIS IIB)",
    descricao: "Nota-se em __, lesão gástrica, plana, pálida, de bordos bem delimitados por mucosa normal."
  },
  {
    diagnostico: "NEOPLASIA GÁSTRICA PRECOCE (PARIS IIB + IIA)",
    descricao: "Nota-se em __, lesão gástrica, predominantemente plana com área elevada periférica, pálida, de bordos bem delimitados por mucosa normal."
  },
  {
    diagnostico: "NEOPLASIA GÁSTRICA PRECOCE (PARIS IIB + IIC)",
    descricao: "Nota-se em __, ),  lesão gástrica, predominantemente plana com área central de leve depressão, pálida, de bordos bem delimitados por mucosa normal."
  },
  {
    diagnostico: "NEOPLASIA GÁSTRICA PRECOCE (PARIS IIB + III)",
    descricao: "Nota-se em __, lesão gástrica, predominantemente plana com área central ulcerada fibrinosa , pálida, de bordos bem delimitados por mucosa normal."
  },
  {
    diagnostico: "NEOPLASIA GÁSTRICA PRECOCE (PARIS IIC)",
    descricao: "Nota-se em __, lesão gástrica, levemente deprimida, pálida, de bordos bem delimitados por mucosa normal."
  },
  {
    diagnostico: "NEOPLASIA GÁSTRICA PRECOCE (PARIS IIC + IIA)",
    descricao: "Nota-se em __, lesão gástrica, predominantemente deprimida e com área elevada periférica, pálida, de bordos bem delimitados por mucosa normal."
  },
  {
    diagnostico: "NEOPLASIA GÁSTRICA PRECOCE (PARIS IIC + IIB)",
    descricao: "Nota-se em __, lesão gástrica, predominantemente deprimida com uma pequena área plana, pálida, de bordos bem delimitados por mucosa normal."
  },
  {
    diagnostico: "NEOPLASIA GÁSTRICA PRECOCE (PARIS IIC + III)",
    descricao: "Nota-se em __, lesão gástrica, predominantemente deprimida com área central ulcerada fibrinosa , pálida, de bordos bem delimitados por mucosa normal."
  },
  {
    diagnostico: "NEOPLASIA GÁSTRICA PRECOCE (PARIS III)",
    descricao: "Nota-se em __, lesão gástrica com ulceração fibrinosa de bordos bem delimitados por mucosa normal."
  },
  {
    diagnostico: "NEOPLASIA GÁSTRICA PRECOCE (PARIS III + IIA)",
    descricao: "Nota-se em __, lesão gástrica predominantemente ulcerada e com pequena área elevada periférica, pálida, de bordos bem delimitados por mucosa normal."
  },
  {
    diagnostico: "NEOPLASIA GÁSTRICA PRECOCE (PARIS III + IIB)",
    descricao: "Nota-se em __, lesão gástrica predominantemente ulcerada e com pequena área plana periférica, pálida, de bordos bem delimitados por mucosa normal."
  },
  {
    diagnostico: "NEOPLASIA GÁSTRICA PRECOCE (PARIS III + IIC)",
    descricao: "Nota-se em __, lesão gástrica predominantemente ulcerada e com pequena área deprimida periférica, pálida, de bordos bem delimitados por mucosa normal."
  },
  {
    diagnostico: "LESÃO GÁSTRICA DE ASPECTO NEOPLÁSICO PRECOCE",
    descricao: "Nota-se em __ , pequena lesão gástrica, pálida, de bordos bem delimitados por mucosa normal, podendo corresponder a neoplasia gástrica precoce."
  },
  {
    diagnostico: "LESÃO GÁSTRICA PODENDO CORRESPONDER À NEOPLASIA PRECOCE",
    descricao: "Nota-se em __ , pequena lesão gástrica, pálida, de bordos bem delimitados por mucosa normal, podendo corresponder a neoplasia gástrica precoce."
  },
  {
    diagnostico: "PÓLIPO GÁSTRICO ELEVADO (YAMADA I)",
    descricao: "Nota-se em __ , pólipo levemente elevado, liso, recoberto por mucosa normal, medindo cerca de 10mm. "
  },
  {
    diagnostico: "PÓLIPO GÁSTRICO SÉSSIL (YAMADA II)",
    descricao: "Nota-se em __ , pólipo levemente séssil, liso, recoberto por mucosa normal, medindo cerca de 10mm."
  },
  {
    diagnostico: "PÓLIPO GÁSTRICO SEMIPEDICULADO (YAMADA III)",
    descricao: "Nota-se em __ , pólipo semipediculado, liso, com superfície enantemática, medindo cerca de 10mm."
  },
  {
    diagnostico: "PÓLIPO GÁSTRICO PEDICULADO (YAMADA IV)",
    descricao: "Nota-se em __ , pólipo com pedículo fino e superfície enantemática, medindo cerca de 10mm."
  },
  {
    diagnostico: "ÁREA GÁSTRICA ELEVADA DE ASPECTO SUBEPITELIAL ",
    descricao: "Nota-se em __ , área elevada, recoberta por mucosa normal, móvel e de consistência fibroelástica ao toque da pinça de biópsia, característica de elevação subepitelial gástrica. "
  },
  {
    diagnostico: "LESÃO ULCEROINFILTRATIVA GÁSTRICA",
    descricao: "Nota-se em __ , lesão ulceroinfiltrativa, friável, de fundo fibrinoso, bordos imprecisos, medindo cerca de 15mm. "
  },
  {
    diagnostico: "LIPOMA GÁSTRICO",
    descricao: "Nota-se em __ , área elevada, recoberta por mucosa normal, móvel e de consistência fibroelástica ao toque da pinça de biópsia, característica de lipoma.  "
  },
  {
    diagnostico: "SARCOMAS DE KAPOSI GÁSTRICO",
    descricao: "Notam-se em __ , áreas eritematosas, algumas violáceas, planas e polipóides sésseis, delimitadas por mucosa gástrica íntegra, sugestivas de sarcoma de Kaposi.  "
  },
  {
    diagnostico: "ÁREAS VIOLÁCEAS EM ESTÔMAGO (SARCOMA DE KAPOSI?)",
    descricao: "Notam-se em __ , áreas eritematosas, algumas violáceas, planas e polipóides sésseis, delimitadas por mucosa gástrica íntegra, sugestivas de sarcoma de Kaposi.  "
  },
  {
    diagnostico: "SARCOMA DE KAPOSI GÁSTRICO",
    descricao: "Nota-se em __, área eritematosa-violácea, plana, delimitada por mucosa gástrica íntegra, sugestiva de sarcoma de Kaposi.  "
  },
  {
    diagnostico: "ÁREA VIOLÁCEA EM ESTÔMAGO (SARCOMA DE KAPOSI?)",
    descricao: "Nota-se em __ , área eritematosa-violácea, plana, delimitada por mucosa gástrica íntegra, sugestiva de sarcoma de Kaposi.  "
  },
  {
    diagnostico: "LESÃO ELEVADA EM ESTÔMAGO (CARCINÓIDE GÁSTRICO?)",
    descricao: "Nota-se em __ , pólipo séssil, enantemático, com mucosa de aspecto granular, bem delimitado por mucosa normal, medindo cerca de 10mm (carcinóide gástrico?)."
  },
  {
    diagnostico: "XANTELASMA GÁSTRICO",
    descricao: "Nota-se em __ , área amarelo-esbranquiçada, plana, medindo cerca de 5mm, característico de xantelasma gástrico."
  },
]

export const fluxoTerapeuticaEstomago = [
  {
    diagnostico: "CAUTERIZAÇÃO DE ANGIECTASIA COM PLASMA DE ARGÔNIO",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado cauterização de angiectasia com plasma de argônio (Pot 30W Fluxo 1,5L/min). Procedimento sem intercorrências. "
  },
  {
    diagnostico: "CAUTERIZAÇÃO DE COTO VASCULAR COM PLASMA DE ARGÔNIO",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado cauterização de coto vascular com plasma de argônio (Pot 60W Fluxo 2,0L/min). Procedimento sem intercorrências. "
  },
  {
    diagnostico: "CAUTERIZAÇÃO DE ECTASIAS VASCULARES ANTRAIS COM PLASMA DE ARGÔNIO",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado cauterização de ectasias vasculares antrais com plasma de argônio (Pot 30W Fluxo 1,5L/min). Procedimento sem intercorrências. "
  },
  {
    diagnostico: "DILATAÇÃO DE ESTENOSE PILÓRICA COM BALÃO TIPO CRE",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado dilatação de estenose pilórica com balão tipo CRE 16mm com bom resultado imediato. Procedimento sem intercorrências. "
  },
  {
    diagnostico: "GASTROSTOMIA ENDOSCÓPICA PERCUT NEA ",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Paciente posicionado em decúbito dorsal + assepsia e antissepsia do abdome + aposição de campos estéreis. Após escolha do ponto de punção por transiluminação, realizada punção com agulha, passado fio-guia através da pele em direção à cavidade gástrica, seguido e apreensão do fio por via endoscópica, sendo retirado pela boca. Entrelaçada sonda de gastrostomia no fio-guia e tracionada pelo sítio de punção, com adequado posicionamento no anteparo interno na parede gástrica. Revisão endoscópica: boa hemostasia e sonda bem posicionada. Procedimento sem intercorrências."
  },
  {
    diagnostico: "HEMOSTASIA DE ÚLCERA COM INJEÇÃO DE ADRENALINA",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizada hemostasia endoscópica nos bordos da úlcera com solução de adrenalina (1:10000) em __ pontos de punção com total infundido de __ ml. Procedimento sem intercorrências e com bom resultado imediato."
  },
  {
    diagnostico: "HEMOSTASIA DE LESÃO DE DIEULAFOY COM INJEÇÃO DE ADRENALINA",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizada hemostasia endoscópica em coto vascular gástrico (Dieulafoy gástrico) com solução de adrenalina (1:10000) em __ pontos de punção com total infundido de __ ml. Procedimento sem intercorrências e com bom resultado imediato."
  },
  {
    diagnostico: "HEMOSTASIA DE GAVE COM PLASMA DE ARGÔNIO",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado cauterização e hemostasia de ectasias vasculares antrais sangrantes com plasma de argônio (Pot 30W Fluxo 1,5L/min). Procedimento sem intercorrências e com bom resultado imediato."
  },
  {
    diagnostico: "HEMOSTASIA DE GAVE COM LIGADURA ELÁSTICA ",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado hemostasia de ectasias vasculares antrais sangrantes através de ligadura elástica com aposição de 6 bandas elásticas em antro. Procedimento sem intercorrências e com bom resultado imediato."
  },
  {
    diagnostico: "HEMOSTASIA ENDOSCÓPICA COM HEMOCLIP EM ÚLCERA GÁSTRICA",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizada hemostasia endoscópica em úlcera gástrica com aposição de hemoclip. Procedimento sem intercorrências e com bom resultado imediato."
  },
  {
    diagnostico: "HEMOSTASIA ENDOSCÓPICA COM HEMOCLIP EM DIEULAFOY GÁSTRICO",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizada hemostasia endoscópica em coto vascular em estômago (Dieulafoy gástrico) com aposição de hemoclip e parada imediata do sangramento. Procedimento sem intercorrências e com bom resultado imediato."
  },
  {
    diagnostico: "DUPLA HEMOSTASIA ENDOSCÓPICA EM DIEULAFOY GÁSTRICO",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizada dupla hemostasia endoscópica em coto vascular em estômago (Dieulafoy gástrico) com solução de adrenalina (1:10000) em __ pontos de punção com total infundido de __ ml e aposição de hemoclip com parada imediata do sangramento. Procedimento sem intercorrências e com bom resultado imediato."
  },
  {
    diagnostico: "MUCOSECTOMIA EM ESTÔMAGO",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizada injeção de solução com adrenalina e índigo-carmin (1:10000) na base da lesão em região submucosa, seguida de mucosectomia com alça diatérmica (Pot 25W PureCut) e resgate da peça para histopatológico. Procedimento sem intercorrências e com bom resultado. "
  },
  {
    diagnostico: "HEMOSTASIA COM ADRENALINA EM ÚLCERA SANGRANTE",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizada hemostasia endoscópica nos bordos da úlcera sangrante com solução de adrenalina (1:10000) em __ pontos de punção com total infundido de __ ml. Procedimento sem intercorrências e com bom resultado imediato."
  },
  {
    diagnostico: "INJEÇÃO DE ÁLCOOL ABSOLUTO EM COTO VASCULAR",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado injeção de álcool absoluto em coto vascular de úlcera. Procedimento sem intercorrências e com bom resultado imediato."
  },
  {
    diagnostico: "DUPLA HEMOSTASIA EM ÚLCERA(INJEÇÃO DE ADRENALINA + HEMOCLIP) ",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizada dupla hemostasia endoscópica em coto vascular em úlcera com solução de adrenalina (1:10000) em __ pontos de punção com total infundido de __ ml e aposição de hemoclip para aproximação dos bordos. Procedimento sem intercorrências e com bom resultado imediato."
  },
  {
    diagnostico: "PASSAGEM DE BALÃO DE SENGSTAKEN BLAKEMORE",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizamos passagem via nasal de balão de Sengstaken Blakemore com adequado posicionamento e preenchimento dos balões gástrico e esofágico com solução salina/ar. Realizamos aposição de contrapeso em extremidade distal do balão. Procedimento sem intercorrências. "
  },
  {
    diagnostico: "PASSAGEM DE BALÃO INTRAGÁSTRICO",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado passagem guiada de balão intragástrico através do cricofaríngeo e introduzido até a cavidade gástrica. Sob visão endoscópica direta, preenchemos o balão com 500/750 ml de solução salina + azul de metileno, seguido da liberação do balão. À  revisão endoscópica: balão bem posicionado em fundo gástrico. Procedimento sem intercorrências. "
  },
  {
    diagnostico: "POLIPECTOMIA COM ALÇA DIATÉRMICA ",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado polipectomia com alça diatérmica (Pot 30W Blend1). Procedimento sem intercorrências. "
  },
  {
    diagnostico: "POLIPECTOMIA COM ALÇA A FRIO",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado polipectomia com alça a frio. Procedimento sem intercorrências."
  },
  {
    diagnostico: "INJEÇÃO DE CIANOACRILATO EM VARIZES DE FUNDO GÁSTRICO",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado injeção de solução cianoacrilato + lipiodol (1:1) com infusão total de 1ml em variz de fundo gástrico. Procedimento sem intercorrências e com bom resultado imediato."
  },
  {
    diagnostico: "RETIRADA DE CORPO ESTRANHO DO ESTÔMAGO COM PINÇA",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado retirada de corpo estranho do estômago com pinça de apreensão. Procedimento sem intercorrências. "
  },
  {
    diagnostico: "RETIRADA DE CORPO ESTRANHO DO ESTÔMAGO COM BASKET ",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado retirada de corpo estranho do estômago com basket. Procedimento sem intercorrências."
  },
  {
    diagnostico: "RETIRADA DE CORPO ESTRANHO DO ESTÔMAGO COM ROTHNET",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado retirada de corpo estranho do estômago com Rothnet. Procedimento sem intercorrências."
  },
  {
    diagnostico: "RETIRADA DE CORPO ESTRANHO COM AUXÍLIO DE OVERTUBE ",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado retirada de corpo estranho com pinça de apreensão e auxílio protetor de overtube. Procedimento sem intercorrências."
  },
  {
    diagnostico: "TROCA DE SONDA DE GASTROSTOMIA COM VISÃO ENDOSCÓPICA DIRETA",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Paciente posicionado em decúbito dorsal + assepsia e antissepsia do abdome + aposição de campos estéreis. Sob visão endoscópica direta, realizada tração externa da sonda com visualização da retirada do anteparo interno. Em seguida, passada sonda de gastrostomia balonada com insuflação do balão e bom posicionamento na parede gástrica. À revisão endoscópica: boa hemostasia e sonda bem posicionada. Procedimento sem intercorrências."
  },
]

// LAUDO DUODENO
export const duodenoDefault = [
  "Bulbo distensível, sem retrações ou abaulamentos e com mucosa normal.",
  "Segunda porção duodenal sem alterações, examinada até o alcance do aparelho.",
]

export const fluxoTerapeuticaDuodeno = [
  {
    diagnostico: "CAUTERIZAÇÃO DE ANGIECTASIA COM PLASMA DE ARGÔNIO",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado cauterização de angiectasia com plasma de argônio (Pot 30W Fluxo 1,5L/min). Procedimento sem intercorrências. "
  },
  {
    diagnostico: "CAUTERIZAÇÃO DE COTO VASCULAR COM PLASMA DE ARGÔNIO",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado cauterização de coto vascular com plasma de argônio (Pot 60W Fluxo 2,0L/min). Procedimento sem intercorrências. "
  },
  {
    diagnostico: "DILATAÇÃO DE ESTENOSE BULBAR COM BALÃO TIPO CRE",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado dilatação de estenose bulbar com balão tipo CRE 16mm com bom resultado imediato. Procedimento sem intercorrências. "
  },
  {
    diagnostico: "HEMOSTASIA DE ÚLCERA COM INJEÇÃO DE ADRENALINA",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizada hemostasia endoscópica nos bordos da úlcera com solução de adrenalina (1:10000) em __ pontos de punção com total infundido de __ ml. Procedimento sem intercorrências."
  },
  {
    diagnostico: "HEMOSTASIA DE LESÃO DE DIEULAFOY COM INJEÇÃO DE ADRENALINA",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizada hemostasia endoscópica em coto vascular duodenal (Dieulafoy duodenal) com solução de adrenalina (1:10000) em __ pontos de punção com total infundido de __ ml. Procedimento sem intercorrências."
  },
  {
    diagnostico: "HEMOSTASIA ENDOSCÓPICA COM HEMOCLIP EM ÚLCERA BULBAR",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizada hemostasia endoscópica em úlcera bulbar com aposição de hemoclip. Procedimento sem intercorrências."
  },
  {
    diagnostico: "HEMOSTASIA ENDOSCÓPICA COM HEMOCLIP EM DIEULAFOY DUODENAL",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizada hemostasia endoscópica em coto vascular (Dieulafoy DUODENAL) com aposição de hemoclip e parada imediata do sangramento. Procedimento sem intercorrências."
  },
  {
    diagnostico: "DUPLA HEMOSTASIA ENDOSCÓPICA EM DIEULAFOY DUODENAL",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizada dupla hemostasia endoscópica em coto vascular (Dieulafoy duodenal) com solução de adrenalina (1:10000) em __ pontos de punção com total infundido de __ ml e aposição de hemoclip com parada imediata do sangramento. Procedimento sem intercorrências."
  },
  {
    diagnostico: "MUCOSECTOMIA EM DUODENO",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizada injeção de solução com adrenalina e índigo-carmin (1:10000) na base da lesão em região submucosa, seguida de mucosectomia com alça diatérmica (Pot 25W PureCut) e resgate da peça para histopatológico. Procedimento sem intercorrências e com bom resultado. "
  },
  {
    diagnostico: "HEMOSTASIA COM ADRENALINA EM ÚLCERA SANGRANTE",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizada hemostasia endoscópica nos bordos da úlcera sangrante com solução de adrenalina (1:10000) em __ pontos de punção com total infundido de __ ml. Procedimento sem intercorrências."
  },
  {
    diagnostico: "INJEÇÃO DE ÁLCOOL ABSOLUTO EM COTO VASCULAR",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado injeção de álcool absoluto em coto vascular de úlcera. Procedimento sem intercorrências. "
  },
  {
    diagnostico: "DUPLA HEMOSTASIA EM ÚLCERA (INJEÇÃO DE ADRENALINA + HEMOCLIP)",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizada dupla hemostasia endoscópica em coto vascular em úlcera com solução de adrenalina (1:10000) em __ pontos de punção com total infundido de __ ml e aposição de hemoclip para aproximação dos bordos. Procedimento sem intercorrências."
  },
  {
    diagnostico: "POLIPECTOMIA COM ALÇA DIATÉRMICA ",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado polipectomia com alça diatérmica (Pot 30W Blend1). Procedimento sem intercorrências. "
  },
  {
    diagnostico: "POLIPECTOMIA COM ALÇA A FRIO",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado polipectomia com alça a frio. Procedimento sem intercorrências."
  },
  {
    diagnostico: "RETIRADA DE CORPO ESTRANHO DO BULBO COM PINÇA",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado retirada de corpo estranho do bulbo com pinça de apreensão. Procedimento sem intercorrências.   "
  },
  {
    diagnostico: "RETIRADA DE CORPO ESTRANHO DO BULBO COM BASKET ",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado retirada de corpo estranho do bulbo com basket. Procedimento sem intercorrências."
  },
  {
    diagnostico: "RETIRADA DE CORPO ESTRANHO DO BULBO COM ROTHNET",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado retirada de corpo estranho do bulbo com Rothnet. Procedimento sem intercorrências."
  },
  {
    diagnostico: "RETIRADA DE CORPO ESTRANHO DO DUODENO COM PINÇA",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado retirada de corpo estranho do duodeno com pinça de apreensão. Procedimento sem intercorrências.   "
  },
  {
    diagnostico: "RETIRADA DE CORPO ESTRANHO DO DUODENO COM BASKET ",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado retirada de corpo estranho do duodeno com basket. Procedimento sem intercorrências."
  },
  {
    diagnostico: "RETIRADA DE CORPO ESTRANHO DO DUODENO COM ROTHNET",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado retirada de corpo estranho do duodeno com Rothnet. Procedimento sem intercorrências."
  },
  {
    diagnostico: "RETIRADA DE CORPO ESTRANHO COM AUXÍLIO DE OVERTUBE ",
    descricao: "TERAPÊUTICA / PROCEDIMENTO: Realizado retirada de corpo estranho com pinça de apreensão e auxílio protetor de overtube. Procedimento sem intercorrências."
  },
]

export const fluxoDuodeno = [
  {
    diagnostico: "ACHADOS SUGESTIVOS DE DOENÇA CELÍACA",
    del: ["1"],
    descricao: "Nota-se em segunda porção duodenal, rarefação e serrilhamento das pregas duodenais, com mucosa irregular, exibindo nodulações e diminuição das vilosidades."
  },
  {
    diagnostico: "ADENOMA DE PAPILA DUODENAL",
    del: ["1"],
    descricao: "Nota-se em segunda porção duodenal, na topografia de papila duodenal (visão tangencial), mucosa de aspecto exofítico, levemente elevada, esbranquiçada, bem delimitada, friável, correspondente a adenoma de papila duodenal. "
  },
  {
    diagnostico: "ACHADOS SUGESTIVOS DE ADENOMA DE PAPILA DUODENAL",
    del: ["1"],
    descricao: "Nota-se em segunda porção duodenal, na topografia de papila duodenal(visão tangencial), mucosa de aspecto exofítico, levemente elevada, esbranquiçada, friável, bem delimitada,  podendo corresponder a adenoma de papila duodenal. "
  },
  {
    diagnostico: "ABAULAMENTO EM INFUNDÍBULO DE PAPILA DUODENAL",
    del: ["1"],
    descricao: "Em visão tangencial, nota-se em segunda porção duodenal, abaulamento acentuado em infundíbulo de papila."
  },
  {
    diagnostico: "DOENÇA CELÍACA",
    del: ["1"],
    descricao: "Nota-se em segunda porção duodenal, rarefação e serrilhamento das pregas duodenais, com mucosa irregular, exibindo nodulações e diminuição das vilosidades."
  },
  {
    diagnostico: "ACHADOS DUODENAIS COMPATÍVEIS COM DOENÇA CELÍACA",
    del: ["1"],
    descricao: "Nota-se em segunda porção duodenal, rarefação e serrilhamento das pregas duodenais, com mucosa irregular, exibindo nodulações e diminuição das vilosidades."
  },
  {
    diagnostico: "ANGIECTASIA EM SEGUNDA PORÇÃO DUODENAL",
    del: ["1"],
    descricao: "Nota-se em segunda porção duodenal, área avermelhada, de aspecto vascular arboriforme, medindo cerca de 5mm, compatível com angiectasia. Não observamos sinais de sangramento ativo. "
  },
  {
    diagnostico: "ANGIECTASIA EM BULBO DUODENAL",
    del: ["0"],
    descricao: "Nota-se em bulbo duodenal, área avermelhada, de aspecto vascular arboriforme, medindo cerca de 5mm, compatível com angiectasia. Não observamos sinais de sangramento ativo. "
  },
  {
    diagnostico: "ANGIECTASIAS EM BULBO E SEGUNDA PORÇÃO DUODENAL",
    del: ["0", "1"],
    descricao: "Notam-se em bulbo e segunda porção duodenal, áreas avermelhadas, puntiformes, de aspecto vascular, compatíveis com angiectasias. Não observamos sinais de sangramento ativo."
  },
  {
    diagnostico: "ANGIECTASIAS EM BULBO DUODENAL",
    del: ["0"],
    descricao: "Notam-se em bulbo, áreas avermelhadas, puntiformes, de aspecto vascular, compatíveis com angiectasias. Não observamos sinais de sangramento ativo."
  },
  {
    diagnostico: "ANGIECTASIAS EM SEGUNDA PORÇÃO DUODENAL",
    del: ["1"],
    descricao: "Notam-se em segunda porção duodenal, áreas avermelhadas, puntiformes, de aspecto vascular, compatíveis com angiectasias. Não observamos sinais de sangramento ativo."
  },
  {
    diagnostico: "ACHADOS SUGESTIVOS DE ADENOMA EM BULBO DUODENAL",
    del: ["0"],
    descricao: "Nota-se em bulbo duodenal, área de mucosa com aspecto exofítico, levemente elevada, esbranquiçada, friável, bem delimitada por mucosa normal,  podendo corresponder a adenoma."
  },
  {
    diagnostico: "ACHADOS SUGESTIVOS DE ADENOMA EM DUODENO",
    del: ["1"],
    descricao: "Nota-se em segunda porção duodenal, área de mucosa com aspecto exofítico, levemente elevada, esbranquiçada, friável, bem delimitada por mucosa normal,  podendo corresponder a adenoma.  "
  },
  {
    diagnostico: "ACHADOS SUGESTIVOS DE ADENOMA EM BULBO DUODENAL (PAREDE ANTERIOR)",
    del: ["0"],
    descricao: "Nota-se em parede anterior de bulbo duodenal, área de mucosa com aspecto exofítico, levemente elevada, esbranquiçada, friável, bem delimitada por mucosa normal,  podendo corresponder a adenoma."
  },
  {
    diagnostico: "ACHADOS SUGESTIVOS DE ADENOMA EM BULBO DUODENAL (PAREDE POSTERIOR)",
    del: ["0"],
    descricao: "Nota-se em parede posterior de bulbo duodenal, área de mucosa com aspecto exofítico, levemente elevada, esbranquiçada, friável, bem delimitada por mucosa normal,  podendo corresponder a adenoma."
  },
  {
    diagnostico: "ACHADOS SUGESTIVOS DE ADENOMA EM BULBO DUODENAL (PAREDE SUPERIOR)",
    del: ["0"],
    descricao: "Nota-se em parede superior de bulbo duodenal, área de mucosa com aspecto exofítico, levemente elevada, esbranquiçada, friável, bem delimitada por mucosa normal,  podendo corresponder a adenoma."
  },
  {
    diagnostico: "ACHADOS SUGESTIVOS DE ADENOMA EM BULBO DUODENAL (PAREDE INFERIOR)",
    del: ["0"],
    descricao: "Nota-se em bulbo parede inferior de bulbo duodenal, área de mucosa com aspecto exofítico, levemente elevada, esbranquiçada, friável, bem delimitada por mucosa normal,  podendo corresponder a adenoma."
  },
  {
    diagnostico: "BULBITE ENANTEMÁTICA LEVE",
    del: ["0"],
    descricao: "Nota-se em bulbo duodenal, mucosa exibindo enantema difuso de leve intensidade."
  },
  {
    diagnostico: "BULBITE ENANTEMÁTICA MODERADA",
    del: ["0"],
    descricao: "Nota-se em bulbo duodenal, mucosa exibindo enantema difuso de moderada intensidade."
  },
  {
    diagnostico: "BULBITE ENANTEMÁTICA INTENSA",
    del: ["0"],
    descricao: "Nota-se em bulbo duodenal, mucosa exibindo enantema difuso intenso."
  },
  {
    diagnostico: "BULBITE EROSIVA LEVE",
    del: ["0"],
    descricao: "Nota-se em bulbo duodenal, mucosa exibindo erosões planas e fibrinosas, em pouca quantidade."
  },
  {
    diagnostico: "BULBITE EROSIVA MODERADA",
    del: ["0"],
    descricao: "Nota-se em bulbo duodenal, mucosa exibindo erosões planas e fibrinosas, em moderada quantidade."
  },
  {
    diagnostico: "BULBITE EROSIVA INTENSA",
    del: ["0"],
    descricao: "Nota-se em bulbo duodenal, mucosa exibindo erosões planas e fibrinosas, em grande quantidade."
  },
  {
    diagnostico: "BULBODUODENITE ENANTEMÁTICA LEVE",
    del: ["0", "1"],
    descricao: "Nota-se em bulbo e segunda porção duodenal, mucosa exibindo enantema difuso de leve intensidade."
  },
  {
    diagnostico: "BULBODUODENITE ENANTEMÁTICA MODERADA",
    del: ["0", "1"],
    descricao: "Nota-se em bulbo e segunda porção duodenal, mucosa exibindo enantema difuso de moderada intensidade."
  },
  {
    diagnostico: "BULBODUODENITE ENANTEMÁTICA INTENSA",
    del: ["0", "1"],
    descricao: "Nota-se em bulbo e segunda porção duodenal, mucosa exibindo enantema difuso intenso."
  },
  {
    diagnostico: "BULBODUODENITE EROSIVA LEVE",
    del: ["0", "1"],
    descricao: "Nota-se em bulbo e segunda porção duodenal, mucosa exibindo erosões planas e fibrinosas, em pouca quantidade."
  },
  {
    diagnostico: "BULBODUODENITE EROSIVA MODERADA",
    del: ["0", "1"],
    descricao: "Nota-se em bulbo e segunda porção duodenal, mucosa exibindo erosões planas e fibrinosas, em moderada quantidade."
  },
  {
    diagnostico: "BULBODUODENITE EROSIVA INTENSA",
    del: ["0", "1"],
    descricao: "Nota-se em bulbo e segunda porção duodenal, mucosa exibindo erosões planas e fibrinosas, em grande quantidade."
  },
  {
    diagnostico: "CICATRIZ DE “KISSING ULCERS” EM BULBO (PAREDES SUPERIOR E INFERIOR)",
    del: ["0"],
    descricao: "Nota-se em bulbo duodenal, cicatrizes brancas de úlceras prévias “em espelho” localizadas em paredes superior e inferior."
  },
  {
    diagnostico: "CICATRIZ DE “KISSING ULCERS” EM BULBO (PAREDES ANTERIOR E POSTERIOR)",
    del: ["0"],
    descricao: "Nota-se em bulbo duodenal, cicatrizes brancas de úlceras prévias “em espelho” localizadas em paredes anterior e posterior."
  },
  {
    diagnostico: "CICATRIZ DE ÚLCERA DUODENAL",
    del: ["0"],
    descricao: "Nota-se em bulbo duodenal, pequena cicatriz de úlcera prévia, esbranquiçada, medindo cerca de 5mm."
  },
  {
    diagnostico: "COMPRESSÃO EXTRÍNSECA EM BULBO ",
    del: ["0"],
    descricao: "Nota-se em parede anterior de bulbo duodenal, leve abaulamento da mucosa, de consistência firme, móvel com a mudança de decúbito, de etiologia extrínseca, que permite a passagem do aparelho sem resistência. Mucosa de aspecto normal."
  },
  {
    diagnostico: "COMPRESSÃO EXTRÍNSECA EM DUODENO",
    del: ["1"],
    descricao: "Nota-se em segunda porção duodenal, leve abaulamento da mucosa, de consistência firme, móvel com a mudança de decúbito, de etiologia extrínseca, que permite a passagem do aparelho sem resistência. Mucosa de aspecto normal."
  },
  {
    diagnostico: "COMPRESSÃO EXTRÍNSECA EM BULBO COM ESTENOSE",
    del: ["0"],
    descricao: "Nota-se em parede anterior de bulbo duodenal, leve abaulamento da mucosa, de consistência firme, móvel com a mudança de decúbito, de etiologia extrínseca, que não permite a passagem do aparelho. Mucosa de aspecto normal."
  },
  {
    diagnostico: "COMPRESSÃO EXTRÍNSECA EM DUODENO COM ESTENOSE",
    del: ["1"],
    descricao: "Nota-se em segunda porção duodenal, leve abaulamento da mucosa, de consistência firme, móvel com a mudança de decúbito, de etiologia extrínseca, que não permite a passagem do aparelho. Mucosa de aspecto normal."
  },
  {
    diagnostico: "DEFORMIDADE BULBAR CICATRICIAL",
    del: ["0"],
    descricao: "Nota-se bulbo duodenal pequeno, pouco distensível, com anatomia deformada por cicatriz de úlcera prévia. Passagem do aparelho para a segunda porção duodenal sem resistência."
  },
  {
    diagnostico: "DIVERTÍCULO DE SEGUNDA PORÇÃO DUODENAL (PRÉ-PAPILAR)",
    del: ["1"],
    descricao: "Nota-se em segunda porção duodenal (pré-papilar), divertículo único, de óstio largo, sem conteúdo em seu interior e sem sinais inflamatórios presentes."
  },
  {
    diagnostico: "DIVERTÍCULO DE SEGUNDA PORÇÃO DUODENAL (PÓS-PAPILAR)",
    del: ["1"],
    descricao: "Nota-se em segunda porção duodenal (pós-papilar), divertículo único, de óstio largo, sem conteúdo em seu interior e sem sinais inflamatórios presentes."
  },
  {
    diagnostico: "DIVERTÍCULO EM BULBO DUODENAL (PAREDE ANTERIOR)",
    del: ["0"],
    descricao: "Nota-se em parede anterior de bulbo duodenal, divertículo único, de óstio largo, sem conteúdo em seu interior e sem sinais inflamatórios presentes."
  },
  {
    diagnostico: "DIVERTÍCULO EM BULBO DUODENAL (PAREDE POSTERIOR)",
    del: ["0"],
    descricao: "Nota-se em parede posterior de bulbo duodenal, divertículo único, de óstio largo, sem conteúdo em seu interior e sem sinais inflamatórios presentes."
  },
  {
    diagnostico: "DIVERTÍCULO EM BULBO DUODENAL (PAREDE SUPERIOR)",
    del: ["0"],
    descricao: "Nota-se em parede superior de bulbo duodenal, divertículo único, de óstio largo, sem conteúdo em seu interior e sem sinais inflamatórios presentes."
  },
  {
    diagnostico: "DIVERTÍCULO EM BULBO DUODENAL (PAREDE INFERIOR)",
    del: ["0"],
    descricao: "Nota-se em parede inferior de bulbo duodenal, divertículo único, de óstio largo, sem conteúdo em seu interior e sem sinais inflamatórios presentes."
  },
  {
    diagnostico: "DIVERTÍCULO DUODENAL JUSTAPAPILAR",
    del: ["1"],
    descricao: "Nota-se em segunda porção duodenal (justapapilar), divertículo único, de óstio largo, sem conteúdo em seu interior e sem sinais inflamatórios presentes."
  },
  {
    diagnostico: "DUODENITE ENANTEMÁTICA LEVE",
    del: ["1"],
    descricao: "Nota-se em segunda porção duodenal, mucosa exibindo enantema difuso de leve intensidade."
  },
  {
    diagnostico: "DUODENITE ENANTEMÁTICA MODERADA",
    del: ["1"],
    descricao: "Nota-se em segunda porção duodenal, mucosa exibindo enantema difuso de moderada intensidade."
  },
  {
    diagnostico: "DUODENITE ENANTEMÁTICA INTENSA",
    del: ["1"],
    descricao: "Nota-se em segunda porção duodenal, mucosa exibindo enantema difuso intenso."
  },
  {
    diagnostico: "DUODENITE EROSIVA LEVE",
    del: ["1"],
    descricao: "Nota-se em segunda porção duodenal, mucosa exibindo erosões planas e fibrinosas, em pouca quantidade."
  },
  {
    diagnostico: "DUODENITE EROSIVA MODERADA",
    del: ["1"],
    descricao: "Nota-se em segunda porção duodenal, mucosa exibindo erosões planas e fibrinosas, em moderada quantidade."
  },
  {
    diagnostico: "DUODENITE EROSIVA INTENSA",
    del: ["1"],
    descricao: "Nota-se em segunda porção duodenal, mucosa exibindo erosões planas e fibrinosas, em grande quantidade."
  },
  {
    diagnostico: "DOENÇA DE CROHN",
    del: ["1"],
    descricao: "Nota-se em segunda porção duodenal, mucosa com áreas de aspecto nodular, em “pedra de calçamento”."
  },
  {
    diagnostico: "DUODENITE ENANTEMATOEROSIVA LEVE",
    del: ["1"],
    descricao: "Nota-se em segunda porção duodenal, mucosa exibindo enantema de leve intensidade e erosões planas e fibrinosas, em pouca quantidade."
  },
  {
    diagnostico: "DUODENITE ENANTEMATOEROSIVA MODERADA",
    del: ["1"],
    descricao: "Nota-se em segunda porção duodenal, mucosa exibindo moderado enantema difuso e moderada quantidade de erosões planas e {fibrinosas."
  },
  {
    diagnostico: "DUODENITE ENANTEMATOEROSIVA INTENSA",
    del: ["1"],
    descricao: "Nota-se em segunda porção duodenal, mucosa exibindo intenso enantema difuso e grande quantidade de erosões planas e fibrinosas."
  },
  {
    diagnostico: "FÍSTULA AORTO-DUODENAL",
    del: ["1"],
    descricao: "Nota-se em segunda porção duodenal, presença de orifício puntiforme correspondente a fístula aorto-duodenal pérvia e ativa, com sangue vivo na luz do órgão em moderada quantidade."
  },
  {
    diagnostico: "BULBODUODENITE ENANTEMATOEROSIVA LEVE",
    del: ["0", "1"],
    descricao: "Nota-se em bulbo e segunda porção duodenal, mucosa exibindo enantema de leve intensidade e erosões planas e fibrinosas, em pouca quantidade."
  },
  {
    diagnostico: "BULBODUODENITE ENANTEMATOEROSIVA MODERADA",
    del: ["0", "1"],
    descricao: "Nota-se em bulbo e segunda porção duodenal, mucosa exibindo moderado enantema difuso e moderada quantidade de erosões planas e fibrinosas."
  },
  {
    diagnostico: "BULBODUODENITE ENANTEMATOEROSIVA INTENSA",
    del: ["0", "1"],
    descricao: "Nota-se em bulbo e segunda porção duodenal, mucosa exibindo intenso enantema difuso e grande quantidade de erosões planas e fibrinosas."
  },
  {
    diagnostico: "STATUS PÓS-DERIVAÇÃO BILIO-DIGESTIVA",
    del: ["0"],
    descricao: "Nota-se em parede anterior de bulbo duodenal, anastomose duodeno-colédoco pérvia, ampla, com presença de bile. "
  },
  {
    diagnostico: "HELMINTÍASE DUODENAL",
    del: ["0", "1"],
    descricao: "Nota-se em bulbo e segunda porção duodenal, mucosa eritematosa e edemaciada com presença de inúmeras pequenas larvas nematódeas em sua superfície. "
  },
  {
    diagnostico: "HIPERPLASIA DE GL NDULAS DE BRUNNER",
    del: ["0"],
    descricao: "Notam-se diminutas áreas elevadas, levemente pálidas, de aspecto polipóide, correspondente a hiperplasia de glândulas de Brunner."
  },
  {
    diagnostico: "LESÃO SUBEPITELIAL DE DUODENO",
    del: ["1"],
    descricao: "Nota-se em segunda porção duodenal, área elevada, recoberta por mucosa normal, móvel e de consistência fibroelástica ao toque da pinça de biópsia, característica de elevação subepitelial."
  },
  {
    diagnostico: "ÁREA BULBAR ELEVADA DE ASPECTO SUBEPITELIAL ",
    del: ["0"],
    descricao: "Nota-se em bulbo duodenal, área elevada, recoberta por mucosa normal, móvel e de consistência fibroelástica ao toque da pinça de biópsia, característica de elevação subepitelial."
  },
  {
    diagnostico: "LESÃO SUBEPITELIAL EM BULBO DUODENAL",
    del: ["0"],
    descricao: "Nota-se em bulbo duodenal, área elevada, recoberta por mucosa normal, móvel e de consistência fibroelástica ao toque da pinça de biópsia, característica de elevação subepitelial."
  },
  {
    diagnostico: "LINFANGIECTASIAS DUODENAIS",
    del: ["0", "1"],
    descricao: "Nota-se em bulbo e segunda porção duodenal, áreas esbranquiçadas na superfície da mucosa características de linfangiectasias. "
  },
  {
    diagnostico: "LIPOMA DUODENAL",
    del: ["1"],
    descricao: "Nota-se em segunda porção duodenal, área elevada, recoberta por mucosa normal, móvel e de consistência fibroelástica ao toque da pinça de biópsia, medindo cerca de 10mm, podendo corresponder a lipoma. "
  },
  {
    diagnostico: "LIPOMA EM BULBO DUODENAL (PAREDE ANTERIOR)",
    del: ["0"],
    descricao: "Nota-se em parede anterior de bulbo, área elevada, recoberta por mucosa normal, móvel e de consistência fibroelástica ao toque da pinça de biópsia, medindo cerca de 10mm,  podendo corresponder a lipoma."
  },
  {
    diagnostico: "LIPOMA EM BULBO DUODENAL (PAREDE POSTERIOR)",
    del: ["0"],
    descricao: "Nota-se em parece posterior de bulbo, área elevada, recoberta por mucosa normal, móvel e de consistência fibroelástica ao toque da pinça de biópsia, medindo cerca de 10mm, podendo corresponder a lipoma."
  },
  {
    diagnostico: "LIPOMA EM BULBO DUODENAL (PAREDE SUPERIOR)",
    del: ["0"],
    descricao: "Nota-se em parede superior de bulbo, área elevada, recoberta por mucosa normal, móvel e de consistência fibroelástica ao toque da pinça de biópsia, medindo cerca de 10mm,  podendo corresponder a lipoma."
  },
  {
    diagnostico: "LIPOMA EM BULBO DUODENAL (PAREDE INFERIOR)",
    del: ["0"],
    descricao: "Nota-se em parede inferior de bulbo, área elevada, recoberta por mucosa normal, móvel e de consistência fibroelástica ao toque da pinça de biópsia, medindo cerca de 10mm,  podendo corresponder a lipoma."
  },
  {
    diagnostico: "MEGABULBO",
    del: ["0"],
    descricao: "Nota-se bulbo duodenal aumentado de tamanho e alongado."
  },
  {
    diagnostico: "ACHADOS SUGESTIVOS DE METAPLASIA GÁSTRICA EM BULBO",
    del: ["0"],
    descricao: "Nota-se mucosa de bulbo, exibindo áreas esbranquiçadas, elevadas, com alteração do padrão das criptas (metaplasia?)."
  },
  {
    diagnostico: "ACHADOS SUGESTIVOS DE METAPLASIA GÁSTRICA EM DUODENO",
    del: ["1"],
    descricao: "Nota-se mucosa de segunda porção duodenal, exibindo áreas esbranquiçadas, elevadas, com alteração do padrão das criptas (metaplasia?)."
  },
  {
    diagnostico: "ACHADOS SUGESTIVOS DE METAPLASIA GÁSTRICA (BULBO E SEGUNDA PORÇÃO)",
    del: ["0", "1"],
    descricao: "Nota-se mucosa de bulbo e segunda porção duodenal, exibindo áreas esbranquiçadas, elevadas, com alteração do padrão das criptas (metaplasia?)."
  },
  {
    diagnostico: "NEOPLASIA DE PAPILA DUODENAL",
    del: ["1"],
    descricao: "Em visão tangencial, nota-se na mucosa de papila duodenal, lesão vegetante, enantemática, friável, de aspecto neoplásico, medindo cerca de 20mm. Não identificamos orifício papilar ou drenagem de bile."
  },
  {
    diagnostico: "NEOPLASIA DUODENAL",
    del: ["1"],
    descricao: "Nota-se mucosa de segunda porção duodenal, próximo à papila duodenal, lesão vegetante, friável, de aspecto neoplásico, ocupando 50% da circunferência duodenal, que não obstrui a luz duodenal e permite a passagem do aparelho sem resistência."
  },
  {
    diagnostico: "NEOPLASIA DUODENAL AVANÇADA COM SUBESTENOSE",
    del: ["1"],
    descricao: "Nota-se mucosa de segunda porção duodenal, próximo à papila duodenal, lesão neoplásica avançada, vegetante, friável, ocupando 75% da circunferência duodenal, obstruindo parcialmente a luz duodenal e permitindo a passagem do aparelho com resistência."
  },
  {
    diagnostico: "NEOPLASIA DUODENAL AVANÇADA COM ESTENOSE COMPLETA",
    del: ["1"],
    descricao: "Nota-se mucosa de segunda porção duodenal, próximo à papila duodenal, lesão neoplásica avançada, vegetante, friável, ocupando 100% da circunferência duodenal, obstruindo a luz duodenal e não permitindo a passagem do aparelho."
  },
  {
    diagnostico: "POLIPOSE DUODENAL",
    del: ["1"],
    descricao: "Nota-se em segunda porção duodenal, presença de múltiplos pólipos sésseis, lisos, de mucosa enantemática, medindo cerca de 2-3mm cada."
  },
  {
    diagnostico: "POLIPOSE EM BULBO DUODENAL",
    del: ["0"],
    descricao: "Nota-se em bulbo duodenal, presença de múltiplos pólipos sésseis, lisos,  de mucosa enantemática, medindo cerca de 2-3mm cada."
  },
  {
    diagnostico: "PÓLIPO DUODENAL",
    del: ["1"],
    descricao: "Nota-se em segunda porção duodenal, presença pólipo séssil único, de mucosa enantemática, medindo cerca de 5mm."
  },
  {
    diagnostico: "PÓLIPO EM BULBO DUODENAL",
    del: ["0"],
    descricao: "Nota-se em bulbo duodenal, presença pólipo séssil único, liso, de mucosa enantemática, medindo cerca de 5mm."
  },
  {
    diagnostico: "PÓLIPO EM BULBO DUODENAL (PAREDE ANTERIOR)",
    del: ["0"],
    descricao: "Nota-se em parede anterior de bulbo duodenal, presença pólipo séssil único, liso, de mucosa enantemática, medindo cerca de 5mm."
  },
  {
    diagnostico: "PÓLIPO EM BULBO DUODENAL (PAREDE POSTERIOR)",
    del: ["0"],
    descricao: "Nota-se em parede posterior de bulbo duodenal, presença pólipo séssil único, liso, de mucosa enantemática, medindo cerca de 5mm."
  },
  {
    diagnostico: "PÓLIPO EM BULBO DUODENAL (PAREDE SUPERIOR)",
    del: ["0"],
    descricao: "Nota-se em parede superior de bulbo duodenal, presença pólipo séssil único, liso, de mucosa enantemática, medindo cerca de 5mm."
  },
  {
    diagnostico: "PÓLIPO EM BULBO DUODENAL (PAREDE INFERIOR)",
    del: ["0"],
    descricao: "Nota-se em parede inferior de bulbo duodenal, presença pólipo séssil único, liso, de mucosa enantemática, medindo cerca de 5mm."
  },
  {
    diagnostico: "PSEUDOMELANOSE DUODENAL",
    del: ["1"],
    descricao: "Nota-se em segunda porção duodenal, mucosa exibindo pontilhado difuso, de coloração acastanhada e levemente enegrecida, com aspecto “tigróide”, sem soluções de continuidade, sugestivo de pseudomelanose."
  },

  {
    diagnostico: "PSEUDOMELANOSE BULBAR ",
    del: ["0"],
    descricao: "Nota-se em bulbo duodenal, mucosa exibindo pontilhado difuso, de coloração acastanhada e levemente enegrecida, com aspecto “tigróide”, sem soluções de continuidade, sugestivo de pseudomelanose.  "
  },
  {
    diagnostico: "PSEUDOMELANOSE EM BULBO E SEGUNDA PORÇÃO DUODENAL",
    del: ["0", "1"],
    descricao: "Nota-se em bulbo e segunda porção duodenal, mucosa exibindo pontilhado difuso, de coloração acastanhada e levemente enegrecida, com aspecto “tigróide”, sem soluções de continuidade, sugestivo de pseudomelanose.  "
  },
  {
    diagnostico: "SARCOMA DE KAPOSI EM BULBO",
    del: ["0"],
    descricao: "Nota-se em bulbo duodenal, área eritematosa, violácea, plano-elevada, delimitada por mucosa íntegra, sugestiva de sarcoma de Kaposi."
  },
  {
    diagnostico: "SARCOMA DE KAPOSI EM BULBO (PAREDE ANTERIOR)",
    del: ["0"],
    descricao: "Nota-se em parede anterior de bulbo duodenal, área eritematosa, violácea, plano-elevada, delimitada por mucosa íntegra, sugestiva de sarcoma de Kaposi."
  },
  {
    diagnostico: "SARCOMA DE KAPOSI EM BULBO (PAREDE POSTERIOR)",
    del: ["0"],
    descricao: "Nota-se em parede posterior de bulbo duodenal, área eritematosa, violácea, plano-elevada, delimitada por mucosa íntegra, sugestiva de sarcoma de Kaposi."
  },
  {
    diagnostico: "SARCOMA DE KAPOSI EM BULBO (PAREDE SUPERIOR)",
    del: ["0"],
    descricao: "Nota-se em parede superior de bulbo duodenal, área eritematosa, violácea, plano-elevada, delimitada por mucosa íntegra, sugestiva de sarcoma de Kaposi."
  },
  {
    diagnostico: "SARCOMA DE KAPOSI EM BULBO (PAREDE INFERIOR)",
    del: ["0"],
    descricao: "Nota-se em parede inferior de bulbo duodenal, área eritematosa, violácea, plano-elevada, delimitada por mucosa íntegra, sugestiva de sarcoma de Kaposi."
  },
  {
    diagnostico: "SARCOMA DE KAPOSI EM DUODENO",
    del: ["1"],
    descricao: "Nota-se em segunda porção duodenal, área eritematosa, violácea, plano-elevada, delimitada por mucosa íntegra, sugestiva de sarcoma de Kaposi."
  },
  {
    diagnostico: "SARCOMAS DE KAPOSI EM BULBO E 2ª PORÇÃO DUODENAL",
    del: ["0", "1"],
    descricao: "Notam-se em bulbo e segunda porção duodenal, áreas eritematosas, violáceas, planas e polipóides sésseis, delimitadas por mucosa íntegra, sugestivas de sarcoma de Kaposi."
  },
  {
    diagnostico: "LESÕES VIOLÁCEAS SUGESTIVAS DE SARCOMA DE KAPOSI EM DUODENO",
    del: ["1"],
    descricao: "Notam-se em segunda porção duodenal, áreas eritematosas, violáceas, planas e polipóides sésseis, delimitadas por mucosa íntegra, sugestivas de sarcoma de Kaposi."
  },
  {
    diagnostico: "LESÕES VIOLÁCEAS SUGESTIVAS DE SARCOMA DE KAPOSI EM BULBO",
    del: ["0"],
    descricao: "Notam-se em bulbo duodenal, áreas eritematosas, violáceas, planas e polipóides sésseis, delimitadas por mucosa íntegra, sugestivas de sarcoma de Kaposi."
  },
  {
    diagnostico: "LESÕES VIOLÁCEAS SUGESTIVAS DE SARCOMA DE KAPOSI EM BULDO E 2ª PORÇÃO DUODENAL",
    del: ["0", "1"],
    descricao: "Notam-se em bulbo e segunda porção duodenal, áreas eritematosas, violáceas, planas e polipóides sésseis, delimitadas por mucosa íntegra, sugestivas de sarcoma de Kaposi."
  },
  {
    diagnostico: "VARIZES DUODENAIS ",
    del: ["1"],
    descricao: "Notam-se em segunda porção duodenal, pequenos cordões varicosos de fino calibre, azulados, sem red spots ou estigmas de sangramento."
  },
  {
    diagnostico: "XANTELASMA EM BULBO DUODENAL ",
    del: ["0"],
    descricao: "Nota-se em bulbo duodenal, área amarelo-esbranquiçada, plana, medindo cerca de 5mm, característico de xantelasma. "
  },
  {
    diagnostico: "XANTELASMA EM BULBO DUODENAL (PAREDE ANTERIOR)",
    del: ["0"],
    descricao: "Nota-se em parede anterior de bulbo duodenal, área amarelo-esbranquiçada, plana, medindo cerca de 5mm, característico de xantelasma. "
  },
  {
    diagnostico: "XANTELASMA EM BULBO DUODENAL (PAREDE POSTERIOR)",
    del: ["0"],
    descricao: "Nota-se em parede posterior de bulbo duodenal, área amarelo-esbranquiçada, plana, medindo cerca de 5mm, característico de xantelasma. "
  },
  {
    diagnostico: "XANTELASMA EM BULBO DUODENAL (PAREDE SUPERIOR)",
    del: ["0"],
    descricao: "Nota-se em parede superior de bulbo duodenal, área amarelo-esbranquiçada, plana, medindo cerca de 5mm, característico de xantelasma. "
  },
  {
    diagnostico: "XANTELASMA EM BULBO DUODENAL (PAREDE INFERIOR)",
    del: ["0"],
    descricao: "Nota-se em parede inferior de bulbo duodenal, área amarelo-esbranquiçada, plana, medindo cerca de 5mm, característico de xantelasma. "
  },
  {
    diagnostico: "XANTELASMA DUODENAL",
    del: ["1"],
    descricao: "Nota-se em segunda porção duodenal, área amarelo-esbranquiçada, plana, medindo cerca de 5mm, característico de xantelasma."
  },
]

export default cricofaringeoDefault