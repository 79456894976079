export const indicacoesLista = [
  "Acalasia do cárdia",
  "Anemia",
  "Angiectasia ",
  "Angiectasias gástricas",
  "Angiectasia duodenal",
  "Angiectasia em cólon",
  "Atresia de esôfago",
  "Atopia ",
  "Alteração do hábito intestinal",
  "Alergias ",
  "Alergia ao glúten",
  "Avaliação de colostomia ",
  "Avaliação pré-op de cirurgia ",
  "Avaliação pré-op de Tx",
  "Avaliação de úlcera gástrica",
  "Avaliação de HDA",
  "Avaliação de HDB",
  "Avaliação de anastomose esofagogástrica",
  "Azia ",
  "Balão intragástrico – aposição",
  "Balão intragástrico – avaliação",
  "Boca amarga (sic)",
  "Candidíase a/e ",
  "Cirrose hepática",
  "Constipação intestinal",
  "Controle de tratamento - úlcera gástrica",
  "Controle de tratamento - úlcera duodenal",
  "Controle de tratamento - H. pylori",
  "Corpo estranho ",
  "Deficiência de vitamina B12",
  "Disfagia",
  "Dispepsia",
  "Diarreia crônica",
  "Diarreia a/e",
  "Dieulafoy gástrico",
  "Distensão abdominal",
  "Diverticulose colônica",
  "Dilatação esofágica ",
  "Dilatação esofágica – pós-Cx  ",
  "DUP",
  "Doença celíaca",
  "Doença celíaca – investigação",
  "Doença celíaca – seguimento",
  "Doença de Crohn",
  "Doença de Crohn - investigação",
  "Doença de Crohn – seguimento",
  "Dor ao engolir",
  "Dor abdominal",
  "Dor epigástrica",
  "Dor pélvica",
  "Doença diverticular",
  "DRGE",
  "Ectasias vasculares antrais",
  "Emagrecimento",
  "Esofagite eosinofílica",
  "Esofagite eosinofílica – investigação",
  "Esôfago de Barrett",
  "Estadiamento de Neo colorretal",
  "Estadiamento de Neo em TGI",
  "Estadiamento de neoplasia pulmonar ",
  "Estenose esofágica",
  "Estenose pilórica",
  "Estenose cáustica",
  "Esofagite",
  "Fístula esofágica ",
  "Fístula esofágica a/e ",
  "Fístula em TGI ",
  "Faringite",
  "Flatulência",
  "Fezes escuras",
  "Fezes brancas",
  "Gastrostomia endoscópica",
  "Gastroparesia",
  "GAVE",
  "GEP",
  "Globus faríngeo",
  "Hematêmese",
  "Hemoptise",
  "Hemorragia alta - HDA",
  "Hemorragia baixa - HDB",
  "Hepatopatia",
  "Hemorroidas",
  "Hipoglicemia",
  "Icterícia a/e ",
  "Icterícia obstrutiva",
  "Intolerância alimentar",
  "Intolerância à lactose",
  "Impactação alimentar",
  "Imunossupressão ",
  "Kaposi esofágico",
  "Kaposi gástrico",
  "Kaposi duodenal",
  "Kaposi – pesquisa ",
  "Laringite",
  "Lesão subepitelial a/e ",
  "Lesão neoplásica precoce (esôfago)",
  "Lesão neoplásica precoce (estômago)",
  "Lesão neoplásica precoce (duodeno)",
  "Lesão neoplásica precoce (retossigmoide)",
  "Lesão neoplásica precoce (cólon)",
  "Linfadenopatia a/e ",
  "Massa abdominal",
  "Massa retroperitoneal",
  "Megaesôfago",
  "Melena",
  "Meteorismo",
  "Muco nas fezes",
  "Náuseas ",
  "Neoplasia esofágica",
  "Neoplasia gástrica",
  "Neoplasia duodenal",
  "Osler-Weber-Rendu",
  "Pancreatite",
  "Passagem de SNE",
  "Pesquisa neoplásica",
  "Pesquisa de sítio primário",
  "Pesquisa de úlceras infecciosas",
  "Pirose",
  "Plenitude pós-prandial",
  "Pólipos gástricos",
  "Polipose gástrica",
  "Polipose colônica",
  "Procedimento endoscópico ",
  "Perda de peso a/e ",
  "Pigarro (sic)",
  "Queda de Hemoglobina",
  "Queimação epigástrica",
  "Queimação retroesternal",
  "Rastreio neoplásico",
  "Rastreio para câncer colorretal",
  "Reconstrução de trânsito – pré-op",
  "Refluxo gastroesofágico",
  "Retite actínica",
  "Regurgitação",
  "Retrovirose",
  "Retirada de balão intragástrico",
  "Revisão de tto com cianoacrilato",
  "Retocolite ulcerativa",
  "RCUI – seguimento",
  "Rotina",
  "Sarcoma de Kaposi ",
  "Sarcoma de Kaposi a/e ",
  "Sangramento anal",
  "Sangue nas fezes",
  "Second-look endoscópico",
  "Seguimento pós-gastrectomia",
  "Seguimento pós-fundoplicatura",
  "Seguimento pós-esofagectomia",
  "Seguimento pós-mucosectomia",
  "Seguimento pós-polipectomia",
  "Seguimento pós ESD",
  "Síndrome do intestino irritável",
  "Síndrome consumptiva ",
  "Síndrome da alça aferente",
  "Tenesmo",
  "Tosse ",
  "Tosse crônica",
  "Tto de varizes esofágicas",
  "Tto de varizes gástricas",
  "Troca de sonda de gastrostomia",
  "Troca de sonda nasoenteral",
  "Tuberculose em TGI",
  "Tuberculose em TGI a/e",
  "Úlcera esofágica",
  "Úlcera gástrica",
  "Úlcera duodenal",
  "Varizes esofágicas – seguimento",
  "Varizes gástricas – seguimento",
  "Vômitos ",
  "Volvo gástrico",
  "Volvo colônico",
  "Vasculite ",
]