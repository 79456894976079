//@ts-nocheck
import React, { HTMLAttributes } from "react"

interface DivProps extends HTMLAttributes<SVGPathElement> {
    color?: string;
}

function BackgroundTemplate2({ color, ...rest }: DivProps) {

    return (

        <svg viewBox="0 0 484 530" fill="none" xmlns="http://www.w3.org/2000/svg"
            style={{width: "100%", height: "100%", position: "absolute", top: "0", left: "0", pointerEvents: 'none'}}
        >
            <path {...rest} fill={color} opacity="0.17" d="M2.23112 542.378C9.67272 545.285 17.2631 547.818 24.9564 549.973C75.7844 564.205 131.123 561.617 180.23 542.144C222.886 525.232 259.461 490.691 285.408 453.556C305.767 424.416 321.359 390.714 332.133 356.816C351.126 297.056 355.586 230.31 337.703 169.723C321.085 113.419 280.634 55.2886 221.877 38.1565C195.285 30.4008 166.367 31.0363 140.045 39.5829C116.256 47.3066 96.1618 65.6822 75.9423 80.1239C61.0797 90.7392 45.0981 95.9828 30.5078 80.6931C14.4187 63.8307 7.56289 31.9437 2 10L6.65214 159.441C27.398 166.292 46.1851 179.895 57.9813 198.401C71.1071 218.996 73.5785 247.541 72.2947 271.384C71.741 281.661 70.2307 291.888 67.7524 301.877C59.0362 337.026 39.105 371.294 6.74368 389.332L2.23112 542.378Z" />
            <path {...rest} fill={color} opacity="0.3" stroke="#4FCFCE" stroke-width="3" stroke-miterlimit="10" d="M0.313965 0.0805664C5.57805 20.9289 12.0656 51.2243 27.2905 67.2449C41.0971 81.7737 56.2224 76.7896 70.2845 66.7042C89.418 52.9833 108.432 35.5228 130.944 28.1868C155.852 20.0668 183.217 19.4652 208.381 26.8316C263.982 43.1108 302.259 98.3394 317.985 151.831C334.905 209.394 330.687 272.808 312.714 329.585C302.519 361.791 287.764 393.811 268.499 421.496C243.947 456.777 209.336 489.592 168.97 505.662C122.503 524.161 70.1351 526.622 22.0373 513.101C14.755 511.053 7.57455 508.646 0.53267 505.884" 
            fillOpacity="0.5"/>
        </svg>
        // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84 130" fill="none"
        //     style={{width: "100%", height: "100%", position: "absolute", top: "0", left: "0"}}
        // >
        //     <path {...rest} opacity="0.9" d="M4.09265 15.5747C-0.740094 4.48378 0.217034 1.78161 0 0L0.0310049 38.2184C0.671773 38.7165 2.26702 39.6968 4.27868 40.8046C6.41802 41.9828 9.37382 45.0192 10.7587 46.5805C12.5942 48.5345 13.6718 51.8678 13.7972 53.477C14.3863 61.0345 12.4301 69.2816 12.0609 70.546C8.40233 83.0747 3.19351 85.1149 0 88.046V127.27C0.196364 127.347 0.775123 127.54 1.51924 127.701C2.26336 127.862 8.36099 128.994 11.3168 129.54C12.4123 129.693 16.1226 130 22.1995 130C29.7957 130 36.1207 127.529 38.5701 126.782C41.0195 126.034 45.8563 123.563 48.3367 122.356C52.5843 119.943 55.9639 117.107 58.5373 115.086C61.8238 112.069 65.0173 108.448 65.6374 107.759C69.265 103.477 70.0711 101.609 70.5982 101.121C71.0198 100.73 73.7297 96.0153 75.0319 93.7069L78.0084 87.1264L80.2097 81.1207C80.7781 79.2241 82.1258 74.2069 82.9691 69.3103C84.0233 63.1897 83.8373 59.6552 83.9613 55.6322C84.2713 50.4023 82.6384 45.1149 81.915 41.8678C80.7988 37.1552 77.1712 31.7816 76.055 29.7414C75.1559 27.9023 73.1096 25.9195 70.9082 23.3621C68.7069 20.8046 65.5134 18.6782 63.9631 17.2701C62.4129 15.8621 56.925 13.0172 56.4599 12.7011C56.0879 12.4483 53.4938 11.4655 52.2433 11.0057C51.4681 10.7471 48.9878 10.1724 48.0266 10C47.294 9.86859 45.3912 9.69349 44.523 9.65517C43.3759 9.5977 42.1357 9.65517 39.3762 10C36.6695 10.3382 32.2451 11.954 30.6639 12.5862C29.0826 13.2184 25.7651 15.0575 25.548 15.2011C25.3744 15.3161 23.9048 16.2452 23.1917 16.6954C21.5174 17.7874 18.0139 20.0575 17.3938 20.4023C16.7737 20.7471 15.7505 21.2739 15.3164 21.4943C14.4173 21.8103 12.1911 22.3736 10.4797 22.0977C8.34032 21.7529 5.39485 18.5632 4.09265 15.5747Z" 
        //     fill={color} fillOpacity="0.5"/>
        // </svg> // #D5E3EE
    )
}





export default BackgroundTemplate2