export function mascNascimento(date: string) {
  date = date.replace('T', '-')
  const arrayDate = date.split('-')
  const dateFormated = `${arrayDate[2]}/${arrayDate[1]}/${arrayDate[0]}`
  return dateFormated
}

export function calculaIdade(dataNasc: string){ 
  let dataAtual = new Date();
  let anoAtual = dataAtual.getFullYear();
  let anoNascParts = dataNasc.split('/');
  let diaNasc = parseInt(anoNascParts[0]);
  let mesNasc = parseInt(anoNascParts[1]);
  let anoNasc = parseInt(anoNascParts[2]);
  let idade = anoAtual - anoNasc;
  let mesAtual = dataAtual.getMonth() + 1; 
  //Se mes atual for menor que o nascimento, nao fez aniversario ainda;  
  if(mesAtual < mesNasc){
      idade--; 
  } else {
  //Se estiver no mes do nascimento, verificar o dia
      if(mesAtual == mesNasc){ 
          if(new Date().getDate() < diaNasc ){ 
          //Se a data atual for menor que o dia de nascimento ele ainda nao fez aniversario
              idade--; 
          }
      }
  } 
  return idade; 
}