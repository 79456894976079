//@ts-nocheck
import React from 'react'
import styled, { css } from 'styled-components'
import { AiOutlineCamera } from 'react-icons/ai'
import BackgroundTemplate1 from '../../assets/BackgroundTemplate1'
import BackgroundTemplate2 from '../../assets/BackgroundTemplate2'
import BackgroundTemplate3 from '../../assets/BackgroundTemplate3'
import LogoBranca from '../../assets/logo/LogoBranca'

interface ILaudo {
  withImage?: boolean,
  colorTemplate?: string,
  indexBackGround?: number,
  headerData?: {
    hospitalName: string,
    hospitalRua: string,
    hospitalTelefone: string
  },
  modelo?: string,
  content?: any,
  medicName: string,
  notes?: any,
  logo?: string,
  headerContent?: any,
  subHeaderContent?: any
}

interface ITextContent {
  text: string,
  setText?: Function
}

interface ITitleAndText {
  title: string,
  text: string,
  setText?: Function
}

export function TitleContent({title, color} : any){
  return(
    <TitleLine>
      <div style={{backgroundColor: color }} ></div>
      <span> {title} </span>
      <div style={{backgroundColor: color }} ></div>
    </TitleLine>
  )
}


export function TextContent({text,  setText} : ITextContent){
  console.log(setText)
  return(
    <Textarea>
      <span 
        //@ts-ignore
        onBlur={setText ? setText : (e) => console.log(e.target.value)}
        // onChange={setText ? setText : (e) => console.log(e.target.value)} 
        className="textarea" 
        role="textbox"
        contentEditable
        style={{whiteSpace: 'pre-line'}}
      > 
        {text} 
      </span>
    </Textarea>
  )
}

export function TitleAndText({title, text , setText}: ITitleAndText){
  return (
    <div>
      <p>{title}:</p>
      <span
        //@ts-ignore
        onBlur={setText ? setText : (e) => console.log(e.target.textContent)}
        contentEditable
      >{text}</span>
    </div>
  )
}

export default function Laudo({ 
    withImage, 
    colorTemplate = '#2FBDBC',
    indexBackGround = 1, 
    headerData, 
    modelo = '',
    content,
    medicName,
    notes,
    logo,
    headerContent,
    subHeaderContent
  }: ILaudo) {



  function CardsImages(){
    return (
      <ImageContainer>
        <AiOutlineCamera color={'white'} size={40} />
      </ImageContainer>
    )
  }

  function BackGroundTemplate(index: number) {
    switch (index) {
      case 1:
        return <BackgroundTemplate1 style={{opacity: "0.15"}} color={colorTemplate} /> 
      case 2:
        return <BackgroundTemplate2 style={{opacity: "0.15"}} color={colorTemplate} /> 
      case 3:
        return <BackgroundTemplate3 style={{opacity: "0.15"}} color={colorTemplate} />  
      default:
        return <div style={{ display: "none" }}></div>
    }
  }

  return (
    <Container>
      {BackGroundTemplate(indexBackGround)}
      <Header>
        <HeaderInicial>
          {logo ? <img src={logo} /> : <LogoBranca />} 
          <div>
            <span> {headerData?.hospitalName}  </span>
            <p> {headerData?.hospitalRua} </p>
            <p> {headerData?.hospitalTelefone} </p>
          </div>
        </HeaderInicial>
        <h1>
          Laudo de Endoscopia Digestiva Alta
        </h1>
        <HeaderCabecalho>
          {headerContent}

        </HeaderCabecalho>
      </Header>

      <Content>
        <ContentInit>
          {subHeaderContent}
        </ContentInit>
        
        <ContentWithImage>
          {content}

          {withImage ? (
            <div>
              {CardsImages()}
              {CardsImages()}
              {CardsImages()}
            </div>
          )
            : null}
        </ContentWithImage>
      </Content>

      {notes}

      <Signature>
        <div></div>
        <span> {medicName} </span>
      </Signature>

      <FooterLaudo>
        <TitleLine>
          <div style={{backgroundColor: colorTemplate }} ></div>
          <div style={{backgroundColor: colorTemplate }}></div>
          <div style={{backgroundColor: colorTemplate }}></div>
        </TitleLine>
        <span>Laudo produzido por Endo Cloud - Laudos inteligentes e automáticos.</span>
        <span>www.endocloud.com.br</span>
      </FooterLaudo>
      
    </Container>
  )
}


const Container = styled.div`
  background-color: #FFFFFF;
  width: 100%;
  max-width: 800px;
  height: 100%;
  max-height: 1132px;
  padding-left: 54px;
  padding-right: 54px;
  padding-top: 28px;
  padding-bottom: 28px;
  position: relative;
  filter: drop-shadow(0px 1px 2px rgba(52, 60, 88, 0.4));

`

const Header = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;

  > h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 4px;
    margin-bottom: 15px;
  }

`

const HeaderInicial = styled.div`

  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  > img, svg {
    width: 54px;
    height: 54px; 
    border-radius: 50%;
    object-fit: cover;
    background-color: #9dafc0ad
  }

  > div {
    font-family: 'Roboto';
    text-align: end;
    line-height: 15px;
    color: #323654;
  }

  > div > span {
    font-size: 11px;
    font-weight: 500;
  }

  > div > p {
    font-size: 9px;
    font-weight: normal;
  }
`

const HeaderCabecalho = styled.div`
    width: 100%;
  > div {
    height: 100px;
    background: #FFFFFF;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  > div > div > div {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 5px;
  }
  > div > div > div > p {
    font-size: 10px;
    font-weight: 600;
    margin-right: 5px;
  }
  > div > div > div > span {
    font-size: 10px;
    outline: none;
  }
`

const Content = styled.div`

`

const ContentInit = styled.div`
  width: 100%;
  margin-bottom: 20px;
   > div > div {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 5px;
  }
  > div > div > p {
    font-size: 10px;
    font-weight: 600;
    white-space: nowrap;
    margin-right: 5px;
  }
  > div > div > span {
    font-size: 10px;
    outline: none;
  }
`

const TitleLine = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 10% auto 1fr;
  place-items: center;


  > span {
    font-size: 17px;
    margin: 0 10px;
  }
  > div {
    width: 100%;
    height: 2px;
  }
`

const Textarea = styled.p`
  resize: vertical;
  width: 100%;
  height: auto;
  min-height: 60px;
  /* border: none; */

  > span {
    display: block;
    width: 100%;
    overflow: hidden;
    resize: none;
    min-height: 40px;
    line-height: 16px;
    outline: none;
    font-size: 9px;
    font-family: 'Roboto';
  }
`

export const Notes = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;

  > input {
    width: 100%;
    outline: none;
    margin-left: 5px;
    border: none;
    font-size: 9px;
    font-family: 'Roboto';
  }
`

const Signature = styled.div`
  width: 200px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  bottom: 64px;
  right: 54px;

  > div {
    height: 1px;
    width: 100%;
    background-color: black;
    margin-bottom: 5px;
  }

  > span {
    font-size: 9px;
    font-weight: bold;
  }
`

const FooterLaudo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 0 54px;
  > div {
    margin-bottom: 10px;
  }

  > span {
    font-size: 7px;
    margin-bottom: 5px;
  }
`

const ImageContainer = styled.div`
  width: 100px;
  height: 100px;
  background-color: #C9CBDB;
  display: grid;
  place-items: center;
  margin-left: 30px;
  margin-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;
`

const ContentWithImage = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`