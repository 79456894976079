export function maskPhone(tel) {
  return tel
      .replace(/\D/g, '')
      .replace(/(\d{0})(\d)/, '$1($2')
      .replace(/(\d{2})(\d)/, '$1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
}

export function maskCreditCard(creditNumber){
  return creditNumber
    .replace(/\D/g, '')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
}

export function numberOnly(number){
  return number
    .replace(/\D/g, '')
}

export function letterOnly(value) {
  let teste = String(value)
  return teste.replace(/[\"\'~`!@#$%^&()_={}[\]:;,.<>+\/?-]+|\d+|^\s+$/g, '').replace(/\s+/ig, ' ')
}

export function maskValidateCreditCard(validate){
  return validate
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
}

export function maskCpf(creditNumber){
  return creditNumber
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1-$2')
}

// Converter para base 64
export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
          resolve(fileReader.result)
      }

      fileReader.onerror = (error) => {
          reject(error)
      }
  })
}


export function isEmail(email){
  let re = /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/;
  return !re.test(email)
}

export async function makeHashCreditCard(creditCard, newHash ){

  let checkout = new window.DirectCheckout('7C5BC9EA5A3A4306CB01AC6EA872C6231C5A48A842D635409F22C2C201301451');

  const expiration = creditCard.expiration.split("/")

  let cardData = {
    cardNumber: creditCard.cardNumber.replace(/\D/g, ''),
    holderName: creditCard.holderName,
    securityCode: creditCard.securityCode,
    expirationMonth: expiration[1],
    expirationYear: expiration[0]
  };

  let valid

  console.log(2)

  valid = await checkout.isValidCardNumber(cardData.cardNumber)
  if(!valid){
    newHash({
      status: false,
      content: 'Número do cartão inválido!'
    })
    return false
  }

  valid = await checkout.isValidSecurityCode(cardData.cardNumber, cardData.securityCode)
  if(!valid){
    newHash({
      status: false,
      content: 'Código de segurança do cartão inválido!'
    })
    return false
  }

  valid = await checkout.isValidExpireDate(cardData.expirationMonth, cardData.expirationYear)
  if(!valid){
    newHash({
      status: false,
      content: 'Data de expiração inválida!'
    })
    return false
  }
  
  return await checkout.getCardHash(cardData, function(cardHash) {
    newHash({
      status: true,
      content: cardHash
    })
  }, function(error) {
    newHash({
      status: false,
      content: 'Erro ao cadastrar o cartão!'
    })
  });

  return true
}