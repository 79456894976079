import React, { useState } from "react";

export const NavbarContext = React.createContext<any>(
  [
    {
      nome: "",
      img_perfil: "",
      logado: false,
      roleUser: "",
      laudoId: ''
    },
    () => { }
  ]
);

interface NavbarProviderProps {}

export const NavbarProvider: React.FC<NavbarProviderProps> = ({ children }) => {
  const [state, setState] = useState({
    nome: "",
    img_perfil: "",
    logado: false,
    roleUser: "",
    laudoId: ''
  });

  return (
    <NavbarContext.Provider value={[state, setState]}>
      {children}
    </NavbarContext.Provider>
  );
};