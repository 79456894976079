export const dataLaudosFake = [ 
  {
    paciente: "Joselino Costa da Silva",
    data: "28/10/2012",
    tipo: "Colonoscopia",
  },
  {
    paciente: "Carla Chaves",
    data: "07/05/2016",
    tipo: "Colonoscopia",
  },
  {
    paciente: "Ivanildo Duarte",
    data: "12/06/2020",
    tipo: "Colonoscopia",
  },
  {
    paciente: "Rafaela Vargas",
    data: "12/06/2020",
    tipo: "Colonoscopia",
  },
  {
    paciente: "Carla Chaves",
    data: "12/06/2020",
    tipo: "EDA",
  },
  {
    paciente: "Ivanildo Duarte",
    data: "12/06/2020",
    tipo: "EDA",
  },
  {
    paciente: "Ivanildo Duarte",
    data: "12/06/2020",
    tipo: "EDA",
  },
  {
    paciente: "Ivanildo Duarte",
    data: "12/06/2020",
    tipo: "Colonoscopia",
  },
  {
    paciente: "Ivanildo Duarte",
    data: "12/06/2020",
    tipo: "Colonoscopia",
  },
  {
    paciente: "Ivanildo Duarte",
    data: "20/08/2020",
    tipo: "EDA",
  },
]

export const dataPacientesFake = [ 
  {
    id: 1,
    nome: "Rafaela Batista",
    senha: 765865,
    idade: "51",
    data_nasc: "",
    sexo: "Masculino",
    email: "joselinocosta@endocloud.com",
    hosp_clin: "HGF",
    convenio: "SUS",
    telefone: "(085) 99424-7467",
  },
  {
    id: 2,
    nome: "Jéssica Saraiva",
    senha: 164865,
    idade: "51",
    data_nasc: "",
    sexo: "Masculino",
    email: "joselinocosta@endocloud.com",
    hosp_clin: "HGF",
    convenio: "SUS",
    telefone: "(085) 99424-7467"
  },
  {
    id: 3,
    nome: "Maitê Canto",
    senha: 765865,
    idade: "51",
    data_nasc: "",
    sexo: "Masculino",
    email: "joselinocosta@endocloud.com",
    hosp_clin: "HGF",
    convenio: "SUS",
    telefone: "(085) 99424-7467"
  },
  {
    id: 4,
    nome: "Letícia Barroso",
    senha: 123789,
    idade: "51",
    data_nasc: "",
    sexo: "Masculino",
    email: "joselinocosta@endocloud.com",
    hosp_clin: "HGF",
    convenio: "SUS",
    telefone: "(085) 99424-7467"
  },
  {
    id: 5,
    nome: "Rafaela Batista",
    senha: 765865,
    idade: "51",
    data_nasc: "",
    sexo: "Masculino",
    email: "joselinocosta@endocloud.com",
    hosp_clin: "HGF",
    convenio: "SUS",
    telefone: "(085) 99424-7467"
  },
  {
    id: 6,
    nome: "Rafaela Batista",
    senha: 765865,
    idade: "51",
    data_nasc: "",
    sexo: "Masculino",
    email: "joselinocosta@endocloud.com",
    hosp_clin: "HGF",
    convenio: "SUS",
    telefone: "(085) 99424-7467"
  },
  {
    id: 7,
    nome: "Rafaela Batista",
    senha: 765865,
    idade: "51",
    data_nasc: "",
    sexo: "Masculino",
    email: "joselinocosta@endocloud.com",
    hosp_clin: "HGF",
    convenio: "SUS",
    telefone: "(085) 99424-7467"
  },
  {
    id: 8,
    nome: "Rafaela Batista",
    senha: 765865,
    idade: "51",
    data_nasc: "",
    sexo: "Masculino",
    email: "joselinocosta@endocloud.com",
    hosp_clin: "HGF",
    convenio: "SUS",
    telefone: "(085) 99424-7467"
  },
  {
    id: 9,
    nome: "Rafaela Batista",
    senha: 765865,
    idade: "51",
    data_nasc: "",
    sexo: "Masculino",
    email: "joselinocosta@endocloud.com",
    hosp_clin: "HGF",
    convenio: "SUS",
    telefone: "(085) 99424-7467"
  },
  {
    id: 10,
    nome: "Rafaela Batista",
    senha: 765865,
    idade: "51",
    data_nasc: "",
    sexo: "Masculino",
    email: "joselinocosta@endocloud.com",
    hosp_clin: "HGF",
    convenio: "SUS",
    telefone: "(085) 99424-7467"
  }
]