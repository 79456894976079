import React, { useState } from "react";

export const LaudoContext = React.createContext<any>(
  [
    {
      paciente: {
        id: 0,
        nome: "",
        data_nasc: "",
        idade: 0,
        sexo: "",
        convenio: "",
        medico_solicitante: "",
        dt_exame: "",
        indicacoes: "",
        exame_realizado: '',
      },
      laudo: {
        sedoanalgesia: "",
        midazolan: "",
        fentanil: "",
        propofol: "",
        cricofaringeo: "",
        esofago: "",
        estomago: "",
        duodeno: "",
        notas: "",
      },
      template: {
        aparelho: "",
        backgroundTemplate: "",
        color: "",
        comImg: "",
        icon: "",
        nome_laudo: "",
        cabecalho: {
          clinica: "",
          endereco: "",
          telefone: ""
        },
        imgEsofago: "",
        imgEstomago: "",
        imgDuodeno: ""
      },
      finalizado: true,
      isLaudoExpress: false
    },
    () => { }
  ]
);

interface LaudoProviderProps {}

export const LaudoProvider: React.FC<LaudoProviderProps> = ({ children }) => {
  const [state, setState] = useState({
    paciente: {
      id: 0,
      nome: "",
      data_nasc: "",
      idade: 0,
      sexo: "",
      convenio: "",
      medico_solicitante: "",
      dt_exame: "",
      indicacoes: "",
    },
    laudo: {
      sedoanalgesia: "",
      midazolan: "",
      fentanil: "",
      propofol: "",
      cricofaringeo: "",
      esofago: "",
      estomago: "",
      duodeno: "",
      notas: "",
    },
    template: {
      aparelho: "",
      backgroundTemplate: "",
      color: "",
      comImg: "",
      icon: "",
      nome_laudo: "",
      cabecalho: {
        clinica: "",
        endereco: "",
        telefone: ""
      },
      imgEsofago: "",
      imgEstomago: "",
      imgDuodeno: ""
    },
    finalizado: true,
    isLaudoExpress: false
  });

  return (
    <LaudoContext.Provider value={[state, setState]}>
      {children}
    </LaudoContext.Provider>
  );
};