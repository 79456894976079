//@ts-nocheck
import React, { useState, useEffect } from "react"
import './styles.css'
import { ArrowLeft, Camera, Edit } from 'react-feather'
import { Link } from 'react-router-dom'
import Button from '../Button'
import Background from '../../assets/Background'
import Input from '../Input'
import Header from '../Header'

import { GetToken, GetUser, MedicoUpdate } from '../../utils/auth'

interface UserInfoPropsMedico {
  nome?: string;
  cpf?: string;
  sexo?: string;
  telefone?: string;
  email?: string;
  img_perfil?: string;
  medico?: {
    crm?: string;
    especialidade?: string;
  }
}

function MeusDadosMedico() {

  function maskPhone(value: any) {
    let teste = String(value)
    return teste
      .replace(/\D/g, '')
      .replace(/(\d{0})(\d)/, '$1($2')
      .replace(/(\d{2})(\d)/, '$1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
  }

  function maskCPF(value: any) {
    let teste = String(value)
    return teste
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1-$2')
  }

  const uploadImage = async (e: any) => {
    const file = e.target.files[0]
    const base64 = await convertBase64(file)
    let strBase64 = String(base64)
    setInfoUser({ ...infoUser, img_perfil: strBase64 })
    await MedicoUpdate({
      nome: infoUser?.nome,
      telefone: infoUser?.telefone,
      email: infoUser?.email,
      img_perfil: strBase64,
      medico: {
        especialidade: infoUser?.medico?.especialidade
      }
    })
  }

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const [infoUser, setInfoUser] = useState<UserInfoPropsMedico>();

  useEffect(() => {
    const getUserInfo = async () => {
      const { token } = await GetToken()
      const user = await GetUser(token)
      setInfoUser(user)
    }
    getUserInfo()

  }, [])

  return (
    <div className="page-meus-dados">
      <Background />
      <Header name="Meus dados" />

      <form>
        <div style={{ width: 470 }}>
          <fieldset className="field-sobre-voce">
            <legend>
              Sobre você e contato
              </legend>

            <div className="field">
              <label>Nome:</label>
              <div
                style={{
                  width: 180,
                  marginRight: 20
                }}>
                <Input
                  name="sexo"
                  type="text"
                  placeholder={infoUser?.sexo}
                  disabled
                />
              </div>

              <Input
                name="nome"
                type="text"
                placeholder={infoUser?.nome}
                disabled
              />
            </div>

            <div className="field">
              <label>CPF:</label>

              <Input
                name="cpf"
                type="text"
                placeholder={maskCPF(infoUser?.cpf)}
                disabled
              />
            </div>

            <div className="field">
              <label>Telefone:</label>

              <Input
                name="telefone"
                type="tel"
                placeholder={maskPhone(infoUser?.telefone)}
                disabled
              />
            </div>
            <div className="field">
              <label>Email:</label>

              <Input
                name="email"
                type="email"
                placeholder={infoUser?.email}
                disabled
              />
            </div>
          </fieldset>

          <fieldset className="field-sobre-trabalho">
            <legend>
              Sobre o trabalho
              </legend>

            <div className="field">
              <label>Especialização:</label>
              <Input
                name="especialização"
                type="text"
                disabled
                placeholder={infoUser?.medico?.especialidade}
              />
            </div>

            <div className="field">
              <label>CRM:*</label>
              <Input
                name="crm"
                type="number"
                placeholder={infoUser?.medico?.crm}
                disabled
                style={{
                  width: 250,
                  marginRight: 20
                }}
              />

              <Input
                name="uf"
                type="text"
                placeholder="CE"
                disabled
              />
            </div>
          </fieldset>

        </div>

        <section className="section-img-perfil">
          <div className="img-perfil">
            {infoUser?.img_perfil === null ?
              <Camera />
              :
              <img src={infoUser?.img_perfil} style={{ width: "100%", height: "100%", borderRadius: "160px" }} alt="icon" />
            }
          </div>
          <input type="file" id="input-file" name="image-upload" accept="image/*" onChange={uploadImage} style={{ display: "none" }} />
          <label htmlFor="input-file"
            style={{ display: "flex", alignItems: "center", cursor: "pointer", float: "right", fontSize: "22px", marginRight: "18px" }}
          >
            <Camera style={{ marginRight: "8px" }} />
            <span>Alterar imagem de Perfil</span>
          </label>
        </section>
      </form>

      <footer>
        <main className="container">
          <Link to="#">
            <ArrowLeft />
              Voltar
            </Link>
          <Link to="/meus-dados/editar">
            <Button>
              <Edit />
                Editar
              </Button>
          </Link>
        </main>
      </footer>
    </div>
  )
}

export default MeusDadosMedico