//@ts-nocheck
import React from 'react'
import { ArrowLeft } from 'react-feather'
import { useHistory } from 'react-router-dom'

import './styles.css'

interface HeaderProps {
  name: string;
}

function Header({ name }: HeaderProps) {
  const history = useHistory();

  return (
    <header className="header-page">
      <span onClick={() => history.goBack()}>
        <ArrowLeft />
      </span>
      <h2>{name}</h2>
    </header>
  )
}

export default Header
