//@ts-nocheck
import React from 'react'
import Button from '../Button';

import './styles.css'

interface ModalAlertaProps {
  setModal: Function; // usada para fechar ou abrir o modal
  titulo: string;
  info: string;
  msgRejeicao?: string;
  msgConfirmacao?: string;
  acaoBtnConfirmacao: Function;
}

function ModalAlerta({ setModal, titulo, info, msgRejeicao, msgConfirmacao, acaoBtnConfirmacao }: ModalAlertaProps) {

  window.addEventListener('click', (e) => {
    if ((e.target as HTMLElement).id === "modal-alerta-container") {
      setModal(false);
    }
  })

  window.addEventListener('keyup', (e) => {
    if (e.key === "Escape") {
      setModal(false);
    }
  })

  return (
    <div className="modal-alerta-container" id="modal-alerta-container">
      <div className="modal-alerta">
        <h1>{titulo}</h1>
        <p>{info}</p>
        <div>
          {msgRejeicao ?
            <Button
              width="large"
              backgroundColor="#fff"
              color="#323654"
              onClick={() => setModal(false)}
            >
              {msgRejeicao}
            </Button> : ''}

          {msgConfirmacao ?
            <Button
              width="large"
              backgroundColor="#BC2A2A"
              color="#fff"
              onClick={() => {
                acaoBtnConfirmacao();
                setModal(false)
              }}
            >
              {msgConfirmacao}
            </Button> : ''}
        </div>
      </div>
    </div>
  )
}

export default ModalAlerta
