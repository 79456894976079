export const convenios = [
  "UNIMED",
  "AMIL ASSISTÊNCIA MÉDIA INTERNACIONAL LTDA",
  "PARTICULAR",
  "SUS",
  "CORTESIA",
  "HAPVIDA",
  "BRADESCO",
  "CAFAZ",
  "BANCO CENTRAL",
  "ASSEFAZ",
  "ISSEC",
  "IPM",
  "CAMED SAÚDE",
  "GEAP",
  "FUSMA",
  "UNIMED – UNIFLEX",
  "UNIMED – UNIPLANO",
  "PLAN ASSIST",
  "CONAB",
  "POSTAL SAÚDE",
  "EMBRATEL",
  "FACHESF",
  "MEDI SERVICE",
  "PAS – SÃO CAMILO",
  "SULAMERICA",
  "PROASA",
  "CRIO",
  "CAPESESP",
  "NOTREDAME",
  "NORCLINICAS",
  "PETROBRAS DISFOR",
  "PETROBRAS ASFOR",
  "TELOS",
  "FAMED",
  "LIFE EMPRESARIAL",
  "CASSI",
  "LIV SAÚDE",
  "SAÚDE CAIXA",
  "FUNSA",
  "CARTAO VAI BEM",
  "VITALLIS",
  "JESUITASDO BRASIL",
  "REDE MAIS SAÚDE E VIDA",
  "HIAS",
  "INSTITUTO RAIMUNDO NOBRE",
  "UNIMED SALUTIS MAX",
  "PAME",
  "UNIMED SALUTIS",
]