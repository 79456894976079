import React, { useContext, useEffect, useState } from 'react';
import Background from '../../../../assets/Background';
import { Check, Mail, Printer } from 'react-feather';
import PdfLaudo from '../../../../components/PdfLaudo';
import { pdf, PDFViewer } from '@react-pdf/renderer';
import printJS from 'print-js';

import Button from '../../../../components/Button';
import './styles.css';
import { LaudoContext } from '../../../../utils/LaudoProvider';
import { SendLaudo } from '../../../../utils/auth';
import Input from '../../../../components/Input';
import { useHistory } from 'react-router-dom';

function LaudoFinalizado() {
  const [laudoCtx, setLaudoCtx] = useContext(LaudoContext);
  const [loading, setLoading] = useState(false);
  const [emailEnviado, setEmailEnviado] = useState(false);
  const [sendMailSelected, setSendMailSelected] = useState(false);

  const [loadingCtx, setLoadingCtx] = useState(true);

  const [emailPaciente, setEmailPaciente] = useState("");

  const generatePdfDocument = async () => {
    const blob = await generateBlob();
    const blobURL = URL.createObjectURL(blob);

    printJS(blobURL);
  };

  const generateBlob = async () => {
    const blob = await pdf(
      <PdfLaudo conteudo={laudoCtx} />
    ).toBlob();

    return blob;
  };

  async function sendMail() {
    if (emailPaciente) {
      const blob = await generateBlob();

      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = async () => {
        let base64data = reader.result;

        let response = await SendLaudo({
          email: emailPaciente,
          laudo: base64data
        })
        setEmailEnviado(response);
      };

      setLoading(false);
    }
  }

  const history = useHistory();

  useEffect(() => {
    if (laudoCtx.finalizado) {
      history.push('/novo-laudo');
    }
    if (laudoCtx.nome) {
      setLoadingCtx(false);
    }
    
  }, [laudoCtx]);

  return (
    <div className="page-laudo-finalizado">
      <Background />
      <h2>Laudo concluído com sucesso!</h2>
      <main>
        <div>
          {
            !loadingCtx ?
              <PDFViewer width='435px' height='635px' className="pdf-final" >
                <PdfLaudo conteudo={laudoCtx} />
              </PDFViewer>
              : ''
          }
          <Button style={{
            width: 170,
            height: 50
          }}>
            <Check />Confirmar
            </Button>
        </div>
        <section>
          <div className="info-acesso-paciente">
            <label>ID do paciente</label>
            <span>{laudoCtx.id_paciente}</span>
            <label>Senha</label>
            <span className="senha">{laudoCtx.senha}</span>
          </div>

          <span onClick={() => generatePdfDocument()}>
            <div>
              <Printer color="#6A79D7" />
            </div>
            <p>Imprimir laudo do Paciente </p>
          </span>
          <span onClick={() => setSendMailSelected(true)}>
            <div>
              <Mail color="#6A79D7" />
            </div>
            {
              loading ?
                <p>Enviando...</p>
                :
                <p>Enviar laudo por email</p>
            }
          </span>
          {
            sendMailSelected ?
              <form onSubmit={(e) => {
                e.preventDefault();
                setLoading(true);
                sendMail();
              }}
              >
                <div>
                  <Input
                    type="email"
                    placeholder="Digite o email do paciente"
                    value={emailPaciente}
                    onChange={(e) => setEmailPaciente(e.target.value)}
                  />
                  <Button style={{
                    width: 46,
                    height: 38,
                    borderRadius: '50%'
                  }}>
                    <Check />
                  </Button>
                </div>
                {
                  emailEnviado ?
                    <span>
                      <p>
                        Email enviado, &nbsp;
                          <a>enviar novamente</a>.
                        </p>
                    </span> : ''
                }
              </form> : ''
          }
        </section>
      </main>

    </div>
  );
}

export default LaudoFinalizado;