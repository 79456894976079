//@ts-nocheck
import React, { useState, useEffect } from "react"
import "./styles.css"
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Check, FileText, Upload, Camera, X } from "react-feather"
import Input from "../../components/Input"
import ColorRadio from "../../components/ColorRadio"
import MiniLaudo from "../../components/MiniLaudo"
import Radio from "../../components/Radio"
import Button from "../../components/Button"
import Background from "../../assets/Background"
import BackgroundTemplate1 from "../../assets/BackgroundTemplate1"
import BackgroundTemplate2 from "../../assets/BackgroundTemplate2"
import { GetOneTemplateLaudo, GetToken, GetUser, SetTemplateLaudo, UpdateTemplateLaudo } from '../../utils/auth'
import Modal from "../../components/Modal";
import Laudo, {TitleContent, TextContent, Notes, TitleAndText} from "../../components/Laudo";
import { Link, useHistory } from "react-router-dom";
import BackgroundTemplate3 from "../../assets/BackgroundTemplate3";
import { convertBase64, maskPhone } from "../../utils";

interface templateCreateProps  {
    cabecalho?: {
        clinica?: string,
        endereco?: string,
        telefone?: string
    },
    backgroundTemplate?: string,
    color?: string,
    aparelho?: string,
    comImg?: string,
    icon?: string,
    nome?: string
};

interface modalInfoProps {
    title?: string,
    contentText?: string,
    loadding?: boolean,
    error?: boolean
}

interface ICriarLaudo {
    id?: any,
    title: string
}

function CriarLaudo( { id, title } : ICriarLaudo)  {

    const [dataTemplate, setDataTemplate] = useState<templateCreateProps>({
        cabecalho: {
            clinica: '',
            endereco: '',
            telefone: ''
        },
        backgroundTemplate: '1',
        color: '#2FBDBC',
        aparelho: '',
        comImg: 'sem-img',
        icon: ''
    });

    const [modalOn, setModalOn] = useState(false)
    const [infoUser, setInfoUser] = useState<any>();
    const [allDateTemplate, setAllDateTemplate] = useState<any>()
    const [type, setType] = useState("")

    const [modalInfo, setModalInfo] = useState<modalInfoProps>()

    const history = useHistory()

    useEffect(() => {

        if(id){
            const getTemplate = async () => {
                try {
                    const res = await GetOneTemplateLaudo(id)
                    setAllDateTemplate(res)
                    setDataTemplate(res.conteudo)
                } catch {
                    window.alert('Template não encontrado, tente novamente mais tarde!')
                    history.push('/home')
                }
            }
            getTemplate()
        }

        const getUser = async () => {
            try {
                const {token, typeUser} = await GetToken()
                const user = await GetUser(token)
    
                setInfoUser(user)
                setType(typeUser)

            } catch {
                window.alert('Erro de autenticação, realize login novamente!')
                history.push('/login')
            }
        }

        getUser()
    }, [])


    const setTemplateLaudo = async () => {
        setModalInfo({...modalInfo, loadding: true, error: false})
        setModalOn(true)
        try {
            let resp
            if(id){
                const data = {
                    is_favorito: allDateTemplate.is_favorito,
                    nome: allDateTemplate.nome,
                    id: id,
                    conteudo: dataTemplate
                }
                resp = await UpdateTemplateLaudo(data)
            } else {
                resp = await SetTemplateLaudo(dataTemplate, dataTemplate.nome)
            }
            setModalInfo({
                title: '',
                contentText: resp.data.message,
                loadding: false
            })
        } catch (err) {
            setModalInfo({
                title: '',
                contentText: '',
                loadding: false,
                error: true
            })
        }
    }

    const uploadImage = async (e: any) => {
        const file = e.target.files[0]
        const base64 = await convertBase64(file)
        let strBase64 = String(base64)
        setDataTemplate({...dataTemplate,  icon: strBase64})
    }

    return (
        <div>
            <div style={{ marginBottom: "30px" }}></div>
            <Background />
            <Header name={title} />
            <div className="container-middle" >
                <div className="content-middle" >
                    <div style={{ display: "flex", flexDirection: "column", marginTop: "40px", marginBottom: "40px" }} >
                        <div style={{ display: "flex", alignItems: 'center', marginBottom: "30px" }}>
                            <FileText size={44} color={"#6A79D7"} style={{ marginRight: "18px" }} />
                            <div style={{ width: "338px" }}>
                                <Input placeholder="Einstein Hospital" name="hospital" onChange={(e) => {
                                    setDataTemplate({...dataTemplate, nome: e.target.value.toUpperCase()})
                                }} />
                            </div>
                        </div>
                        <h3>Cor de Template</h3>
                        <div style={{ marginTop: "20px", marginBottom: "20px", display: "flex", alignItems: "center" }}>
                        <div onClick={() => {
                                setDataTemplate({...dataTemplate, color: "#2FBDBC"})
                                }}>
                                <ColorRadio colorSelect="#2FBDBC" active={dataTemplate.color === '#2FBDBC'} />
                            </div>
                            <div onClick={() => {
                                setDataTemplate({...dataTemplate, color: "#DAA213"})
                                }}>
                                <ColorRadio colorSelect="#DAA213" active={dataTemplate.color === '#DAA213'} />
                            </div>
                            <div onClick={() => {
                                setDataTemplate({...dataTemplate, color: "#005596"})
                                }}>
                                <ColorRadio colorSelect="#005596" active={dataTemplate.color === '#005596'} />
                            </div>
                            <div onClick={() => {
                                setDataTemplate({...dataTemplate, color: "#C9CBDB"})
                                }}>
                                <ColorRadio colorSelect="#C9CBDB" active={dataTemplate.color === '#C9CBDB'} />
                            </div>
                        </div>
                        <h3>Estilos de Template</h3>
                        <div style={{alignItems: "center", marginTop: "28px", marginBottom: "40px", display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
                        <div style={{ width: "110px", height: "170px", marginRight: "24px" }} onClick={() => {
                                setDataTemplate({...dataTemplate, backgroundTemplate: "1" })
                                }}>
                                <MiniLaudo color="#2FBDBC" active={dataTemplate.backgroundTemplate === '1'} icon={<FileText size={16} />} background="1" />
                            </div>
                            <div style={{ width: "110px", height: "170px", marginRight: "24px" }} onClick={() => {
                                setDataTemplate({...dataTemplate, backgroundTemplate: "2"})
                                }}>
                                <MiniLaudo color="#DAA213" active={dataTemplate.backgroundTemplate === '2'} icon={<FileText size={16} />} background="2" />
                            </div>
                            
                            <div style={{ width: "110px", height: "170px", marginRight: "24px" }} onClick={() => {
                                setDataTemplate({...dataTemplate, backgroundTemplate: "3"})
                                }}>
                                <MiniLaudo color="#005596" active={dataTemplate.backgroundTemplate === '3'} icon={<FileText size={16} />} background="3" />
                            </div>

                            <div style={{ width: "110px", height: "170px", marginTop: '24px' }} onClick={() => {
                                setDataTemplate({...dataTemplate, backgroundTemplate: "branco"})
                                }}>
                                <MiniLaudo color="#C9CBDB" active={dataTemplate.backgroundTemplate === 'branco'} icon={<FileText size={16} />} background="branco" />
                            </div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", marginBottom: "30px" }}>
                            <span style={{ fontSize: "20px", marginRight: "44px" }}>Opções de Laudo*</span>
                            <Radio id="c-img" label="Com imagem" state={dataTemplate?.comImg === "com-img"} onChangeRadio={() => {
                                setDataTemplate({...dataTemplate, comImg: "com-img"})
                                }} />
                            <Radio id="s-img" label="Sem imagem" state={dataTemplate?.comImg === "sem-img"} onChangeRadio={() => {
                                setDataTemplate({...dataTemplate, comImg: "sem-img"})
                                }} />
                        </div>
                        <h4 style={{ marginBottom: "30px" }}>Cabeçalho de Laudo</h4>
                        <div style={{ width: "460px", marginBottom: "14px" }}>
                            <Input name="doutor" onChange={(e) => {
                                setDataTemplate({...dataTemplate, cabecalho: {...dataTemplate.cabecalho, clinica: e.target.value.toUpperCase()}})
                                }}
                                value={dataTemplate.cabecalho?.clinica}
                                placeholder="Einstein Hosptital Israelista - Dr Sandra" />
                        </div>
                        <div style={{ width: "460px", marginBottom: "14px" }}>
                            <Input name="endereco" onChange={(e) => {
                                setDataTemplate({...dataTemplate, cabecalho: {...dataTemplate.cabecalho, endereco: e.target.value.toUpperCase()}})
                                }}
                                value={dataTemplate.cabecalho?.endereco}
                                placeholder="Rua Riachuelo 400 Papicu" />
                        </div>
                        <div style={{ width: "460px", marginBottom: "30px" }}>
                            <Input name="telefone" onChange={(e) => {
                                    setDataTemplate({...dataTemplate, cabecalho: {...dataTemplate.cabecalho, telefone: e.target.value}})
                                }}
                                value={maskPhone(dataTemplate.cabecalho?.telefone)}
                                maxLength={15}
                                placeholder="(85) 98828-2476" />
                        </div>
                        <h4 style={{ marginBottom: "30px" }}>Aparelho/Modelo</h4>
                        <div style={{ width: "460px", marginBottom: "30px" }}>
                            <Input name="aparelho" onChange={(e) => {
                                setDataTemplate({...dataTemplate,aparelho: e.target.value.toUpperCase()})
                                }}
                                value={dataTemplate?.aparelho}
                                placeholder="Olympus C-180" />
                        </div>
                        <h4 style={{ marginBottom: "30px" }}>Carregar Logo</h4>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img src={dataTemplate.icon} style={{ width: "200px", height: "200px", borderRadius: "50%", marginRight: "50px" }} alt="icon" />
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                <span style={{ color: "#74799C", fontSize: "15px", width: "208px", marginBottom: "20px" }}>O tamanho ideal são imagens de no mínimo 320 x 320 pixels</span>
                                <input type="file" id="input-file" name="image-upload" accept="image/*" onChange={uploadImage} style={{ display: "none" }} />
                                <Button color="#070606" backgroundColor="#6A79D7" width="230px" style={{ fontSize: "18px", padding: "12px" }} >
                                    <label htmlFor="input-file" style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                                        <Upload size={24} />
                                        Carregar Imagem
                                    </label>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginTop: "-200px" }} >
                        <h3>Pré-Visualização</h3>
                        <div style={{ width: '595px', height: '842px' }} >
                        <Laudo 
                            withImage={dataTemplate?.comImg === 'com-img'}
                            colorTemplate={dataTemplate.color} 
                            indexBackGround={Number(dataTemplate.backgroundTemplate) || 0} 
                            headerData={{
                                hospitalName: dataTemplate.cabecalho?.clinica || 'Einstein Hospital Israelita',
                                hospitalRua: dataTemplate.cabecalho?.endereco || 'Rua Riachuelo 900 Papicu - Fortaleza/CE',
                                hospitalTelefone: dataTemplate.cabecalho?.telefone || '(99) 99999-9999'
                                }
                            }
                            modelo={dataTemplate?.aparelho || 'OLYMPUS C-180'}
                            content={
                                <div>
                                {TitleContent({title: 'Esofâgo', color: dataTemplate.color})}
                                {TextContent({text: 'Calibre e distensibilidade preservados \n Ausência de varizes \n Relevo mucoso íntegro em todo trajeto \n Passagem fácil pelo cárdia \n A transição esofagogástrica (TEG) situa-se ao nível do pinçamento.'})}
                                {TitleContent({title: 'Estômago', color: dataTemplate.color})}
                                {TextContent({text: 'Anatomia preservada, boa distensibilidade. \n Lago mucoso claro em volume habitual. \n À retrovisão, hiato justo ao aparelho e cárdia de aspecto normal. \n Fundo gástrico sem alterações. \n Região do corpo com pregueado mucoso normotrófico, sem erosões, úlceras ou pólipos. \n Incisura angularis sem lesões. \n Antro com mucosa íntegra. \n Piloro pérvio sem deformidades. \n'})} 
                                {TitleContent({title: 'Duodeno', color: dataTemplate.color})}
                                {TextContent({text: 'Bulbo distensível, sem retrações ou abaulamentos e com mucosa normal. \n Segunda porção duodenal sem alterações, examinada até o alcance do aparelho.'})}
                                {TitleContent({title: 'Conclusão', color: dataTemplate.color})}
                                {TextContent({text: 'Exame Normal.'})}
                                </div>
                            }
                            medicName={infoUser !== undefined ? infoUser.nome : 'Seu nome aqui!'}
                            notes={
                                <Notes> 
                                    <span>Notas:</span>
                                    <input value='Realizado teste da urease' />
                                </Notes>
                            }
                            logo={dataTemplate.icon}
                            headerContent={
                                <div>
                                    <div>
                                        {TitleAndText({title: 'Paciente', text: 'Rafael Ferreira'})}
                                        {TitleAndText({title: 'Sexo', text: 'Masculino'})}
                                        {TitleAndText({ title:'Idade', text: '23'})}
                                        {TitleAndText({ title:'Data do exame', text: '20/03/2021'})}
                                        {TitleAndText({ title:'indicação', text: 'Dor Abdominal'})}
                                    </div>
                                    <div>
                                        {TitleAndText({ title:'Cadastro', text: '908875'})}
                                        {TitleAndText({ title:'Convênio', text: 'Não'})}
                                        {TitleAndText({ title: 'Médico solicitante', text: 'Dr. Carlos B.'})}
                                        {TitleAndText({ title:'Aparelho/modelo', text: dataTemplate?.aparelho || 'OLYMPUS C-180'})}
                                    </div>
                                </div>
                            }
                            subHeaderContent={
                                <div>
                                    {TitleAndText({title: 'Sedoanalgesia', text: 'Anestesia orofaríngea com lidocaína spray | endovenosa com Midazolam 3mg + Fentanil 50mcg + Propofol 40mg'})}
                                    {TitleAndText({title: 'Passagem no Cricofaríngeo', text: 'Passagem do aparelho sob visão direta pelo crocofaringeo sem obstáculos ou alterações'})}
                                </div>
                            }
                        />
                        </div>
                    </div>
                </div>
            </div>
            {modalOn && <Modal setModal={setModalOn} children={
                <div className="modal-novo-paciente">
                    <X
                        size={30} 
                        color={"#74799C"} 
                        style={{marginTop: "30px", marginBottom: "22px", marginLeft: "700px", cursor: "pointer"}}
                        onClick={() =>  setModalOn(false)}
                    />
                    {modalInfo?.loadding ? 
                    <h4 style={{marginBottom: "56px"}}>Carregando...</h4>
                    :
                    !modalInfo?.error ?
                    <>
                        <h4 style={{color: "#2FBDBC", marginBottom: "56px"}}>{ id ? 'Editado' : 'Criado'} com sucesso!</h4>
                        <Link to="/home" style={{textDecoration: "none"}}>
                            <Button color="#FFFFFF" backgroundColor="#6A79D7" width="120px" style={{padding: "12px", fontSize: "18px", marginBottom: "30px"}} >
                                Ir para página inicial
                            </Button>
                        </Link>
                    </>    
                    : 
                    <h4 style={{color: "#BC2A2A", marginBottom: "56px"}}>Erro inesperado, tente novamente mais tarde!</h4>
                    }
                </div>
            } />}
            <Footer 
                name="Voltar" 
                link_back="/home" 
                name_btn="Salvar" 
                icon_btn={<Check />} 
                state_btn={true} 
                onClick={(e) => {
                    setTemplateLaudo()
                }} />
        </div>
    )
}

export default CriarLaudo