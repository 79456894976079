import React, { useState } from 'react'
import { Search } from 'react-feather'

import Background from '../../../assets/Background'
import Button from '../../../components/Button'
import Footer from '../../../components/Footer'
import Header from '../../../components/Header'
import Input from '../../../components/Input'

import { GetPaciente } from "../../../utils/auth"
import { mascNascimento, calculaIdade }  from '../../../utils/metodos/dataIdade'

import './styles.css'

interface PacienteProps {
  nome: string;
  sexo: string;
  dt_nascimento: string;
  id: string;
  convenio: string;
} 

function PacienteCadastrado() {
  const [search, setSearch] = useState("");

  const [dataPaciente, setDataPaciente] = useState<Array<PacienteProps>>([]);

  const getPaciente = async () => {
    const response = await GetPaciente("teste")
    response ? 
      setDataPaciente(response)
      :
      console.log('deu errado')

    console.log(response)
  }

  // function mascNascimento(date: string) {
  //   date = date.replace('T', '-')
  //   const arrayDate = date.split('-')
  //   const dateFormated = `${arrayDate[2]}/${arrayDate[1]}/${arrayDate[0]}`
  //   return dateFormated
  // }

  // function calculaIdade(dataNasc: string){ 
  //   let dataAtual = new Date();
  //   let anoAtual = dataAtual.getFullYear();
  //   let anoNascParts = dataNasc.split('/');
  //   let diaNasc = parseInt(anoNascParts[0]);
  //   let mesNasc = parseInt(anoNascParts[1]);
  //   let anoNasc = parseInt(anoNascParts[2]);
  //   let idade = anoAtual - anoNasc;
  //   let mesAtual = dataAtual.getMonth() + 1; 
  //   //Se mes atual for menor que o nascimento, nao fez aniversario ainda;  
  //   if(mesAtual < mesNasc){
  //       idade--; 
  //   } else {
  //   //Se estiver no mes do nascimento, verificar o dia
  //       if(mesAtual == mesNasc){ 
  //           if(new Date().getDate() < diaNasc ){ 
  //           //Se a data atual for menor que o dia de nascimento ele ainda nao fez aniversario
  //               idade--; 
  //           }
  //       }
  //   } 
  //   return idade; 
  // }

  return (
    <div className="page-paciente-cadastrado">
      <Background />
      <Header name="Pesquisar Paciente" />

      <main>
        <div className="pesquisar-paciente">
          <label>Procurar Pacientes</label>
          <Input
            name="search-patient"
            type="search" placeholder="Nome do paciente"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
          <span className="search-icon" onClick={() => {
            //searchPatient()
            //GetPaciente("teste")
            getPaciente()
            }}>
            <Search style={{ color: "#fff" }} />
          </span>
        </div>

        {
          dataPaciente.length > 1 ?
            <p>{dataPaciente.length} pacientes encontrados</p>
            : ''
        }

        {
          dataPaciente.length === 0 ?
            <section>
              <p>Pesquise para ver as informações de um paciente</p>
            </section>
            :
            dataPaciente.map((paciente, i) => {
              return (
                <div key={i}>
                  <section className="info-paciente">
                    <div>
                      <label>Nome:</label>
                      <span>{ paciente.nome }</span>
                    </div>
                    <div>
                      <label>Sexo:</label>
                      <span>{paciente.sexo}</span>
                    </div>
                    <div>
                      <label>Data de Nascimento:</label>
                      <span>{mascNascimento(paciente.dt_nascimento)}</span>
                    </div>
                    <div className="flex" style={{ columnGap: 80 }}>
                      <div>
                        <label>Idade:</label>
                        <span>{calculaIdade(mascNascimento(paciente.dt_nascimento))}</span>
                      </div>
                      <div>
                        <label>Id:</label>
                        <span>{paciente.id}</span>
                      </div>
                    </div>
                    {/*<div>
                      <label>Hospital/Clínica:</label>
                      <span>{paciente.hosp_clin}</span>
                    </div>*/
                    }
                    <div>
                      <label>Convênio:</label>
                      <span>{paciente.convenio}</span>
                    </div>
                  </section>
                  {
                    dataPaciente.length > 1 ?
                      <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: 10
                      }}>
                        <Button
                          style={{
                            width: 200,
                            padding: '12px 0'
                          }}
                          onClick={() => setDataPaciente([paciente])}
                        >Escolher paciente</Button>
                      </div> : ''
                  }
                </div>
              )
            })
        }
      </main>

      <Footer
        name="Voltar"
        link_back="/"
        link_btn="/"
        name_btn="Adicionar Consulta"
        state_btn={dataPaciente.length === 1}
      />
    </div>
  )
}

export default PacienteCadastrado