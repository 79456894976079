//@ts-nocheck
import React, { useEffect, useState, useContext } from 'react'
import { Calendar, FileText, PlusCircle, Edit3, X } from "react-feather"
import { Link } from 'react-router-dom'
import Background from '../../assets/Background'
import NavbarSec from '../../components/NavbarSec'
import Modal from "../../components/Modal"
import Button from "../../components/Button"
import MiniLaudo from "../../components/MiniLaudo"

import "./styles.css"
import { GetToken, GetUser, GetTemplateLaudo, UpdateTemplateLaudo } from '../../utils/auth'
import { NavbarContext } from '../../providers/NavbarProvider'

interface UserInfoProps {
  nome?: string;
  telefone?: string;
  email?: string;
  senha?: string;
  medico?: {
    crm?: string;
    uf?: string;
    especialidade?: string;
  }
}


function Inicio() {
  interface templateInterface  {
    id?: number,
    id_pessoa: number,
    is_favorito: boolean,
    nome: string,
    conteudo: {
      cabecalho: {
        clinica: string,
        endereco: string,
        telefone: string
      },
      backgroundTemplate: string,
      color: string,
      aparelho: string,
      comImg: string,
      icon: string
    }
  };

  const [modal, setModall] = useState(false);
  const [target, setTarget] = useState(true);
  const [templates, setTemplates] = useState<templateInterface[]>([])

  const [infoUser, setInfoUser] = useState<UserInfoProps>();
  
  const [navbarInfo, setNavbarInfo] = useContext(NavbarContext);

  useEffect(() => {

    const getInfoUser = async () => {
      try {
        const { token } = await GetToken()
        const data  = await GetUser(token)
        setInfoUser( data )
      } catch(err) {
        console.log(err)
      }
    }
    getInfoUser()
      
  }, [])

  const getTemplateFavorito = async (response: any) => {
    try {
        for( let i = 0; i < response.length; i++){
        console.log(i)
        if(response[i].is_favorito) {
          setNavbarInfo({
            ...navbarInfo,
            laudoId: response[i].id
          })
          return
        }
      }
    } catch(err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getTeplate()
  }, [])

  const getTeplate = async () => {
    try {
      const response: templateInterface[] = await GetTemplateLaudo()
      //@ts-ignore
      response.sort((t1, t2) => (t1.id > t2.id) ? 1 : -1)
      setTemplates(response)
    } catch (err) {
      window.alert('erro ao listar templates')
    }
  }

  useEffect(() => {
    getTemplateFavorito(templates)

  }, [templates])

  const setTemplateLaudo = async () => {
    for (let i = 0; i < templates.length; i++) {
      await UpdateTemplateLaudo(templates[i])
    }
  }

  const selectFavorite = async (index: number) => {
      for (let i:number = 0; i < templates.length; i++) {
        if(i !== index && templates[i].is_favorito) {
          templates[i].is_favorito = !templates[i].is_favorito
        }
      }

      setTemplates(templates)
    
      setTemplateLaudo()
  }
    
  return (
    <div>
      <Background />
      <NavbarSec page="home" />
      <div className="middle-container">
        <div className="middle-title-home">
          <span><h2>Olá {infoUser?.nome}, bem-vindo</h2></span>
          <span className="middle-title-home-span-icon"><Calendar style={{ marginRight: "8px" }} />Hoje, quarta-feira 18/11</span>
        </div>
        <div className="middle-container-elements">
          <div className="left-elements-middle">
            <div className="img-home"></div>
          </div>
          <div className="right-elements-middle">
            <div>
              <span>8:00</span><div className="pacient-name">Carmem Lúcia dos Santos<div className="btn-pacient-name">Exame</div></div>
            </div>
            <div>
              <span>9:00</span><div className="pacient-name">Diego Rodrigues de Melo<div className="btn-pacient-name">Consulta</div></div>
            </div>
            <div>
              <span>12:00</span><div className="pacient-name">Larissa Rocha de Oliveira<div className="btn-pacient-name">Exame</div></div>
            </div>
            <div>
              <span>12:00</span><div className="pacient-name">Larissa Rocha de Oliveira<div className="btn-pacient-name">Exame</div></div>
            </div>
            <div>
              <span>12:00</span><div className="pacient-name">Larissa Rocha de Oliveira<div className="btn-pacient-name">Exame</div></div>
            </div>
            <div>
              <span>12:00</span><div className="pacient-name">Larissa Rocha de Oliveira<div className="btn-pacient-name">Exame</div></div>
            </div>
            <div>
              <span>12:00</span><div className="pacient-name">Larissa Rocha de Oliveira<div className="btn-pacient-name">Exame</div></div>
            </div>
            <div>
              <span>12:00</span><div className="pacient-name">Larissa Rocha de Oliveira<div className="btn-pacient-name">Exame</div></div>
            </div>
            <div>
              <span>12:00</span><div className="pacient-name">Larissa Rocha de Oliveira<div className="btn-pacient-name">Exame</div></div>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer-home">
        <div className="footer-items">
          <div style={{display: "flex", alignItems: "center"}}>
            <div style={{display: "flex", alignItems: "center", flexDirection: "column", marginRight: "48px"}}>
              <div style={{display: "flex", alignItems: "center", width: "200px", marginBottom: "16px"}}>
                <FileText size={24} color={"#2FBDBC"} />
                <span style={{fontSize: "18px", color: "#2FBDBC"}}> Templates de Laudo </span>
              </div>
              <div style={{display: "flex", alignItems: "center", width: "200px"}}>
                <span style={{fontSize: "12px", color: "#323654"}}> Antes de gerar um laudo você deve ter um template de laudo registrado </span>
              </div>
            </div>
            <div style={{display: "flex", alignItems: "center", maxWidth: "550px", overflow: "auto", overflowY: "hidden"}}>
            
              {templates.map( (template, index) => (
                    <div style={{display: "flex", alignItems: "center", flexDirection: "column", marginRight: "24px", marginLeft: "24px", justifyContent: "center", marginTop: "5px"}}
                    key={`miniLaudo-${index}`}
                      onClick={(e) => {
                        //e.preventDefault();
                        template.is_favorito = true
                        setNavbarInfo({
                          ...navbarInfo,
                          laudoId: template.id
                        })
                        //e.preventDefault();
                        template.is_favorito = true
                        selectFavorite(index)
                        setTarget(!target);
                      }}
                    >
                      <div style={{width: "74px", height: "106px"}}>
                        <MiniLaudo color={template.conteudo.color} active={template.is_favorito} icon={<PlusCircle size={14} />} background={template.conteudo.backgroundTemplate} />
                      </div>
                      <span style={{fontSize: "12px", color: "#74799C", justifyContent: "center", marginTop: "8px"}}> {template.nome} </span>
                    </div>
                  )
                )
              }
              
            </div>
            <Link to="/criar-laudo" style={{textDecoration: "none"}}>
              <div className="add-template">
                <PlusCircle size={24} color={"#6a79d7"} />
                <span style={{fontSize: "12px", color: "#6a79d7", marginTop: "10px"}}> Adicionar </span>
              </div>
            </Link>
          </div>
          <div style={{display: "flex", alignItems: "center"}}>
            <div className="btn-editar"
            onClick={(e) => {
              e.preventDefault();
              setModall(true);
            }}>
              <Edit3 size={24} color={"#74799C"} />
              <span style={{fontSize: "18px", color: "#74799C", marginLeft: "8px"}}> Editar </span>
            </div>
          </div>
        </div>
      </footer>
      
      { modal && <Modal setModal={setModall} children={
      <div className="modal-home">
        <X size={30} color={"#74799C"} style={{position: 'absolute', top:'30px', right: '40px', cursor: "pointer"}}
          onClick={(e) => {
            e.preventDefault();
            setModall(false);
          }}
        />
        <h4 style={{marginBottom: "56px", marginTop: '30px'}}>Escolha um template para editar</h4>
        <div style={{display: "flex", maxHeight: "400px", flexWrap: "wrap", alignItems: "center", maxWidth: "80%", marginBottom: "12px", overflow: "auto"}} >

          {templates.map((template, index) => (
            <div key={`minilaudo-${index}`} style={{display: "flex", alignItems: "center", flexDirection: "column", margin: "20px 35px", justifyContent: "center"}}>
              <div style={{width: "122px", height: "182px"}}>
                <MiniLaudo color={template.conteudo.color} active={false} icon={<PlusCircle size={18} />} background={template.conteudo.backgroundTemplate} />
              </div>
              <span style={{fontSize: "12px", color: "#74799C", justifyContent: "center", marginBottom: "18px", marginTop: "8px"}}> {template.nome} </span>
              <Link to={`/editar-laudo/${template.id}`} style={{textDecoration: "none"}}>
                <Button color="#FFFFFF" backgroundColor="#6A79D7" width="120px" style={{padding: "12px", fontSize: "18px"}} >
                  <Edit3 size={24}/>
                  Editar
                </Button>
              </Link>
            </div>
          ))}
          
        </div>
      </div>} /> }
    </div>

  )
}

export default Inicio
