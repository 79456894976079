import React from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import './App.css';

import Navbar from './components/Navbar';

import { NavbarProvider } from './providers/NavbarProvider';
import Routes from './routes';

/*let location = useLocation();
React.useEffect(() => {
}, [location]); */

function App() {

  return (
    <BrowserRouter>
      <div className="App">
        <NavbarProvider>
          <Navbar />
          <div className="container">
            <Routes />
          </div>
        </NavbarProvider>
      </div>
    </BrowserRouter>
  );
}

export default App;
