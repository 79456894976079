import React, { FormEvent, useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'

import { LogoutUser, resetPassword } from "../../utils/auth"

import { CheckCircle, Facebook, Instagram, Mail, Phone, X } from 'react-feather'

import LogoBranca from '../../assets/logo/LogoBranca'
import Background from '../../assets/Background'

import Input from '../../components/Input'
import Button from '../../components/Button'

import './styles.css'

interface QueryInterface {
  token: string,
  id: string
} 

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function RestaurarSenha() {

    const [query, setQuery] = useState<QueryInterface>()
    const [newPassword, setNewPassword] = useState('');
    const [alert, setAlert] = useState('');
    
    const history = useHistory();

    const newQuery = useQuery()

    useEffect(() => {

      async function logout() {
        await LogoutUser()
      }
      logout()

      setQuery({
        token: newQuery.get('token') || '',
        id: newQuery.get('id') || '' 
      })
    }, [])

    useEffect(() => {
      if(
        query?.token === ''  ||
        query?.id === ''
      ){
        history.push('/login')
      }
    }, [query])

    async function submitRecovery(e: FormEvent) {
      e.preventDefault();
  
      const token = query?.token
      const id = query?.id

      if (
        token
        && newPassword
        && newPassword.toString().trim()
        && id
      ) {
        const ret = await resetPassword({ 
            token,
            senha: newPassword,
            id: id as unknown as number
          })
        
        if (ret.success) {
          setAlert('');
          window.alert('Senha alterada com sucesso!')
          history.push('/login');
        } else {
          setAlert(ret.message);
        }
  
      }
    }

    return (
      <div className="page-login">
        <Background />
        <div className="navbar-login">
          <nav className="container">
            <Link to="/home"><LogoBranca /></Link>
            <ul>
              <li><Link to="/login">Laudo online</Link></li>
              <li><a target='_blacnk' href="https://endocloud.com.br/cadastro-medico">Planos</a></li>
              <li><Link to="/login">Blog</Link></li>
              <li><Link to="/login">Entrar</Link></li>
            </ul>
          </nav>
        </div>
  
        <main>
          <form onSubmit={submitRecovery}>
            <p>Restaurar senha</p>
            <div>
              <Input
                name="newPassword"
                type="password"
                placeholder="Digite sua nova senha"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                autoFocus
                data-cy="login-password"
              />
  
            </div>
  
            {
              alert &&
              <div
                className="alert-login"
                data-cy="login-alert"
              >
                <p>{alert}</p>
                <span onClick={() => setAlert('')}>
                  <X />
                </span>
              </div>
            }
  
            <Button
              width="large"
              type="submit"
              data-cy="login-btn"
            >
              <CheckCircle />
              Restaurar senha
            </Button>
  
          </form>
        </main>
  
        <div className="footer-login">
          <footer className="container">
            <Link to="/home"><LogoBranca /></Link>
            <ul>
              <li>
                <Mail color="#FFFFFF" />
                <a href="#">
                  endocloud@email.com
                </a>
              </li>
              <li>
                <Phone color="#FFFFFF" />
                <a href="#">(85)99999-9999</a>
              </li>
              <li>
                <Instagram color="#FFFFFF" />
                <a href="#">@endocloud</a>
              </li>
              <li>
                <Facebook color="#FFFFFF" />
                <a href="#">endocloud/facebook.com</a>
              </li>
            </ul>
          </footer>
        </div>
      </div>
    )
}
export default RestaurarSenha