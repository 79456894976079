//@ts-nocheck
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { ChevronDown, DollarSign, HelpCircle, LogOut, User } from 'react-feather'

import { GetToken, GetUser, isAuthenticated, LogoutUser } from '../../utils/auth'
import { NavbarContext } from '../../providers/NavbarProvider'

import LogoBranca from '../../assets/logo/LogoBranca'

import './styles.css'

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const [navbarInfo, setNavbarInfo] = useContext(NavbarContext);

  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }

  // evitando que a navbar renderize na pagina de login
  if (navbarInfo.nome === '') {
    return null;
  }

  async function logout() {
    await LogoutUser()
    setNavbarInfo({
      ...navbarInfo,
      nome: '',
      img_perfil: '',
      logado: false,
      roleUser: ''
    })
  }

  return (

    <div className="navbar">
      <nav className="container">
        <Link to="/home" onClick={() => setMenuOpen(false)}><LogoBranca /></Link>
        <ul>
          <div onClick={() => toggleMenu()} id="info-user-navbar">
            <ChevronDown color="#fff" />
            <span>{navbarInfo.nome}</span>
            <div>
              {
                navbarInfo.img_perfil ?
                  <img src={navbarInfo.img_perfil} alt="imagem de perfil" /> : ''
              }
            </div>
          </div>

          <ul id="menu-navbar" className={menuOpen ? "show-menu" : ''} onClick={() => setMenuOpen(false)}>
            <Link to="/meus-dados"><User /> Meus dados</Link>
            <Link to="/meu-plano"><DollarSign /> Meu Plano</Link>
            <Link to="/ajuda"><HelpCircle /> Ajuda/Suporte</Link>
            <Link to="/login"><LogOut /> <span onClick={() =>logout() }>Sair</span></Link>
          </ul>
        </ul>
      </nav>
    </div>
  )
}

export default Navbar