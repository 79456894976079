//@ts-nocheck
import React from 'react'
import { PlusCircle, X } from 'react-feather';
import Select, { components } from 'react-select'

import './styles.css'

const customStyles = {
  option: (provided: any, state: any) => ({
    padding: 15,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: "rgb(238, 238, 238)"
    },
    backgroundColor: state.isSelected ? "rgb(238, 238, 238)" : "",
    fontSize: 16,
    zIndex: 2
  }),
  control: (provided: any, state: any) => ({
    height: '48px',
    display: 'flex',
    background: '#FFFFFF',
    boxShadow: '0px 1px 2px rgba(52, 60, 88, 0.4)',
    borderRadius: '6px',
    padding: '0 5px',
    cursor: "pointer",
    fontSize: 16,
    border: state.isFocused ? '2px solid var(--color-button)' : '2px solid transparent'
  }),
  placeholder: (provided: any, state: any) => ({
    color: "#74799C",
    fontSize: 16,
    display: state.isFocused ? 'none' : ''
  })
}

const DropdownIndicator = () => {
  return (
    <div className="arrow-select"></div>
  );
};

const IndicatorSeparator = () => {
  return <span style={{
    display: 'none'
  }} />;
};

interface DropdownProps {
  placeholder: string;
  noOptionsMsg: string;
  options: {
    label: string;
    value: string;
  }[];
  value?: {
    label: string;
    value: string;
  };
  onChange: Function;
  iconExist?: boolean;
}

function Dropdown({ placeholder, noOptionsMsg, options, value, onChange, iconExist }: DropdownProps) {

  const NoOptionsMessage = () => {
    return (
      <button className="button-noOptions-select">
        {
          iconExist ? <PlusCircle /> : ''
        }
        {noOptionsMsg}
      </button>
    );
  };

  const Option = (props: any) => {
    return (
      <components.Option {...props} className="option-select">
        {props.label}
        {
          iconExist ? <X color="#C9CBDB" /> : ''
        }
      </components.Option>
    );
  }

  return (
    <Select
      styles={customStyles}
      components={{ DropdownIndicator, IndicatorSeparator, NoOptionsMessage, Option }}
      options={options}
      className='react-select-container'
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange({
        label: e?.label,
        value: e?.value
      })}
    />
  )
}

export default Dropdown
