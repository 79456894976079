import React, {useState} from 'react'
import { useHistory } from "react-router-dom";
import './styles.css'

// Markdown
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import ReactMarkdown from "react-markdown";

// Componentes
import Button from '../../components/Button';
import {useDropzone} from 'react-dropzone';


export default function BlogCreateNewPost() {

  const [pageSelected, setPageSelected] = useState('admin-assinantes');

  const [postTitle, setPostTitle] = useState('')
  const [postResume, setPostResume] = useState('')
  const [postText, setPostText] = useState('')

  const [postImg, setPostImg] = useState<FileList>()
  const [previewImages, setPreviewImages] = useState<any>('')

  const [selectedTab, setSelectedTab] = useState<"write" | "preview">('write');

  let history = useHistory();

  // Inincia o DropZone
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    maxFiles: 1,
    onDrop: acceptedFiles => {
      setPreviewImages(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });

  function ChangePage(router: string){
    history.push(router)
  } 

  // Cria a postagem
  function CreatePost() {
    let error: boolean = false
    const imgTypeAccept: Array<String> = ['image/png', 'image/jpeg']
    
    // Verifica se possui algum arquivo selecionado
    if(postImg === undefined){
      return
    }

    const myPostImg = postImg![0]

    // Verifica o tipo de arquivo
    if( imgTypeAccept.indexOf(myPostImg!.type) === -1 ){
      error = true
    }

    // Verifia se existe um Título
    if (postTitle === ''){
      error = true
    }

    // Verifica se existe um Resumo
    if (postResume === ''){
      error = true
    }

    // Verifica se existe um texto
    if (postText === ''){
      error = true
    }
    

    if(error){
      return
    }

  }

  // Apresenta um preview das imagens ou pdf
  let myImagesPreview

  if(previewImages[0] !== undefined){
    myImagesPreview = (
      <img
        src={previewImages[0].preview}
        style={{width: '100%', height: '250px'}}
      />
    )
  }

  return (
    <div  className="page-blog-new-post">
      <div className="navbar-sec">
        <nav className="container">
          <ul>
          <li
              className={''}
              onClick={() => {
                ChangePage("/admin/blog")
              }}
            >Minhas Postagens</li>
            <li
              className={ "selected"}
              onClick={() => {
                ChangePage("/admin/blog/nova-postagem");
              }}
            >+ Nova Postagem</li>
          </ul>

          <span>Olá Anderson!</span>
        </nav>
      </div> 
      <main>
        <header>
          <p> Criar nova postagem </p>
        </header>
        <section style={{ paddingBottom: '90px'}} >

          <div className='page-blog-new-post-container' >

            <div className='page-blog-new-post-inputContainer' >
              <label> Titulo da postagem: </label>
              <input value={postTitle} onChange={e => setPostTitle(e.target.value)} type='text' />
            </div>

            <div className='page-blog-new-post-inputContainer' >
              <label> Resumo da postagem: </label>
              <textarea value={postResume} onChange={e => setPostResume(e.target.value)} />
            </div>

            <div className='page-blog-new-post-inputContainer' style={{height: 'auto', minHeight: '200px'}} >
              <label> Imagem de capa da postagem: </label>

              <div {...getRootProps({isDragActive, isDragAccept, isDragReject}) } className='page-blog-new-post-inputContainer-image' >
                <input {...getInputProps()} />
                  {
                    previewImages.length !== 0 ? (
                      null
                    ) : (
                      <p>Selecione ou arraste até 1 arquivo por vez (.jpeg ou .png)</p>
                    )
                  }
                  <div>
                      {previewImages && myImagesPreview}
                  </div>
              </div>
                  {
                    previewImages && (
                      <Button onClick={() => setPreviewImages('')} style={{backgroundColor: 'red', paddingTop: '10px', paddingBottom: '10px', width: '180px'}}>
                        Remover imagem
                      </Button>
                    )
                  }
            </div>

            <div style={{marginTop: '20px', marginBottom: '40px'}} >
              <ReactMde 
                value={postText}
                onChange={setPostText}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                generateMarkdownPreview={(markdown) =>
                  Promise.resolve(<ReactMarkdown source={markdown} />)
                }
                childProps={{
                  writeButton: {
                    tabIndex: -1
                  }
                }}
              />
            </div>
            <Button onClick={() => CreatePost()} >
              Criar Postagem
            </Button>
          </div>

        </section>
      </main>

    </div>
  )
}
