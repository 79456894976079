//@ts-nocheck
import React from 'react'
import { ArrowLeft, ArrowRight } from 'react-feather'

import './styles.css'

interface PaginationProps {
  totalPages?: number;
}

function Pagination({ totalPages }: PaginationProps) {
  return (
    <div className="pagination">
      <span>1-10 de 233 itens</span>


      <div className="block-pages">
        <div className="arrow"><ArrowLeft /></div>
        <div className="page active">1</div>
        <div className="page">2</div>
        <div className="page">3</div>
        <div className="page">4</div>
        <div className="page">5</div>
        <div className="page">6</div>
        <div className="page">7</div>
        <div className="page">8</div>
        <div className="page">9</div>
        <div className="page">...</div>
        <div className="page">15</div>
        <div className="arrow"><ArrowRight /></div>
      </div>
    </div>
  )
}

export default Pagination
