//@ts-nocheck
import React from 'react';
import { Svg, G, Path, Defs, ClipPath, Rect, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  background: {
    marginTop: 100,
    position: "absolute"
  }
});
interface BackgroundEDAProps {
  color: string;
}

function BackgroundEDA({ color }: BackgroundEDAProps) {
  return (
    <Svg width="337" height="559" viewBox="0 0 337 559" style={styles.background}>
      <G opacity={0.1} >
        <Path opacity={0.08} d="M-9.76888 542.378C-2.32728 545.285 5.26306 547.818 12.9564 549.973C63.7844 564.205 119.123 561.617 168.23 542.144C210.886 525.232 247.461 490.691 273.408 453.556C293.767 424.416 309.359 390.714 320.133 356.816C339.126 297.056 343.586 230.31 325.703 169.723C309.085 113.419 268.634 55.2886 209.877 38.1565C183.285 30.4008 154.367 31.0363 128.045 39.5829C104.256 47.3066 84.1618 65.6822 63.9423 80.1239C49.0797 90.7392 33.0981 95.9828 18.5078 80.6931C2.41868 63.8307 -4.43711 31.9437 -10 10L-5.34786 159.441C15.398 166.292 34.1851 179.895 45.9813 198.401C59.1071 218.996 61.5785 247.541 60.2947 271.384C59.741 281.661 58.2307 291.888 55.7524 301.877C47.0362 337.026 27.105 371.294 -5.25632 389.332L-9.76888 542.378Z" fill={color} />
        <G clip-path="url(#clip0)">
          <Path opacity={0.15} d="M-7.28369 142.062C12.3478 148.571 30.1258 161.495 41.2884 179.077C53.7091 198.644 56.0478 225.764 54.833 248.417C54.3089 258.181 52.8798 267.897 50.5347 277.388C42.2866 310.782 23.426 343.34 -7.19708 360.477" stroke={color} strokeWidth="3" stroke-miterlimit="10" />
          <Path opacity={0.15} d="M-11.686 0.080368C-6.42195 20.9287 0.0655904 51.2241 15.2905 67.2447C29.0971 81.7735 44.2224 76.7894 58.2845 66.704C77.418 52.9831 96.4324 35.5226 118.944 28.1866C143.852 20.0666 171.217 19.465 196.381 26.8314C251.982 43.1106 290.259 98.3392 305.985 151.831C322.905 209.394 318.687 272.808 300.714 329.585C290.519 361.791 275.764 393.811 256.499 421.496C231.947 456.777 197.336 489.592 156.97 505.662C110.503 524.161 58.1351 526.621 10.0373 513.1C2.75502 511.052 -4.42545 508.646 -11.4673 505.884" stroke={color} strokeWidth="3" stroke-miterlimit="10" />
        </G>
      </G>
      <Defs>
        <ClipPath id="clip0">
          <Rect width="329" height="522" fill="white" transform="translate(-12)" x="1" y="1" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export default BackgroundEDA;