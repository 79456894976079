//@ts-nocheck
import React from 'react'

import './styles.css'

interface ModalProps {
  setModal: Function; // usada para fechar ou abrir o modal
  position?: string; // determina a posição do modal, default é centralizado no meio
  children: React.ReactNode;
}

function Modal({ setModal, position = "center", children }: ModalProps) {

  window.addEventListener('click', (e) => {
    if ((e.target as HTMLElement).id === "modal-container") {
      setModal(false);
    }
  })

  window.addEventListener('keyup', (e) => {
    if (e.key === "Escape") {
      setModal(false);
    }
  })

  return (
    <div className="modal-container" id="modal-container">
      <div className="modal"
        style={{
          height: position !== "center" ? "100%" : '',
          width: position !== "center" ? "50%" : '',
          left: position === "right" ? "50%" : position === "left" ? "0" : '',
          margin: position !== "center" ? "0" : "4% 10%",
          borderRadius: position === "center" ? "24px" : 0,
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default Modal