//@ts-nocheck
import React from 'react'

import './styles.css'

interface CheckMarkProps {
  label?: string;
  check: boolean;
  setCheck: Function;
}

function CheckMark({ label, check, setCheck }: CheckMarkProps) {
  return (
    <div className="checkmark-padrao" onClick={() => setCheck()}>
      <span className={ check ? "checked" : ''}></span>
      <label>{label}</label>
    </div>
  )
}

export default CheckMark