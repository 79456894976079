//@ts-nocheck
import React, { useState } from 'react'
import { Cloud, Home, PieChart, UserPlus, Clipboard } from 'react-feather'
import { Link } from 'react-router-dom'
import Button from '../Button';
import ModalInfo from '../ModalInfo';

import './styles.css'

interface NavbarSecProps {
  page: string;
}

function NavbarSec({ page }: NavbarSecProps) {
  const [modalInfoConsulta, setModalInfoConsulta] = useState(false);

  return (
    <div className="container-home">
      <div className="container-dashboard">
        <div className="left-elements">
          <Link to="/home" className={page === "home" ? "target-pag" : ''} ><Home style={{ marginRight: "8px" }} />Início</Link>
          <Link to="/minha-nuvem" className={page === "minha-nuvem" ? "target-pag" : ''} ><Cloud style={{ marginRight: "8px" }} />Minha Nuvem</Link>
          <Link to="/estatisticas" className={page === "estatisticas" ? "target-pag" : ''} ><PieChart style={{ marginRight: "8px" }} />Estatísticas</Link>
        </div>
        <div className="right-elements">
          <div className="span-consult" onClick={() => setModalInfoConsulta(true)}>
            <UserPlus style={{ marginRight: "8px" }} />
            Paciente para consulta
          </div>
          <Link to="/novo-laudo" className="btn-laudo">
            <Clipboard style={{ marginRight: "8px" }} />
            Gerar Laudo
          </Link>
        </div>
      </div>

      { modalInfoConsulta &&
        <ModalInfo
          setModal={setModalInfoConsulta}
          titulo="Selecione uma opção para continuar"
          info="Para registrar uma consulta você pode cadastrar um novo paciente ou consultar os que já estão cadastrados na nuvem"
        >
          <div>
            <Link to="/paciente-cadastrado-consulta">
              <Button width="large">
                Paciente cadastrado
            </Button>
            </Link>
            <Link to="/novo-paciente-consulta">
              <Button width="large">
                Novo paciente
            </Button>
            </Link>
          </div>
        </ModalInfo>
      }
    </div>
  )
}

export default NavbarSec
