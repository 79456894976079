import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ArrowRightCircle, ChevronsDown, Plus, Trash2 } from 'react-feather'
import Background from '../../../assets/Background'
import Button from '../../../components/Button'
import Checkbox from '../../../components/Checkbox'
import CheckMark from '../../../components/CheckMark'
import Footer from '../../../components/Footer'
import Header from '../../../components/Header'
import Input from '../../../components/Input'
import Radio from '../../../components/Radio'
import SelectPadrao from '../../../components/SelectPadrao'
import Dropdown from '../../../components/Dropdown'

import { cricofaringeoDefault, fluxoCricofaringeo, esofagoDefault, fluxoEsofago, fluxoTerapeuticaEsofago, fluxoDuodeno, duodenoDefault, fluxoTerapeuticaDuodeno, fluxoEstomago, fluxoEstomagoDiagnosticosX, estomagoDefault, fluxoTerapeuticaEstomago } from './FluxoLaudos'

import './styles.css'

import { LaudoContext } from '../../../utils/LaudoProvider'

function LaudoPersonalizado() {
  const [laudoCtx, setLaudoCtx] = useContext(LaudoContext);

  const [sedoanalgesia, setSedoanalgesia] = useState(laudoCtx.laudo.sedoanalgesia ? laudoCtx.laudo.sedoanalgesia : "");

  const [midazolan, setMidazolan] = useState(laudoCtx.laudo.midazolan ? true : false);
  const [MGmidazolan, setMGmidazolan] = useState(laudoCtx.laudo.midazolan);
  const [fentanil, setFentanil] = useState(laudoCtx.laudo.fentanil ? true : false);
  const [MCGfentanil, setMCGfentanil] = useState(laudoCtx.laudo.fentanil);
  const [propofol, setPropofol] = useState(laudoCtx.laudo.propofol ? true : false);
  const [MGpropofol, setMGPropofol] = useState(laudoCtx.laudo.propofol);

  const [passagemNoCricofaringeo, setPassagemNoCricofaringeo] = useState(typeof laudoCtx.laudo.cricofaringeo === "string" ? laudoCtx.laudo.cricofaringeo : "passagem-no-cricofaríngeo-alterado");
  const [esofago, setEsofago] = useState(typeof laudoCtx.laudo.esofago === "string" ? laudoCtx.laudo.esofago : "esofago-alterado");
  const [estomago, setEstomago] = useState(typeof laudoCtx.laudo.estomago === "string" ? laudoCtx.laudo.estomago : "estomago-alterado");
  const [duodeno, setDuodeno] = useState(typeof laudoCtx.laudo.duodeno === "string" ? laudoCtx.laudo.duodeno : "duodeno-alterado");
  const [notas, setNotas] = useState<string[]>(typeof laudoCtx.laudo.notas === "string" ? ["", ""] : laudoCtx.laudo.notas);

  const [diagnosticoDuodeno, setDiagnosticoDuodeno] = useState({ value: "Diagnóstico", label: "Diagnóstico" });
  const [terapeuticaDuodeno, setTerapeuticaDuodeno] = useState({ value: "Terapêutica/Procedimento", label: "Terapêutica/Procedimento" });

  // Diagnóstico duodeno : ÚLCERA DUODENAL
  const [duodenoUlceraAtividade, setDuodenoUlceraAtividade] = useState({ value: "Atividade (SAKITA)", label: "Atividade (SAKITA)" });
  const [duodenoUlceraFormato, setDuodenoUlceraFormato] = useState({ value: "Formato", label: "Formato" });
  const [duodenoUlceraBordos, setDuodenoUlceraBordos] = useState({ value: "Bordos", label: "Bordos" });
  const [duodenoUlceraFundo, setDuodenoUlceraFundo] = useState({ value: "Fundo", label: "Fundo" });
  const [duodenoUlceraPrimeiroTamanho, setDuodenoUlceraPrimeiroTamanho] = useState("");
  const [duodenoUlceraSegundoTamanho, setDuodenoUlceraSegundoTamanho] = useState("");
  const [duodenoUlceraConvPregas, setDuodenoUlceraConvPregas] = useState({ value: "Convergência de pregas", label: "Convergência de pregas" });
  const [duodenoUlceraLocalizAnatomica, setDuodenoUlceraLocalizAnatomica] = useState({ value: "Localização anatômica", label: "Localização anatômica" });
  const [duodenoUlceraBulboOuSegundaPorcaoDuodenal, setDuodenoUlceraBulboOuSegundaPorcaoDuodenal] = useState("Bulbo");

  // Diagnóstico duodeno : CICATRIZ DE ÚLCERA DUODENAL
  const [duodenoCicatrizAtividade, setDuodenoCicatrizAtividade] = useState({ value: "Atividade (SAKITA)", label: "Atividade (SAKITA)" });
  const [duodenoCicatrizFormato, setDuodenoCicatrizFormato] = useState({ value: "Formato", label: "Formato" });
  const [duodenoCicatrizBordos, setDuodenoCicatrizBordos] = useState({ value: "Bordos", label: "Bordos" });
  const [duodenoCicatrizTamanho, setDuodenoCicatrizTamanho] = useState("");
  const [duodenoCicatrizConvPregas, setDuodenoCicatrizConvPregas] = useState({ value: "Convergência de pregas", label: "Convergência de pregas" });
  const [duodenoCicatrizLocalizAnatomica, setDuodenoCicatrizLocalizAnatomica] = useState({ value: "Localização anatômica", label: "Localização anatômica" });
  const [duodenoCicatrizBulboOuSegundaPorcaoDuodenal, setDuodenoCicatrizBulboOuSegundaPorcaoDuodenal] = useState("Bulbo");

  const [terapeuticaEstomago, setTerapeuticaEstomago] = useState({ value: "Terapêutica/Procedimento", label: "Terapêutica/Procedimento" });
  const [diagnosticoEstomago, setDiagnosticoEstomago] = useState({ value: "Diagnóstico", label: "Diagnóstico" });
  // Diagnóstico estomago : DIAGNÓSTICOS X (LISTA)
  const [estomagoDiagnosticosXLocalizAnatomica, setEstomagoDiagnosticosXLocalizAnatomica] = useState({ value: "Localização anatômica", label: "Localização anatômica" });
  const [estomagoDiagnosticosXCorpoOuAntro, setEstomagoDiagnosticosXCorpoOuAntro] = useState("Corpo");
  const [estomagoDiagnosticosXLocalizEspecifica, setEstomagoDiagnosticosXLocalizEspecifica] = useState({ value: "Localização específica", label: "Localização específica" });
  // Diagnóstico estomago : ÚLCERA GÁSTRICA
  const [estomagoUlceraFormato, setEstomagoUlceraFormato] = useState({ value: "Formato", label: "Formato" });
  const [estomagoUlceraAtividade, setEstomagoUlceraAtividade] = useState({ value: "Atividade (SAKITA)", label: "Atividade (SAKITA)" });
  const [estomagoUlceraBordos, setEstomagoUlceraBordos] = useState({ value: "Bordos", label: "Bordos" });
  const [estomagoUlceraFundo, setEstomagoUlceraFundo] = useState({ value: "Fundo", label: "Fundo" });
  const [estomagoUlceraPrimeiroTamanho, setEstomagoUlceraPrimeiroTamanho] = useState("");
  const [estomagoUlceraSegundoTamanho, setEstomagoUlceraSegundoTamanho] = useState("");
  const [estomagoUlceraConvPregas, setEstomagoUlceraConvPregas] = useState({ value: "Convergência de pregas", label: "Convergência de pregas" });
  const [estomagoUlceraLocalizAnatomica, setEstomagoUlceraLocalizAnatomica] = useState({ value: "Localização anatômica", label: "Localização anatômica" });
  const [estomagoUlceraCorpoOuAntro, setEstomagoUlceraCorpoOuAntro] = useState("Corpo");
  const [estomagoUlceraLocalizEspecifica, setEstomagoUlceraLocalizEspecifica] = useState({ value: "Localização específica", label: "Localização específica" });
  // Diagnóstico estomago : CICATRIZ DE ÚLCERA GÁSTRICA
  const [estomagoCicatrizAtividade, setEstomagoCicatrizAtividade] = useState({ value: "Atividade (SAKITA)", label: "Atividade (SAKITA)" });
  const [estomagoCicatrizFormato, setEstomagoCicatrizFormato] = useState({ value: "Formato", label: "Formato" });
  const [estomagoCicatrizBordos, setEstomagoCicatrizBordos] = useState({ value: "Bordos", label: "Bordos" });
  const [estomagoCicatrizTamanho, setEstomagoCicatrizTamanho] = useState("");
  const [estomagoCicatrizConvPregas, setEstomagoCicatrizConvPregas] = useState({ value: "Convergência de pregas", label: "Convergência de pregas" });
  const [estomagoCicatrizLocalizAnatomica, setEstomagoCicatrizLocalizAnatomica] = useState({ value: "Localização anatômica", label: "Localização anatômica" });
  const [estomagoCicatrizCorpoOuAntro, setEstomagoCicatrizCorpoOuAntro] = useState("Corpo");
  const [estomagoCicatrizLocalizEspecifica, setEstomagoCicatrizLocalizEspecifica] = useState({ value: "Localização específica", label: "Localização específica" });

  const [terapeuticaEsofago, setTerapeuticaEsofago] = useState({ value: "Terapêutica/Procedimento", label: "Terapêutica/Procedimento" });
  const [diagnosticoEsofago, setDiagnosticoEsofago] = useState({ value: "Diagnóstico", label: "Diagnóstico" });
  // Diagnóstico esofago : VARIZES ESOFÁGICAS
  const [esofagoVarizesNumTotal, setEsofagoVarizesNumTotal] = useState({ value: "Número total de varizes", label: "Número total de varizes" });
  const [esofagoVarizesQtdCalibreFino, setEsofagoVarizesQtdCalibreFino] = useState({ value: "Fino", label: "Fino" });
  const [esofagoVarizesQtdCalibreMedio, setEsofagoVarizesQtdCalibreMedio] = useState({ value: "Médio", label: "Médio" });
  const [esofagoVarizesQtdCalibreGrosso, setEsofagoVarizesQtdCalibreGrosso] = useState({ value: "Grosso", label: "Grosso" });
  const [esofagoVarizesFormato, setEsofagoVarizesFormato] = useState({ value: "Formato", label: "Formato" });
  const [esofagoVarizesCor, setEsofagoVarizesCor] = useState({ value: "Cor", label: "Cor" });
  const [esofagoVarizesSinaisCorVermelha, setEsofagoVarizesSinaisCorVermelha] = useState({ value: "Sinais da cor vermelha", label: "Sinais da cor vermelha" });
  const [esofagoVarizesLocalizacao, setEsofagoVarizesLocalizacao] = useState({ value: "Localização", label: "Localização" });
  const [existePontoDeRuptura, setExistePontoDeRuptura] = useState(false);
  const [esofagoVarizesPontoRuptura, setEsofagoVarizesPontoRuptura] = useState({ value: "Ponto de ruptura", label: "Ponto de ruptura" });

  // LAUDO CRICOFARINGEO ---------------------------------------------------------
  const [diagnosticoCricofaringeo, setDiagnosticoCricofaringeo] = useState({ value: "Diagnóstico", label: "Diagnóstico" });

  const [laudoCricofaringeo, setLaudoCricofaringeo] = useState(
    typeof laudoCtx.laudo.cricofaringeo !== "string" ? laudoCtx.laudo.cricofaringeo :
      [{
        descricao: 'Passagem do aparelho sob visão direta pelo cricofaríngeo sem obstáculos ou alterações.',
        del: [""],
        diagnostico: "Passagem do aparelho sob visão direta pelo cricofaríngeo sem obstáculos ou alterações.",
        bx: false
      }]
  );

  function addDiagnosticoCricofaringeo() {
    const diagnostico = diagnosticoCricofaringeo.value;
    if (diagnostico !== "Diagnóstico") {
      addDiagnosticoNormal(cricofaringeoDefault, fluxoCricofaringeo, laudoCricofaringeo, setLaudoCricofaringeo, diagnostico, setDiagnosticoCricofaringeo);
    }
  }

  // LAUDO ESOFAGO ---------------------------------------------------------------
  const [laudoEsofago, setLaudoEsofago] = useState(
    typeof laudoCtx.laudo.esofago !== "string" ? laudoCtx.laudo.esofago.diagnostico :
      [
        {
          descricao: 'Calibre e distensibilidade preservados',
          del: [""],
          diagnostico: "Calibre e distensibilidade preservados",
          bx: false
        },
        {
          descricao: 'Ausência de varizes',
          del: [""],
          diagnostico: "Ausência de varizes",
          bx: false
        },
        {
          descricao: 'Relevo mucoso íntegro em todo trajeto',
          del: [""],
          diagnostico: "Relevo mucoso íntegro em todo trajeto",
          bx: false
        },
        {
          descricao: 'A transição esofagogástrica (TEG) situa-se ao nível do pinçamento diafragmático',
          del: [""],
          diagnostico: "A transição esofagogástrica (TEG) situa-se ao nível do pinçamento diafragmático",
          bx: false
        },
        {
          descricao: 'Passagem fácil pelo cárdia',
          del: [""],
          diagnostico: "Passagem fácil pelo cárdia",
          bx: false
        },
      ]
  );

  function addDiagnosticoEsofago() {
    const diagnostico = diagnosticoEsofago.value;
    if (diagnostico !== "Diagnóstico") {
      if (diagnostico !== "VARIZES ESOFÁGICAS") {
        addDiagnosticoNormal(esofagoDefault, fluxoEsofago, laudoEsofago, setLaudoEsofago, diagnostico, setDiagnosticoEsofago);
      }
      else {
        // montando diagnostico e descricao
        let Anumero = `${esofagoVarizesNumTotal.value} cord${esofagoVarizesNumTotal.value === "1" ? "ão" : "ões"} varicoso${esofagoVarizesNumTotal.value === "1" ? "" : "s"}`;
        let espessuraFino = esofagoVarizesQtdCalibreFino.value;
        let espessuraMedio = esofagoVarizesQtdCalibreMedio.value;
        let espessuraGrosso = esofagoVarizesQtdCalibreGrosso.value;
        let Bespessura = `${espessuraMedio === "Médio" && espessuraGrosso === "Grosso" ?
          `${espessuraFino} de fino calibre` : espessuraFino === "Fino" && espessuraGrosso === "Grosso" ? `${espessuraMedio} de médio calibre` : espessuraFino === "Fino" && espessuraMedio === "Médio" ? `${espessuraGrosso} de médio calibre` : espessuraGrosso === "Grosso" ? `${espessuraFino} de fino e ${espessuraMedio} de médio calibre` : espessuraMedio === "Médio" ? `${espessuraFino} de fino e ${espessuraGrosso} de grosso calibre` : espessuraFino === "Fino" ? `${espessuraMedio} de médio e ${espessuraGrosso} de grosso calibre` : `${espessuraFino} de fino, ${espessuraMedio} de médio e ${espessuraGrosso} de grosso calibre`}`;
        let Cformato = `${esofagoVarizesFormato.value.toLocaleLowerCase()}`;
        let Dcor = `${esofagoVarizesCor.value.toLocaleLowerCase()}`;
        let EsinaisCorVermelha = `${esofagoVarizesSinaisCorVermelha.value.toLocaleLowerCase()}`;
        let Flocalizacao = `${esofagoVarizesLocalizacao.value.toLocaleLowerCase()}`;
        let GpontoDeRuptura = `${existePontoDeRuptura ? esofagoVarizesPontoRuptura.value.toLocaleLowerCase() : ""}`

        let descricao = `Presença de ${Anumero} (${Bespessura}) ${Cformato} ${Dcor} ${EsinaisCorVermelha} ${Flocalizacao} ${GpontoDeRuptura}`;

        let BespessuraDiagnostico = `${espessuraMedio === "Médio" && espessuraGrosso === "Grosso" ?
          `fino calibre` : espessuraFino === "Fino" && espessuraGrosso === "Grosso" ? `médio calibre` : espessuraFino === "Fino" && espessuraMedio === "Médio" ? `médio calibre` : espessuraGrosso === "Grosso" ? `fino e médio calibre` : espessuraMedio === "Médio" ? `fino e grosso calibre` : espessuraFino === "Fino" ? `médio e grosso calibre` : `fino, médio e grosso calibre`}`;

        let diagnosticoLaudo = `${esofagoVarizesNumTotal.value === "1" ? "VARIZ ESOFÁGICA" : "VARIZES ESOFÁGICAS"} DE ${BespessuraDiagnostico.toLocaleUpperCase()} ${EsinaisCorVermelha.toLocaleUpperCase()} ${GpontoDeRuptura.toLocaleUpperCase()}`

        if (!existeDiagnosticoNoLaudo(laudoEsofago, diagnosticoLaudo)) {
          addDiagnostico(esofagoDefault, laudoEsofago, setLaudoEsofago, {
            descricao,
            diagnostico: diagnosticoLaudo,
            del: ["1"],
            bx: false
          })

          setDiagnosticoEsofago({ value: "Diagnóstico", label: "Diagnóstico" });
          setEsofagoVarizesNumTotal({ value: "Número total de varizes", label: "Número total de varizes" });
          setEsofagoVarizesQtdCalibreFino({ value: "Fino", label: "Fino" });
          setEsofagoVarizesQtdCalibreMedio({ value: "Médio", label: "Médio" });
          setEsofagoVarizesQtdCalibreGrosso({ value: "Grosso", label: "Grosso" });
          setEsofagoVarizesFormato({ value: "Formato", label: "Formato" });
          setEsofagoVarizesCor({ value: "Cor", label: "Cor" });
          setEsofagoVarizesSinaisCorVermelha({ value: "Sinais da cor vermelha", label: "Sinais da cor vermelha" });
          setEsofagoVarizesLocalizacao({ value: "Localização", label: "Localização" });
          setExistePontoDeRuptura(false);
          setEsofagoVarizesPontoRuptura({ value: "Ponto de ruptura", label: "Ponto de ruptura" });
        }
      }
    }
  }

  const [laudoTerapeuticaEsofago, setLaudoTerapeuticaEsofago] = useState<{
    diagnostico: string;
    descricao: string;
    bx: boolean;
  }[]>(typeof laudoCtx.laudo.esofago !== "string" ? laudoCtx.laudo.esofago.terapeutica : [])

  function addTerapeuticaEsofago() {
    addTerapeutica(terapeuticaEsofago.value, setTerapeuticaEsofago, laudoTerapeuticaEsofago, setLaudoTerapeuticaEsofago, fluxoTerapeuticaEsofago);
  }

  // LAUDO ESTOMAGO
  const [laudoEstomago, setLaudoEstomago] = useState(
    typeof laudoCtx.laudo.estomago !== "string" ? laudoCtx.laudo.estomago.diagnostico :
      [
        {
          descricao: 'Anatomia preservada, boa distensibilidade',
          del: [""],
          diagnostico: "Anatomia preservada, boa distensibilidade",
          bx: false
        },
        {
          descricao: 'Lago mucoso claro em volume habitual',
          del: [""],
          diagnostico: "Lago mucoso claro em volume habitual",
          bx: false
        },
        {
          descricao: 'À retrovisão, hiato justo ao aparelho e cárdia de aspecto normal',
          del: [""],
          diagnostico: "À retrovisão, hiato justo ao aparelho e cárdia de aspecto normal",
          bx: false
        },
        {
          descricao: 'Fundo gástrico sem alterações',
          del: [""],
          diagnostico: "Fundo gástrico sem alterações",
          bx: false
        },
        {
          descricao: 'Região do corpo com pregueado mucoso normotrófico, sem erosões, úlceras ou pólipos',
          del: [""],
          diagnostico: "Região do corpo com pregueado mucoso normotrófico, sem erosões, úlceras ou pólipos",
          bx: false
        },
        {
          descricao: 'Incisura angularis sem lesões ',
          del: [""],
          diagnostico: "Incisura angularis sem lesões ",
          bx: false
        },
        {
          descricao: 'Antro com mucosa íntegra.',
          del: [""],
          diagnostico: "Antro com mucosa íntegra.",
          bx: false
        },
        {
          descricao: 'Piloro pérvio sem deformidades',
          del: [""],
          diagnostico: "Piloro pérvio sem deformidades",
          bx: false
        },
      ]
  );

  function addDiagnosticoEstomago() {
    const diagnostico = diagnosticoEstomago.value;
    if (diagnostico !== "Diagnóstico") {
      if (diagnostico !== "ÚLCERA GÁSTRICA" && diagnostico !== "CICATRIZ DE ÚLCERA GÁSTRICA" && !isDiagnoticoXestomago()) {
        addDiagnosticoNormal(estomagoDefault, fluxoEstomago, laudoEstomago, setLaudoEstomago, diagnostico, setDiagnosticoEstomago);
      }
      else if (diagnostico === "ÚLCERA GÁSTRICA") {
        // montando diagnostico e descricao
        let Aformato = `${estomagoUlceraFormato.value.toLocaleLowerCase()}`;
        let Batividade = `${estomagoUlceraAtividade.value.toLocaleLowerCase()}`;
        let Cbordos = `${estomagoUlceraBordos.value.toLocaleLowerCase()}`;
        let Dfundo = `${estomagoUlceraFundo.value.toLocaleLowerCase()}`;
        let Etamanho = `medindo ${estomagoUlceraPrimeiroTamanho}mm x ${estomagoUlceraSegundoTamanho}mm`;
        let FconvPregas = `${estomagoUlceraConvPregas.value.toLocaleLowerCase()}`;
        let GlocalizacaoAnatomica = `${estomagoUlceraLocalizAnatomica.value.toLocaleLowerCase()}`;
        let HlocalizacaoEspecifica = `${estomagoUlceraCorpoOuAntro.toLocaleLowerCase()}`;
        let HaLocalizacaoEspecifica = `${estomagoUlceraLocalizEspecifica.value.toLocaleLowerCase()}`;

        let descricao = `Nota-se úlcera ${Aformato}, ${Batividade}, bordos ${Cbordos}, fundo ${Dfundo}, ${Etamanho}, ${FconvPregas}, localizada em ${GlocalizacaoAnatomica} de ${HlocalizacaoEspecifica} ${HaLocalizacaoEspecifica}`;

        let BatividadeDiagnostico = `${Batividade.toLocaleUpperCase()} ${Batividade === "em fase inicial de cicatrização" ? "(SAKITA H1)" : Batividade === "em fase final de cicatrização" ? "(SAKITA H2)" : ""}`;

        let DfundoDiagnostico = `${Dfundo === "recoberto por hematina" ? "(FORREST IIc)" : Dfundo === "recoberto por fibrina e hematina" ? "(FORREST IIc)" : Dfundo === "com sangramento em babação" ? "(FORREST Ib)" : Dfundo === "com sangramento ativo em jato" ? "(FORREST Ia)" : Dfundo === "com vaso sanguíneo sem sangramento" ? "(FORREST IIa)" : Dfundo === "com coágulo aderido sem sangramento ativo" ? "(FORREST IIb)" : Dfundo === "de base limpa" ? "(FORREST III)" : ""}`;

        let diagnosticoLaudo = `ÚLCERA ${BatividadeDiagnostico} EM ${HlocalizacaoEspecifica.toLocaleUpperCase()} ${DfundoDiagnostico}`;

        if (!existeDiagnosticoNoLaudo(laudoEstomago, diagnosticoLaudo)) {
          addDiagnostico(estomagoDefault, laudoEstomago, setLaudoEstomago, {
            descricao,
            diagnostico: diagnosticoLaudo,
            del: [""],
            bx: false
          })

          setDiagnosticoEstomago({ value: "Diagnóstico", label: "Diagnóstico" });
          setEstomagoUlceraAtividade({ value: "Atividade (SAKITA)", label: "Atividade (SAKITA)" });
          setEstomagoUlceraFormato({ value: "Formato", label: "Formato" });
          setEstomagoUlceraBordos({ value: "Bordos", label: "Bordos" });
          setEstomagoUlceraFundo({ value: "Fundo", label: "Fundo" });
          setEstomagoUlceraPrimeiroTamanho("");
          setEstomagoUlceraSegundoTamanho("");
          setEstomagoUlceraConvPregas({ value: "Convergência de pregas", label: "Convergência de pregas" });
          setEstomagoUlceraLocalizAnatomica({ value: "Localização anatômica", label: "Localização anatômica" });
          setEstomagoUlceraCorpoOuAntro("Corpo");
          setEstomagoUlceraLocalizEspecifica({ value: "Localização específica", label: "Localização específica" });
        }
      }
      else if (diagnostico === "CICATRIZ DE ÚLCERA GÁSTRICA") {
        // montando diagnostico e descricao
        let Aatividade = `${estomagoCicatrizAtividade.value === "Cicatriz vermelha (SAKITA S1)" ? "vermelha" : "branca"}`;
        let Bformato = `${estomagoCicatrizFormato.value.toLocaleLowerCase()}`;
        let Cbordos = `${estomagoCicatrizBordos.value.toLocaleLowerCase()}`;
        let Etamanho = `medindo ${estomagoCicatrizTamanho}mm`;
        let FconvPregas = `${estomagoCicatrizConvPregas.value.toLocaleLowerCase()}`;
        let Glocalizacao = `${estomagoCicatrizLocalizAnatomica.value.toLocaleLowerCase()}`;
        let HlocalizacaoEspecifica = `de ${estomagoCicatrizCorpoOuAntro}`;
        let HaLocalizacaoEspecifica = `de ${estomagoCicatrizCorpoOuAntro}`;

        let descricao = `Nota-se cicatriz de úlcera ${Aatividade} ${Bformato}, ${Cbordos}, ${Etamanho}, ${FconvPregas}, ${Glocalizacao} ${HlocalizacaoEspecifica} ${HaLocalizacaoEspecifica}`;

        let AatividadeDiagnostico = `${estomagoCicatrizAtividade.value.toLocaleUpperCase() === "CICATRIZ VERMELHA (SAKITA S1)" ? "(SAKITA S1)" : "(SAKITA S2)"}`
        let diagnosticoLaudo = `CICATRIZ DE ÚLCERA ${AatividadeDiagnostico} ${HlocalizacaoEspecifica.toLocaleUpperCase()}`;

        if (!existeDiagnosticoNoLaudo(laudoEstomago, diagnosticoLaudo)) {
          addDiagnostico(estomagoDefault, laudoEstomago, setLaudoEstomago, {
            descricao,
            diagnostico: diagnosticoLaudo,
            del: [""],
            bx: false
          })

          setDiagnosticoEstomago({ value: "Diagnóstico", label: "Diagnóstico" });
          setEstomagoCicatrizAtividade({ value: "Atividade (SAKITA)", label: "Atividade (SAKITA)" });
          setEstomagoCicatrizFormato({ value: "Formato", label: "Formato" });
          setEstomagoCicatrizBordos({ value: "Bordos", label: "Bordos" });
          setEstomagoCicatrizTamanho("");
          setEstomagoCicatrizConvPregas({ value: "Convergência de pregas", label: "Convergência de pregas" });
          setEstomagoCicatrizLocalizAnatomica({ value: "Localização anatômica", label: "Localização anatômica" });
          setEstomagoCicatrizCorpoOuAntro("Corpo");
          setEstomagoCicatrizLocalizEspecifica({ value: "Localização anatômica", label: "Localização específica" });
        }
      }
      else {
        if (!existeDiagnosticoNoLaudo(laudoEstomago, diagnostico)) {
          fluxoEstomagoDiagnosticosX.forEach(fluxo => {
            if (fluxo.diagnostico === diagnostico) {
              let descricaoSplit = fluxo.descricao.split("__");

              let descricaoLaudo = `${descricaoSplit[0]} ${estomagoDiagnosticosXLocalizAnatomica.value.toLocaleLowerCase()} ${estomagoDiagnosticosXLocalizAnatomica.value !== "Incisura angularis" ? `de ${estomagoDiagnosticosXCorpoOuAntro} ${estomagoDiagnosticosXLocalizEspecifica}` : ''}${descricaoSplit[1]}`;

              let linhaDeletada;
              if (estomagoDiagnosticosXLocalizAnatomica.value === "Incisura angularis") {
                linhaDeletada = "5";
              }
              else {
                if (estomagoDiagnosticosXCorpoOuAntro === "Corpo") {
                  linhaDeletada = "4";
                }
                else {
                  linhaDeletada = "6";
                }
              }

              addDiagnostico(estomagoDefault, laudoEstomago, setLaudoEstomago, {
                descricao: descricaoLaudo,
                diagnostico: fluxo.diagnostico,
                del: [linhaDeletada],
                bx: false
              })
            }
          });
          setDiagnosticoEstomago({ value: "Diagnóstico", label: "Diagnóstico" });
        }
      }
    }
  }

  function isDiagnoticoXestomago() {
    return diagnosticoEstomago.value === fluxoEstomagoDiagnosticosX.find(item => item.diagnostico === diagnosticoEstomago.value)?.diagnostico;
  }

  const [laudoTerapeuticaEstomago, setLaudoTerapeuticaEstomago] = useState<{
    diagnostico: string;
    descricao: string;
    bx: boolean;
  }[]>(typeof laudoCtx.laudo.estomago !== "string" ? laudoCtx.laudo.estomago.terapeutica : [])

  function addTerapeuticaEstomago() {
    addTerapeutica(terapeuticaEstomago.value, setTerapeuticaEstomago, laudoTerapeuticaEstomago, setLaudoTerapeuticaEstomago, fluxoTerapeuticaEstomago);
  }

  // LAUDO DUODENO
  const [laudoDuodeno, setLaudoDuodeno] = useState(
    typeof laudoCtx.laudo.duodeno !== "string" ? laudoCtx.laudo.duodeno.diagnostico :
      [{
        descricao: "Bulbo distensível, sem retrações ou abaulamentos e com mucosa normal.",
        del: [""],
        diagnostico: "Bulbo distensível, sem retrações ou abaulamentos e com mucosa normal.",
        bx: false
      },
      {
        descricao: "Segunda porção duodenal sem alterações, examinada até o alcance do aparelho.",
        del: [""],
        diagnostico: "Segunda porção duodenal sem alterações, examinada até o alcance do aparelho.",
        bx: false
      }
      ]
  );

  function addDiagnosticoDuodeno() {
    const diagnostico = diagnosticoDuodeno.value;
    if (diagnostico !== "Diagnóstico") {
      if (diagnostico !== "ÚLCERA DUODENAL" && diagnostico !== "CICATRIZ DE ÚLCERA DUODENAL") {
        addDiagnosticoNormal(duodenoDefault, fluxoDuodeno, laudoDuodeno, setLaudoDuodeno, diagnostico, setDiagnosticoDuodeno);
      }
      else if (diagnostico === "ÚLCERA DUODENAL") {
        // montando diagnostico e descricao
        let Aformato = `${duodenoUlceraFormato.value.toLocaleLowerCase()}`;
        let Batividade = `${duodenoUlceraAtividade.value.toLocaleLowerCase()}`;
        let Cbordos = `${duodenoUlceraBordos.value.toLocaleLowerCase()}`;
        let Dfundo = `${duodenoUlceraFundo.value.toLocaleLowerCase()}`;
        let Etamanho = `medindo ${duodenoUlceraPrimeiroTamanho}mm x ${duodenoUlceraSegundoTamanho}mm`;
        let FconvPregas = `${duodenoUlceraConvPregas.value.toLocaleLowerCase()}`;
        let Glocalizacao = `${duodenoUlceraLocalizAnatomica.value.toLocaleLowerCase()}`;
        let HlocalizacaoAnatomica = `${duodenoUlceraBulboOuSegundaPorcaoDuodenal.toLocaleLowerCase()} ${duodenoUlceraBulboOuSegundaPorcaoDuodenal === "Bulbo" ? "duodenal" : ""}`;

        let descricao = `Nota-se úlcera ${Aformato}, ${Batividade}, bordos ${Cbordos}, fundo ${Dfundo}, ${Etamanho}, ${FconvPregas}, localizada em ${Glocalizacao} em ${HlocalizacaoAnatomica}`;

        let BatividadeDiagnostico = `${Batividade.toLocaleUpperCase()} ${Batividade === "em fase inicial de cicatrização" ? "(SAKITA H1)" : Batividade === "em fase final de cicatrização" ? "(SAKITA H2)" : ""}`;
        let DfundoDiagnostico = `${Dfundo === "recoberto por hematina" ? "(FORREST IIc)" : Dfundo === "recoberto por fibrina e hematina" ? "(FORREST IIc)" : Dfundo === "com sangramento em babação" ? "(FORREST Ib)" : Dfundo === "com sangramento ativo em jato" ? "(FORREST Ia)" : Dfundo === "com vaso sanguíneo visível" ? "(FORREST IIa)" : Dfundo === "com coágulo aderido sem sangramento ativo" ? "(FORREST IIb)" : Dfundo === "de base limpa" ? "(FORREST III)" : ""}`;

        let diagnosticoLaudo = `ÚLCERA ${BatividadeDiagnostico} EM ${HlocalizacaoAnatomica.toLocaleUpperCase()} ${DfundoDiagnostico}`;
        if (!existeDiagnosticoNoLaudo(laudoDuodeno, diagnosticoLaudo)) {
          addDiagnostico(duodenoDefault, laudoDuodeno, setLaudoDuodeno, {
            descricao,
            diagnostico: diagnosticoLaudo,
            del: [""],
            bx: false
          })

          setDiagnosticoDuodeno({ value: "Diagnóstico", label: "Diagnóstico" });
          setDuodenoUlceraAtividade({ value: "Atividade (SAKITA)", label: "Atividade (SAKITA)" });
          setDuodenoUlceraFormato({ value: "Formato", label: "Formato" });
          setDuodenoUlceraBordos({ value: "Bordos", label: "Bordos" });
          setDuodenoUlceraFundo({ value: "Fundo", label: "Fundo" });
          setDuodenoUlceraPrimeiroTamanho("");
          setDuodenoUlceraSegundoTamanho("");
          setDuodenoUlceraConvPregas({ value: "Convergência de pregas", label: "Convergência de pregas" });
          setDuodenoUlceraLocalizAnatomica({ value: "Localização anatômica", label: "Localização anatômica" });
          setDuodenoUlceraBulboOuSegundaPorcaoDuodenal("Bulbo");
        }
      }
      else {
        // montando diagnostico e descricao
        let Aatividade = `${duodenoCicatrizAtividade.value === "Cicatriz vermelha (SAKITA S1)" ? "vermelha" : "branca"}`;
        let Bformato = `${duodenoCicatrizFormato.value.toLocaleLowerCase()}`;
        let Cbordos = `${duodenoCicatrizBordos.value.toLocaleLowerCase()}`;
        let Etamanho = `medindo ${duodenoCicatrizTamanho}mm`;
        let FconvPregas = `${duodenoCicatrizConvPregas.value.toLocaleLowerCase()}`;
        let Glocalizacao = `${duodenoCicatrizLocalizAnatomica.value.toLocaleLowerCase()}`;
        let HlocalizacaoAnatomica = `em ${duodenoCicatrizBulboOuSegundaPorcaoDuodenal.toLocaleLowerCase()} ${duodenoCicatrizBulboOuSegundaPorcaoDuodenal === "Bulbo" ? "duodenal" : ""}`;

        let descricao = `Nota-se cicatriz de úlcera ${Aatividade} ${Bformato}, ${Cbordos}, ${Etamanho}, ${FconvPregas}, ${Glocalizacao}, ${HlocalizacaoAnatomica}`;

        let AatividadeDiagnostico = `${duodenoCicatrizAtividade.value.toLocaleUpperCase() === "CICATRIZ VERMELHA (SAKITA S1)" ? "(SAKITA S1)" : "(SAKITA S2)"}`
        let diagnosticoLaudo = `CICATRIZ DE ÚLCERA ${AatividadeDiagnostico} ${HlocalizacaoAnatomica.toLocaleUpperCase()}`;

        if (!existeDiagnosticoNoLaudo(laudoDuodeno, diagnosticoLaudo)) {
          addDiagnostico(duodenoDefault, laudoDuodeno, setLaudoDuodeno, {
            descricao,
            diagnostico: diagnosticoLaudo,
            del: [""],
            bx: false
          })

          setDiagnosticoDuodeno({ value: "Diagnóstico", label: "Diagnóstico" });
          setDuodenoCicatrizAtividade({ value: "Atividade (SAKITA)", label: "Atividade (SAKITA)" });
          setDuodenoCicatrizFormato({ value: "Formato", label: "Formato" });
          setDuodenoCicatrizBordos({ value: "Bordos", label: "Bordos" });
          setDuodenoCicatrizTamanho("");
          setDuodenoCicatrizConvPregas({ value: "Convergência de pregas", label: "Convergência de pregas" });
          setDuodenoCicatrizLocalizAnatomica({ value: "Localização anatômica", label: "Localização anatômica" });
          setDuodenoCicatrizBulboOuSegundaPorcaoDuodenal("Bulbo");
        }
      }
    }
  }

  const [laudoTerapeuticaDuodeno, setLaudoTerapeuticaDuodeno] = useState<{
    diagnostico: string;
    descricao: string;
    bx: boolean;
  }[]>(typeof laudoCtx.laudo.duodeno !== "string" ? laudoCtx.laudo.duodeno.terapeutica : [])

  function addTerapeuticaDuodeno() {
    addTerapeutica(terapeuticaDuodeno.value, setTerapeuticaDuodeno, laudoTerapeuticaDuodeno, setLaudoTerapeuticaDuodeno, fluxoTerapeuticaDuodeno);
  }

  // -------------------------------------------------

  function existeDiagnosticoNoLaudo(laudo: any, diagnostico: string) {
    // @ts-ignore
    let found = laudo.find(item => item.diagnostico === diagnostico);
    return found !== undefined;
  }

  function removeLinhaLaudo(laudo: any, setLaudo: any, diagnostico: string, descricaoLaudoDefault: string[], tipo: 'terapeutica' | 'diagnostico' = 'diagnostico',) {
    let delDiagnostico;
    // removendo linha do diagnostico
    //@ts-ignore
    let novoLaudo = laudo.filter(item => {
      if (item.diagnostico !== diagnostico) {
        return item;
      }
      else if (tipo === 'diagnostico') {
        delDiagnostico = [...item.del];
      }
    });
    if (novoLaudo[0] === undefined && tipo === 'diagnostico') {
      novoLaudo = [...descricaoLaudoDefault.map(descricaoDefault => {
        return {
          descricao: descricaoDefault,
          del: [""],
          diagnostico: descricaoDefault,
          bx: false
        }
      })]
    }
    if (tipo === 'diagnostico') {
      // @ts-ignore
      delDiagnostico.forEach(element => {
        let linhaCorrespondente = element;
        if (linhaCorrespondente !== "") {
          // verifica a incidencia de linhaCorrespondente
          // @ts-ignore
          let incidencia = novoLaudo.findIndex(item => item.del[0] === linhaCorrespondente);
          if (incidencia === -1) {
            // caso não haja incidencia de mesma linhaCorrespondente, verificar a primeira linha maior, e assim inserir antes
            let linhaAserInserida = {
              descricao: descricaoLaudoDefault[parseInt(element)],
              del: [""],
              diagnostico: descricaoLaudoDefault[parseInt(element)],
              bx: false
            }
            // @ts-ignore
            incidencia = novoLaudo.findIndex(item => item.del[0] > linhaCorrespondente);
            if (incidencia !== -1) {
              novoLaudo.splice(incidencia, 0, linhaAserInserida);
            }
            else {
              for (let i = parseInt(linhaCorrespondente); i < descricaoLaudoDefault.length; i++) {
                // @ts-ignore
                incidencia = novoLaudo.findIndex(item => item.diagnostico === descricaoLaudoDefault[i]);
                if (incidencia !== -1) {
                  break;
                }
              }
              if (incidencia !== -1) {
                novoLaudo.splice(incidencia, 0, linhaAserInserida);
              }
              else {
                novoLaudo.push(linhaAserInserida);
              }
            }
          }
        }

      });

    }

    setLaudo(novoLaudo);
  }

  function removeDiagnostico(orgao: string, diagnostico: string) {
    if (orgao === "cricofaringeo") {
      removeLinhaLaudo(laudoCricofaringeo, setLaudoCricofaringeo, diagnostico, cricofaringeoDefault)
    }
    else if (orgao === "esofago") {
      removeLinhaLaudo(laudoEsofago, setLaudoEsofago, diagnostico, esofagoDefault);
    }
    else if (orgao === "esofago-terapeutica") {
      removeLinhaLaudo(laudoTerapeuticaEsofago, setLaudoTerapeuticaEsofago, diagnostico, [], 'terapeutica');
    }
    else if (orgao === "duodeno") {
      removeLinhaLaudo(laudoDuodeno, setLaudoDuodeno, diagnostico, duodenoDefault);
    }
    else if (orgao === "duodeno-terapeutica") {
      removeLinhaLaudo(laudoTerapeuticaDuodeno, setLaudoTerapeuticaDuodeno, diagnostico, [], 'terapeutica');
    }
    else if (orgao === "estomago") {
      removeLinhaLaudo(laudoEstomago, setLaudoEstomago, diagnostico, estomagoDefault);
    }
    else if (orgao === "estomago-terapeutica") {
      removeLinhaLaudo(laudoTerapeuticaEstomago, setLaudoTerapeuticaEstomago, diagnostico, [], 'terapeutica');
    }
  }

  function attBxLaudo(laudo: any, setLaudo: any, diagnostico: string) {
    //@ts-ignore
    let novoLaudo = laudo.map(item => {
      if (item.diagnostico === diagnostico) {
        item.bx = !item.bx;
      }
      return item;
    })
    setLaudo(novoLaudo);
  }

  function attBxDiagnostico(orgao: string, diagnostico: string) {
    if (orgao === "cricofaringeo") {
      attBxLaudo(laudoCricofaringeo, setLaudoCricofaringeo, diagnostico);
    }
    else if (orgao === "esofago") {
      attBxLaudo(laudoEsofago, setLaudoEsofago, diagnostico);
    }
    else if (orgao === "esofago-terapeutica") {
      attBxLaudo(laudoTerapeuticaEsofago, setLaudoTerapeuticaEsofago, diagnostico);
    }
    else if (orgao === "duodeno") {
      attBxLaudo(laudoDuodeno, setLaudoDuodeno, diagnostico);
    }
    else if (orgao === "duodeno-terapeutica") {
      attBxLaudo(laudoTerapeuticaDuodeno, setLaudoTerapeuticaDuodeno, diagnostico);
    }
    else if (orgao === "estomago") {
      attBxLaudo(laudoEstomago, setLaudoEstomago, diagnostico);
    }
    else if (orgao === "estomago-terapeutica") {
      attBxLaudo(laudoTerapeuticaEstomago, setLaudoTerapeuticaEstomago, diagnostico);
    }
  }
  interface IElementoLaudo {
    descricao: string,
    diagnostico: string,
    del: string[],
    bx: boolean,
  }

  function addDiagnostico(descricaoLaudoDefault: string[], laudo: any, setLaudo: any, elemento: IElementoLaudo) {
    let novoLaudo = [...laudo];
    let index = 0;
    // apagando linhas defaults no laudo, e adicionando diagnostico no lugar da aparição da primeira linha
    // @ts-ignore
    elemento.del.forEach(del => {
      if (del !== "") {
        let linha = parseInt(del);
        let linhaDefaultNoLaudo = novoLaudo.findIndex(item => item?.diagnostico === descricaoLaudoDefault[linha]);
        if (linhaDefaultNoLaudo !== -1 && index === 0) {
          novoLaudo[linhaDefaultNoLaudo].diagnostico = elemento.diagnostico;
          novoLaudo[linhaDefaultNoLaudo].descricao = elemento.descricao;
          novoLaudo[linhaDefaultNoLaudo].del = [...elemento.del];
          novoLaudo[linhaDefaultNoLaudo].bx = false;
          index++;
        }
        else if (linhaDefaultNoLaudo !== -1) {
          novoLaudo[linhaDefaultNoLaudo].diagnostico = "";
        }
      }
    })
    let laudoFinal;
    // index = 0 significa que nenhuma linha default estava no laudo, então deve ser adicionado após os diagnosticos que tambem apagam essa linha
    if (index === 0) {
      let linhaAserInserida = {
        descricao: elemento.descricao,
        diagnostico: elemento.diagnostico,
        del: [...elemento.del],
        bx: false,
      }
      let linhaCorrespondente = elemento.del[0];
      if (linhaCorrespondente !== "") {
        // verifica a incidencia de linhaCorrespondente, e ao final do bloco insere o elemento
        let incidencia = novoLaudo.findIndex(item => item.del[0] === linhaCorrespondente);
        if (incidencia !== -1) {
          while (novoLaudo[incidencia]?.del[0] === linhaCorrespondente) {
            incidencia++;
          }
          novoLaudo.splice(incidencia, 0, linhaAserInserida);
        }
        else {
          // caso não haja incidencia de mesma linhaCorrespondente, verificar a primeira linha maior, e assim inserir antes
          incidencia = novoLaudo.findIndex(item => item.del[0] > linhaCorrespondente);
          if (incidencia !== -1) {
            novoLaudo.splice(incidencia, 0, linhaAserInserida);
          }
          else {
            for (let i = parseInt(linhaCorrespondente) + 1; i < descricaoLaudoDefault.length; i++) {
              incidencia = novoLaudo.findIndex(item => item.diagnostico === descricaoLaudoDefault[i]);
              if (incidencia !== -1) {
                break;
              }
            }
            if (incidencia !== -1) {
              novoLaudo.splice(incidencia, 0, linhaAserInserida);
            }
            else {
              novoLaudo.push(linhaAserInserida);
            }
          }
        }
      }
      else {
        novoLaudo.push(linhaAserInserida);
      }
      laudoFinal = [...novoLaudo];
    }
    else {
      laudoFinal = novoLaudo.filter(elem => elem.diagnostico !== "");
    }

    setLaudo(laudoFinal);
  }

  function addDiagnosticoNormal(descricaoLaudoDefault: string[], fluxoLaudo: any, laudo: any, setLaudo: any, diagnostico: string, setDiagnostico: any) {
    if (!existeDiagnosticoNoLaudo(laudo, diagnostico)) {
      // @ts-ignore
      fluxoLaudo.forEach(fluxo => {
        // procurando diagnostico no fluxo correspondente
        if (fluxo.diagnostico === diagnostico) {
          addDiagnostico(descricaoLaudoDefault, laudo, setLaudo, {
            diagnostico: fluxo.diagnostico,
            descricao: fluxo.descricao,
            del: [...fluxo.del],
            bx: false,
          })
        }
      });
      setDiagnostico({ value: "Diagnóstico", label: "Diagnóstico" });
    }
  }

  function addTerapeutica(terapeutica: string, setTerapeutica: any, laudoTerapeutica: any, setLaudoTerapeutica: any, fluxoTerapeutica: any) {
    if (terapeutica !== "Terapêutica/Procedimento") {
      if (!existeDiagnosticoNoLaudo(laudoTerapeutica, terapeutica)) {
        // @ts-ignore
        fluxoTerapeutica.forEach(fluxo => {
          if (fluxo.diagnostico === terapeutica) {
            let novoLaudoTerapeutica = [...laudoTerapeutica];
            novoLaudoTerapeutica.push({
              diagnostico: fluxo.diagnostico,
              descricao: fluxo.descricao,
              bx: false
            })

            setLaudoTerapeutica(novoLaudoTerapeutica);
          }
        });
        setTerapeutica({ value: "Terapêutica/Procedimento", label: "Terapêutica/Procedimento" });
      }
    }
  }


  function setNovoLaudoToContext() {
    let cricofaringeoLaudoCtx = passagemNoCricofaringeo !== "passagem-no-cricofaríngeo-alterado" ? passagemNoCricofaringeo : laudoCricofaringeo;
    let esofagoLaudoCtx = esofago !== "esofago-alterado" ? esofago : {
      diagnostico: laudoEsofago,
      terapeutica: laudoTerapeuticaEsofago,
    };
    let estomagoLaudoCtx = estomago !== "estomago-alterado" ? estomago : {
      diagnostico: laudoEstomago,
      terapeutica: laudoTerapeuticaEstomago,
    };
    let duodenoLaudoCtx = duodeno !== "duodeno-alterado" ? duodeno : {
      diagnostico: laudoDuodeno,
      terapeutica: laudoTerapeuticaDuodeno,
    };

    setLaudoCtx({
      ...laudoCtx,
      laudo: {
        sedoanalgesia,
        midazolan: MGmidazolan,
        fentanil: MCGfentanil,
        propofol: MGpropofol,
        cricofaringeo: cricofaringeoLaudoCtx,
        esofago: esofagoLaudoCtx,
        estomago: estomagoLaudoCtx,
        duodeno: duodenoLaudoCtx,
        notas
      }
    })
  }

  const history = useHistory();

  useEffect(() => {
    if (laudoCtx.finalizado) {
      history.push('/novo-laudo');
    }
  }, [])

  return (
    <div className="page-laudo-personalizado">
      <Background />
      <Header name="Gerar laudo personalizado" />

      <main>
        <header>

          <span>Endo Cloud</span>
        </header>

        <div className="flex-between align-center">
          <span>Sedoanalgesia*</span>
          <Radio id="sedoanalgesia1" label="Aos cuidados do(a) anestesia" state={sedoanalgesia === "sedoanalgesia1"} onChangeRadio={() => setSedoanalgesia("sedoanalgesia1")} />

          <Radio id="sedoanalgesia2" label="Aos cuidados do(a) médico(a) assistente" state={sedoanalgesia === "sedoanalgesia2"} onChangeRadio={() => setSedoanalgesia("sedoanalgesia2")} />

          <Radio id="sedoanalgesia3" label="Lidocaína Spray" state={sedoanalgesia === "sedoanalgesia3"} onChangeRadio={() => setSedoanalgesia("sedoanalgesia3")} />

        </div>

        <section>
          <div>
            <CheckMark check={midazolan} setCheck={() => setMidazolan(!midazolan)} label="Midazolan" />
            <Input type="text" name="midazolan" style={{ width: 70 }} disabled={!midazolan} value={MGmidazolan} onChange={e => setMGmidazolan(e.target.value)} />
            <span>mg</span>
          </div>

          <div>
            <CheckMark check={fentanil} setCheck={() => setFentanil(!fentanil)} label="Fentanil" />
            <Input type="text" name="fentanil" style={{ width: 70 }} disabled={!fentanil} value={MCGfentanil} onChange={e => setMCGfentanil(e.target.value)} />
            <span>mcg</span>
          </div>

          <div>
            <CheckMark check={propofol} setCheck={() => setPropofol(!propofol)} label="Propofol" />
            <Input type="text" name="propofol" style={{ width: 70 }} disabled={!propofol} value={MGpropofol} onChange={e => setMGPropofol(e.target.value)} />
            <span>mg</span>
          </div>
        </section>

        <div className="exames-orgaos">
          <div>
            <div>
              <span style={{ width: 238 }}>Passagem no Cricofaríngeo*</span>
              <Radio id="passagem-no-cricofaríngeo-normal" label="Normal" state={passagemNoCricofaringeo === "passagem-no-cricofaríngeo-normal"} onChangeRadio={() => setPassagemNoCricofaringeo("passagem-no-cricofaríngeo-normal")} />
              <Radio id="passagem-no-cricofaríngeo-alterado" label="Alterado" state={passagemNoCricofaringeo === "passagem-no-cricofaríngeo-alterado"} onChangeRadio={() => setPassagemNoCricofaringeo("passagem-no-cricofaríngeo-alterado")} style={{ marginLeft: 10 }} />
            </div>
            <ChevronsDown />
          </div>

          {
            passagemNoCricofaringeo === "passagem-no-cricofaríngeo-alterado" ?
              <section className="drop">
                <div>
                  <div style={{ width: 300 }}>
                    <Dropdown
                      placeholder="Diagnóstico"
                      noOptionsMsg="Nenhum diagnóstico encontrado"
                      options={
                        fluxoCricofaringeo.map(item => {
                          return {
                            value: item.diagnostico,
                            label: item.diagnostico
                          }
                        })
                      }
                      value={diagnosticoCricofaringeo}
                      onChange={setDiagnosticoCricofaringeo}
                    />
                  </div>
                  <Button style={{ height: 40, width: 150, marginTop: 5 }} disabled={diagnosticoCricofaringeo.value === "Diagnóstico"} onClick={() => addDiagnosticoCricofaringeo()}>
                    <Plus />
                    Adicionar
                  </Button>
                  <div style={{ width: 480 }}>
                    {
                      // @ts-ignore
                      laudoCricofaringeo.map((item) => {
                        let found = cricofaringeoDefault.find(linha => linha === item.diagnostico);
                        if (found !== undefined) {
                          return ""
                        }
                        else {
                          return (
                            <div className="flex-between align-center" style={{ marginBottom: 10 }} key={item.diagnostico}>
                              <textarea value={item.diagnostico} placeholder="Diagnóstico escolhido" disabled ></textarea>
                              <Trash2 color="#BC2A2A" onClick={() => removeDiagnostico("cricofaringeo", item.diagnostico)} />
                              <div className="flex align-center">
                                <label style={{ marginRight: 8 }}>Bx</label>
                                <Checkbox id="cricofaringeo-diagnostico" setState={() => attBxDiagnostico("cricofaringeo", item.diagnostico)} state={item.bx} />
                              </div>
                            </div>
                          )
                        }
                      })
                    }
                  </div>
                </div>
              </section> : ''
          }

          <div>
            <div>
              <span style={{ width: 90 }}>Esôfago*</span>
              <Radio id="esofago-normal" label="Normal" state={esofago === "esofago-normal"} onChangeRadio={() => setEsofago("esofago-normal")} />
              <Radio id="esofago-alterado" label="Alterado" state={esofago === "esofago-alterado"} onChangeRadio={() => setEsofago("esofago-alterado")} />
              <Radio id="esofago-nao-examinado" label="Não Examinado" state={esofago === "esofago-nao-examinado"} onChangeRadio={() => setEsofago("esofago-nao-examinado")} />
            </div>
            <ChevronsDown />
          </div>

          {
            esofago === "esofago-alterado" ?
              <section className="drop">
                <div>
                  <div style={{ width: 300 }}>
                    <Dropdown
                      placeholder="Diagnóstico"
                      noOptionsMsg="Nenhum diagnóstico encontrado"
                      options={
                        (() => {
                          let novoArray = fluxoEsofago.map(item => {
                            return {
                              value: item.diagnostico,
                              label: item.diagnostico
                            }
                          })
                          novoArray.push({
                            value: "VARIZES ESOFÁGICAS",
                            label: "VARIZES ESOFÁGICAS"
                          })
                          return novoArray;
                        })()
                      }
                      value={diagnosticoEsofago}
                      onChange={setDiagnosticoEsofago}
                    />
                  </div>
                  <Button
                    style={{ height: 40, width: 150, marginTop: 5 }}
                    disabled={
                      (diagnosticoEsofago.value === "Diagnóstico") ||
                      (diagnosticoEsofago.value === "VARIZES ESOFÁGICAS" &&
                        (esofagoVarizesNumTotal.value === "Número total de varizes" ||
                          (esofagoVarizesQtdCalibreFino.value === "Fino" &&
                            esofagoVarizesQtdCalibreMedio.value === "Médio" &&
                            esofagoVarizesQtdCalibreGrosso.value === "Grosso") ||
                          esofagoVarizesFormato.value === "Formato" ||
                          esofagoVarizesCor.value === "Cor" ||
                          esofagoVarizesSinaisCorVermelha.value === "Sinais da cor vermelha" ||
                          esofagoVarizesLocalizacao.value === "Localização" ||
                          (existePontoDeRuptura && esofagoVarizesPontoRuptura.value === "Ponto de ruptura")))
                    }
                    onClick={() => addDiagnosticoEsofago()}
                  >
                    <Plus />
                    Adicionar
                  </Button>

                  <div style={{ width: 480 }}>
                    {
                      // @ts-ignore
                      laudoEsofago.map((item) => {
                        let found = esofagoDefault.find(linha => linha === item.diagnostico);
                        if (found !== undefined) {
                          return ""
                        }
                        return (
                          <div className="flex-between align-center" style={{ marginBottom: 10 }} key={item.diagnostico}>
                            <textarea value={item.diagnostico} placeholder="Diagnóstico escolhido" disabled ></textarea>
                            <Trash2 color="#BC2A2A" onClick={() => removeDiagnostico("esofago", item.diagnostico)} />
                            <div className="flex align-center">
                              <label style={{ marginRight: 8 }}>Bx</label>
                              <Checkbox id="esofago-diagnostico" setState={() => attBxDiagnostico("esofago", item.diagnostico)} state={item.bx} />
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>

                {
                  diagnosticoEsofago.value === "VARIZES ESOFÁGICAS" ?
                    <span className="info-diagnostico">
                      <div style={{ width: 250 }}>
                        <SelectPadrao
                          options={[
                            { value: "1", label: "1" },
                            { value: "2", label: "2" },
                            { value: "3", label: "3" },
                            { value: "4", label: "4" },
                            { value: "5", label: "5" },
                            { value: "6", label: "6" },
                          ]}
                          onChange={setEsofagoVarizesNumTotal}
                          value={esofagoVarizesNumTotal}
                        />
                        <div className="flex align-center" style={{ gap: 10, width: 560 }}>
                          <label style={{ flex: 'none' }}>Quantidade por calibre</label>
                          <SelectPadrao
                            options={[
                              { value: "0", label: "0" },
                              { value: "1", label: "1" },
                              { value: "2", label: "2" },
                              { value: "3", label: "3" },
                              { value: "4", label: "4" },
                              { value: "5", label: "5" },
                              { value: "6", label: "6" },
                            ]}
                            onChange={setEsofagoVarizesQtdCalibreFino}
                            value={esofagoVarizesQtdCalibreFino}
                          />
                          <SelectPadrao
                            options={[
                              { value: "0", label: "0" },
                              { value: "1", label: "1" },
                              { value: "2", label: "2" },
                              { value: "3", label: "3" },
                              { value: "4", label: "4" },
                              { value: "5", label: "5" },
                              { value: "6", label: "6" },
                            ]}
                            onChange={setEsofagoVarizesQtdCalibreMedio}
                            value={esofagoVarizesQtdCalibreMedio}
                          />
                          <SelectPadrao
                            options={[
                              { value: "0", label: "0" },
                              { value: "1", label: "1" },
                              { value: "2", label: "2" },
                              { value: "3", label: "3" },
                              { value: "4", label: "4" },
                              { value: "5", label: "5" },
                              { value: "6", label: "6" },
                            ]}
                            onChange={setEsofagoVarizesQtdCalibreGrosso}
                            value={esofagoVarizesQtdCalibreGrosso}
                          />
                        </div>
                        <SelectPadrao
                          options={[
                            { value: "Retilíneo(s)", label: "Retilíneo(s)" },
                            { value: "Tortuoso(s)", label: "Tortuoso(s)" },
                            { value: "Interrompido(s)", label: "Interrompido(s)" },
                          ]}
                          onChange={setEsofagoVarizesFormato}
                          value={esofagoVarizesFormato}
                        />
                        <SelectPadrao
                          options={[
                            { value: "Azulado(s)", label: "Azulado(s)" },
                            { value: "Esbranquiçado(s)", label: "Esbranquiçado(s)" },
                          ]}
                          onChange={setEsofagoVarizesCor}
                          value={esofagoVarizesCor}
                        />
                        <SelectPadrao
                          options={[
                            { value: "Sem sinais da cor vermelha", label: "Sem sinais da cor vermelha" },
                            { value: "Com sinais da cor vermelha", label: "Com sinais da cor vermelha" },
                            { value: "Sem “red spots”", label: "Sem “red spots”" },
                            { value: "Com “red spots”", label: "Com “red spots”" },
                          ]}
                          onChange={setEsofagoVarizesSinaisCorVermelha}
                          value={esofagoVarizesSinaisCorVermelha}
                        />
                        <SelectPadrao
                          options={[
                            { value: "Restrito(s) ao terço distal", label: "Restritos ao terço distal" },
                            { value: "Em terço médio e distal", label: "Em terço médio e distal" },
                            { value: "A partir de terço proximal", label: "A partir de terço proximal" },
                          ]}
                          onChange={setEsofagoVarizesLocalizacao}
                          value={esofagoVarizesLocalizacao}
                        />
                        <div className="flex-between align-center" style={{ gap: 10 }}>
                          <label style={{ flex: 'none' }}>Ponto de ruptura</label>
                          <CheckMark check={!existePontoDeRuptura} setCheck={() => setExistePontoDeRuptura(false)} label={"Não"} />
                          <CheckMark check={existePontoDeRuptura} setCheck={() => setExistePontoDeRuptura(true)} label={"Sim"} />
                        </div>
                        {
                          existePontoDeRuptura ?
                            <SelectPadrao
                              options={[
                                { value: "Ponto de ruptura recente com tampão fibrinoso", label: "Ponto de ruptura recente com tampão fibrinoso" },
                                { value: "Ponto de ruptura recente com sangramento em jato", label: "Ponto de ruptura recente com sangramento em jato" },
                                { value: "Ponto de ruptura recente com sangramento em babação", label: "Ponto de ruptura recente com sangramento em babação" },
                              ]}
                              onChange={setEsofagoVarizesPontoRuptura}
                              value={esofagoVarizesPontoRuptura}
                            /> : ''
                        }
                      </div>
                    </span> : ''
                }

                <div>
                  <div style={{ width: 300 }}>
                    <Dropdown
                      placeholder="Diagnóstico"
                      noOptionsMsg="Nenhum diagnóstico encontrado"
                      options={
                        fluxoTerapeuticaEsofago.map(item => {
                          return {
                            value: item.diagnostico,
                            label: item.diagnostico
                          }
                        })
                      }
                      value={terapeuticaEsofago}
                      onChange={setTerapeuticaEsofago}
                    />
                  </div>
                  <Button style={{ height: 40, width: 150, marginTop: 5 }} disabled={terapeuticaEsofago.value === "Terapêutica/Procedimento"} onClick={() => addTerapeuticaEsofago()}>
                    <Plus />
                    Adicionar
                  </Button>

                  <div style={{ width: 480 }}>
                    {
                      laudoTerapeuticaEsofago.length === 0 ? ""
                        : laudoTerapeuticaEsofago.map((item) => {
                          return (
                            <div className="flex-between align-center" style={{ marginBottom: 10 }} key={item.diagnostico}>
                              <textarea value={item.diagnostico} placeholder="Terapêutica/Procedimento escolhido" disabled ></textarea>
                              <Trash2 color="#BC2A2A" onClick={() => removeDiagnostico("esofago-terapeutica", item.diagnostico)} />
                              <div className="flex align-center">
                                <label style={{ marginRight: 8 }}>Bx</label>
                                <Checkbox id="esofago-terapeutica" setState={() => attBxDiagnostico("esofago-terapeutica", item.diagnostico)} state={item.bx} />
                              </div>
                            </div>
                          )
                        })
                    }
                  </div>
                </div>

              </section> : ''
          }

          <div>
            <div>
              <span>Estômago*</span>
              <Radio id="estomago-normal" label="Normal" state={estomago === "estomago-normal"} onChangeRadio={() => setEstomago("estomago-normal")} />
              <Radio id="estomago-alterado" label="Alterado" state={estomago === "estomago-alterado"} onChangeRadio={() => setEstomago("estomago-alterado")} />
              <Radio id="estomago-nao-examinado" label="Não Examinado" state={estomago === "estomago-nao-examinado"} onChangeRadio={() => setEstomago("estomago-nao-examinado")} />
            </div>
            <ChevronsDown />
          </div>

          {
            estomago === "estomago-alterado" ?
              <section className="drop">
                <div>
                  <div style={{ width: 300 }}>
                    <Dropdown
                      placeholder="Diagnóstico"
                      noOptionsMsg="Nenhum diagnóstico encontrado"
                      options={
                        (() => {
                          let novoArray = fluxoEstomago.map(item => {
                            return {
                              value: item.diagnostico,
                              label: item.diagnostico
                            }
                          })
                          novoArray.push(
                            ...fluxoEstomagoDiagnosticosX.map(item => {
                              return {
                                value: item.diagnostico,
                                label: item.diagnostico
                              }
                            }),
                            { value: "ÚLCERA GÁSTRICA", label: "ÚLCERA GÁSTRICA" },
                            { value: "CICATRIZ DE ÚLCERA GÁSTRICA", label: "CICATRIZ DE ÚLCERA GÁSTRICA" })
                          return novoArray;
                        })()
                      }
                      value={diagnosticoEstomago}
                      onChange={setDiagnosticoEstomago}
                    />
                  </div>

                  <Button
                    style={{ height: 40, width: 150, marginTop: 5 }}
                    disabled={
                      (diagnosticoEstomago.value === "Diagnóstico") ||
                      (isDiagnoticoXestomago() &&
                        (estomagoDiagnosticosXLocalizAnatomica.value === "Localização anatômica" ||
                          (estomagoDiagnosticosXLocalizAnatomica.value !== "Incisura angularis" && estomagoDiagnosticosXLocalizEspecifica.value === "Localização específica"))) ||
                      (diagnosticoEstomago.value === "ÚLCERA GÁSTRICA" &&
                        (estomagoUlceraFormato.value === "Formato" ||
                          estomagoUlceraAtividade.value === "Atividade (SAKITA)" ||
                          estomagoUlceraBordos.value === "Bordos" ||
                          estomagoUlceraFundo.value === "Fundo" ||
                          estomagoUlceraPrimeiroTamanho === "" ||
                          estomagoUlceraSegundoTamanho === "" ||
                          estomagoUlceraConvPregas.value === "Convergência de pregas" ||
                          estomagoUlceraLocalizAnatomica.value === "Localização anatômica" ||
                          estomagoUlceraLocalizEspecifica.value === "Localização específica"
                        )
                      ) ||
                      (diagnosticoEstomago.value === "CICATRIZ DE ÚLCERA GÁSTRICA" &&
                        (estomagoCicatrizAtividade.value === "Atividade (SAKITA)" ||
                          estomagoCicatrizFormato.value === "Formato" ||
                          estomagoCicatrizBordos.value === "Bordos" ||
                          estomagoCicatrizConvPregas.value === "Convergência de pregas" ||
                          estomagoCicatrizLocalizAnatomica.value === "Localização anatômica" ||
                          estomagoCicatrizTamanho === "" ||
                          estomagoCicatrizLocalizEspecifica.value === "Localização específica"
                        )
                      )
                    }
                    onClick={() => addDiagnosticoEstomago()}
                  >
                    <Plus />
                    Adicionar
                  </Button>

                  <div style={{ width: 480 }}>
                    {
                      // @ts-ignore
                      laudoEstomago.map((item) => {
                        let found = estomagoDefault.find(linha => linha === item.diagnostico);
                        if (found !== undefined) {
                          return ""
                        }
                        return (
                          <div className="flex-between align-center" style={{ marginBottom: 10 }} key={item.diagnostico}>
                            <textarea value={item.diagnostico} placeholder="Diagnóstico escolhido" disabled ></textarea>
                            <Trash2 color="#BC2A2A" onClick={() => removeDiagnostico("estomago", item.diagnostico)} />
                            <div className="flex align-center">
                              <label style={{ marginRight: 8 }}>Bx</label>
                              <Checkbox id="estomago-diagnostico" setState={() => attBxDiagnostico("estomago", item.diagnostico)} state={item.bx} />
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                {
                  isDiagnoticoXestomago() ?
                    <span className="info-diagnostico">
                      <div style={{ width: 250 }}>
                        <SelectPadrao
                          options={[
                            { value: "Pequena curvatura", label: "Pequena curvatura" },
                            { value: "Grande curvatura", label: "Grande curvatura" },
                            { value: "Parede posterior", label: "Parede posterior" },
                            { value: "Parede anterior", label: "Parede anterior" },
                            { value: "Incisura angularis", label: "Incisura angularis" },
                          ]}
                          onChange={setEstomagoDiagnosticosXLocalizAnatomica}
                          value={estomagoDiagnosticosXLocalizAnatomica}
                        />
                        {
                          estomagoDiagnosticosXLocalizAnatomica.value === "Incisura angularis" ||
                            estomagoDiagnosticosXLocalizAnatomica.value === "Localização anatômica" ?
                            "" :
                            <>
                              <div className="flex-between" style={{ gap: 10 }}>
                                <CheckMark check={estomagoDiagnosticosXCorpoOuAntro === "Corpo"} setCheck={() => setEstomagoDiagnosticosXCorpoOuAntro("Corpo")} label={"Corpo"} />
                                <CheckMark check={estomagoDiagnosticosXCorpoOuAntro === "Antro"} setCheck={() => setEstomagoDiagnosticosXCorpoOuAntro("Antro")} label={"Antro"} />
                              </div>
                              <SelectPadrao
                                options={[
                                  { value: "Alto", label: "Alto" },
                                  { value: "Proximal", label: "Proximal" },
                                  { value: "Médio", label: "Médio" },
                                  { value: "Distal", label: "Distal" },
                                  { value: "Pré-pilórico", label: "Pré-pilórico" },
                                  { value: "Subcárdico", label: "Subcárdico" },
                                ]}
                                onChange={setEstomagoDiagnosticosXLocalizEspecifica}
                                value={estomagoDiagnosticosXLocalizEspecifica}
                              />
                            </>
                        }

                      </div>
                    </span> : diagnosticoEstomago.value === "ÚLCERA GÁSTRICA" ?
                      <span className="info-diagnostico">
                        <div style={{ width: 250 }}>
                          <SelectPadrao
                            options={[
                              { value: "Arredondada", label: "Arredondada" },
                              { value: "Ovalada", label: "Ovalada" },
                              { value: "Semicircunferencial", label: "Semicircunferencial" },
                              { value: "Circunferencial", label: "Circunferencial" },
                              { value: "Elipsóide", label: "Elipsóide" },
                              { value: "Em espelho", label: "Em espelho" },
                              { value: "Estrelada", label: "Estrelada" },
                              { value: "Linear", label: "Linear" },
                            ]}
                            onChange={setEstomagoUlceraFormato}
                            value={estomagoUlceraFormato}
                          />
                          <SelectPadrao
                            options={[
                              { value: "Ativa (SAKITA A1)", label: "Ativa (SAKITA A1)" },
                              { value: "Ativa (SAKITA A2)", label: "Ativa (SAKITA A2)" },
                              { value: "Em fase inicial de cicatrização", label: "Em fase inicial de cicatrização" },
                              { value: "Em fase final de cicatrização", label: "Em fase final de cicatrização" }
                            ]}
                            onChange={setEstomagoUlceraAtividade}
                            value={estomagoUlceraAtividade}
                          />
                          <SelectPadrao
                            options={[
                              { value: "Bem delimitados", label: "Bem delimitados" },
                              { value: "Imprecisos", label: "Imprecisos" },
                              { value: "Enantemáticos", label: "Enantemáticos" },
                              { value: "Elevados", label: "Elevados" },
                              { value: "Elevados e enantemáticos", label: "Elevados e enantemáticos" },
                            ]}
                            onChange={setEstomagoUlceraBordos}
                            value={estomagoUlceraBordos}
                          />
                          <SelectPadrao
                            options={[
                              { value: "Recoberto por fibrina espessa", label: "Recoberto por fibrina espessa" },
                              { value: "Recoberto por fibrina tênue", label: "Recoberto por fibrina tênue" },
                              { value: "Recoberto por hematina", label: "Recoberto por hematina" },
                              { value: "Recoberto por fibrina e hematina", label: "Recoberto por fibrina e hematina" },
                              { value: "Com sangramento em babação", label: "Com sangramento em babação" },
                              { value: "Com sangramento ativo em jato", label: "Com sangramento ativo em jato" },
                              { value: "Com vaso sanguíneo visível", label: "Com vaso sanguíneo visível" },
                              { value: "Com coágulo aderido sem sangramento ativo", label: "Com coágulo aderido sem sangramento ativo" },
                              { value: "De base limpa", label: "De base limpa" },
                            ]}
                            onChange={setEstomagoUlceraFundo}
                            value={estomagoUlceraFundo}
                          />
                          <div className="flex align-center" style={{ gap: 10 }}>
                            <label>Tamanho</label>
                            <Input type="text" name="tamanho1" style={{ width: 70 }} value={estomagoUlceraPrimeiroTamanho} onChange={(e) => setEstomagoUlceraPrimeiroTamanho(e.target.value)} />
                            <span style={{ color: "#74799C" }}>mm</span>
                            <span>X</span>
                            <Input type="text" name="tamanho2" style={{ width: 70 }} value={estomagoUlceraSegundoTamanho} onChange={(e) => setEstomagoUlceraSegundoTamanho(e.target.value)} />
                            <span style={{ color: "#74799C" }}>mm</span>
                          </div>
                          <SelectPadrao
                            options={[
                              { value: "Sem convergência de pregas", label: "Sem convergência de pregas" },
                              { value: "Com convergência de pregas", label: "Com convergência de pregas" },
                            ]}
                            onChange={setEstomagoUlceraConvPregas}
                            value={estomagoUlceraConvPregas}
                          />
                          <SelectPadrao
                            options={[
                              { value: "Pequena curvatura", label: "Pequena curvatura" },
                              { value: "Grande curvatura", label: "Grande curvatura" },
                              { value: "Parede posterior", label: "Parede posterior" },
                              { value: "Parede anterior", label: "Parede anterior" },
                              { value: "Incisura angularis", label: "Incisura angularis" },

                            ]}
                            onChange={setEstomagoUlceraLocalizAnatomica}
                            value={estomagoUlceraLocalizAnatomica}
                          />
                          <div className="flex-between" style={{ gap: 10 }}>
                            <CheckMark check={estomagoUlceraCorpoOuAntro === "Corpo"} setCheck={() => setEstomagoUlceraCorpoOuAntro("Corpo")} label={"Corpo"} />
                            <CheckMark check={estomagoUlceraCorpoOuAntro === "Antro"} setCheck={() => setEstomagoUlceraCorpoOuAntro("Antro")} label={"Antro"} />
                          </div>
                          <SelectPadrao
                            options={[
                              { value: "Alto", label: "Alto" },
                              { value: "Proximal", label: "Proximal" },
                              { value: "Médio", label: "Médio" },
                              { value: "Distal", label: "Distal" },
                              { value: "Pré-pilórico", label: "Pré-pilórico" },
                              { value: "Subcárdico", label: "Subcárdico" },
                            ]}
                            onChange={setEstomagoUlceraLocalizEspecifica}
                            value={estomagoUlceraLocalizEspecifica}
                          />
                        </div>
                      </span> : diagnosticoEstomago.value === "CICATRIZ DE ÚLCERA GÁSTRICA" ?
                        <span className="info-diagnostico">
                          <div style={{ width: 250 }}>
                            <SelectPadrao
                              options={[
                                { value: "Cicatriz vermelha (SAKITA S1)", label: "Cicatriz vermelha (SAKITA S1)" },
                                { value: "Cicatriz branca (SAKITA S2)", label: "Cicatriz branca (SAKITA S2)" },
                              ]}
                              onChange={setEstomagoCicatrizAtividade}
                              value={estomagoCicatrizAtividade}
                            />
                            <SelectPadrao
                              options={[
                                { value: "Arredondada", label: "Arredondada" },
                                { value: "Ovalada", label: "Ovalada" },
                                { value: "Semicircunferencial", label: "Semicircunferencial" },
                                { value: "Circunferencial", label: "Circunferencial" },
                                { value: "Elipsóide", label: "Elipsóide" },
                                { value: "Em espelho", label: "Em espelho" },
                                { value: "Estrelada", label: "Estrelada" },
                                { value: "Linear", label: "Linear" },
                              ]}
                              onChange={setEstomagoCicatrizFormato}
                              value={estomagoCicatrizFormato}
                            />
                            <SelectPadrao
                              options={[
                                { value: "Bem delimitados", label: "Bem delimitados" },
                                { value: "Imprecisos", label: "Imprecisos" },
                                { value: "Enantemáticos", label: "Enantemáticos" },
                                { value: "Elevados", label: "Elevados" },
                                { value: "Elevados e enantemáticos", label: "Elevados e enantemáticos" },
                              ]}
                              onChange={setEstomagoCicatrizBordos}
                              value={estomagoCicatrizBordos}
                            />
                            <div className="flex align-center" style={{ gap: 10 }}>
                              <label>Tamanho</label>
                              <Input type="text" name="tamanho1" style={{ width: 70 }} value={estomagoCicatrizTamanho} onChange={(e) => setEstomagoCicatrizTamanho(e.target.value)} />
                              <span style={{ color: "#74799C" }}>mm</span>
                            </div>
                            <SelectPadrao
                              options={[
                                { value: "Sem convergência de pregas", label: "Sem convergência de pregas" },
                                { value: "Com convergência de pregas", label: "Com convergência de pregas" },
                              ]}
                              onChange={setEstomagoCicatrizConvPregas}
                              value={estomagoCicatrizConvPregas}
                            />
                            <SelectPadrao
                              options={[
                                { value: "Pequena curvatura", label: "Pequena curvatura" },
                                { value: "Grande curvatura", label: "Grande curvatura" },
                                { value: "Parede posterior", label: "Parede posterior" },
                                { value: "Parede anterior", label: "Parede anterior" },
                                { value: "Incisura angularis", label: "Incisura angularis" },

                              ]}
                              onChange={setEstomagoCicatrizLocalizAnatomica}
                              value={estomagoCicatrizLocalizAnatomica}
                            />
                            <div className="flex-between" style={{ gap: 10 }}>
                              <CheckMark check={estomagoCicatrizCorpoOuAntro === "Corpo"} setCheck={() => setEstomagoCicatrizCorpoOuAntro("Corpo")} label={"Corpo"} />
                              <CheckMark check={estomagoCicatrizCorpoOuAntro === "Antro"} setCheck={() => setEstomagoCicatrizCorpoOuAntro("Antro")} label={"Antro"} />
                            </div>
                            <SelectPadrao
                              options={[
                                { value: "Alto", label: "Alto" },
                                { value: "Proximal", label: "Proximal" },
                                { value: "Médio", label: "Médio" },
                                { value: "Distal", label: "Distal" },
                                { value: "Pré-pilórico", label: "Pré-pilórico" },
                                { value: "Subcárdico", label: "Subcárdico" },
                              ]}
                              onChange={setEstomagoCicatrizLocalizEspecifica}
                              value={estomagoCicatrizLocalizEspecifica}
                            />
                          </div>
                        </span> : ''
                }
                <div>
                  <div style={{ width: 300 }}>
                    <Dropdown
                      placeholder="Diagnóstico"
                      noOptionsMsg="Nenhum diagnóstico encontrado"
                      options={
                        fluxoTerapeuticaEstomago.map(item => {
                          return {
                            value: item.diagnostico,
                            label: item.diagnostico
                          }
                        })
                      }
                      value={terapeuticaEstomago}
                      onChange={setTerapeuticaEstomago}
                    />
                  </div>

                  <Button style={{ height: 40, width: 150, marginTop: 5 }} disabled={terapeuticaEstomago.value === "Terapêutica/Procedimento"} onClick={() => addTerapeuticaEstomago()}>
                    <Plus />
                    Adicionar
                  </Button>

                  <div style={{ width: 480 }}>
                    {
                      laudoTerapeuticaEstomago.length === 0 ? ""
                        : laudoTerapeuticaEstomago.map((item) => {
                          return (
                            <div className="flex-between align-center" style={{ marginBottom: 10 }} key={item.diagnostico}>
                              <textarea value={item.diagnostico} placeholder="Terapêutica/Procedimento escolhido" disabled ></textarea>
                              <Trash2 color="#BC2A2A" onClick={() => removeDiagnostico("estomago-terapeutica", item.diagnostico)} />
                              <div className="flex align-center">
                                <label style={{ marginRight: 8 }}>Bx</label>
                                <Checkbox id="estomago-terapeutica" setState={() => attBxDiagnostico("estomago-terapeutica", item.diagnostico)} state={item.bx} />
                              </div>
                            </div>
                          )
                        })
                    }
                  </div>

                </div>


              </section> : ''
          }

          <div>
            <div>
              <span style={{ width: 90 }}>Duodeno*</span>
              <Radio id="duodeno-normal" label="Normal" state={duodeno === "duodeno-normal"} onChangeRadio={() => setDuodeno("duodeno-normal")} />
              <Radio id="duodeno-alterado" label="Alterado" state={duodeno === "duodeno-alterado"} onChangeRadio={() => setDuodeno("duodeno-alterado")} />
              <Radio id="duodeno-nao-examinado" label="Não Examinado" state={duodeno === "duodeno-nao-examinado"} onChangeRadio={() => setDuodeno("duodeno-nao-examinado")} />
            </div>
            <ChevronsDown />
          </div>

          {
            duodeno === "duodeno-alterado" ?
              <section className="drop">
                <div>
                  <div style={{ width: 300 }}>
                    <Dropdown
                      placeholder="Diagnóstico"
                      noOptionsMsg="Nenhum diagnóstico encontrado"
                      options={
                        (() => {
                          let novoArray = fluxoDuodeno.map(item => {
                            return {
                              value: item.diagnostico,
                              label: item.diagnostico
                            }
                          })
                          novoArray.push({
                            value: "ÚLCERA DUODENAL",
                            label: "ÚLCERA DUODENAL"
                          }, {
                            value: "CICATRIZ DE ÚLCERA DUODENAL",
                            label: "CICATRIZ DE ÚLCERA DUODENAL"
                          }
                          )
                          return novoArray;
                        })()
                      }
                      value={diagnosticoDuodeno}
                      onChange={setDiagnosticoDuodeno}
                    />
                  </div>

                  <Button
                    style={{ height: 40, width: 150, marginTop: 5 }}
                    disabled={
                      (diagnosticoDuodeno.value === "Diagnóstico") ||
                      (diagnosticoDuodeno.value === "ÚLCERA DUODENAL" &&
                        (duodenoUlceraAtividade.value === "Atividade (SAKITA)" ||
                          duodenoUlceraFormato.value === "Formato" ||
                          duodenoUlceraBordos.value === "Bordos" ||
                          duodenoUlceraFundo.value === "Fundo" ||
                          duodenoUlceraPrimeiroTamanho === "" ||
                          duodenoUlceraSegundoTamanho === "" ||
                          duodenoUlceraConvPregas.value === "Convergência de pregas" ||
                          duodenoUlceraLocalizAnatomica.value === "Localização anatômica"
                        )
                      ) ||
                      (diagnosticoDuodeno.value === "CICATRIZ DE ÚLCERA DUODENAL" &&
                        (duodenoCicatrizAtividade.value === "Atividade (SAKITA)" ||
                          duodenoCicatrizFormato.value === "Formato" ||
                          duodenoCicatrizBordos.value === "Bordos" ||
                          duodenoCicatrizTamanho === "" ||
                          duodenoCicatrizConvPregas.value === "Convergência de pregas" ||
                          duodenoCicatrizLocalizAnatomica.value === "Localização anatômica"
                        )
                      )
                    }
                    onClick={() => addDiagnosticoDuodeno()}
                  >
                    <Plus />
                    Adicionar
                  </Button>

                  <div style={{ width: 480 }}>
                    {
                      // @ts-ignore
                      laudoDuodeno.map((item) => {
                        let found = duodenoDefault.find(linha => linha === item.diagnostico);
                        if (found !== undefined) {
                          return ""
                        }
                        return (
                          <div className="flex-between align-center" style={{ marginBottom: 10 }} key={item.diagnostico}>
                            <textarea value={item.diagnostico} placeholder="Diagnóstico escolhido" disabled ></textarea>
                            <Trash2 color="#BC2A2A" onClick={() => removeDiagnostico("duodeno", item.diagnostico)} />
                            <div className="flex align-center">
                              <label style={{ marginRight: 8 }}>Bx</label>
                              <Checkbox id="duodeno-diagnostico" setState={() => attBxDiagnostico("duodeno", item.diagnostico)} state={item.bx} />
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                {
                  diagnosticoDuodeno.value === "ÚLCERA DUODENAL" ?
                    <span className="info-diagnostico">
                      <div style={{ width: 250 }}>
                        <SelectPadrao
                          options={[
                            { value: "Ativa (SAKITA A1)", label: "Ativa (SAKITA A1)" },
                            { value: "Ativa (SAKITA A2)", label: "Ativa (SAKITA A2)" },
                            { value: "Em fase inicial de cicatrização", label: "Em fase inicial de cicatrização" },
                            { value: "Em fase final de cicatrização", label: "Em fase final de cicatrização" }
                          ]}
                          onChange={setDuodenoUlceraAtividade}
                          value={duodenoUlceraAtividade}
                        />
                        <SelectPadrao
                          options={[
                            { value: "Arredondada", label: "Arredondada" },
                            { value: "Ovalada", label: "Ovalada" },
                            { value: "Semicircunferencial", label: "Semicircunferencial" },
                            { value: "Circunferencial", label: "Circunferencial" },
                            { value: "Elipsóide", label: "Elipsóide" },
                            { value: "Em espelho", label: "Em espelho" },
                            { value: "Estrelada", label: "Estrelada" },
                            { value: "Linear", label: "Linear" },
                          ]}
                          onChange={setDuodenoUlceraFormato}
                          value={duodenoUlceraFormato}
                        />
                        <SelectPadrao
                          options={[
                            { value: "Bem delimitados", label: "Bem delimitados" },
                            { value: "Imprecisos", label: "Imprecisos" },
                            { value: "Enantemáticos", label: "Enantemáticos" },
                            { value: "Elevados", label: "Elevados" },
                            { value: "Elevados e enantemáticos", label: "Elevados e enantemáticos" },
                          ]}
                          onChange={setDuodenoUlceraBordos}
                          value={duodenoUlceraBordos}
                        />
                        <SelectPadrao
                          options={[
                            { value: "Recoberto por fibrina espessa", label: "Recoberto por fibrina espessa" },
                            { value: "Recoberto por fibrina tênue", label: "Recoberto por fibrina tênue" },
                            { value: "Recoberto por hematina", label: "Recoberto por hematina" },
                            { value: "Recoberto por fibrina e hematina", label: "Recoberto por fibrina e hematina" },
                            { value: "Com sangramento em babação", label: "Com sangramento em babação" },
                            { value: "Com sangramento ativo em jato", label: "Com sangramento ativo em jato" },
                            { value: "Com vaso sanguíneo visível", label: "Com vaso sanguíneo visível" },
                            { value: "Com coágulo aderido sem sangramento ativo", label: "Com coágulo aderido sem sangramento ativo" },
                            { value: "De base limpa", label: "De base limpa" },
                          ]}
                          onChange={setDuodenoUlceraFundo}
                          value={duodenoUlceraFundo}
                        />
                        <div className="flex align-center" style={{ gap: 10 }}>
                          <label>Tamanho</label>
                          <Input type="text" name="tamanho1" style={{ width: 70 }} value={duodenoUlceraPrimeiroTamanho} onChange={(e) => setDuodenoUlceraPrimeiroTamanho(e.target.value)} />
                          <span style={{ color: "#74799C" }}>mm</span>
                          <span>X</span>
                          <Input type="text" name="tamanho2" style={{ width: 70 }} value={duodenoUlceraSegundoTamanho} onChange={(e) => setDuodenoUlceraSegundoTamanho(e.target.value)} />
                          <span style={{ color: "#74799C" }}>mm</span>
                        </div>

                        <SelectPadrao
                          options={[
                            { value: "Sem convergência de pregas", label: "Sem convergência de pregas" },
                            { value: "Com convergência de pregas", label: "Com convergência de pregas" },
                          ]}
                          onChange={setDuodenoUlceraConvPregas}
                          value={duodenoUlceraConvPregas}
                        />
                        <SelectPadrao
                          options={[
                            { value: "Parede superior", label: "Parede superior" },
                            { value: "Parede inferior", label: "Parede inferior" },
                            { value: "Parede posterior", label: "Parede posterior" },
                            { value: "Parede anterior", label: "Parede anterior" },
                            { value: "Posteroinferior", label: "Posteroinferior" },
                            { value: "Anteroinferior", label: "Anteroinferior" },
                            { value: "Pré-papilar", label: "Pré-papilar" },
                            { value: "Pós-papilar", label: "Pós-papilar" },
                          ]}
                          onChange={setDuodenoUlceraLocalizAnatomica}
                          value={duodenoUlceraLocalizAnatomica}
                        />
                        <div className="flex-between" style={{ gap: 10, width: 400 }}>
                          <CheckMark check={duodenoUlceraBulboOuSegundaPorcaoDuodenal === "Bulbo"} setCheck={() => setDuodenoUlceraBulboOuSegundaPorcaoDuodenal("Bulbo")} label={"Bulbo"} />
                          <CheckMark check={duodenoUlceraBulboOuSegundaPorcaoDuodenal === "Segunda porção duodenal"} setCheck={() => setDuodenoUlceraBulboOuSegundaPorcaoDuodenal("Segunda porção duodenal")} label={"Segunda porção duodenal"} />
                        </div>
                      </div>
                    </span> : diagnosticoDuodeno.value === "CICATRIZ DE ÚLCERA DUODENAL" ?
                      <span className="info-diagnostico">
                        <div style={{ width: 250 }}>
                          <SelectPadrao
                            options={[
                              { value: "Cicatriz vermelha (SAKITA S1)", label: "Cicatriz vermelha (SAKITA S1)" },
                              { value: "Cicatriz branca (SAKITA S2)", label: "Cicatriz branca (SAKITA S2)" },
                            ]}
                            onChange={setDuodenoCicatrizAtividade}
                            value={duodenoCicatrizAtividade}
                          />
                          <SelectPadrao
                            options={[
                              { value: "Arredondada", label: "Arredondada" },
                              { value: "Ovalada", label: "Ovalada" },
                              { value: "Semicircunferencial", label: "Semicircunferencial" },
                              { value: "Circunferencial", label: "Circunferencial" },
                              { value: "Elipsóide", label: "Elipsóide" },
                              { value: "Em espelho", label: "Em espelho" },
                              { value: "Estrelada", label: "Estrelada" },
                              { value: "Linear", label: "Linear" },
                            ]}
                            onChange={setDuodenoCicatrizFormato}
                            value={duodenoCicatrizFormato}
                          />
                          <SelectPadrao
                            options={[
                              { value: "Bem delimitados", label: "Bem delimitados" },
                              { value: "Imprecisos", label: "Imprecisos" },
                              { value: "Enantemáticos", label: "Enantemáticos" },
                              { value: "Elevados", label: "Elevados" },
                              { value: "Elevados e enantemáticos", label: "Elevados e enantemáticos" },
                            ]}
                            onChange={setDuodenoCicatrizBordos}
                            value={duodenoCicatrizBordos}
                          />
                          <div className="flex align-center" style={{ gap: 10 }}>
                            <label>Tamanho</label>
                            <Input type="text" name="tamanho1" style={{ width: 70 }} value={duodenoCicatrizTamanho} onChange={(e) => setDuodenoCicatrizTamanho(e.target.value)} />
                            <span style={{ color: "#74799C" }}>mm</span>
                          </div>
                          <SelectPadrao
                            options={[
                              { value: "Sem convergência de pregas", label: "Sem convergência de pregas" },
                              { value: "Com convergência de pregas", label: "Com convergência de pregas" },
                            ]}
                            onChange={setDuodenoCicatrizConvPregas}
                            value={duodenoCicatrizConvPregas}
                          />
                          <SelectPadrao
                            options={[
                              { value: "Parede superior", label: "Parede superior" },
                              { value: "Parede inferior", label: "Parede inferior" },
                              { value: "Parede posterior", label: "Parede posterior" },
                              { value: "Parede anterior", label: "Parede anterior" },
                              { value: "Posteroinferior", label: "Posteroinferior" },
                              { value: "Anteroinferior", label: "Anteroinferior" },
                              { value: "Pré-papilar", label: "Pré-papilar" },
                              { value: "Pós-papilar", label: "Pós-papilar" },
                            ]}
                            onChange={setDuodenoCicatrizLocalizAnatomica}
                            value={duodenoCicatrizLocalizAnatomica}
                          />
                          <div className="flex-between align-center" style={{ gap: 10, width: 400 }}>
                            <CheckMark check={duodenoCicatrizBulboOuSegundaPorcaoDuodenal === "Bulbo"} setCheck={() => setDuodenoCicatrizBulboOuSegundaPorcaoDuodenal("Bulbo")} label={"Bulbo"} />
                            <CheckMark check={duodenoCicatrizBulboOuSegundaPorcaoDuodenal === "Segunda porção duodenal"} setCheck={() => setDuodenoCicatrizBulboOuSegundaPorcaoDuodenal("Segunda porção duodenal")} label={"Segunda porção duodenal"} />
                          </div>
                        </div>
                      </span> : ''
                }

                <div>
                  <div style={{ width: 300 }}>
                    <Dropdown
                      placeholder="Diagnóstico"
                      noOptionsMsg="Nenhum diagnóstico encontrado"
                      options={
                        fluxoTerapeuticaDuodeno.map(item => {
                          return {
                            value: item.diagnostico,
                            label: item.diagnostico
                          }
                        })
                      }
                      value={terapeuticaDuodeno}
                      onChange={setTerapeuticaDuodeno}
                    />
                  </div>

                  <Button style={{ height: 40, width: 150, marginTop: 5 }} disabled={terapeuticaDuodeno.value === "Terapêutica/Procedimento"} onClick={() => addTerapeuticaDuodeno()}>
                    <Plus />
                    Adicionar
                  </Button>

                  <div style={{ width: 480 }}>
                    {
                      laudoTerapeuticaDuodeno.length === 0 ? ""
                        : laudoTerapeuticaDuodeno.map((item) => {
                          return (
                            <div className="flex-between align-center" style={{ marginBottom: 10 }} key={item.diagnostico}>
                              <textarea value={item.diagnostico} placeholder="Terapêutica/Procedimento escolhido" disabled ></textarea>
                              <Trash2 color="#BC2A2A" onClick={() => removeDiagnostico("duodeno-terapeutica", item.diagnostico)} />
                              <div className="flex align-center">
                                <label style={{ marginRight: 8 }}>Bx</label>
                                <Checkbox id="duodeno-terapeutica" setState={() => attBxDiagnostico("duodeno-terapeutica", item.diagnostico)} state={item.bx} />
                              </div>
                            </div>
                          )
                        })
                    }
                  </div>

                </div>
              </section> : ''
          }

          <div>
            <div>
              <span style={{ width: 90 }}>Notas</span>
              <Radio id="realizado-teste-da-urease" label="Realizado teste da urease" state={notas[0] === "Realizado teste da urease"} onChangeRadio={() => setNotas([notas[0] = "Realizado teste da urease", notas[1], notas[2]])} />
              <Radio id="biopsias-de-corpo-e-antro" label="Biópsias de corpo e antro" state={notas[1] === "Biópsias de corpo e antro"} onChangeRadio={() => setNotas([notas[0], notas[1] = "Biópsias de corpo e antro", notas[2]])} />
              <Radio id="outro" label="Outro" state={notas[2] !== undefined} onChangeRadio={() => setNotas([notas[0], notas[1], notas[2] = ""])} />
            </div>
            <ChevronsDown />
          </div>
          {
            notas[2] !== undefined ?
              <div className="notas-outro">
                <textarea placeholder="Digite as notas aqui" value={notas[2]} onChange={e => setNotas([notas[0], notas[1], notas[2] = e.target.value])} ></textarea>
              </div> : ''
          }
        </div>
      </main>

      <Footer name="Voltar" link_back="/" link_btn="/novo-laudo/visualizacao-de-laudo" name_btn="Avançar" icon_btn={<ArrowRightCircle />}
        state_btn={
          sedoanalgesia !== "" &&
          passagemNoCricofaringeo !== "" &&
          esofago !== "" &&
          estomago !== "" &&
          duodeno !== ""
        }
        onClick={() => setNovoLaudoToContext()}
      />
    </div>
  )
}

export default LaudoPersonalizado
