import React, { useState } from 'react'
import { ArrowLeft, Check, Edit, Search, Trash2, User, X } from 'react-feather'

import Background from '../../assets/Background'
import Button from '../../components/Button'
import Dropdown from '../../components/Dropdown'
import Input from '../../components/Input'
import Modal from '../../components/Modal'
import ModalAlerta from '../../components/ModalAlerta'
import NavbarSec from '../../components/NavbarSec'
import Pagination from '../../components/Pagination'

import { dataLaudosFake, dataPacientesFake } from './dados'

import './styles.css'

// informações dos modais de alerta
const infosAlertaVoltar = {
  titulo: "Tem certeza que deseja voltar?",
  info: "Há alterações que foram feitas nesse paciente que não serão salvas caso você continue.",
  msgRejeicao: "Não, continuar editando",
  msgConfirmacao: "Sim, perder alterações"
}
const infosAlertaExcluir = {
  titulo: "Tem certeza que deseja excluir esse paciente?",
  info: "Todos os dados desse paciente serão perdidos.",
  msgRejeicao: "Não, manter no sistema",
  msgConfirmacao: "Sim, excluir usuário"
}

const labelsLaudos = [
  "NOME DO PACIENTE",
  "DATA",
  "TIPO DE EXAME",
  "AÇÕES"
]

const labelsPacientes = [
  "ID",
  "NOME",
  "SENHA",
  "IDADE",
  "AÇÕES"
]

const widthsLabelsLaudos = [300, 250, 300];
const widthsLabelsPacientes = [85, 290, 235, 240];


function MinhaNuvem() {
  const [page, setPage] = useState("laudos");

  const [dataLaudos, setDataLaudos] = useState<Array<{
    paciente: string,
    data: string,
    tipo: string
  }>>(dataLaudosFake);

  const [dataPacientes, setDataPacientes] = useState<Array<{
    id: number,
    nome: string,
    idade: string,
    data_nasc: string,
    sexo: string,
    telefone: string,
    email: string,
    hosp_clin: string,
    convenio: string,
    senha: number,
  }>>(dataPacientesFake);


  // modais de visualizaçao e edição
  const [modal, setModal] = useState(false);
  const [acaoModal, setAcaoModal] = useState('');

  const [dataModal, setDataModal] = useState({
    id: 1,
    nome: "",
    idade: "",
    data_nasc: "",
    sexo: "",
    telefone: "",
    email: "",
    hosp_clin: "",
    convenio: "",
    senha: 1
  });
  const [dataModalEditado, setDataModalEditado] = useState({
    id: 1,
    nome: "",
    idade: "",
    data_nasc: "",
    sexo: "",
    telefone: "",
    email: "",
    hosp_clin: "",
    convenio: "",
    senha: 1
  });

  // modal alerta
  const [modalAlerta, setModalAlerta] = useState(false);
  const [infosModalAlerta, setInfosModalAlerta] = useState({
    titulo: "",
    info: "",
    msgRejeicao: "",
    msgConfirmacao: ""
  });

  const [hosp_clin, setHosp_clin] = useState({
    label: 'Selecione seu Hospital ou Clínica',
    value: 'Selecione seu Hospital ou Clínica'
  });

  const [convenio, setConvenio] = useState({
    label: 'Selecione seu convênio',
    value: 'Selecione seu convênio'
  });

  function VerificarAlteracao() {
    return (dataModal.nome === dataModalEditado.nome && dataModal.email === dataModalEditado.email && dataModal.idade === dataModalEditado.idade && dataModal.data_nasc === dataModalEditado.data_nasc && dataModal.sexo === dataModalEditado.sexo && dataModal.telefone === dataModalEditado.telefone && dataModal.hosp_clin === dataModalEditado.hosp_clin && dataModal.convenio === dataModalEditado.convenio)
  }

  return (
    <div className="page-minha-nuvem">
      <Background />

      <NavbarSec page="minha-nuvem" />

      <section>
        <Input
          name="search-patient"
          type="search"
          placeholder="Procure pelo nome do paciente"
          icon={<Search color="#74799C" />}
        />
        <span>
          <Search color="#fff" />
        </span>
      </section>

      <div className="tabs">
        <div
          className={page === "laudos" ? "active" : ""}
          onClick={() => setPage("laudos")}
        >Laudos</div>
        <div
          className={page === "pacientes" ? "active" : ""}
          onClick={() => setPage("pacientes")}
        >Pacientes</div>
      </div>

      <div className="table-padrao">

        {
          page === "laudos" ?
            <>
              <div className="labels" >
                {labelsLaudos.map((label, i) => <span key={label} style={{ width: widthsLabelsLaudos[i] }}>{label}</span>)}
              </div>
              <div className="dados">
                {dataLaudos?.map((item, i) => {
                  const dateArray = item.data.split("/");
                  // const dateFormated = `20${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;

                  return (
                    <div key={i} className="linha">
                      <span style={{ width: 280, marginRight: 20 }}>{item.paciente}</span>
                      <span style={{ width: 230, marginRight: 20 }}>{item.data}</span>
                      <span style={{ width: 280, marginRight: 20 }}>{item.tipo}</span>

                      <span className="button-action" style={{ marginRight: 25 }}>
                        <Search />
                        Visualizar
                      </span>
                      <span className="button-action">
                        <Edit />
                        Editar
                      </span>
                    </div>
                  )
                })}
              </div>
            </> : page === "pacientes" ?
              <>
                <div className="labels" >
                  {labelsPacientes.map((label, i) => <span key={label} style={{ width: widthsLabelsPacientes[i] }}>{label}</span>)}
                </div>
                <div className="dados">
                  {dataPacientes?.map((item, i) => {

                    return (
                      <div key={i} className="linha">
                        <span style={{ width: 80, marginRight: 20 }}>{item.id}</span>
                        <span style={{ width: 350, marginRight: 20 }}>{item.nome}</span>
                        <span style={{ width: 280, marginRight: 20 }}>{item.senha}</span>
                        <span style={{ width: 280, marginRight: 20 }}>{item.idade}</span>

                        <span className="button-action" style={{ marginRight: 25 }} onClick={() => {
                          setAcaoModal("visualizar")
                          setModal(true);
                          setDataModal(item);
                          setDataModalEditado(item);
                        }}>
                          <Search />
                          Visualizar
                        </span>
                        <span className="button-action" onClick={() => {
                          setAcaoModal("editar")
                          setModal(true);
                          setDataModal(item);
                          setDataModalEditado(item);
                        }}>
                          <Edit />
                          Editar
                        </span>
                      </div>
                    )
                  })}
                </div>
              </> : ''
        }
      </div>

      <Pagination />

      {modal &&
        <Modal setModal={setModal} position="right">

          <div className="modal-page-admin modal-page-admin-residente">
            <header>
              <h3>
                {
                  acaoModal === "visualizar" ?
                    <>
                      <User />
                          Visualizar paciente
                        </>
                    :
                    <>
                      <Edit />
                          Editar dados
                        </>
                }
              </h3>
              <div onClick={() => {
                // caso tenha modificações
                if (!VerificarAlteracao() && acaoModal === "editar") {
                  setInfosModalAlerta(infosAlertaVoltar)
                  setModalAlerta(true);
                }
                else {
                  setModal(false);
                }
              }}>
                <X />
              </div>
            </header>

            <form>
              <div className="field">
                <label>Nome:</label>

                {acaoModal === "editar" ?
                  <Input
                    name="nome"
                    type="text"
                    placeholder="Joselino Costa da Silva"
                    onChange={(e) => setDataModalEditado({ ...dataModalEditado, nome: e.target.value })}
                    value={dataModalEditado.nome}
                  /> :
                  <span>{dataModal.nome}</span>
                }

              </div>


              <div className="field-group justify-between">
                <div className="flex align-center">
                  <label style={{ marginRight: 16 }}>Idade:</label>

                  {acaoModal === "editar" ?
                    <Input
                      name="idade"
                      type="text"
                      onChange={(e) => setDataModalEditado({ ...dataModalEditado, idade: e.target.value })}
                      value={dataModalEditado.idade}
                    /> :
                    <span>{dataModal.idade}</span>
                  }
                </div>

                <div className="flex align-center">
                  <label style={{ width: 170, marginRight: 16 }}>Data de nascimento:</label>

                  <Input
                    name="data-nasc"
                    type="date"
                    onChange={(e) => setDataModalEditado({ ...dataModalEditado, data_nasc: e.target.value })}
                    value={dataModalEditado.data_nasc}
                    disabled={acaoModal === "editar" ? false : true}
                  />
                </div>

              </div>

              <div className="field-group justify-between">
                <div className="flex align-center">
                  <label style={{ marginRight: 16 }}>Sexo:</label>

                  {acaoModal === "editar" ?
                    <Input
                      name="sexo"
                      type="text"
                      onChange={(e) => setDataModalEditado({ ...dataModalEditado, sexo: e.target.value })}
                      value={dataModalEditado.sexo}
                    /> :
                    <span>{dataModal.sexo}</span>
                  }

                </div>
                <div className="flex align-center">
                  <label style={{ marginRight: 16 }}>Telefone:</label>

                  {acaoModal === "editar" ?
                    <Input
                      name="telefone"
                      type="tel"
                      onChange={(e) => setDataModalEditado({ ...dataModalEditado, telefone: e.target.value })}
                      value={dataModalEditado.telefone}
                    /> :
                    <span>{dataModal.telefone}</span>
                  }
                </div>
              </div>

              <div className="field">
                <label style={{ width: 70 }}>E-mail:</label>

                {acaoModal === "editar" ?
                  <Input
                    name="email"
                    type="text"
                    placeholder="joselinocosta@endocloud.com"
                    onChange={(e) => setDataModalEditado({ ...dataModalEditado, email: e.target.value })}
                    value={dataModalEditado.email}
                  /> :
                  <span>{dataModal.email}</span>
                }
              </div>


              <div className="field">
                <label>Hospital/Clínica:</label>

                {/* {acaoModal === "editar" ?
                  <Input
                    name="hospital-clinica"
                    type="text"
                    onChange={(e) => setDataModalEditado({ ...dataModalEditado, hosp_clin: e.target.value })}
                    value={dataModalEditado.hosp_clin}
                  /> :
                  <span>{dataModal.hosp_clin}</span>
                } */}

                {acaoModal === "editar" ?
                  <Dropdown
                    placeholder="Selecione o Hospital ou Clínica"
                    noOptionsMsg="Adicionar Hospital"
                    options={[
                      { value: 'HGF', label: 'HGF' },
                      { value: 'Sírio Libanês', label: 'Sírio Libanês' },
                      { value: 'Einstein', label: 'Einstein' },
                      { value: 'Gonzaguinha', label: 'Gonzaguinha' }
                    ]}
                    onChange={setHosp_clin}
                  />
                  : <span>{dataModal.hosp_clin}</span>
                }

              </div>

              <div className="field">
                <label>Convênio:</label>

                {/* {acaoModal === "editar" ?
                  <Input
                    name="convenio"
                    type="text"
                    onChange={(e) => setDataModalEditado({ ...dataModalEditado, convenio: e.target.value })}
                    value={dataModalEditado.convenio}
                  /> :
                  <span>{dataModal.convenio}</span>
                } */}

                {acaoModal === "editar" ?
                  <Dropdown
                    placeholder="Selecione seu convênio"
                    noOptionsMsg="Adicionar Convênio"
                    options={[
                      { value: 'SUS', label: 'SUS' },
                      { value: 'Sírio Libanês', label: 'Sírio Libanês' },
                      { value: 'Einstein', label: 'Einstein' },
                      { value: 'Gonzaguinha', label: 'Gonzaguinha' }
                    ]}
                    onChange={setConvenio}
                  />
                  : <span>{dataModal.convenio}</span>
                }

              </div>

              {
                acaoModal === "editar" ?
                  <span onClick={() => {
                    setInfosModalAlerta(infosAlertaExcluir)
                    setModalAlerta(true);
                  }}>
                    <Trash2 />
                    Excluir paciente
                  </span> : ''
              }

              <footer>
                <main className="container">
                  <span onClick={() => {
                    // caso tenha modificações
                    if (!VerificarAlteracao() && acaoModal === "editar") {
                      setInfosModalAlerta(infosAlertaVoltar)
                      setModalAlerta(true);
                    }
                    else {
                      setModal(false)
                    }
                  }}>
                    <ArrowLeft />
                    Voltar
                  </span>

                  {
                    acaoModal === "editar" ?
                      (dataModal.nome === dataModalEditado.nome && dataModal.email === dataModalEditado.email && dataModal.idade === dataModalEditado.idade && dataModal.data_nasc === dataModalEditado.data_nasc && dataModal.sexo === dataModalEditado.sexo && dataModal.telefone === dataModalEditado.telefone && dataModal.hosp_clin === dataModalEditado.hosp_clin && dataModal.convenio === dataModalEditado.convenio) ?
                        <Button type="submit" disabled>
                          <Check />
                          Salvar alterações
                        </Button> :
                        <Button type="submit">
                          <Check />
                          Salvar alterações
                        </Button>
                      :
                      <Button type="submit" onClick={() => {
                        setAcaoModal("editar");
                      }}>
                        <Edit />
                        Editar
                      </Button>
                  }

                </main>
              </footer>

            </form>
          </div>

        </Modal>
      }

      {modalAlerta &&
        <ModalAlerta
          setModal={setModalAlerta}
          titulo={infosModalAlerta.titulo}
          info={infosModalAlerta.info}
          msgRejeicao={infosModalAlerta.msgRejeicao}
          msgConfirmacao={infosModalAlerta.msgConfirmacao}
          acaoBtnConfirmacao={() => setModal(false)}
        />
      }
    </div>
  )
}

export default MinhaNuvem
