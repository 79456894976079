//@ts-nocheck
import React, { SVGAttributes, HTMLAttributes } from "react"

interface DivProps extends HTMLAttributes<SVGPathElement> {
    color?: string;
}

function BackgroundTemplate3 ({ color, ...rest }: DivProps) {

    return (

        <svg viewBox="0 0 359 421" fill="none" xmlns="http://www.w3.org/2000/svg"
            style={{width: "70%", height: "100%", position: "absolute", top: "0", left: "0", pointerEvents: 'none'}}
        >
            <path {...rest} fill={color} opacity="0.1" d="M-34.8303 316.604C-55.7182 273.135 -68.8199 226.417 -60.5626 177.397C-55.4425 147.008 -41.4285 117.784 -20.033 95.558C2.63744 72.0089 34.3957 58.2515 63.3203 44.1565C96.4053 28.0289 131.29 15.5896 167.133 7.18574C186.549 2.63224 206.52 -0.757277 226.398 1.02078C268.854 4.81454 306.854 41.9047 324.819 79.1659C334.938 100.158 342.84 122.213 348.45 144.826C362.024 199.513 360.979 256.688 350.041 311.639C342.968 347.174 331.122 381.729 315.053 414.169C286.757 471.274 246.941 533.428 173.829 518.424C147.606 513.041 123.716 499.505 101.886 483.964C41.8904 441.238 -3.09305 382.648 -34.8303 316.604Z"/>
            <path {...rest} fill={color} opacity="0.15" d="M149.962 484.067C170.375 486.91 191.327 481.963 209.964 467.654C240.603 444.132 262.319 405.993 278.458 371.504C302.45 320.227 305.215 263.38 283.18 210.753C268.419 175.506 239.727 132.671 202.364 118.993C174.737 108.877 143.31 113.246 115.871 121.591C93.5115 128.391 72.2817 138.391 51.1418 148.354C35.6066 155.678 19.8915 163.114 6.65519 174.065C-34.4982 208.109 -24.76 270.709 -6.29159 314.232C15.927 366.593 59.3333 426.973 102.939 462.637C117.245 474.343 133.428 481.76 149.962 484.067Z" />
            <path {...rest} fill={color} opacity="0.18" d="M48.1023 306.76C47.5829 327.331 50.2684 348.516 54.7525 367.966C59.0768 386.732 67.6152 405.506 82.1484 418.56C95.1146 430.207 114.064 431.07 130.54 430.943C150.071 430.79 169.557 426.92 187.635 419.549C193.018 417.355 198.442 414.734 202.24 410.329C216.88 393.364 196.39 346.342 191.06 328.357C178.902 287.32 163.736 230.834 134.972 197.947C130.283 192.586 124.168 188.189 117.18 186.873C93.0431 182.323 77.8714 211.673 68.6878 228.983C60.3868 244.626 54.3817 261.499 51.01 278.9C49.2623 287.904 48.3426 297.268 48.1023 306.76Z" />
            <defs>
                <linearGradient id="paint0_linear" x1="49.2895" y1="-6.41006" x2="51.436" y2="130.331" gradientUnits="userSpaceOnUse">
                <stop stopColor={color}/>
                <stop offset="1" stopColor={color} stopOpacity="0"/>
                </linearGradient>
            </defs>
        </svg>


        // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130 132" fill="none"
        //     style={{width: "100%", height: "100%", position: "absolute", top: "0", left: "0"}}
        // >
        //     <path {...rest} opacity="0.3" d="M-4.2058 26.5229L-7.80562 27.5444C-29.2446 33.6284 -44.5462 52.5305 -46.0327 74.7664C-48.0899 105.54 -23.4174 131.506 7.42139 131.022L80.5097 129.874C120.393 129.248 152.218 96.4087 151.592 56.525L151.232 33.6224C151.038 21.2473 144.085 9.97165 133.114 4.24221C119.909 -2.65438 103.743 -0.0747454 93.3399 10.5892L88.8999 15.1406C80.0812 24.1804 67.6841 28.8061 55.0993 27.7525L16.4866 24.5199C9.52483 23.9371 2.51499 24.6156 -4.2058 26.5229Z" fill={color} fillOpacity="0.4"/>
        //     <defs>
        //         <linearGradient id="paint0_linear" x1="49.2895" y1="-6.41006" x2="51.436" y2="130.331" gradientUnits="userSpaceOnUse">
        //         <stop stopColor={color}/>
        //         <stop offset="1" stopColor={color} stopOpacity="0"/>
        //         </linearGradient>
        //     </defs>
        // </svg> // #005596
    )
}

export default BackgroundTemplate3