import api from '../servidor/api'

interface MedicoUpdateProps {
  nome?: string;
  telefone?: string;
  email?: string;
  //senha?: string;
  img_perfil?: string;
  medico?: {
    especialidade?: string;
  }
}

interface MedicoUpdateAdminProps {
  nome?: string;
  id?: number;
  email?: string;
  //senha?: string;
  img_perfil?: string;
  medico?: {
    especialidade?: string;
  }
}

interface ResidenteUpdateAdminProps {
  nome?: string;
  id?: number;
  email?: string;
  //senha?: string;
  img_perfil?: string;
  residente?: {
    instituicao?: string;
  }
}

interface ResidenteUpdateProps {
  nome?: string,
  telefone?: string,
  email?: string,
  //senha?: string;
  img_perfil?: string;
  sexo?: string;
  residente?: {
    curso?: string,
    instituicao?: string
  }
}

interface LoginProps {
  email: string,
  password: string,
}

interface LoginReturn {
  sucess: boolean,
  messenger: string,
  type: string
}
interface UserReturn {
  token: string,
  typeUser: string
}

interface PacienteProps {
  nome?: string;
  sexo?: string;
  dt_nascimento?: string;
  convenio?: string;
  senha?: string;
}

interface templateUpdateProps  {
  id?: number,
  id_pessoa?: number,
  is_favorito?: boolean,
  nome?: string,
  conteudo?: {
    cabecalho?: {
      clinica?: string,
      endereco?: string,
      telefone?: string
    },
    backgroundTemplate?: string,
    color?: string,
    aparelho?: string,
    comImg?: string,
    icon?: string
  }
};

interface PasswordRecoveryProps {
  email: string
}

interface ResetPasswordProps {
  token: string; 
  senha: string;
  id: number;
}
interface IDataLaudo {
  conteudo: {
    sedoanalgesia: string,
    fentanil: string;
    midazolan: string;
    propofol: string;
    cricofaringeo: string;
    esofago: string;
    estomago: string;
    duodeno: string;
    conclusao: string;
    notas: string;
    id_paciente: number,
    nome: string,
    data_nasc: string,
    idade: number,
    sexo: string,
    convenio: string,
    medico_solicitante: string,
    dt_exame: string,
    indicacoes: string,
    aparelho: string
  }
  // dados para estatistica
  /*
  exame_realizado: string;
  diagnostico: string[];
  procedimento_terapeutico: string[];
  indicacoes: string;
  */
}

export async function isAuthenticated() {
  return true;
};

export function isAdmin() {
  return false;
};

export async function UserLogin({ email, password }: LoginProps): Promise<LoginReturn> {
  const params = {
    email: email,
    senha: password
  }
  const response = await api.post('login', params)
    .then((res) => {
      SaveToken(res.data)
      console.log("Login realizado com sucesso!");
      return {
        sucess: true,
        messenger: "Login realizado com sucesso!",
        type: res.data.role
      }
    })
    .catch((err) => {
      console.log(err.response)
      return {
        sucess: false,
        messenger: "Falha ao realizar login.",
        type: "erro"
      }
    })
  return response
}

export async function SaveToken(token: any) {
  localStorage.setItem('user', JSON.stringify(token));
}

export async function LogoutUser() {
  localStorage.clear()
  return true
}

export async function GetToken(): Promise<UserReturn> {
  const userStr = localStorage.getItem('user');

  if (userStr) {
    const userObj = JSON.parse(userStr)
    const token = userObj.accessToken
    const typeUser = userObj.role
    //console.log(userObj)
    if (token) {
      //console.log(token);
      return {
        token: token,
        typeUser: typeUser
      }
    }
  }
  return {
    token: '',
    typeUser: ''
  }
}

export async function GetUser(token: any): Promise<any> {

  const headers = {
    'Authorization': `${token}`
  }
  //console.log(headers)
  const response = await api.get('profile', {
    headers: headers
  })
  .then(res => {
    console.log(res.data)
    return res.data
  })
  .catch(err => {
    console.log(err.response)
    return err.response 
  })
  return response
}

export async function MedicoUpdate(data: MedicoUpdateProps | undefined): Promise<any> { // problema no update especialidade
  const { token, typeUser } = await GetToken()

  const headers = {
    'Authorization': token
  }

  const response = await api.put(`${typeUser}`, data, { headers: headers })
    .then(res => {
      console.log("deu certo")
      console.log(res)
      console.log(res.data)
    })
    .catch(err => {
      console.log(err.response)
    })

    return response
}

export async function ResidenteUpdate(data: ResidenteUpdateProps | undefined): Promise<any> {
  const { token } = await GetToken()

  const headers = {
    'Authorization': token
  }

  console.log("aqui")
  console.log(data)

  const response = await api.put("residente", data, { headers: headers })
    .then(res => {
      console.log("deu certo")
      console.log(res)
      console.log(res.data)
    })
    .catch(err => {
      console.log(err.response)
    })

    return response
}

export async function GetMedico(token: any): Promise<any> {

  const headers = {
    'Authorization': `${token}`
  }
  console.log(headers)
  const response = await api.get('medico', {
    headers: headers
  })
    .then(res => {
      console.log(headers);
      console.log(res.data)
      return res.data
    })
    .catch(err => {
      console.log("deu ruim");
      console.log(err.response)
      return err.response
    })
  return response
}

export async function GetResidente(token: any): Promise<any> {

  const headers = {
    'Authorization': `${token}`
  }
  console.log(headers)
  const response = await api.get('residente', {
    headers: headers
  })
    .then(res => {
      console.log(headers);
      console.log(res.data)
      return res.data
    })
    .catch(err => {
      console.log("deu ruim");
      console.log(err.response)
      return err.response
    })
  return response
}



export async function SetTemplateLaudo(conteudo: any, nome: any): Promise<any> {
  const { token } = await GetToken()

  //console.log("aqui")
  //console.log(token)

  const headers = {
    'Authorization': `${token}`
  }

  const response = await api.post('template', {conteudo, nome}, { headers: headers })
    .then(res => {
      console.log(res)
      return res
    })
    .catch(err => {
      console.log(err.response)
      return false
    })

    return response
}

interface templateInterface  {
  id_pessoa: number,
  is_favorito: boolean,
  nome: string,
  conteudo: {
    cabecalho: {
      clinica: string,
      endereco: string,
      telefone: string
    },
    backgroundTemplate: string,
    color: string,
    aparelho: string,
    comImg: string,
    icon: string
  }
};

export async function UpdateTemplateLaudo(data: templateUpdateProps): Promise<any> {
  const { token } = await GetToken()

  const headers = {
    'Authorization': `${token}`
  }

  const dataTemplateLaudo = {
    is_favorito: data.is_favorito,
    nome: data.nome,
    conteudo: {
      cabecalho: {
        clinica: data.conteudo?.cabecalho?.clinica,
        endereco: data.conteudo?.cabecalho?.endereco,
        telefone: data.conteudo?.cabecalho?.telefone
      },
      backgroundTemplate: data.conteudo?.backgroundTemplate,
      color: data.conteudo?.color,
      aparelho: data.conteudo?.aparelho,
      comImg: data.conteudo?.comImg,
      icon: data.conteudo?.icon
    }
  }

  const response = await api.put(`template/${String(data.id)}`, dataTemplateLaudo, { headers: headers })
    .then(res => {
      return res
    })
    .catch(err => {
      console.log(err.response)
      return false
    })  
  
  return response

}

export async function GetTemplateLaudo(): Promise<templateInterface[]> {
  const { token } = await GetToken()

  const headers = {
    'Authorization': `${token}`
  }

  const response = await api.get('medico/templates', { headers: headers })
    

  return response.data
}

export async function GetOneTemplateLaudo(id: any): Promise<templateInterface> {
  const { token } = await GetToken()

  const headers = {
    'Authorization': `${token}`
  }

  const response = await api.get(`template/${id}`, { headers: headers })
    
  return response.data
}

export async function SetPaciente(data: PacienteProps): Promise<any> {
  const { token } = await GetToken()

  const headers = {
    'Authorization': `${token}`
  }

  const response = await api.post('paciente', data, { headers: headers })
    .then(res => {
      console.log(res)
      return res
    })
    .catch(err => {
      console.log(err.response)
      return false
    })

    return response
}

interface FilterStatsQtdExames {
  datainicio: string;
  datafim: string;
}

interface Patient {
  nome: string;
  sexo: string;
  dt_nascimento: string;
  id: string;
  convenio: string;
} 

export async function GetPaciente(strNome: string): Promise <any> {
  const { token } = await GetToken()

  const headers = {
    'Authorization': `${token}`
  }

  const response = await api.get('/medico/pacientes', { 
    headers: headers,
    params: {
      nome: strNome
    }
  })

  return response.data
}

export async function UpdatePaciente(paciente: Patient){
  const { token } = await GetToken()

  const headers = {
    'Authorization': `${token}`
  }

  const response = await api.put(`/paciente/${paciente.id}`, paciente, { 
    headers: headers,
  })

  return response.data
}

export async function GetStats(type: string, data: FilterStatsQtdExames): Promise<any> {
  const { token } = await GetToken()

  const headers = {
    'Authorization': `${token}`
  }

  const response = await api.post(`estatistica/${type}`, data, { headers: headers })
    .then(res => {
      console.log(res)
      return res.data
    })
    .catch(err => {
      console.log(err.response)
    })

  return response
}

export async function MedicoUpdateAdmin(data: MedicoUpdateAdminProps): Promise<any> { // problema no update especialidade
  const { token } = await GetToken()

  const headers = {
    'Authorization': token
  }

  const dataMedico = {
    nome: data.nome,
    email: data.email,
  }

  const response = await api.put(`admin/medico/${String(data.id)}`, dataMedico, { headers: headers })
    .then(res => {
      console.log("deu certo")
      console.log(res)
      console.log(res.data)
    })
    .catch(err => {
      console.log(err.response)
    })

    return response
}

export async function ResidenteUpdateAdmin(data: ResidenteUpdateAdminProps): Promise<any> { // problema no update especialidade
  const { token } = await GetToken()

  const headers = {
    'Authorization': token
  }

  const dataResidente = {
    nome: data.nome,
    email: data.email,
  }

  const response = await api.put(`admin/residente/${String(data.id)}`, dataResidente, { headers: headers })
    .then(res => {
      console.log("deu certo")
      console.log(res)
      console.log(res.data)
    })
    .catch(err => {
      console.log(err.response)
    })

    return response
}

export async function SubmitPasswordRecovery(data: PasswordRecoveryProps): Promise<any>{

  return api.post(`recuperacaoSenha/requisicao/`, data)
  .then((response)=>{return {success:true, message: response.data}})
  .catch((response)=>{return {success:false, message: "Não foi possível enviar o email de recuperação para o seu email"}})
}

export async function resetPassword(data: ResetPasswordProps): Promise<any>{
  return api.post(`recuperacaoSenha/restauracao/`, data)
  .then((response)=>{return {success:true, message: response.data}})
  .catch((response)=>{return {success:false, message: "Não foi possível restaurar a sua senha"}})
}

export async function SetLaudo(data: IDataLaudo): Promise<any> {
  const { token } = await GetToken();

  const headers = {
    'Authorization': `${token}`
  }

  const response = await api.post('laudo', data, { headers: headers })
    .then(res => {
      console.log(res)
      return true
    })
    .catch(err => {
      console.log(err.response)
      return false
    })

    return response
}

interface LaudoExpress {
  nome: string,
  id: string,
}

export async function GetAllLaudoExpress(): Promise<LaudoExpress[]>{
  const { token } = await GetToken()

  const headers = {
    'Authorization': `${token}`
  }

  const response = await api.get(`/laudoExpress`, { 
    headers: headers,
  })

  return response.data;
}

export async function GetLaudoExpress(id: string){
  const { token } = await GetToken()

  const headers = {
    'Authorization': `${token}`
  }

  const response = await api.get(`/laudoExpress/${id}`, { 
    headers: headers,
  })

  return response.data;
}

interface ISendLaudo {
  email: string;
  laudo: string | ArrayBuffer | null;
}

export async function SendLaudo(data: ISendLaudo): Promise<any> {
  const { token } = await GetToken();

  const headers = {
    'Authorization': `${token}`
  }

  const response = await api.post('/mail/laudo', data, { headers: headers })
    .then(res => {
      console.log(res)
      return true
    })
    .catch(err => {
      console.log(err.response)
      return false
    })

    return response
}

export async function GetSenhaPaciente(id: string){
  const { token } = await GetToken()

  const headers = {
    'Authorization': `${token}`
  }

  const response = await api.put(`/paciente/${id}/newpassword`, {} , { 
    headers: headers,
  })

  return response.data
}