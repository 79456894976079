//@ts-nocheck
import React from 'react'

function Background() {
  return (
    <svg viewBox="0 0 1366 652" fill="none" xmlns="http://www.w3.org/2000/svg"
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1,
      }}
    >
      <path opacity="0.2" d="M219.36 189.135L189.197 197.14C21.1882 241.727 -100.613 387.173 -115.009 560.4C-134.923 800.021 54.1872 1005.5 294.635 1005.5H874.326C1185.08 1005.5 1437 753.582 1437 442.826V265.034C1437 167.688 1383.59 78.1921 1297.91 31.9829C1195.46 -23.268 1068.95 -5.14084 986.141 76.6538L950.539 111.82C880.197 181.301 782.576 215.865 684.184 206.126L380.996 176.116C326.773 170.749 272.025 175.159 219.36 189.135Z" fill="url(#paint0_linear)" fillOpacity="0.6" />
      <defs>
        <linearGradient id="paint0_linear" x1="642.5" y1="-61" x2="642.5" y2="1005.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5EDCDA" />
          <stop offset="1" stopColor="#5EDCDA" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Background
