import React, { useContext, useEffect, useState } from 'react';
import { ArrowRightCircle, HelpCircle, Search } from 'react-feather';
import Background from '../../assets/Background';
import Button from '../../components/Button';
import Dropdown from '../../components/Dropdown';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Input from '../../components/Input';
import Radio from '../../components/Radio';
import './styles.css'

import { LaudoContext } from '../../utils/LaudoProvider';
import { convenios } from '../../utils/dados/convenios';
import { indicacoesLista } from '../../utils/dados/indicacoesLista';
import { GetAllLaudoExpress, GetLaudoExpress, GetSenhaPaciente, GetPaciente, SetPaciente, UpdatePaciente } from '../../utils/auth'
import { mascNascimento, calculaIdade } from '../../utils/metodos/dataIdade'

import Checkbox from '../../components/Checkbox';
import { useHistory } from 'react-router';

interface Patient {
  nome: string;
  sexo: string;
  dt_nascimento: string;
  id: string;
  convenio: string;
}

interface LaudoExpress {
  nome: string,
  id: string,
}

interface LaudoExpress {
  nome: string,
  id: string,
}

function NovoLaudo() {
  const [laudoCtx, setLaudoCtx] = useContext(LaudoContext);

  const [search, setSearch] = useState("");

  const [alerta, setAlerta] = useState(false);

  // informações do paciente e laudo
  const [infoPatient, setInfoPatient] = useState<Patient>(
    {
      nome: !laudoCtx.finalizado ? laudoCtx.paciente.nome : '',
      sexo: !laudoCtx.finalizado ? laudoCtx.paciente.sexo : '',
      dt_nascimento: !laudoCtx.finalizado ? laudoCtx.paciente.dt_nascimento : '',
      id: !laudoCtx.finalizado ? laudoCtx.paciente.id : '',
      convenio: !laudoCtx.finalizado ? laudoCtx.paciente.convenio : ''
    })

  const [idade, setIdade] = useState(laudoCtx.finalizado ? "" : laudoCtx.paciente.idade);
  const [convenio, setConvenio] = useState(
    laudoCtx.finalizado ? {
      label: 'Selecione seu convênio',
      value: 'Selecione seu convênio'
    } : {
      label: laudoCtx.paciente.convenio,
      value: laudoCtx.paciente.convenio
    }
  );
  const [medico_solicitante, setMedico_solicitante] = useState(laudoCtx.finalizado ? "" : laudoCtx.paciente.medico_solicitante);
  const [exame, setExame] = useState(laudoCtx.finalizado ? "EDA" : laudoCtx.paciente.exame);
  const [indicacoes, setIndicacoes] = useState(
    laudoCtx.finalizado ? {
      label: 'Selecione indicações',
      value: 'Selecione indicações'
    } : {
      label: laudoCtx.paciente.indicacoes,
      value: laudoCtx.paciente.indicacoes
    }
  );
  const [indicacoesOutros, setIndicacoesOutros] = useState(false);
  const [indicacoesOutrosText, setIndicacoesOutrosText] = useState("");
  const [tipoLaudo, setTipoLaudo] = useState("");
  const [laudoExpress, setLaudoExpress] = useState(
    {
      label: 'Selecione o laudo express',
      value: 'Selecione o laudo express'
    }
  );
  const [listLaudoExpress, setListLaudoExpress] = useState<LaudoExpress[]>([]);

  const [dataPaciente, setDataPaciente] = useState<Patient[]>([]);


  async function searchPatient() {
    if (search) {
      const getPatientByName = async () => {
        try {
          const response = await GetPaciente(search)
          setDataPaciente(response)
          if (response.length !== 0) {
            setAlerta(false)
          } else {
            setAlerta(true)
          }
        }
        catch (err) {
        }
      }
      getPatientByName()
    }
    else {
      setDataPaciente([])
    }
  }

  function selectPatient(patient: Patient) {
    setInfoPatient({
      convenio: patient.convenio,
      dt_nascimento: patient.dt_nascimento.slice(0, 10),
      id: patient.id,
      nome: patient.nome,
      sexo: patient.sexo
    })
    setIdade(calculaIdade(mascNascimento(patient.dt_nascimento)))
    setConvenio({
      label: patient.convenio,
      value: patient.convenio
    })
  }

  // Atualiza os dados do Paciente
  async function updatePatient() {
    const atualizarPaciente = async () => {
      try {
        await UpdatePaciente(infoPatient);
      } catch (err) {
      }
    }
    atualizarPaciente();
  }

  async function getPasswordPatient() {
    try {
      const senha = await GetSenhaPaciente(infoPatient.id);
      return senha;
    } 
    catch (err) {
    }
  }

  // Adicionar novo paciente
  async function createPatient() {
    try {
      const response = await SetPaciente(infoPatient);
      return response.data;
    } 
    catch (err) {

    }
  }

  const history = useHistory();

  async function setNovoLaudoToContext() {
    let senha = '';
    let id = '';

    if (infoPatient.id != '') {
      updatePatient();
      senha = await getPasswordPatient();
      id = infoPatient.id;
    } else {
      let data = await createPatient();
      
      senha = data.senha;
      id = data.id;
    }

    let dadosCtx = {
        paciente: {
        ...infoPatient,
        data_nasc: infoPatient.dt_nascimento,
        idade,
        convenio: convenio.value === "Selecione seu convênio" ? "" : convenio.value,
        medico_solicitante,
        dt_exame: new Date().toLocaleDateString().toString(),
        indicacoes: indicacoesOutros ? indicacoesOutrosText : indicacoes.value,
        exame,
        senha,
        id
      },
      finalizado: false,
    }
    
    let isLaudoExpress = tipoLaudo === "laudo-express";
    if (isLaudoExpress) {
      // fazendo req para obter conteudo de laudo express
      let { conteudo } = await GetLaudoExpress(laudoExpress.value);
      setLaudoCtx({
        ...laudoCtx,
        ...dadosCtx,
        laudo: {
          ...conteudo
        },
        isLaudoExpress: tipoLaudo === "laudo-express"
      })
      history.push("/novo-laudo/visualizacao-de-laudo");
    }
    else {
      setLaudoCtx({
        ...laudoCtx,
        ...dadosCtx,
      })
      history.push("/novo-laudo/laudo-personalizado");
    }
  }

  useEffect(() => {
    GetAllLaudoExpress()
      .then(data => {
        setListLaudoExpress(data);
      })
      .catch(error => console.log(error));
  }, [])

  return (

    <div className="page-novo-laudo">

      <Background />
      <Header name="Paciente para Laudo" />

      <main>
        <div className="pesquisar-paciente">
          <label>Procurar Pacientes</label>
          <Input
            name="search-patient-2"
            type="search" placeholder="Nome do paciente"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            icon={<Search color="#74799C" />}
          />
          <span className="search-icon" onClick={() => {
            searchPatient()
          }}>
            <Search style={{ color: "#fff" }} />
          </span>
        </div>

        {
          alerta ?
            <p className="alert">Esse paciente não foi encontrado</p> : ''
        }
        {
          dataPaciente.length !== 0 ?
            <p>{dataPaciente.length
            } pacientes encontrados</p> : ''
        }
        <div className='page-novo-laudo-lista-paciente' style={{ height: dataPaciente.length !== 0 ? '300px' : '0px' }} >
          {
            dataPaciente.length !== 0 ?
              dataPaciente.map((paciente, i) => {
                return (
                  <span key={i}>
                    <section className="info-paciente-pesquisado">
                      <div>
                        <label>Nome:</label>
                        <span>{paciente.nome}</span>
                      </div>
                      <div>
                        <label>Sexo:</label>
                        <span>{paciente.sexo}</span>
                      </div>
                      <div>
                        <label>Data de Nascimento</label>
                        <span>{mascNascimento(paciente.dt_nascimento)}</span>
                      </div>
                      <div className="flex" style={{ columnGap: 80 }}>
                        <div>
                          <label>Idade:</label>
                          <span>{calculaIdade(mascNascimento(paciente.dt_nascimento))}</span>
                        </div>
                        <div>
                          <label>Id:</label>
                          <span>{paciente.id}</span>
                        </div>
                      </div>
                      <div>
                        <label>Convênio:</label>
                        <span>{paciente.convenio}</span>
                      </div>
                    </section>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: '-50px',
                      marginBottom: '20px'
                    }}>
                      <Button
                        style={{
                          width: 200,
                          padding: '12px 0'
                        }}
                        onClick={() => { selectPatient(paciente); setDataPaciente([]); setSearch('') }}
                      >Escolher paciente</Button>
                    </div>
                  </span>
                )
              }) : ''
          }
        </div>

        <section>
          <div>
            <label>Nome*</label>
            <Input
              name="name-patient"
              type="text" placeholder="Nome Sobrenome"
              onChange={(e) => setInfoPatient({ ...infoPatient, nome: e.target.value })}
              value={infoPatient?.nome}
            />
          </div>

          <div style={{ opacity: infoPatient?.id !== "0" ? 1 : 0 }}>
            <label>Id do Paciente</label>
            <span>{infoPatient?.id}</span>
          </div>
          <div>
            <label>Data de Nascimento*</label>
            <Input
              name="data-nasc"
              type="date"
              onChange={(e) => { setInfoPatient({ ...infoPatient, dt_nascimento: e.target.value }); setIdade(calculaIdade(mascNascimento(e.target.value))) }}
              value={infoPatient?.dt_nascimento}
            />
          </div>

          <div style={{ width: 150 }}>
            <label>Idade*</label>
            <Input
              disabled
              name="age-patient"
              type="text" placeholder="00"
              onChange={(e) => setIdade(e.target.value)}
              value={idade}
            />
          </div>
          <div>
            <label>Convênio</label>
            <Dropdown
              placeholder="Selecione seu convênio"
              noOptionsMsg="Nenhum convênio encontrado"
              options={
                convenios.map(item => {
                  return {
                    label: item,
                    value: item,
                  }
                })
              }
              value={{
                label: infoPatient?.convenio ? infoPatient.convenio : '',
                value: infoPatient?.convenio ? infoPatient.convenio : ''
              }}
              onChange={(cur: any) => { setConvenio(cur); setInfoPatient({ ...infoPatient, convenio: cur?.value }) }}
            />
          </div>
          <div>
            <label>Sexo*</label>
            <Radio id="masc" label="M" state={infoPatient.sexo === "Masculino"} onChangeRadio={() => setInfoPatient({ ...infoPatient, sexo: 'Masculino' })} />
            <Radio id="fem" label="F" state={infoPatient.sexo === "Feminino"} onChangeRadio={() => setInfoPatient({ ...infoPatient, sexo: 'Feminino' })} />
          </div>
        </section>

        <div style={{ width: 510 }}>
          <label style={{ flex: 'none' }}>Médico Solicitante</label>
          <Input
            name="medico-solicitante"
            type="text" placeholder="Digite o nome do médico solicitante"
            onChange={(e) => setMedico_solicitante(e.target.value)}
            value={medico_solicitante}
          />
        </div>

        <div>
          <label>Exame*</label>
          <Radio id="EDA" label="EDA" state={exame === "EDA"} onChangeRadio={() => setExame("EDA")} />
          <Radio id="Colonoscopia" label="Colonoscopia" state={exame === "Colonoscopia"} onChangeRadio={() => {
            setExame("Colonoscopia");
            setTipoLaudo("laudo-express");
          }} />
        </div>

        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: 40 }}>
          <div className="flex align-center">
            <label>Indicações*</label>
            {
              indicacoesOutros ?
                <Input
                  name="indicacoes"
                  type="text" placeholder="Digite as indicações"
                  onChange={(e) => setIndicacoesOutrosText(e.target.value)}
                  value={indicacoesOutrosText}
                />
                :
                <Dropdown
                  placeholder="Selecione indicações"
                  noOptionsMsg="Nenhuma indicação encontrada"
                  options={
                    indicacoesLista.map(item => {
                      return {
                        label: item,
                        value: item,
                      }
                    })
                  }
                  value={indicacoes}
                  onChange={setIndicacoes}
                />
            }
          </div>

          <div className="flex align-center">
            <Checkbox
              id={'indicacoes-outros'}
              state={indicacoesOutros}
              setState={() => setIndicacoesOutros(!indicacoesOutros)}
            />
            <label>Outros</label>
          </div>

        </div>

        <div className="tipo-laudo">
          {
            exame === "EDA" ?
              <>
                <div>
                  <Radio id="laudo-personalizado" label="Gerar laudo personalizado" state={tipoLaudo === "laudo-personalizado"} onChangeRadio={() => setTipoLaudo("laudo-personalizado")} />
                  <HelpCircle />
                </div>
              </> : ''
          }
          <div>
            <Radio id="laudo-express" label="Laudo Express" state={tipoLaudo === "laudo-express"} onChangeRadio={() => setTipoLaudo("laudo-express")} />
            <HelpCircle />
          </div>

          {
            tipoLaudo === "laudo-express" ?
              <section style={{ width: 360 }}>
                <Dropdown
                  placeholder="Selecione o laudo express"
                  noOptionsMsg="Nenhum laudo express encontrado"
                  options={
                    listLaudoExpress.map(item => {
                      return {
                        label: item.nome,
                        value: item.id,
                      }
                    })
                  }
                  value={laudoExpress}
                  onChange={setLaudoExpress}
                />
              </section> : ''
          }
        </div>

      </main>

      <Footer
        name="Voltar"
        link_back="/"
        name_btn="Avançar" icon_btn={<ArrowRightCircle />}
        onClick={() => setNovoLaudoToContext()}
        state_btn={
          infoPatient.nome !== "" &&
          infoPatient.dt_nascimento !== "" &&
          idade !== "" &&
          infoPatient.sexo !== '' &&
          (
            indicacoes.value !== "Selecione indicações" ||
            (indicacoesOutros && indicacoesOutrosText !== "")
          )
        }
      />
    </div>


  );
}

export default NovoLaudo;
