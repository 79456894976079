import React, {useContext, useEffect} from 'react'
import { Route, Switch, useParams, useHistory, Redirect, RouteProps } from 'react-router-dom'

import Login from './pages/Login'
import Inicio from './pages/Home'
import MeusDados from './pages/MeusDados'
import EditarDados from './pages/MeusDados/EditarDados'
import MeuPlano from './pages/MeuPlano'
import Ajuda from './pages/Ajuda'
import Admin from './pages/Admin'
import MinhaNuvem from './pages/MinhaNuvem'
import NovoPaciente from './pages/Consulta/NovoPaciente'
import PacienteCadastrado from './pages/Consulta/PacienteCadastrado'
import NovoLaudo from './pages/NovoLaudo'
import LaudoPersonalizado from './pages/NovoLaudo/LaudoPersonalizado'
import Estatisticas from './pages/Estatisticas'
import VisualizacaoLaudo from './pages/NovoLaudo/VisualizacaoLaudo';
import LaudoFinalizado from './pages/NovoLaudo/VisualizacaoLaudo/LaudoFinalizado';
import CriarLaudo from "./pages/CriarLaudo"
import EditarLaudo from "./pages/EditarLaudo"
import EsqueciMinhaSenha from "./pages/EsqueciMinhaSenha" 
import RestaurarSenha from "./pages/RestaurarSenha"

import { LaudoProvider } from './utils/LaudoProvider';
//import PacienteConsulta from './pages/PacienteCosulta'
import BlogListPostAdmin from './pages/BlogListPostAdmin'
import BlogCreateNewPost from './pages/BlogCreateNewPost'
import BlogEditPost from './pages/BlogEditPost'
import { NavbarContext } from './providers/NavbarProvider'
import { GetToken, GetUser } from './utils/auth'
import { ProtectedRouteAdmin, ProtectRouterProps, ProtectedRouteMedico } from './utils/protectedRoute'

function Routes() {
  
  const [navbarInfo, setNavbarInfo] = useContext(NavbarContext);
  
  const history = useHistory();
  
  const defaultProtectedRoute: ProtectRouterProps = {
    isAuthenticated: navbarInfo.logado,
    typeUser: navbarInfo.roleUser,
    redirectRouter: !navbarInfo.logado ? '/login' : (navbarInfo.roleUser === 'admin' ? '/admin/blog' : '/home')
  } 

  useEffect( () => {
    const verificarUsuario = async () => {
      const { token, typeUser } = await GetToken()
      if(token){
        const user = await GetUser(token)
        if(user){
          setNavbarInfo({
            ...navbarInfo,
            nome: user.nome,
            img_perfil: user.img_perfil,
            logado: true,
            roleUser: typeUser,
            laudoId:  user.id_template_favorito
          })
          return
        }
      }
      setNavbarInfo({
        ...navbarInfo,
        nome: '',
        img_perfil: '',
        logado: false,
        roleUser: '',
        laudoId: ''
      })
    }
    verificarUsuario()
  }, [])

  return (
    <Switch>
      {/* START - Public Router*/}

      <Route component={Login} path="/login" exact />
      <Route component={EsqueciMinhaSenha} path="/esqueci-minha-senha" exact />
      <Route component={RestaurarSenha} path="/restaurar-senha" exact />
      {/* END - Public Router */}

      {/* START - Blog Router */}
      <Route component={BlogListPostAdmin} path="/admin/blog" exact />
      <Route component={BlogCreateNewPost} path="/admin/blog/nova-postagem" exact />
      <Route component={EditPost} path="/admin/blog/post/:id" exact />
      {/* Blog Router */}


      {/* START - ADMIN router */}
      <ProtectedRouteAdmin {...defaultProtectedRoute} exact path="/admin/blog" component={BlogListPostAdmin} />
      <ProtectedRouteAdmin {...defaultProtectedRoute} exact path="/admin/blog/nova-postagem" component={BlogCreateNewPost} />
      <ProtectedRouteAdmin {...defaultProtectedRoute} exact path="/admin/blog/post/:id" component={EditPost} />
      {/* END - ADMIN router */}

      {/* START - Medicos router / Residentes router */}
      <ProtectedRouteMedico {...defaultProtectedRoute} component={Inicio} path="/home" exact />
      <ProtectedRouteMedico {...defaultProtectedRoute} component={Inicio} path="/" exact />
      <ProtectedRouteMedico {...defaultProtectedRoute} component={MeusDados} path="/meus-dados" exact />
      <ProtectedRouteMedico {...defaultProtectedRoute} component={EditarDados} path="/meus-dados/editar" exact />
      <ProtectedRouteMedico {...defaultProtectedRoute} component={MeuPlano} path="/meu-plano" exact />
      <ProtectedRouteMedico {...defaultProtectedRoute} component={Ajuda} path="/ajuda" exact />
      <ProtectedRouteMedico {...defaultProtectedRoute} component={Admin} path="/admin" exact />
      <ProtectedRouteMedico {...defaultProtectedRoute} component={MinhaNuvem} path="/minha-nuvem" exact />
      <ProtectedRouteMedico {...defaultProtectedRoute} component={NovoPaciente} path="/novo-paciente-consulta" exact />
      <ProtectedRouteMedico {...defaultProtectedRoute} component={PacienteCadastrado} path="/paciente-cadastrado-consulta" exact />
      <ProtectedRouteMedico {...defaultProtectedRoute} component={Estatisticas} path="/estatisticas" exact />
      <ProtectedRouteMedico {...defaultProtectedRoute} component={() => <CriarLaudo title={'Criar template de Laudo'} />} path="/criar-laudo" exact />
      <ProtectedRouteMedico {...defaultProtectedRoute} component={EditLaudo} path="/editar-laudo/:id" exact />
      <LaudoProvider>
        <ProtectedRouteMedico {...defaultProtectedRoute} component={NovoLaudo} path="/novo-laudo" exact />
        <ProtectedRouteMedico {...defaultProtectedRoute} component={LaudoPersonalizado} path="/novo-laudo/laudo-personalizado" exact />
        <ProtectedRouteMedico {...defaultProtectedRoute} component={VisualizacaoLaudo} path="/novo-laudo/visualizacao-de-laudo" exact />
        <ProtectedRouteMedico {...defaultProtectedRoute} component={LaudoFinalizado} path="/novo-laudo/visualizacao-de-laudo/conclusao-laudo" exact />
      </LaudoProvider>
      {/*  END - Medicos router / Residentes router */}      
    </Switch>
  )
}

function EditLaudo() {
  const params: any = useParams()
  return (
    <CriarLaudo id={params.id} title={'Editar template de Laudo'}/>
  )
}

function EditPost() {
  const id : any = useParams()
  return (
    <BlogEditPost id={id} />
  )
}

export default Routes
